<template>
  <div class="box">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>提交管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">提交批改</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px;min-height:800px">
      <TableTittle :tittle="TableName[0]"></TableTittle>
      <div class="dataForm">
        <el-form :model="dataForm"
                 label-position="left"
                 ref="dataForm"
                 :rules="rules"
                 label-width="60px"
                 size="small">
          <el-form-item label="分数"
                        prop="score">
            <el-input v-model="dataForm.correct_score"
                      placeholder="请输入分数"
                      style="width:150px"></el-input>
            <span class="score">分</span>
          </el-form-item>
          <el-form-item label="评语">
            <el-input type="textarea"
                      v-model="dataForm.correct_comment"
                      placeholder="请输入评语"
                      autocomplete="off"
                      style="width:400px"></el-input>
          </el-form-item>
          <el-form-item label="解析">
            <UEeditor :setDataStr="dataForm.correct_explain"
                      @change="initDtaForm"></UEeditor>
          </el-form-item>

        </el-form>
        <div slot="footer"
             class="footer">
          <el-button @click="cancle">取 消</el-button>
          <el-button type="primary"
                     @click="submit('dataForm')">确 定</el-button>
        </div>
      </div>
    </el-card>

  </div>
</template>
<script>
import UEeditor from '@/components/UEeditor'
import { adminUrl } from '@/utils/helper.js'
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
export default {
  components: { UEeditor, TableTittle },
  data() {
    return {
      e: '',
      TableName: ['提交批改'],
      dataForm: {},
      correct_score: '',
      rules: {
        // score: [{ required: true, message: '请输入分数', trigger: 'blur' }],
      },
    }
  },
  methods: {
    initDtaForm(e) {
      this.e = e
      console.log('e', e, this.dataForm.correct_explain)
    },
    emitClose() {},
    submit() {
      this.$refs['dataForm'].validate((val) => {
        if (val) {
          let str = adminUrl()
          this.$axios({
            url: `${str}/api/v1/back/measureScroll/userMeasureScroll/correct`,
            method: 'post', //可以省略 不写默认是get
            data: {
              id: this.$route.params.id,
              is_correct_access: 1,
              correct_score: this.dataForm.correct_score,
              correct_comment: this.dataForm.correct_comment,
              correct_explain: this.e,
            },
          }).then((res) => {
            this.$message({
              message: '提交成功',
              type: 'sucess',
            })
            this.$router.push('/testPaper/testMangent')
          })
        }
      })
    },
    cancle() {
      this.$router.push('/testPaper/testMangent')
    },
  },
}
</script>
<style lang="less" scoped>
.dataForm {
  position: relative;
  margin-top: 40px;
  // width: 400px;
  background: #fff;
  .score {
    position: absolute;
    left: 120px;
  }
  .footer {
    display: flex;
    justify-content: flex-start;
    margin-left: 50px;
  }
}
</style>