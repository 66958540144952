<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/overview' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>招生</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/recruitstudents/administration' }"
          >招生管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>跟进记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card class="operation-card">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
      >
      </el-form>
      <ScreenRecord></ScreenRecord>
    </el-card>
    <el-card style="margin-top: 10px">
      <TableTittle :tittle="TableName"></TableTittle>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column prop="status" label="学员状态" width="172">
        </el-table-column>
        <el-table-column
          prop="content"
          label="跟进内容"
          width="646"
          show-overflow-tooltip
          align="left"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.change == null">
              {{ scope.row.content }}</span
            >
            <div
              v-else-if="
                scope.row.change.before == null && scope.row.change.after
              "
            >
              <span>创建了此学员</span>
            </div>
            <span v-else>
              <span
                v-show="
                  scope.row.change.after.nickname ||
                  scope.row.change.before.nickname
                "
                >将&nbsp;<span class="changename">昵称</span
                ><span class="changetext"
                  >&nbsp;{{
                    scope.row.change.before.nickname | fomateAll
                  }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.nickname | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.realname ||
                  scope.row.change.before.realname
                "
                >将&nbsp;<span class="changename">姓名</span
                ><span class="changetext"
                  >&nbsp;{{
                    scope.row.change.before.realname | fomateAll
                  }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.realname | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.phone || scope.row.change.before.phone
                "
                >将&nbsp;<span class="changename">手机号</span
                ><span class="changetext"
                  >&nbsp;{{ scope.row.change.before.phone | fomateAll }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.phone | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.wechat ||
                  scope.row.change.before.wechat
                "
                >将&nbsp;<span class="changename">微信号</span
                ><span class="changetext"
                  >&nbsp;{{ scope.row.change.before.wechat | fomateAll }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.wechat | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="scope.row.change.after.qq || scope.row.change.before.qq"
                >将&nbsp;<span class="changename">QQ号</span
                ><span class="changetext"
                  >&nbsp;{{ scope.row.change.before.qq | fomateAll }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.qq | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.tags || scope.row.change.before.tags
                "
                >将&nbsp;<span class="changename">学员标签</span
                ><span class="changetext"
                  >&nbsp;{{ scope.row.change.before.tags | fomateAll }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.tags | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.status ||
                  scope.row.change.before.status
                "
                >将&nbsp;<span class="changename">学员状态</span
                ><span class="changetext"
                  >&nbsp;{{ scope.row.change.before.status | fomateAll }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.status | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.apply_year ||
                  scope.row.change.before.apply_year
                "
                >将&nbsp;<span class="changename">考研年份</span
                ><span class="changetext"
                  >&nbsp;{{
                    scope.row.change.before.apply_year | fomateAll
                  }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.apply_year | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.apply_major_name ||
                  scope.row.change.before.apply_major_name
                "
                >将&nbsp;<span class="changename">意向专业</span
                ><span class="changetext"
                  >&nbsp;{{
                    scope.row.change.before.apply_major_name | fomateAll
                  }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.apply_major_name | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.apply_academy_name ||
                  scope.row.change.before.apply_academy_name
                "
                >将&nbsp;<span class="changename">意向学院</span
                ><span class="changetext"
                  >&nbsp;{{
                    scope.row.change.before.apply_academy_name | fomateAll
                  }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.apply_academy_name | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.apply_college_name ||
                  scope.row.change.before.apply_college_name
                "
                >将&nbsp;<span class="changename">意向院校</span
                ><span class="changetext"
                  >&nbsp;{{
                    scope.row.change.before.apply_college_name | fomateAll
                  }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.apply_college_name | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.products ||
                  scope.row.change.before.products
                "
                >将&nbsp;<span class="changename">意向课程</span
                ><span class="changetext"
                  >&nbsp;{{
                    scope.row.change.before.products | formateproducts
                  }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.products | formateproducts
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.subjects ||
                  scope.row.change.before.subjects
                "
                >将&nbsp;<span class="changename">意向科目</span
                ><span class="changetext"
                  >&nbsp;{{
                    scope.row.change.before.subjects | formatesubjects
                  }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.subjects | formatesubjects
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.source ||
                  scope.row.change.before.source
                "
                >将&nbsp;<span class="changename">学员来源</span
                ><span class="changetext"
                  >&nbsp;{{ scope.row.change.before.source | fomateAll }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.source | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.remark ||
                  scope.row.change.before.remark
                "
                >将&nbsp;<span class="changename">备注</span
                ><span class="changetext"
                  >&nbsp;{{ scope.row.change.before.remark | fomateAll }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.remark | fomateAll
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.watcher ||
                  scope.row.change.before.watcher
                "
                >将&nbsp;<span class="changename">负责人</span
                ><span class="changetext"
                  >&nbsp;{{
                    scope.row.change.before.watcher | formatewatcher
                  }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.watcher | formatewatcher
                }}</span
                >；</span
              >
              <span
                v-show="
                  scope.row.change.after.service_status ||
                  scope.row.change.before.service_status
                "
                >将&nbsp;<span class="changename">服务状态</span
                ><span class="changetext"
                  >&nbsp;{{
                    scope.row.change.before.service_status
                      | formateservice_status
                  }}</span
                >&nbsp;改为&nbsp;<span class="changetext">{{
                  scope.row.change.after.service_status | formateservice_status
                }}</span
                >；</span
              >
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="creator" label="跟进人" width="158">
          <template slot-scope="scope">
            <span v-if="scope.row.creator">{{ scope.row.creator.alias }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="跟进日期" width="268">
          <template slot-scope="scope">
            <span>{{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}</span>
          </template>
        </el-table-column>
        <!--         <el-table-column prop="creator" label="修改日期" width="150">
        </el-table-column> -->
        <el-table-column label="操作" width="auto" fixed="right">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              v-show="scope.row.change == null"
              @click="openEditRecordDialog(scope.row)"
              :disabled="!$buttonPermission('studentAdvance:edit')"
              >编辑</el-link
            >
            <el-divider
              direction="vertical"
              v-if="scope.row.change == null"
            ></el-divider>
            <el-link
              type="danger"
              :underline="false"
              v-show="scope.row.change == null"
              @click="deleteItem(scope.row)"
              :disabled="!$buttonPermission('studentAdvance:remove')"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>
    <el-dialog title="跟进内容" :visible.sync="dialogVisible" width="30%">
      <el-descriptions column="1" :labelStyle="labelStyle">
        <el-descriptions-item label="跟进内容">{{
          content.content
        }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <EditRecord
      :isVisible="EditRecordDialog"
      :content="EditRecordContent"
      @close="EditRecordDialog = false"
      @submit="EditRecordSubmit"
    ></EditRecord>
  </div>
</template>

<script>
import ScreenRecord from "../../../../components/ScreenComponents/ScreenRecord/ScreenRecord.vue";
import TableTittle from "../../../../components/TableTittle/TableTittle.vue";
import EditRecord from "../../../../components/DialogComponents/EditRecord/EditRecord.vue";
import {
  studentadvancelist,
  editstudentadvance,
  deletestudentadvance,
} from "../../../../request/api";
export default {
  components: {
    ScreenRecord,
    TableTittle,
    EditRecord,
  },
  data() {
    return {
      studentInfo: {},
      formInline: {},
      TableName: "跟进记录",
      tableData: [],
      dialogVisible: false,
      EditRecordDialog: false,
      EditRecordContent: {},
      content: {},
      labelStyle: {
        width: "100px",
      },
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        student_id: "",
      },
    };
  },
  filters: {
    formateproducts: function (val) {
      if (val == null || val == "") {
        return "无";
      }
      val = JSON.parse(val);
      var str = "";
      val.forEach((item) => {
        str += item.product_name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formatewatcher: function (val) {
      if (val == null || val == "") {
        return "无";
      }
      val = JSON.parse(val);
      var str = "";
      val.forEach((item) => {
        str += item.alias + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formatesubjects: function (val) {
      if (val == null || val == "") {
        return "无";
      }
      val = JSON.parse(val);
      var str = "";
      val.forEach((item) => {
        str += item.subject_name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formateservice_status: function (val) {
      switch (val) {
        case 1:
          return "待签单";
        case 2:
          return "签单审核中";
        case 3:
          return "签单成功";
        case 4:
          return "签单失败";
        default:
          break;
      }
    },
    fomateAll: function (val) {
      if (!val) {
        return "无";
      } else {
        return val;
      }
    },
  },
  mounted() {
    this.studentInfo = this.$route.query;
    this.query.student_id = this.studentInfo.id;
    this.getList(this.query);
  },
  methods: {
    //页面列表刷新
    getList(query) {
      studentadvancelist(query).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    checkStudentChange(item) {
      this.dialogVisible = true;
      this.content = item;
    },
    openEditRecordDialog(item) {
      this.EditRecordContent = JSON.parse(JSON.stringify(item));
      this.EditRecordDialog = true;
    },
    deleteItem(item) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            id: item.id,
          };
          deletestudentadvance(query).then((res) => {
            this.$notify.success({
              title: "提示",
              message: "删除成功",
            });
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    EditRecordSubmit({ form }) {
      let query = {
        status: form.status,
        content: form.content,
        id: form.id,
      };
      editstudentadvance(query).then((res) => {
        this.getList(this.query);
        this.EditRecordDialog = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 35px;
}
.operation-card {
  width: 100%;
  margin-top: 10px;
  .demo-form-inline {
    border-bottom: 2px dashed #d2d5df;
  }
}
.star {
  width: 18px;
  height: 18px;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin: 16px 0px;
}
.changename {
  font-weight: 900;
}
.changetext {
  color: #409eff;
}
</style>