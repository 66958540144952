<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>智能问答</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">命题趋势</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="button-wrap">
        <el-button
          type="primary"
          size="small"
          @click="handleAdd"
        >
          新建
        </el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px; padding-top: 20px">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="院校：" prop="name">
          <el-input
            @input="getDataList(true)"
            v-model.trim="query.apply_college_keyword"
            placeholder="搜索院校"
            clearable
          ></el-input>
        </el-form-item>
         <el-form-item label="专业：" prop="name">
          <el-input
            @input="getDataList(true)"
            v-model.trim="query.apply_major_keyword"
            placeholder="搜索专业"
            clearable
          ></el-input>
        </el-form-item>
        
        <el-form-item label="学院：" prop="name">
          <el-input
            @input="getDataList(true)"
            v-model.trim="query.apply_academy_keyword"
            placeholder="搜索学院"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="科目：" prop="name">
          <el-input
            @input="getDataList(true)"
            v-model.trim="query.apply_subject_keyword"
            placeholder="搜索科目"
            clearable
          ></el-input>
        </el-form-item>
         <el-form-item label="申请人">
          <el-select
          multiple 
            v-model="query.manage_creator_ids"
            placeholder="请选择"
            @change="getDataList(true)"
            clearable
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker
          format="yyyy-MM-dd HH:mm"  
          :default-time="['00:00:00', '23:59:59']"
           value-format="timestamp"
            @change="getDataList(true)"
            v-model="time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle tittle="列表"></TableTittle>
        <el-radio-group v-model="query.statuses" @change="getDataList(true)">
          <el-radio-button label="">全部</el-radio-button>
          <el-radio-button :label="2">已完成</el-radio-button>
          <el-radio-button :label="1">解答中</el-radio-button>
        </el-radio-group>
      </div>
      <el-table
        :data="dataList"
        :header-cell-style="{
          textAlign: 'center', 
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ fontSize: '13px' }"
        border
      >
        <el-table-column
          type="index"
          label="序号"
          width="80"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="paper_demand"
          label="状态"
          width="100"
           align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 1 ? 'warning' : 'success'">
              {{ scope.row.status == 1 ? "解答中" : "已完成" }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column

          align="left"
          prop="title"
          label="标题"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column  width="180" prop="" label="申请人"  align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ getManageName(scope.row.manage_creator ) }}
          </template>
        </el-table-column>
        <el-table-column label="申请时间" width="150"  align="center">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="完成时间" width="150"  align="center">
          <template slot-scope="scope" >
            {{
              scope.row.answer_time
                ? $moment(scope.row.answer_time).format("YYYY-MM-DD HH:mm")
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100"  align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToDetail(scope.row)"
              >查看</el-link
            >
            <!--  -->
            <el-link
              type="primary"
              :underline="false"
              :disabled="scope.row.status==1"
              @click="handleToEdit(scope.row)"
              >答案</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <PlanningDetails ref="planningDetails"/>
    <Answer ref="answer" />
  </div>
</template>

<script>
import { majorlist } from "@/request/api";
import { manageUrl } from "@/utils/helper";
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import PlanningDetails from "../../../components/QuestionAnswering/PlanningComponents/PlanningDetails.vue";
import Answer from "../../../components/QuestionAnswering/PlanningComponents/Answer.vue";
export default {
  components: {
    TableTittle,
    PlanningDetails,
    Answer
  },
  data() {
    return {
      time: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        statuses: "",
        manage_creator_ids:[],
         types: 3,
      },
      dataList:[],
      pageTotal: 0,
      timer:null
    };
  },
  created() {
    this.timer = setInterval(()=>{
      this.getDataList();
    },30000)
    this.getDataList();
    this.$store.dispatch('getStaffList')
    this.$store.dispatch("getAllColleges");
  },
  // watch:{
  //   '$route' () {
  //     this.getDataList(); 
  //   }
  // },
  beforeDestroy(){
    clearInterval(this.timer)
     this.timer = null
  },

  methods: {
      getManageName(id){
        // console.log("id",id)
        // console.log("Cc", this.$store.state.staffOptions)
          let item = this.$store.state.staffOptions.find(item=>item.id==id)
          if(item){
            return item.alias
          }
          return "-"

        //   

      },
      handleToEdit(row){
          this.$refs.answer.open(row.id)
      },
      handleToDetail(row){
          this.$refs.planningDetails.open(row.id,'命题趋势')
      },

      handleAdd(){
          this.$router.push('/questionAnswering/propositionalTendency/propositionalTendencyAdd')
      },

    getDataList(flag) {
      if (flag) {
        this.query.pageIndex = 1;
      }
      let str = manageUrl();
      this.$axios({
        url: `${str}/api/v1/gpt/gptQA/list`,
        method: "get", //可以省略 不写默认是get
        params: {
          ...this.query,
          manage_creator_ids:this.query.manage_creator_ids.toString(),
          start_create_time: (this.time&&this.time.length > 0) ? this.time[0] : "",
          end_create_time: (this.time&&this.time.length > 0) ? this.time[1] : "",
        },
      }).then((res) => {
        this.dataList = res.data.list;
        this.pageTotal = res.data.count;
        // console.log("ii", res);
      });
    },

 

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getDataList();
    },
  },
};
</script>

<style lang="less" scoped>
</style>