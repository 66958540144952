<template>
  <div>
    <div class="form_main">
      <div class="input-item">
        <img src="../../../assets/images/phone.png" alt="" />
        <input type="text" placeholder="请输入用手机号" v-model="phone" />
      </div>
      <div class="input-item">
        <img src="../../../assets/images/password.png" alt="" />
        <input
          :type="passwordStyle"
          placeholder="请输入登录密码"
          v-model="password"
        />
        <img
          src="../../../assets/images/eyesopen.png"
          alt=""
          srcset=""
          style="width: 20px; height: 20px"
          v-if="passwordStyle == 'password'"
          @click="eyesChange('text')"
        />
        <img
          src="../../../assets/images/eyesclose.png"
          alt=""
          srcset=""
          v-else
          style="width: 20px; height: 20px"
          @click="eyesChange('password')"
        />
      </div>
    </div>
    <div class="forget-password">
      <span>注：首次登录请用验证码登录</span>
      <el-link type="primary" :underline="false" @click="forgetChange"
        >忘记密码？</el-link
      >
    </div>
    <div class="submit-div">
      <el-button :loading="loading" @click="login">{{
        loading ? "登陆中..." : "点击登录"
      }}</el-button>
    </div>
    <div class="agreement-div">
      <span>
        登录即代表您同意
        <el-link type="primary" :underline="false" @click="open"
          >《研盒用户使用协议》</el-link
        ></span
      >
    </div>
  </div>
</template>

<script>
import { passwordlogin } from "../../../request/api";
export default {
  data() {
    return {
      passwordStyle: "password",
      loading: false,
      phone: "",
      password: "",
    };
  },
  methods: {
    eyesChange(style) {
      this.passwordStyle = style;
    },
    forgetChange() {
      this.$emit("forgetChange");
    },
    open() {
      this.$emit("open");
    },
    login() {
      this.loading = true;
      let query = {
        phone: this.phone,
        password: this.password,
      };
      passwordlogin(query).then((res) => {
        this.loading = false;
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        localStorage.setItem("token", res.data.token);
        this.$emit("submit", { form: res.data.companys });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form_main {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .input-item {
    margin-bottom: 72px;
    height: 45px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3e6ef;
    width: 100%;
    img {
      width: 24px;
      height: 24px;
    }
    input {
      margin-left: 32px;
      padding: 0 8px;
      height: 100%;
      border: none;
      width: 80%;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 28px;
      color: #252631;
      opacity: 1;
      outline: 0;
    }
  }
}
.submit-div {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
  .el-button {
    width: 100%;
    height: 49px;
    background: #409eff;
    opacity: 1;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 22px;
    color: #ffffff;
    opacity: 1;
  }
}
.forget-password {
  position: absolute;
  width: 80%;
  top: 65%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: space-between;
  span {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #c0c4cc;
    opacity: 1;
  }
}
.agreement-div {
  position: absolute;
  width: 87%;
  top: 89%;
  left: 10%;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #c0c4cc;
    opacity: 1;
  }
}
</style>