<template>
  <!-- title的英文拼成tittle了，注意变量名 -->
  <div class="table-title">
    <el-divider direction="vertical"></el-divider>
    <span>{{ tittle }}</span>
  </div>
</template>

<script>
export default {
  props: {
    tittle: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.table-title {
  width: 170px;
  margin-bottom: 24px;
  .el-divider--vertical {
    background-color: #409EFF;
    border-radius: 4px;
    border: 2px solid #409EFF;
  }
  span {
    font-size: 16px;
    font-weight: 800;
    color: #252631;
    opacity: 1;
  }
}
</style>