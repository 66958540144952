<template>
  <div class="content">
    <div class="demo-drawer__content">
      <el-form
        :label-position="labelPosition"
        :model="form"
        :inline="true"
        label-width="68px"
        size="mini"
      >
        <!--   <el-form-item label="服务状态">
          <el-select
            v-model="form.service_status"
            placeholder="请选择服务状态"
            @change="submit"
            clearable
          >
            <el-option label="待定校" value="1"></el-option>
            <el-option label="待服务" value="2"></el-option>
            <el-option label="正在对接" value="3"></el-option>
            <el-option label="正在匹配" value="4"></el-option>
            <el-option label="匹配成功" value="5"></el-option>
            <el-option label="匹配失败" value="6"></el-option>
            <el-option label="开始上课" value="7"></el-option>
            <el-option label="正在上课" value="8"></el-option>
            <el-option label="课程结束" value="9"></el-option>
            <el-option label="课程终止" value="10"></el-option>
            <el-option label="已归档" value="19"></el-option>
          </el-select>
        </el-form-item> -->
          <el-form-item label="考研年份">
          <el-select
            v-model="form.apply_year"
            placeholder="请选择报名课程"
            @change="submit"
            clearable
          >
            <!-- <el-option
              label="2022"
              value="2022"
            ></el-option>
            <el-option
              label="2023"
              value="2023"
            ></el-option> -->
            <el-option
              label="2024考研"
              value="2024"
            ></el-option>
            <el-option
              label="2025考研"
              value="2025"
            ></el-option>
            <el-option
              label="2026考研"
              value="2026"
            ></el-option>
            <el-option
              label="2027考研"
              value="2027"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="学员信息">
          <el-input
            v-model="form.student_keyword"
            placeholder="输入姓名/手机号"
            @input="submit"
            clearable
          ></el-input>
        </el-form-item> -->

        <el-form-item label="课程状态">
          <el-select
            v-model="form.student_order_sync_statuses"
            placeholder="请选择课程状态"
            @change="submit"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in filters.student_order_sync_statuses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

         <el-form-item label="报考信息">
          <el-input
            v-model="form.college_info_keyword"
            placeholder="输入报考院校/学院/专业/方向"
            @input="submit"
            clearable
          ></el-input>
        </el-form-item>

         <el-form-item label="辅导科目">
          <el-input
            v-model="form.subject_keyword"
            placeholder="输入辅导科目"
            @input="submit"
            clearable
          ></el-input>
        </el-form-item>
        <!--         <el-form-item label="报名课程">
          <el-select
            v-model="form.product_id"
            placeholder="请选择报名课程"
            style="width: 380px"
            @change="submit"
            clearable
          >
            <el-option
              v-for="item in $store.state.productOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      
        <el-form-item label="教务老师">
          <el-select
            v-model="form.watcher_id"
            placeholder="请选择教务老师"
            @change="submit"
            clearable
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!--     <el-form-item label="未跟进时间" prop="region" label-width="90px">
          <el-select
            v-model="end_advance_time"
            placeholder="请选择未跟进时间"
            @change="submit"
            clearable
          >
            <el-option
              v-for="item in timeArr"
              :key="item.timestamp"
              :label="item.name"
              :value="item.timestamp"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="报名日期">
          <el-date-picker
            v-model="create_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="submit"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="跟进日期">
          <el-date-picker
            v-model="advance_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="submit"
          >
          </el-date-picker>
        </el-form-item> -->
      </el-form>
      <!--     <div class="demo-drawer__footer">
        <el-button class="elbutton" size="small"
          >重 置</el-button
        >
        <el-button type="primary" class="elbutton" size="small">确 定</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      labelPosition: "left",
      end_advance_time: "",
      create_time: "",
      advance_time: "",
      timeArr: [
        {
          name: "一天",
          timestamp: Date.now() - 1 * 24 * 60 * 60 * 1000,
        },
        {
          name: "三天",
          timestamp: Date.now() - 3 * 24 * 60 * 60 * 1000,
        },
        {
          name: "一周",
          timestamp: Date.now() - 7 * 24 * 60 * 60 * 1000,
        },
        {
          name: "两周",
          timestamp: Date.now() - 14 * 24 * 60 * 60 * 1000,
        },
        {
          name: "一个月",
          timestamp: Date.now() - 30 * 24 * 60 * 60 * 1000,
        },
        {
          name: "两个月",
          timestamp: Date.now() - 60 * 24 * 60 * 60 * 1000,
        },
        {
          name: "三个月",
          timestamp: Date.now() - 90 * 24 * 60 * 60 * 1000,
        },
      ],
      filters: {
        student_order_sync_statuses: [
          { value: 1, label: '待定校' },
          { value: 2, label: '待服务' },
          { value: 3, label: '待对接' },
          { value: 4, label: '匹配中' },
          { value: 5, label: '上课中' },
          { value: 6, label: '已终止' },
          { value: 7, label: '已结束' },

        ]
      }
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit() {
      // start/end_apply_time
      // 创建时间区间处理
      if (this.create_time) {
        this.form.start_apply_time = this.create_time[0];
        this.form.end_apply_time = this.create_time[1] + 86399000;
      } else {
        this.form.start_apply_time = "";
        this.form.end_apply_time = "";
      }
      // 跟进时间区间处理
      if (this.advance_time) {
        this.form.start_advance_time = this.advance_time[0];
        this.form.end_advance_time = this.advance_time[1] + 86399000;
      } else {
        this.form.start_advance_time = "";
        this.form.end_advance_time = "";
      }
      // 判断，因为为跟进时间传end_advance_time不传start_advance_time
      if (this.end_advance_time) {
        this.form.end_advance_time = this.end_advance_time;
      }
      this.$emit("submit", { screenForm: this.form });
    },
  },
};
</script>

<style lang="less" scoped>
.demo-drawer__content {
  padding-top: 24px;
  height: 100%;
}
.demo-drawer__footer {
  margin-bottom: 24px;
  float: right;
}
</style>