<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>开票记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card class="operation-card">
      <el-form :inline="true" class="demo-form-inline" size="small">
        <el-form-item label="发票状态：">
          <el-select
            v-model="query.invoice_status"
            placeholder="请选择发票状态"
            clearable
            @change="getList(query)"
          >
            <el-option label="待处理" value="0"></el-option>
            <el-option label="待开票" value="1"></el-option>
            <el-option label="开票成功" value="2"></el-option>
            <el-option label="开票失败" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票时间" prop="last_advance_time">
          <el-date-picker
            v-model="invoice_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="dataChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="创建时间" prop="last_advance_time">
          <el-date-picker
            v-model="create_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="dataChange"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="table-card">
      <TableTittle :tittle="TableName"></TableTittle>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column prop="invoice_status" label="发票状态">
          <template slot-scope="scope">
            <el-tag
              size="small"
              type="success"
              v-show="scope.row.invoice_status == 0"
              >待处理</el-tag
            >
            <el-tag
              size="small"
              type="success"
              v-show="scope.row.invoice_status == 1"
              >待开票</el-tag
            >
            <el-tag
              size="small"
              type="success"
              v-show="scope.row.invoice_status == 2"
              >开票成功</el-tag
            >
            <el-tag
              size="small"
              type="success"
              v-show="scope.row.invoice_status == 3"
              >开票失败</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="date" label="关联账单">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="openConfirmBillsDialog(scope.row)"
              >账单列表</el-link
            >
          </template>
        </el-table-column>
        <el-table-column prop="invoice_amount" label="开票类型">
          <template slot-scope="scope">
            {{scope.row.invoice_type==1?'纸质发票':'电子发票'}}
          </template>
        </el-table-column>
        <el-table-column prop="invoice_amount" label="开票金额">
          <template slot-scope="scope">
            <span>{{ scope.row.invoice_amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="申请人">
          <template slot-scope="scope">
            <span>{{ scope.row.creator?scope.row.creator.alias:"无" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column prop="invoice_time" label="开票时间">
          <template slot-scope="scope">
            <span v-if="scope.row.invoice_time">{{
              scope.row.invoice_time | dateFormat("YYYY-MM-DD")
            }}</span>
            <span v-else>无</span>
            <!-- {{ scope.row.invoice_time | dateFormat("YYYY-MM-DD") }} -->
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="80">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="openCheckBillsStatusDialog(scope.row)"
              >查看</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <CheckBillsStatus
      :content="CheckBillsStatusContent"
      :isVisible="CheckBillsStatusDialog"
      @close="CheckBillsStatusDialog = false"
    >
    </CheckBillsStatus>
    <CheckBillsTable
      :content="ConfirmContent"
      :isVisible="ConfirmBillsDialog"
      @close="ConfirmBillsDialog = false"
    ></CheckBillsTable>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import CheckBillsStatus from "../../../components/DialogComponents/CheckBillsStatus/CheckBillsStatus.vue";
import CheckBillsTable from "../../../components/DialogComponents/CheckBillsTable/CheckBillsTable.vue";
import { invoiceapplylist } from "../../../request/api";
export default {
  components: {
    TableTittle,
    CheckBillsStatus,
    CheckBillsTable,
  },
  data() {
    return {
      formInline: {},
      TableName: "开票记录",
      ConfirmContent: {},
      CheckBillsStatusContent: {},
      CheckBillsStatusDialog: false,
      ConfirmBillsDialog: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      pageTotal: 0,
      tableData: [],
      create_time: "",
      invoice_time: "",
    };
  },
  mounted() {
    this.getList(this.query);
  },
  methods: {
    openCheckBillsStatusDialog(item) {
      this.CheckBillsStatusContent = JSON.parse(JSON.stringify(item));
      this.CheckBillsStatusDialog = !this.CheckBillsStatusDialog;
    },
    //页面列表刷新
    getList(query) {
      invoiceapplylist(query).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    openConfirmBillsDialog(item) {
      this.ConfirmContent = item;
      this.ConfirmBillsDialog = !this.ConfirmBillsDialog;
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    dataChange() {
      // 时间区间处理
      if (this.invoice_time) {
        this.query.start_invoice_time = this.invoice_time[0];
        this.query.end_invoice_time = this.invoice_time[1] + 86399000;
      } else {
        this.query.start_invoice_time = "";
        this.query.end_invoice_time = "";
      }
      if (this.create_time) {
        this.query.start_create_time = this.create_time[0];
        this.query.end_create_time = this.create_time[1] + 86399000;
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
      }
      this.getList(this.query);
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 35px;
}
.operation-card {
  width: 100%;
  margin-top: 20px;
}
.table-card {
  margin-top: 16px;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
</style>