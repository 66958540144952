<template>
  <el-container>
    <vHead @collapse="collapse"
           @company="changecompany"></vHead>
    <el-container>
      <el-aside style="width: auto"
                :style="{ width: !iscollapse ? 'auto' : '0px' }">
        <el-menu @select="select"
                 :default-active="onRoutes"
                 class="el-menu-vertical-demo"
                 background-color="#394167"
                 text-color="#fff"
                 active-text-color="#409EFF"
                 unique-opened
                 router
                 :collapse="iscollapse">
          <template v-for="item in menuList">
            <template v-if="item.subs">
              <el-submenu :index="item.index"
                          :key="item.index">
                <template slot="title">
                  <!--                 <i :class="item.icon"></i>
                  <span slot="title">{{ item.title }}</span> -->
                  <img :src="item.icon"
                       alt=""
                       class="menu_img" />
                  <span slot="title">{{ item.title }}</span>
                </template>
                <template v-for="subItem in item.subs">
                  <el-submenu v-if="subItem.subs"
                              :index="subItem.index"
                              :key="subItem.index">
                    <template slot="title">{{ subItem.title }}</template>
                    <el-menu-item v-for="(threeItem, i) in subItem.subs"
                                  :key="i"
                                  :index="threeItem.index">{{ threeItem.title }}</el-menu-item>
                  </el-submenu>
                  <el-menu-item v-else
                                :index="subItem.index"
                                :key="subItem.index">{{ subItem.title }}</el-menu-item>
                </template>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item :index="item.index"
                            :key="item.index">
                <img :src="item.icon"
                     alt=""
                     class="menu_img" />
                <span slot="title">{{ item.title }}</span>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    <div class="fix-wrap"
         @click="MatterTableDialog = true">
      <div class="count"
           v-if="$store.state.msgCount.total_count > 0">
        {{ $store.state.msgCount.total_count }}
      </div>
      <div class="img">
        <img src="@/assets/images/bell.png"
             alt=""
             srcset="" />
      </div>
      <div class="text">待办事项</div>
    </div>
    <MatterTable :isVisible="MatterTableDialog"
                 @close="MatterTableDialog = false" />
  </el-container>
</template>

<script>
import vHead from '../components/Eheader/Eheader.vue'
import { computePermission } from '../utils/helper'
import MatterTable from '@/components/DialogComponents/Service/MatterTable.vue'

const menus = [
  {
    icon: require('../assets/images/menu0.png'),
    index: '/overview',
    title: '总 览',
  },
  {
    icon: require('../assets/images/menu1.png'),
    index: '/recruitstudents',
    title: '招 生',
    subs: [
      {
        index: '/recruitstudents/administration',
        title: '招生管理',
      },
      {
        index: '/recruitstudents/examine',
        title: '签单审批',
      },
    ],
  },
  {
    icon: require('../assets/images/menu7.png'),
    index: '/teacherdata',
    title: '师 资',
    subs: [
      /*       {
              index: "/service/studentmanagement",
              title: "学员管理",
            }, */
      {
        index: '/service/ordermatching',
        title: '师资匹配',
      },
      {
        index: '/service/dispatchorder',
        title: '师资派遣',
      },
    ],
  },
  {
    icon: require('../assets/images/menu2.png'),
    index: '/service',
    title: '服 务',
    subs: [
      {
        index: '/dataInventory/student',
        title: '学员列表',
      },
      {
        index: '/service/exteriorstudent',
        title: '学员管理',
      },
      {
        index: '/service/teachingandresearch',
        title: '教研服务',
      },
      {
        index: '/service/studentmanagement',
        title: '课程安排',
      },
    ],
  },

  {
    icon: require('../assets/images/menu4.png'),
    index: '/product',
    title: '产 品',
    subs: [
      {
        index: '/product/coursecatalogue',
        title: '产品列表',
      },
      {
        index: '/product/lesson',
        title: '课程列表',
      },
    ],
  },
  {
    icon: require('../assets/images/menu8.png'),
    index: '/dataInventory',
    title: '资 料',
    subs: [
      {
        index: '/dataInventory/dataList',
        title: '资料列表',
      },
      {
        index: '/dataInventory/stock',
        title: '库存管理',
      },
      {
        index: '/dataInventory/deliverList',
        title: '发货清单',
      },
    ],
  },
  {
    icon: require('../assets/images/menu3.png'),
    index: '/finance',
    title: '财 务',
    subs: [
      {
        index: '/finance/bill',
        title: '服务账单',
      },
      {
        index: '/finance/recharge',
        title: '账户充值',
      },
      {
        index: '/finance/billingrecord',
        title: '开票记录',
      },
      {
        index: '/finance/recordsofconsumption',
        title: '交易流水',
      },
    ],
  },
  {
    icon: require('../assets/images/menu5.png'),
    index: '/employee',
    title: '员 工',
    subs: [
      {
        index: '/employee/employeemanagement',
        title: '员工管理',
      },
      {
        index: '/employee/departmentmanagement',
        title: '部门管理',
      },
      {
        index: '/employee/rolemanagement',
        title: '角色管理',
      },
    ],
  },
  {
    icon: require('../assets/images/menu9.png'),
    index: '/practicetest',
    title: '模 考',
    subs: [
      {
        index: '/practicetest/management',
        title: '模考管理',
      },
      {
        index: '/practicetest/papermnagent',
        title: '试卷管理',
      },
      {
        index: '/practicetest/testdetail',
        title: '考试详情',
      },
    ],
  },
  {
    icon: require('../assets/images/test.png'),
    index: '/testPaper',
    title: '测试卷',
    subs: [
      {
        index: '/testPaper/testMangent',
        title: '提交管理',
      },
    ],
  },
  {
    icon: require('../assets/images/menu10.png'),
    index: '/answering',
    title: '问答',
    subs: [
      {
        index: '/questionAnswering/planning',
        title: '教学计划',
      },
      {
        index: '/questionAnswering/examinationOutline',
        title: '考试大纲',
      },
      {
        index: '/questionAnswering/propositionalTendency',
        title: '命题趋势',
      },
      {
        index: '/questionAnswering/enrollmentForecast',
        title: '招生预测',
      },
    ],
  },
  {
    icon: require('../assets/images/menu6.png'),
    index: '/setting',
    title: '设 置',
    subs: [
      {
        index: '/setting/aboutstudent',
        title: '关于学员',
      },
      {
        index: '/setting/signbill',
        title: '签单开票',
      },
    ],
  },
]
export default {
  components: {
    vHead,
    MatterTable,
  },
  data() {
    return {
      iscollapse: false,
      menuList: [],
      MatterTableDialog: false,
    }
  },
  computed: {
    onRoutes() {
      const route = this.$route
      const { meta, path } = route
      // 可以在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
      // meta中 有activeMenu 字段的子页面，都会显示高亮
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  mounted() {
    this.$store.dispatch('getTaskMsgCount')
  },
  created() {
    // 菜单判断
    // this.items = menus
    this.menuList = JSON.parse(JSON.stringify(menus)).filter((item) => {
      return this.computeRoute(item)
    })
    // console.log("log",this.menuList);
  },
  methods: {
    select(E) {
      console.log('E:', E)
    },
    changecompany() {
      this.menuList = JSON.parse(JSON.stringify(menus)).filter((item) => {
        return this.computeRoute(item)
      })
      location.reload()
    },
    computeRoute(item) {
      if (item.subs) {
        item.subs = item.subs.filter((sub) => {
          return this.computeRoute(sub)
        })
        if (item.subs.length) {
          return true
        }
      }
      return computePermission(item.index)
    },
    collapse(collapse) {
      this.iscollapse = collapse
    },
  },
}
</script>

<style lang="less" scoped>
.el-container {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0;
  bottom: 0;
}
.el-header {
  padding: 0;
  z-index: 1000;
}

.el-header .fr {
  float: right;
}
.el-header .el-menu {
  border-bottom: none;
}
.el-main {
  display: flex;
  flex-direction: column;
  padding-top: 59px;
}
.el-main {
  background: #f5f5f5;
}
.el-aside .el-menu {
  border-right: none;
}
.menu_img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
// 菜单收缩栏样式(关闭时)
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
.el-menu {
  height: 100%;
}
.el-aside {
  padding-top: 40px;
}
.fix-wrap {
  position: fixed;
  top: 600px;
  right: 0px;
  width: 56px;
  height: 190px;
  z-index: 9999;
  background: #409eff;
  cursor: pointer;
  .count {
    position: absolute;
    width: 32px;
    height: 32px;
    background: red;
    border-radius: 50%;
    top: -16px;
    left: -16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
  }
  .img {
    width: 100%;
    height: 56px;
    border-bottom: 1px solid #62afff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .text {
    font-size: 20px;
    width: 20px;
    margin: 8px auto;
    color: #fff;
  }
}
</style>