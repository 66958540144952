var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('el-table',{attrs:{"data":_vm.tableData,"header-cell-style":{
        textAlign: 'center',
        color: '#909399',
        fontFamily: 'PingFang SC',
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: '#F2F6FC',
      },"size":"mini","cell-style":{ textAlign: 'center', fontSize: '13px' }}},[_c('el-table-column',{attrs:{"label":"姓名","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"placeholder":"请输入内容","disabled":_vm.disabled},on:{"input":_vm.sync},model:{value:(scope.row.receiver_name),callback:function ($$v) {_vm.$set(scope.row, "receiver_name", $$v)},expression:"scope.row.receiver_name"}})]}}])}),_c('el-table-column',{attrs:{"label":"手机号","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"placeholder":"请输入内容","disabled":_vm.disabled},on:{"input":_vm.sync},model:{value:(scope.row.receiver_phone),callback:function ($$v) {_vm.$set(scope.row, "receiver_phone", $$v)},expression:"scope.row.receiver_phone"}})]}}])}),_c('el-table-column',{attrs:{"label":"收货地址"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"placeholder":"请输入内容","disabled":_vm.disabled},on:{"input":_vm.sync},model:{value:(scope.row.receiver_address),callback:function ($$v) {_vm.$set(scope.row, "receiver_address", $$v)},expression:"scope.row.receiver_address"}})]}}])}),_c('el-table-column',{attrs:{"label":"","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{staticStyle:{"width":"16px","height":"16px"},attrs:{"src":require("@/assets/images/close_icon.png"),"alt":"","srcset":""},on:{"click":function($event){return _vm.handleToRemove(scope.$index)}}})]}}])})],1),_c('div',{staticClass:"btn-wrap"},[_c('el-button',{staticClass:"small-btn",attrs:{"size":"small"},on:{"click":_vm.addUser}},[_vm._v("增加用户")]),_c('div',{staticClass:"upload-demo"},[_c('el-upload',{attrs:{"action":_vm.imgUploadUrl,"multiple":"","accept":".xlsx,.xls","before-upload":_vm.uploadBefore}},[_c('el-button',{attrs:{"slot":"trigger","size":"small","type":"primary"},slot:"trigger"},[_vm._v("导入用户")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }