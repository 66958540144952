<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>制定招生预测</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">新建</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card style="margin-top: 20px; padding-top: 20px">
      <TableTittle tittle="新建"></TableTittle>
      <el-form
        :model="dataForm"
        label-position="right"
        ref="dataForm"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item label="院校：" prop="apply_college_code">
          <el-select
            style="width: 760px"
            v-model="dataForm.apply_college_code"
            filterable
            placeholder="请选择"
            clearable
            @change="collegeChoose"
          >
            <el-option
              v-for="item in $store.state.collegeList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业：" prop="apply_major_code">
          <el-select
            filterable
            placeholder="请选择"
            v-model="dataForm.apply_major_code"
            @change="majorChoose"
            clearable
            style="width: 760px"
          >
            <el-option
              v-for="(item, index) in majorOptions"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
              <span>{{ `（${item.code}）${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学院：" prop="apply_academy_code">
          <el-select
            filterable
            placeholder="请选择"
            v-model="dataForm.apply_academy_code"
            @change="academyChoose"
            clearable
            style="width: 760px"
          >
            <el-option
              v-for="(item, index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="item.academy_code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="辅导科目" prop="apply_subject_codes">
          <el-select
            v-model="dataForm.apply_subject_codes"
            placeholder="请选择"
            style="width: 760px"
          >
            <el-option
              v-for="(item, index) in subjectsOptions"
              :key="index"
              :label="item.subject_name"
              :value="item.subject_code"
            >
            </el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="预测年份" prop="enrol_predict_target_year">
          <el-select
            v-model="dataForm.enrol_predict_target_year"
            @change="yearChange"
            placeholder="请选择"
            style="width: 760px"
          >
            <el-option
              v-for="(item, index) in yearList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="往年录取情况" prop="year">
          <div
            class="admission-situation"
            v-for="(item, index) in dataForm.enrol_predict_history_year_list"
            :key="index"
          >
            <div class="title">{{ item.year }}年</div>
            <div class="main">
              <el-form-item
                label="分数线:"
                :prop="'enrol_predict_history_year_list.' + index + '.line'"
                label-width="70px"
                :rules="[
                  {
                  required: true,
                  message: '分数线不能为空',
                  trigger: 'blur',
                },
                 { pattern:  /^(500|[1-4]{0,1}\d{0,1}\d)$/, message: '请输入正整数(不超过500)', trigger: 'blur' }
                ]"
                  
              >
                <el-input
                  :max="500"
                  v-model.number="item.line"
                  placeholder="请输入"
                  style="width: 138px"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="招生人数:"
                :prop="'enrol_predict_history_year_list.' + index + '.plan'"
                label-width="85px"
                :rules="[
                  {
                  required: true,
                  message: '招生人数不能为空',
                  trigger: 'blur',
                },
                 { pattern:  /^([0]|[1-9][0-9]*)$/, message: '请输入正整数', trigger: 'blur' }
                ]"
              >
                <el-input
                  v-model.number="item.plan"
                  placeholder="请输入"
                  style="width: 138px"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="录取比例:"
                :prop="'enrol_predict_history_year_list.' + index + '.ratio_recruit'"
                label-width="85px"
                :rules="[
                  {
                  required: true,
                  message: '录取比例不能为空',
                  trigger: 'blur',
                },
               
                ]"
              >
                <el-input
                  v-model.number="item.ratio_basic"
                  :readonly="true"
                  style="width: 64px;"
                ></el-input>
                :
                <el-input
                  v-model="item.ratio_recruit"
                  style="width: 64px"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">提交</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </el-card>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import UEeditor from "@/components/UEeditor";
import { majorlist, subjectlist } from "@/request/api";
import { adminUrl, manageUrl } from "@/utils/helper";
export default {
  components: {
    TableTittle,
    UEeditor,
  },
  data() {
    return {
      dataForm: {
        enrol_predict_target_year:2024,
        enrol_predict_history_year_list : [
          {
            year: 2023,
            line: "",
            plan: "",
            ratio_basic: 1,
            ratio_recruit: "",
          },
          {
            year: 2022,
            line: "",
            plan: "",
            ratio_basic: 1,
            ratio_recruit: "",
          },
          {
            year: 2021,
            line: "",
            plan: "",
            ratio_basic: 1,
            ratio_recruit: "",
          },
        ],
      },
      yearList: [
        {
          label: "2024年",
          value: 2024,
        },
        {
          label: "2025年",
          value: 2025,
        },
        {
          label: "2026年",
          value: 2026,
        },
      ],
      majorOptions: [],
      academyOptions: [],
      subjectsOptions: [],
      rules: {
        apply_college_code: [
          { required: true, message: "请选择院校", trigger: "blur" },
        ],
        apply_major_code: [
          { required: true, message: "请选择专业", trigger: "blur" },
        ],
        apply_academy_code: [
          { required: true, message: "请选择学院", trigger: "blur" },
        ],

        ground_topic_types: [
          { required: true, message: "请选择题型", trigger: "blur" },
        ],
        enrol_predict_target_year:[
          {
             required: true, message: "请选择预测年份", trigger: "blur"
          }
        ]
      },
    };
  },
  created() {
    this.$store.dispatch("getAllColleges");
  },
  methods: {

    validateNum(rule, value, callback){
      if(value<=1){
         callback(new Error('请输入大于1的正数'))
      }else{
        callback();
      }
    },
    yearChange(e){
      // console.log("年份",e)
      this.dataForm.enrol_predict_history_year_list.forEach((item,index)=>{
        item.year = e - (index+1)
      })

    },
    // 提交
    submit() {
      this.dataForm.enrol_predict_history_year_list.forEach(item=>{
        item.ratio_recruit = Number(item.ratio_recruit)
      })
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          // this.dataForm.apply_subject_names = this.subjectsOptions.find(
          //   (its) => its.subject_code == this.dataForm.apply_subject_codes
          // ).subject_name;
          let str = manageUrl();
          this.$axios({
            url: `${str}/api/v1/gpt/gptQA`,
            method: "post", //可以省略 不写默认是get
            data: {
              ...this.dataForm,
              type: 4,
              uid: Number(localStorage.getItem("userId")),
              title:
                "" +
                this.dataForm.enrol_predict_target_year
                +
                this.dataForm.apply_college_name +
                this.dataForm.apply_academy_name +
                "(" +
                this.dataForm.apply_major_code +
                ")" +
                this.dataForm.apply_major_name +
                "专业的招生预测",
            },
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "创建成功!",
              });
              this.$router.go(-1);
            }
            // console.log(res)
          });
        }
      });
    },

    initDtaForm(e) {
      //   console.log('eee',e)
      this.dataForm.plan_course_outline = e;
    },
    initDtaFormW(e) {
      this.dataForm.plan_others = e;
    },

    referenceAdd() {
      this.dataForm.apply_reference_list.push({
        apply_reference_name: "",
      });
    },
    referenceDel(index) {
      this.dataForm.apply_reference_list.splice(index, 1);
      //   this.dataForm.apply_reference_list.
    },
    // 院校
    collegeChoose(e) {
      this.$set(this.dataForm, "apply_major_code", "");
      this.$set(this.dataForm, "apply_major_name", "");
      this.majorOptions = [];
      this.$set(this.dataForm, "apply_academy_name", "");
      this.$set(this.dataForm, "apply_academy_code", "");
      this.academyOptions = [];
      this.$set(this.dataForm, "apply_subject_names", "");
      this.$set(this.dataForm, "apply_subject_codes", "");
      this.subjectsOptions = [];
      this.getMajorOptions(e);

      let item = this.$store.state.collegeList.find((item) => item.code == e);
      this.$set(this.dataForm, "apply_college_name", item.name);
    },

    // 获取专业
    getMajorOptions(colleag_code) {
      let query = {
        isPaging: 0,
        college_code: colleag_code,
      };
      majorlist(query).then((res) => {
        this.majorOptions = res.data.list || [];
      });
    },
    // 选择专业
    majorChoose(e) {
      this.$set(this.dataForm, "apply_academy_name", "");
      this.$set(this.dataForm, "apply_academy_code", "");
      this.academyOptions = [];
      this.$set(this.dataForm, "apply_subject_names", []);
      this.$set(this.dataForm, "apply_subject_codes", []);
      this.subjectsOptions = [];
      this.getMajorInfo(this.dataForm.apply_college_code, e);

      let item = this.majorOptions.find((item) => item.code == e);
      this.$set(this.dataForm, "apply_major_name", item.name);

      // console.log("e",e)
    },
    // 获取学院
    getMajorInfo(college_code, major_code) {
      let str = adminUrl();
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: "get", //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((res) => {
        this.academyOptions = res.data.academys;
      });
    },
    // 选择学院
    academyChoose(e) {
      this.$set(this.dataForm, "apply_subject_names", []);
      this.$set(this.dataForm, "apply_subject_codes", []);
      this.subjectsOptions = [];
      this.getSubjects(
        this.dataForm.apply_college_code,
        e,
        this.dataForm.apply_major_code
      );

      let item = this.academyOptions.find((item) => item.academy_code == e);
      this.$set(this.dataForm, "apply_academy_name", item.academy_name);
    },

    // 获取科目
    getSubjects(college_code, academy_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        academy_code: academy_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        this.subjectsOptions = res.data.list || [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.reference {
  display: flex;
  /deep/.el-button--small,
  .el-button--small.is-round {
    height: 32px !important;
    margin-left: 5px;
  }
}

.admission-situation {
  width: 760px;
  height: 100px;
  background: rgba(242, 246, 252, 0.6);
  border-radius: 4px 4px 4px 4px;
  padding: 16px;
  box-sizing: border-box;
  margin-top: 8px;
  .title{
    font-size: 14px;
font-family: PingFang SC-Medium, PingFang SC;
font-weight: 500;
color: #303133;
line-height: 16px;
margin-bottom: 12px;
  }
  .main {
    display: flex;
    justify-content: space-between;
    /deep/.el-form-item__label {
      text-align: left;
    }
    /deep/.el-form-item{
      margin-bottom: 0px !important;
    }
  }
}
</style>