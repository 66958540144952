import EmployeeManagement from "../views/Employee/EmployeeManagement/main.vue";
import DepartmentManagement from "../views/Employee/DepartmentManagement/main.vue";
import RoleManagement from "../views/Employee/RoleManagement/main.vue";


export default [
    /* 员  工 */
    {
        path: '/employee/employeemanagement',
        component: EmployeeManagement,
        meta: { title: '员工管理' }
    },
    {
        path: '/employee/departmentmanagement',
        component: DepartmentManagement,
        meta: { title: '部门管理' }
    },
    {
        path: '/employee/rolemanagement',
        component: RoleManagement,
        meta: { title: '角色管理' }
    }
]