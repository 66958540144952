<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>产品</el-breadcrumb-item>
        <el-breadcrumb-item>课程列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button
          type="primary"
          @click="opencreateDialog"
          size="small"
        >新建</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="名称">
          <el-input
            v-model.trim="query.name_keyword"
            placeholder="请输入名称"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="query.statuses"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
          >
            <el-option
              label="上架"
              :value="1"
            >
            </el-option>
            <el-option
              label="下架"
              :value="0"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级类型">
          <el-select
            v-model="query.years"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              label="2023"
              value="2023"
            >
            </el-option>
            <el-option
              label="2024"
              value="2024"
            >
            </el-option>
            <el-option
              label="2025"
              value="2025"
            >
            </el-option>
            <el-option
              label="2026"
              value="2026"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="老师">
          <el-input
            v-model.trim="query.teacher_name_keyword"
            placeholder="请输入老师关键字"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="产品经理：">
          <el-select
            v-model="query.watcher_ids"
            placeholder="请选择产品经理"
            clearable
            multiple
            @change="fetchList(true)"
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.alias"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            class="datetime"
            v-model="create_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="fetchList(true)"
            clearable
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTitle :tittle="TableName"></TableTitle>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          backgroundColor: '#F2F6FC',
        }"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        > </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="80"
          show-overflow-tooltip
        >

        </el-table-column>
        <el-table-column
          prop="name"
          label="课程名称"
          align="center"
          width="260"
          show-overflow-tooltip
        >

        </el-table-column>
        <el-table-column
          label="课程课时"
          align="center"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.hours}}小时
          </template>
        </el-table-column>
        <el-table-column
          label="课程年份"
          align="center"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.year}}年
          </template>
        </el-table-column>
        <el-table-column
          label="授课老师"
          align="center"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.teacher_name}}
          </template>
        </el-table-column>
        <el-table-column
          label="产品经理"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{formateWatcher(scope.row.watcher)}}
          </template>
        </el-table-column>
        <el-table-column
          label="课程状态"
          align="center"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.status==1"
              size="mini"
            >上架</el-tag>
            <el-tag
              type="danger"
              v-if="scope.row.status==0"
              size="mini"
            >下架</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="创建人"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.creator?scope.row.creator.alias:'暂无'}}
          </template>
        </el-table-column>
        <el-table-column
          label="创建日期"
          align="center"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="修改日期"
          align="center"
        >
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToCalendar(scope.row)"
            >课程表</el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="handleToEdit(scope.row)"
            >编辑</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button
          size="mini"
          type="danger"
          @click="handleToDeleteAll()"
        >批量删除</el-button>
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <LessonCreate
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="fetchList(true)"
    />
    <LessonEdit
      :isVisible="editDialog"
      @close="editDialog=false"
      @submit="fetchList(true)"
      :id="editId"
    />
  </div>
</template>

<script>
import TableTitle from "@/components/TableTittle/TableTittle.vue";
import LessonCreate from "../../../components/DialogComponents/Product/LessonCreate.vue";
import LessonEdit from "../../../components/DialogComponents/Product/LessonEdit.vue";





import {
  normalCourselist,
  normalCourselisttDelete
} from "@/request/api";
export default {
  components: {
    TableTitle,
    LessonCreate,
    LessonEdit
  },
  data() {
    return {
      TableName: '课程列表',
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      createDialog: false,
      editDialog: false,
      editId: null,
      chooseList: [],
      create_time: '',
    };
  },
  created() {
    this.parseQuery();
    this.fetchList();

    this.$store.dispatch('getStaffList')

  },
  watch: {
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
        this.fetchList(true)
      }
    },
  },
  computed: {

  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.fetchList();
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      if (params.years) {
        params.years = params.years.join(',')
      }
      if (params.watcher_ids) {
        params.watcher_ids = params.watcher_ids.join(',')
      }
      normalCourselist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
      if (this.query.years && this.query.years.length) {
        this.query.years = this.query.years.split(",").map(v => { return Number(v) });
      }
      if (this.query.watcher_ids && this.query.watcher_ids.length) {
        this.query.watcher_ids = this.query.watcher_ids.split(",").map(v => { return Number(v) });
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      if (params.years) {
        params.years = params.years.filter((v) => v).join(",");
      }
      if (params.watcher_ids) {
        params.watcher_ids = params.watcher_ids.filter((v) => v).join(",");
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    opencreateDialog() {
      this.createDialog = true
    },
    formateWatcher(val) {
      if (val && val.length) {
        return val.map(v => {
          return v.alias
        }).join(',')
      } else {
        return '暂无'
      }
    },
    handleToEdit(row) {
      this.editDialog = true
      this.editId = row.id
    },
    handleToCalendar(row) {
      this.$router.push(`/product/calendar?id=${row.id}`)
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm("确定要删除这些信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map(v => { return v.id })
        normalCourselisttDelete({ ids }).then((res) => {
          this.fetchList(this.query);
        });
      })
    },

  }
};
</script>

<style lang="less" scoped>
.content {
}
</style>
