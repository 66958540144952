<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>模考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">试卷管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap"></div>
    </div>
    <el-card style="margin-top: 20px; padding-top: 20px">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="试卷名称">
          <el-input
            v-model.trim="query.name_keyword"
            placeholder="试卷名称"
            @input="getList(query, true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="院校专业">
          <el-input
            v-model.trim="query.college_major_keyword"
            placeholder="院校专业"
            @input="getList(query, true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="出题人">
          <el-input
            v-model.trim="query.author_keyword"
            placeholder="学长名称/手机号"
            @input="getList(query, true)"
            clearable
          ></el-input>
        </el-form-item>
         <el-form-item label="模考名称">
           <el-select clearable v-model="query.mock_exam_id" filterable placeholder="请选择模考名称" @change="getList(query, true)">
          <el-option
            v-for="item in mockOption"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        </el-form-item>
       
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName[0]"></TableTittle>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" fixed="left">
        </el-table-column>
        <el-table-column prop="id" label="ID" show-overflow-tooltip width="100">
        </el-table-column>
        <el-table-column
          prop="name"
          label="试卷名称"
          show-overflow-tooltip
          width="260"
        >
        </el-table-column>
        <el-table-column
          prop="year"
          label="年份"
          show-overflow-tooltip
          width="100"
        >
        </el-table-column>
        <el-table-column label="院校专业" show-overflow-tooltip width="260">
          <template slot-scope="scope">
            {{ scope.row.college_name }}/({{ scope.row.major_code }}){{
              scope.row.major_name
            }}
          </template>
        </el-table-column>
        <el-table-column label="科目" show-overflow-tooltip width="260">
          <template slot-scope="scope">
            {{
              scope.row.subjects && scope.row.subjects.length
                ? scope.row.subjects
                    .map((v) => {
                      return `(${v.subject_code})${v.subject_name}`;
                    })
                    .join(",")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="已发放" show-overflow-tooltip width="100">
          <template slot-scope="scope"> {{ scope.row.send_count }}人 </template>
        </el-table-column>
        <el-table-column label="已查看" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            {{ scope.row.readed_count }}人
          </template>
        </el-table-column>
        <el-table-column label="未查看" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            {{ scope.row.unreaded_count }}人
          </template>
        </el-table-column>
        <el-table-column label="出题人" show-overflow-tooltip width="200">
          <template slot-scope="scope">
            {{ scope.row.author_realname }}/{{ scope.row.author_mobile }}
          </template>
        </el-table-column>
        <el-table-column label="阅卷人" show-overflow-tooltip width="120">
          <template slot-scope="scope">
            {{ scope.row.corrector_realname }}
          </template>
        </el-table-column>
        <el-table-column label="提交时间" width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="审核时间" width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToDetail(scope.row)"
              >查看</el-link
            >
            <el-link
              type="primary"
              @click="handleToEdit(scope.row)"
              :underline="false"
              >编辑</el-link
            >
            <el-link
              type="danger
            "
              @click="handleToRemove(scope.row.id)"
              :underline="false"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button size="mini" type="danger" @click="handleToDeleteAll()"
          >批量删除</el-button
        >
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <EditPaper
      :id="editId"
      :isVisible="editDialog"
      @close="editDialog = false"
      @submit="getList()"
    />
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import EditPaper from "@/components/DialogComponents/PracticeTest/EditPaper.vue";

import {
  mockexampaperlist,
  mockexampaperDelete,
  mockexampaperlistDelete,
  mockexamlist
} from "@/request/api";
export default {
  components: {
    TableTittle,
    EditPaper,
  },
  data() {
    return {
      TableName: ["试卷列表"],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        check_status: 1,
      },
      pageTotal: 0,
      tableData: [],
      createDialog: false,
      editId: null,
      editDialog: false,
      detailId: null,
      detailDialog: false,
      chooseList: [],
      mockOption:[]
    };
  },
  filters: {},
  watch: {},
  created() {
    this.parseQuery();

    this.getList();
    this.mockExamination()
  },
  mounted() {},
  methods: {
     mockExamination() {
      
      let params = {
       
        isPaging: 0,
        cid: localStorage.getItem("cid"),
      }
      mockexamlist(params).then((res) => {
        // console.log("rr",res)
        this.mockOption = res.data.list
        // this.tableData = res.data.list || [];
        // this.pageTotal = res.data.count;
      });

    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList();
    },

    getList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      mockexampaperlist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    handleToEdit(item) {
      this.editId = item.id;
      this.editDialog = true;
    },
    handleToDetail(item) {
      this.$router.push(`/practicetest/papermnagent/detail/${item.id}`);
    },
    handleToRemove(id) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        mockexampaperDelete({ id }).then((res) => {
          this.$nextTick(() => {
            this.getList(this.query);
          });
        });
      });
    },
    handleSelectionChange(val) {
      this.chooseList = val;
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error("还没有选择要删除的数据");
        return;
      }
      this.$confirm("确定要删除这些信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map((v) => {
          return v.id;
        });
        mockexampaperlistDelete({ ids }).then((res) => {
          this.getList(this.query);
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>