<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/product/lesson' }">课程列表</el-breadcrumb-item>
        <el-breadcrumb-item>课程表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button
          type="primary"
          size="small"
          @click="createDialog=true"
        >新建</el-button>
      </div>
    </div>

    <el-card
      style="margin-top: 20px"
      class="card"
    >
      <el-calendar v-model="calendarValue">
        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
        <template
          slot="dateCell"
          slot-scope="{date, data}"
        >
          <div class="date-cell">
            <div
              class="cell-title"
              :class="{'isSelected': data.isSelected,'notMonth':notMonth(data)}"
            >
              {{ data.day.split('-').slice(2).join('-') }}
            </div>
            <div class="topic-wrap">
              <div
                class="topic-item"
                v-for="(item,index) in formateDateCell(date,data)"
                :key="index"
                v-show="index<splice"
                @click.stop="opendetailDialog(item)"
              >
                <!--   橙色  -->
                <div
                  class="point"
                  style="background-color:#E6A23C;"
                  v-if="[0,20,21,41].includes(item.plan_status)"
                ></div>
                <!--   蓝色  -->
                <div
                  class="point"
                  style="background-color:#409EFF;"
                  v-if="[1,40].includes(item.plan_status)"
                ></div>
                <!--   红色  -->
                <div
                  class="point"
                  style="background-color:#F56C6C;"
                  v-if="[22,42,5,46].includes(item.plan_status)"
                ></div>
                <!--   绿色  -->
                <div
                  class="point"
                  style="background-color:#67C23A;"
                  v-if="[43,45].includes(item.plan_status)"
                ></div>
                <div class="topic-name">
                  <span class="time">{{$moment(item.plan_start_time).format("HH:mm")}}</span>
                  {{item.course_theme}}
                </div>
              </div>
              <PublishPopover
                :list="formateDateCell(date,data)"
                :date="date"
                v-if="formateDateCell(date,data)&&formateDateCell(date,data).length>splice"
                :splice="splice"
                @submit="opendetailDialog"
              />
            </div>
          </div>
        </template>
      </el-calendar>

    </el-card>
    <AddCoursePlan
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="submitInit()"
      :company_normal_course_id="Number($route.query.id)||null"
      :student_id="Number($route.query.student_id)||null"
    />
    <CalendarDetail
      :isVisible="detailDialog"
      :company_normal_course_id="Number($route.query.id)"
      @close="detailDialog=false"
      :plan_id="plan_id"
      @submit="fetchList()"
    />

  </div>
</template>

<script>

import PublishPopover from "@/components/DialogComponents/Product/PublishPopover.vue"
import AddCoursePlan from "@/components/DialogComponents/Product/AddCoursePlan.vue";
import CalendarDetail from "@/components/DialogComponents/Product/CalendarDetail.vue";



import { manageUrl } from "@/utils/helper";
export default {
  components: {

    AddCoursePlan,
    PublishPopover,
    CalendarDetail
  },
  data() {
    return {
      calendarValue: new Date().getTime(),
      results: [],
      detailDialog: false,
      plan_id: null,
      createDialog: false,
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      splice: 0,
    };
  },
  mounted() {
    this.heightComputed(document.documentElement.clientHeight)
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        this.windowHeight = window.fullHeight;  // 高
        this.heightComputed(this.windowHeight)
      })()
    }
  },
  created() {
    this.fetchList();
  },
  watch: {
    calendarValue(value) {
      this.fetchList()
    },
  },
  computed: {

  },
  methods: {
    heightComputed(val) {
      if (val < 950) {
        this.splice = 1
      } else if (val > 950 && val < 1200) {
        this.splice = 2
      } else if (val > 1200) {
        this.splice = 3
      }
    },
    fetchList() {
      const startOfMonth = this.$moment(this.calendarValue).startOf('month').format('x');
      const endOfMonth = this.$moment(this.calendarValue).endOf('month').format('x');
      let str = manageUrl()
      this.$axios({
        url: `${str}/api/v1/lesson/coursePlan/list`,
        method: 'get',//可以省略 不写默认是get
        params: {
          isPaging: 0,
          start_plan_start_time: startOfMonth,
          end_plan_start_time: endOfMonth,
          company_normal_course_id: this.$route.query.id,
          _deletes: -1,
          student_id: this.$route.query.student_id ? this.$route.query.student_id : '',
        },
      }).then(res => {
        this.results = res.data.list
        this.results.sort(this.compare('plan_start_time'));
      })

    },
    compare(attr) {
      return function (a, b) {
        var val1 = a[attr];
        var val2 = b[attr];
        return val1 - val2;
      }
    },
    formateDateCell(date, data) {
      if (this.results.length) {
        let item_time = this.$moment(date.getTime()).startOf('day').format('x')
        let arr = []
        this.results.forEach((v) => {
          if (this.$moment(v.plan_start_time).startOf('day').format('x') == item_time) {
            arr.push(v)
          }
        })
        return arr
      }
    },
    submitInit() {
      this.fetchList();
    },
    opendetailDialog(item) {
      this.plan_id = item.id
      this.detailDialog = true
    },
    notMonth(data) {
      if (data.type == 'current-month') {
        return false
      } else {
        return true
      }
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .card {
    flex: 1;
    overflow-y: scroll;
  }
  .date-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    .cell-title {
      color: #333;
      font-weight: 600;
      padding: 4px;
      font-size: 12px;
    }
    .isSelected {
      border-radius: 50%;
      color: #fff;
      background: #409eff;
    }
    .notMonth {
      color: #ccc;
    }
    .topic-wrap {
      width: 100%;
      padding: 0px 4px;

      .topic-item {
        background-color: #ebf5ff;
        & + .topic-item {
          margin-top: 5px;
        }
        display: flex;
        align-items: center;
        padding: 4px;
        border-radius: 4px;

        .point {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #67c23a;
          margin-right: 10px;
        }
        .point-other {
          background: #f56c6c;
        }
        .topic-name {
          width: 100%;
          font-size: 12px;
          color: #606266;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          .time {
            color: #909399;
          }
        }
      }
      /*     .topic-item:hover {
      background: #333;
    } */
      .more-content {
        cursor: pointer;
        margin-top: 16px;
        text-align: center;
        color: #999;
      }
    }
  }
  /deep/.el-card__body {
    height: 95%;
  }
  /deep/.el-calendar {
    height: 95%;
  }
  /deep/.el-calendar__body {
    height: 95%;
  }
  /deep/.el-calendar-day:hover {
    background-color: transparent;
  }
  /deep/.el-calendar__title {
    font-size: 18px;
    font-weight: 600;
  }
  /deep/ .current:hover {
    background-color: #f2f8fe;
  }
  /deep/.el-calendar-table {
    height: 100%;
    th {
      height: 20px;
      text-align: center;
      background: #f2f6fc;
    }
    th:before {
      content: "周";
    }
    tr {
    }
  }
}
</style>
