<template>
  <div class="content">
    <div class="demo-drawer__content">
      <el-form
        :label-position="labelPosition"
        :model="form"
        :inline="true"
        size="small"
      >
        <el-form-item label="部门">
          <el-select
            v-model="form.dept"
            placeholder="请选择"
            @change="submit"
            clearable
          >
            <el-option
              v-for="item in $store.state.DeptList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色">
          <el-select
            v-model="form.role"
            placeholder="请选择"
            @change="submit"
            clearable
          >
            <el-option
              v-for="item in $store.state.roleOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间" prop="last_advance_time">
          <el-date-picker
            v-model="create_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="submit"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="demo-drawer__footer">
        <!--         <el-button @click="emitClose" class="elbutton" size="small"
          >取 消</el-button
        >
        <el-button type="primary" class="elbutton" size="small">确定</el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      labelPosition: "left",
      create_time: "",
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit() {
      // 创建时间区间处理
      if (this.create_time) {
        this.form.start_create_time = this.create_time[0];
        this.form.end_create_time = this.create_time[1] + 86399000;
      } else {
        this.form.start_create_time = "";
        this.form.end_create_time = "";
      }
      this.$emit("submit", { screenForm: this.form });
    },
  },
};
</script>

<style lang="less" scoped>
.demo-drawer__content {
  padding-top: 24px;
  height: 100%;
}
.el-form {
}
.demo-drawer__footer {
  margin-bottom: 24px;
  float: right;
}
</style>