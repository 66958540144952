<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料</el-breadcrumb-item>
        <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button
          type="primary"
          size="small"
          @click="createDialog=true"
        >新建</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px;padding-top:20px;">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="产品名称">
          <el-input
            v-model.trim="query.product_name_keyword"
            placeholder="输入产品名称"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="产品规格">
          <el-input
            v-model.trim="query.product_sku_keyword"
            placeholder="输入产品规格"
            @input="getList(query,true)"
            clearable
            style="width:150px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="年份">
          <el-select
            v-model="query.year"
            placeholder="请选择考研年份"
            clearable
            style="width:150px;"
            @change="getList(query,true)"
          >
            <el-option
              label="不限"
              value=""
            ></el-option>
            <el-option
              label="2022年"
              value="2022"
            ></el-option>
            <el-option
              label="2023年"
              value="2023"
            ></el-option>
            <el-option
              label="2024年"
              value="2024"
            ></el-option>
            <el-option
              label="2025年"
              value="2025"
            ></el-option>
            <el-option
              label="2026年"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建人">
          <el-select
            v-model="query.creator"
            placeholder="请选择"
            @change="getList(query,true)"
            clearable
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合作厂家">
          <el-input
            v-model.trim="query.factory_name_keyword"
            placeholder="输入合作厂家"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            class="datetime"
            v-model="createDatetime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList(query,true)"
            clearable
            style="width:260px;"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName"></TableTittle>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="50"
        > </el-table-column>
        <el-table-column
          prop="product_name"
          label="资料名称"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            《{{scope.row.product_name}}》
          </template>
        </el-table-column>
        <el-table-column
          prop="year"
          label="年份"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.year?scope.row.year+"年":"不限"}}
          </template>
        </el-table-column>
        <el-table-column
          prop="product_sku"
          label="规格"
          width="160"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="quantity"
          label="采购数量"
          width="100"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="采购总价"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.total_amount}}元
          </template>
        </el-table-column>
        <el-table-column
          prop="factory_name"
          label="合作厂家"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="260"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="采购人"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.creator.alias}}
          </template>
        </el-table-column>
        <el-table-column
          label="采购时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")}}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToEdit(scope.row.id)"
            >编辑</el-link>
            <el-link
              type="danger
            "
              @click="handleToRemove(scope.row.id)"
              :underline="false"
            >删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button
          size="small"
          type="danger"
          @click="handleToDeleteAll()"
        >批量删除</el-button>
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddStockData
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="getList(query)"
    />
    <EditStockData
      :id="editId"
      :isVisible="editDialog"
      @close="editDialog=false"
      @submit="getList(query)"
    />
  </div>
</template>

<script>
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import AddStockData from "@/components/DialogComponents/Stock/AddStockData.vue"
import EditStockData from "@/components/DialogComponents/Stock/EditStockData.vue"


import {
  stocksliplist,
  stockslipDelete,
  stocksliplistDelete
} from "@/request/api";
export default {
  components: {
    TableTittle,
    AddStockData,
    EditStockData
  },
  data() {
    return {
      TableName: "库存列表",
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      pageTotal: 0,
      tableData: [],
      createDialog: false,
      editId: null,
      editDialog: false,
      chooseList: [],
      createDatetime: "",
    };
  },
  filters: {

  },
  watch: {
    // 时间数据监听
    createDatetime(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
        this.getList(this.query, true)
      }
    },
  },
  mounted() {
    this.getList(this.query);
    this.$store.dispatch('getStaffList')
    this.$store.dispatch('getProducts')
  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    getList(query, isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      stocksliplist(query).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    handleToEdit(id) {
      this.editId = id
      this.editDialog = true
    },
    handleToRemove(id) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        stockslipDelete({ id }).then((res) => {
          this.$nextTick(() => {
            this.getList(this.query);
          });
        });
      })
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm("确定要删除这些信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map(v => { return v.id })
        stocksliplistDelete({ ids }).then((res) => {
          this.getList(this.query);
        });
      })
    }

  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.card-header {
  display: flex;
  justify-content: space-between;

  .el-button {
    height: 35px;
    line-height: 10px;
  }
}
.block {
  margin-bottom: 14px;
  float: right;
}
.el-input {
  width: 200px;
}
</style>