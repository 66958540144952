<template>
  <div class="content">
    <div class="welcome-div">
      <span>欢迎你！{{ companyUser.alias }}</span>
      <span>{{ date | dateFormat("YYYY-MM-DD HH:mm:ss") }}</span>
    </div>
    <SetPassword
      :isVisible="SetPasswordDialog"
      @close="SetPasswordDialog = false"
      @submit="SetPasswordSubmit"
    ></SetPassword>
  </div>
</template>

<script>
import SetPassword from "../components/LoginComponents/SetPassword/SetPassword.vue";
import { setpassword, userInfo, companychoose } from "../request/api";
export default {
  components: {
    SetPassword,
  },
  data() {
    return {
      SetPasswordDialog: false,
      date: new Date(),
    };
  },
  computed: {
    userInfo: function () {
      if (localStorage.getItem("userInfo")) {
        return JSON.parse(localStorage.getItem("userInfo"));
      } else {
        return {}
      }
    },
    companyUser: function () {
      if (localStorage.getItem("companyUser")) {
        return JSON.parse(localStorage.getItem("companyUser"));
      } else {
        return {}
      }
    },
  },
  mounted() {
    let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.date = new Date(); // 修改数据date
    }, 1000);
  },
  created() {
    if (this.$route.query.userInfo) {
      let userInfo = JSON.parse(this.$route.query.userInfo)
      localStorage.setItem('token', userInfo.token)
      localStorage.setItem('userInfo', this.$route.query.userInfo)
      setTimeout(() => {
        this.choosecompany()
      }, 500);
    }
    if (JSON.parse(localStorage.getItem("userInfo")).isPwd == 0) {
      this.SetPasswordDialog = !this.SetPasswordDialog;
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  },
  methods: {
    // 机构切换
    choosecompany() {
      let query = {
        cid: this.$route.query.cid,
      };
      companychoose(query).then((res) => {
        localStorage.setItem("token", res.token);
        localStorage.setItem("cid", this.$route.query.cid);
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.permissions)
        );
        window.location.href = '/overview'
      });
    },
    SetPasswordSubmit({ form }) {
      setpassword({ password: form.secondPwd }).then((res) => {
        if (res.code == 0) {
          this.$notify.success({
            title: "提示",
            message: "设置成功",
          });
          localStorage.setItem("userInfo", JSON.stringify(res));
          this.SetPasswordDialog = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/overviewimg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
.welcome-div {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  left: 50%;
  top: 7%;
  transform: translate(-50%, 0);
  span {
    font-size: 24px;
    color: #303133;
    font-family: PingFang SC;
  }
}
</style>