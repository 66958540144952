<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>模考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">模考管理</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="button-wrap">
        <el-button
          type="primary"
          size="small"
          @click="createDialog=true"
        >
          新建
        </el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px;padding-top:20px;">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="模考名称">
          <el-input
            v-model.trim="query.name_keyword"
            placeholder="模考名称"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="院校专业">
          <el-input
            v-model.trim="query.college_major_keyword"
            placeholder="院校专业"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName[0]"></TableTittle>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
            textAlign: 'center',
            color: '#909399',
            fontFamily: 'PingFang SC',
            fontSize: '14px',
            fontWeight: '400',
            backgroundColor: '#F2F6FC',
          }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column
          prop="name"
          label="模考名称"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="院校学科"
          width="260"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.college_name?scope.row.college_name:""}}
            {{scope.row.college_name&&scope.row.major_name?"/":""}}
            {{scope.row.major_code?`(${scope.row.major_code})${scope.row.major_name}`:""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="paper_demand"
          label="试卷要求"
          width="260"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop=""
          label="已提交"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.commit_count}}份
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="已通过"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.access_count}}份
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="已驳回"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.refuse_count}}份
          </template>
        </el-table-column>
        <el-table-column
          label="创建人"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.creator.alias}}
          </template>
        </el-table-column>
        <el-table-column
          label="创建日期"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="修改日期"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToDetail(scope.row)"
            >查看</el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="handleToExtension(scope.row)"
            >推广</el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="handleToEdit(scope.row.id)"
            >编辑</el-link>
            <el-link
              type="danger"
              @click="handleToRemove(scope.row.id)"
              :underline="false"
            >删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddPracticeTest
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="getList()"
    />
    <EditPracticeTest
      :id="editId"
      :isVisible="editDialog"
      @close="editDialog=false"
      @submit="getList()"
    />
    <PracticeExtension
      :id="editId"
      :isVisible="extensionDialog"
      @close="extensionDialog=false"
    />

  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddPracticeTest from "@/components/DialogComponents/PracticeTest/AddPracticeTest.vue"
import EditPracticeTest from "@/components/DialogComponents/PracticeTest/EditPracticeTest.vue"
import PracticeExtension from "@/components/DialogComponents/PracticeTest/PracticeExtension.vue"




import {
  mockexamlist,
  mockexamdelete
} from "@/request/api";
export default {
  components: {
    TableTittle,
    AddPracticeTest,
    PracticeExtension,
    EditPracticeTest
  },
  data() {
    return {
      TableName: ["模考列表"],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      pageTotal: 0,
      tableData: [],
      createDialog: false,
      editId: null,
      editDialog: false,
      extensionDialog: false,
    };
  },
  filters: {

  },
  watch: {

  },
  created() {
    this.parseQuery();

    this.getList();
  },
  mounted() {

  },
  methods: {

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList();
    },

    getList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      mockexamlist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    handleToEdit(id) {
      this.editId = id
      this.editDialog = true
    },
    handleToDetail(item) {
      this.$router.push(`/practicetest/management/detail/${item.id}`)
    },
    handleToExtension(item) {
      this.editId = item.id
      this.extensionDialog = true
    },
    handleToRemove(id) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        mockexamdelete({ id }).then((res) => {
          this.$nextTick(() => {
            this.getList(this.query);
          });
        });
      })
    },
  },
};
</script>

<style lang="less" scoped>
</style>