<template>
  <div>
    <el-dialog title="新建"
               :append-to-body='false'
               :visible.sync="dialogFormVisible"
               width="700px"
               :close-on-click-modal="false"
               @close="emitClose"
               @open="open">
      <el-form :model="dataForm"
               label-position="left"
               ref="dataForm"
               :rules="rules"
               label-width="80px"
               size="small">
        <el-form-item label="选择学员"
                      prop="student">
          <div class="chose"
               ref="student"
               @click="choseStudent">选择学员</div>
          <el-tag size="medium"
                  closable
                  v-for="(item, index) in studentList"
                  :key="index"
                  @close="closeTag(item)">{{item.realname}}/{{item.phone}}</el-tag>
        </el-form-item>
        <el-form-item label="选择课程"
                      prop="course">
          <el-select v-model="dataForm.course"
                     placeholder="请选择"
                     reserve-keyword
                     remote
                     filterable
                     @change="handlecourseChange"
                     :remote-method="remoteMethod"
                     clearable
                     collapse-tags>
            <el-option v-for="item in courseList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择章节"
                      prop="chapter">
          <el-select v-model="dataForm.chapter"
                     placeholder="请选择"
                     clearable
                     collapse-tags>
            <el-option v-for="item in chapterList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择试卷"
                      prop="paper">
          <el-select v-model="dataForm.paper"
                     placeholder="请选择"
                     clearable
                     collapse-tags>
            <el-option v-for="item in paperList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.measure_scroll_obj.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传答题"
                      prop="test">
          <el-upload drag
                     ref="upload"
                     :action="imgUploadUrl"
                     :file-list="fileList"
                     multiple
                     accept=".jpg,.png,.doc,.docx,.ppt,.pptx,.pdf"
                     :on-remove="fileListRemove"
                     :on-success="fileListSucccess"
                     :limit="12">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确 定</el-button>
      </div>
      <student :studentVisible="studentVisible"
               @close="close"
               @checkList="checkList"></student>
    </el-dialog>
  </div>
</template>
<script>
import { adminUrl } from '@/utils/helper.js'
import student from '@/components/DialogComponents/testPaper/student.vue'
import { imgUploadUrl } from '@/request/http'
export default {
  components: { student },
  data() {
    return {
      fileList: [],
      imgUploadUrl: imgUploadUrl(),
      courseList: [],
      chapterList: [],
      paperList: [],
      studentList: [],
      studentVisible: false,
      dataForm: {},
      rules: {
        student: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.studentList.length == 0) {
                callback(new Error('请选择学员'))
              } else {
                callback()
              }
            },
          },
        ],
        test: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.fileList.length == 0) {
                callback(new Error('请上传答题'))
              } else {
                callback()
              }
            },
          },
        ],
        course: [{ required: true, message: '请选择课程', trigger: 'change' }],
        chapter: [{ required: true, message: '请选择章节', trigger: 'change' }],
        paper: [{ required: true, message: '请选择试卷', trigger: 'change' }],
      },
      query: {
        sort: '1',
        pageIndex: 1,
        pageSize: localStorage.getItem('pageSize')
          ? Number(localStorage.getItem('pageSize'))
          : 10,
        isPaging: 0,
      },
      dialogFormVisible: false,
      crm_correctors: '',
    }
  },
  created() {
    this.$store.dispatch('getStaffList')
  },
  props: {
    addVisible: {
      type: Boolean,
      default: false,
    },
    chooseList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  watch: {
    addVisible(val) {
      this.dialogFormVisible = val
    },
    studentList(val) {
      if (val.length > 0) {
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate(['student'])
        })
      }
    },
  },
  methods: {
    handlecourseChange(val) {
      if (val) {
        let str = adminUrl()
        this.$axios({
          url: `${str}/api/v1/courseChapter/list`,
          method: 'get', //可以省略 不写默认是get
          params: { product_id: val },
        }).then((res) => {
          this.chapterList = res.data.list
          res.data.list.map((item) => {
            item.children.map((k) => {
              if (k.extra_type == 0 && k.measure_scroll_obj != null) {
                this.paperList.push(k)
              }
            })
          })
        })
      } else {
        this.dataForm.chapter = ''
        this.dataForm.paper = ''
        this.courseList = []
        this.chapterList = []
        this.paperList = []
      }
    },
    //打开弹窗展示50条数据
    getCourse() {
      this.courseList = []
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/course/list`,
        method: 'get', //可以省略 不写默认是get
        params: { isPaging: 1, pageIndex: 1, pageSize: 20 },
      }).then((res) => {
        this.courseList = res.data.list
      })
    },
    ///获取全部课程远程搜索
    remoteMethod(query) {
      if (query !== null) {
        let str = adminUrl()
        this.$axios({
          url: `${str}/api/v1/back/course/list`,
          method: 'get', //可以省略 不写默认是get
          params: { isPaging: 0, name: query },
        }).then((res) => {
          this.courseList = res.data.list
          console.log('res', res)
        })
      }
    },
    fileListRemove(file, fileList) {
      this.fileList = fileList
      console.log('jkjk', this.fileList)
    },
    fileListSucccess(response, file, fileList) {
      if (response.code == 400) {
        this.$message.error(response.error)
      } else {
        this.fileList.push({
          name: file.raw.name,
          url: response.data.url,
        })
        console.log('this.fileList', this.fileList)
        this.$refs['dataForm'].clearValidate(['test'])
      }
    },
    // 课程列表请求
    checkList(val) {
      this.studentList = val
      console.log('pppp', val)
    },
    closeTag(val) {
      this.studentList = this.studentList.filter((item) => {
        return item.id != val.id
      })
      console.log('this.chooo', this.studentList)
    },
    close() {
      this.studentVisible = false
    },
    open() {
      this.getCourse()
    },

    emitClose() {
      this.dataForm.paper = ''
      this.dataForm.chapter = ''
      this.dataForm.course = ''
      this.paperList = []
      this.courseList = []
      this.paperList = []
      this.studentList = []
      this.$emit('emitClose')
      this.$refs['dataForm'].clearValidate()
    },
    choseStudent() {
      this.studentVisible = true
    },
    submit() {
      console.log('this.dataform', this.dataForm.student)
      let img = []
      let file = []
      this.fileList.map((i) => {
        if (i.name.includes('png') || i.name.includes('jpg')) {
          img.push(i.url)
        }
        if (
          i.name.includes('doc') ||
          i.name.includes('docx') ||
          i.name.includes('ppt') ||
          i.name.includes('pptx') ||
          i.name.includes('pdf')
        ) {
          file.push(i.url)
        }
      })

      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          console.log('dataForm', this.dataForm)
          let params = { arr_student_id: [] }
          params.measure_scroll_id = this.dataForm.paper
          if (img.length > 0) {
            params.answer_img_urls = img.join(',')
          }
          if (file.length > 0) {
            params.answer_file_urls = file.join(',')
          }
          this.studentList.map((i) => {
            params.arr_student_id.push(i.id)
          })
          console.log('erer', this.studentList)
          console.log('parmas', params)
          let str = adminUrl()
          this.$axios({
            url: `${str}/api/v1/back/measureScroll/userMeasureScroll/createByStudent`,
            method: 'post', //可以省略 不写默认是get
            data: params,
          }).then((res) => {
            this.$message({
              message: '提交成功',
              type: 'sucess',
            })
            this.$emit('getList')
            this.emitClose()
          })
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
::v-deep .el-dialog__header {
  background: #ebeef5;
}
.chose {
  cursor: pointer;
  color: #409eff;
}
</style>