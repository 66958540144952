<template>
  <div class="content">
    <el-dialog
      top="5vh"
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="dialogFormVisible = false"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        :rules="rules"
        ref="dataForm"
        label-width="130px"
        class="dataForm"
      >
        <el-form-item label="订单用户：" prop="users">
          <div class="tag-wrap">
            <el-tag
              class="user-item"
              size="medium"
              :key="item.id"
              v-for="item in users"
              closable
              :disable-transitions="false"
              @close="handleUserRemove(item)"
            >
              {{
                item.phone
                  ? `${item.realname}/${item.phone}`
                  : item.realname
              }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="选择商品：" prop="ref_id">
          <div class="tag-wrap">
            <el-tag
              class="user-item"
              size="medium"
              :key="item.id"
              v-for="item in products"
              closable
              :disable-transitions="false"
              @close="handleProductsRemove(item)"
              type="success"
            >
              {{ item.name }}
            </el-tag>
            <el-button type="text" @click="handleGoodChoose"
              >选择商品</el-button
            >
          </div>
        </el-form-item>
      </el-form>

      <div class="footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="submit()" plain>确定</el-button>
      </div>
    </el-dialog>
    <GoodsTabsChoose
      :visible="goodsChooseDialog"
      type="multiple"
      @close="goodsChooseDialog = false"
      @submit="saveGoods"
      :goods="products"
    />
  </div>
</template>

<script>
import GoodsTabsChoose from "../../DialogComponents/GoodsTabsChoose.vue";
import { adminUrl } from "@/utils/helper.js";
export default {
  components: {
    GoodsTabsChoose,
  },
  data() {
    return {
      title: "开通App课程",
      dialogFormVisible: false,
      users: [],
      products: [],
      goodsChooseDialog: false,
      dataForm: {
        ref_type: null,
        ref_id: "",
        price: "",
        pay_type: 3,
      },
      rules: {
        ref_type: [
          { required: true, message: "请选择商品类型", trigger: "blur" },
        ],
        price: [{ required: true, message: "请填写商品价格", trigger: "blur" }],
        users: [
          {
            required: true,
            message: "请选择用户",
            trigger: "change",
            validator: (rule, value, callback) => {
              if (!this.users.length ) {
                return callback(new Error(rule.message));
              } else {
                callback();
              }
            },
          },
        ],
        ref_id: [
          {
            required: true,
            message: "请选择商品",
            trigger: "change",
            validator: (rule, value, callback) => {
              if (!this.products.length) {
                return callback(new Error(rule.message));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },

  created() {},
  methods: {
    open(list) {
      this.users = list;
      this.products = []
      this.dialogFormVisible = true;
    },
    submit() {
      let time = this.SecondToDate(
        Number(this.users.length * 5) 
      );
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let loadingInstance = this.$loading({
            text: "订单任务创建中...请不要关闭页面!",
            background: "rgba(0, 0, 0, 0.8)",
          });

          this.loading = true;
          let user_val = this.users.map((v) => v.phone);
          let query = {
            arr_ref_data: this.products.map((v) => {
              return {
                ref_type: v.type,
                ref_id: v.id,
                price: v.price,
              };
            }),
            pay_type: 3,
            users: [],
            admin_id:1,
            user_mobiles:user_val
            // user_mobiles: this.userMobiles.length
            //   ? this.userMobiles.map((v) => {
            //       return v.mobile;
            //     })
            //   : [],
          };
          let str = adminUrl();
          this.$axios({
            url: `${str}/api/v1/back/member/order/build/multi`,
            method: "post", //可以省略 不写默认是get
            data: query,
          }).then((res) => {
            if (res.data == null) {
              this.$message.success("请求成功");
              this.dialogFormVisible = false
              loadingInstance.close();
        //       this.$confirm(
        //         `已成功提交订单创建任务，后台正在陆续创建中，预计需要${time}完成！`,
        //         "提示",
        //         {
        //           confirmButtonText: "好的",
        //           cancelButtonText: "查看任务列表",
        //           type: "warning",
        //         }
        //       )
        //         .then(() => {
        //           this.handleClose();
        //         })
        //         .catch(() => {
        //           this.openAdminOrderTableDialog();
        //         });
            }
          });
          //   buildmulti(query).then(res => {
          //     if (res == null) {
          //       this.$message.success('请求成功')
          //       loadingInstance.close();
          //       this.$confirm(`已成功提交订单创建任务，后台正在陆续创建中，预计需要${time}完成！`, '提示', {
          //         confirmButtonText: '好的',
          //         cancelButtonText: '查看任务列表',
          //         type: 'warning'
          //       }).then(() => {
          //         this.handleClose()
          //       }).catch(() => {
          //         this.openAdminOrderTableDialog()
          //       });
          //     }
          //   })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    SecondToDate(msd) {
      var time = msd;
      if (null != time && "" != time) {
        if (time > 60 && time < 60 * 60) {
          time =
            parseInt(time / 60.0) +
            "分钟" +
            parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60) +
            "秒";
        } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
          time =
            parseInt(time / 3600.0) +
            "小时" +
            parseInt(
              (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
            ) +
            "分钟" +
            parseInt(
              (parseFloat(
                (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
              ) -
                parseInt(
                  (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
                )) *
                60
            ) +
            "秒";
        } else if (time >= 60 * 60 * 24) {
          time =
            parseInt(time / 3600.0 / 24) +
            "天" +
            parseInt(
              (parseFloat(time / 3600.0 / 24) - parseInt(time / 3600.0 / 24)) *
                24
            ) +
            "小时" +
            parseInt(
              (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
            ) +
            "分钟" +
            parseInt(
              (parseFloat(
                (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
              ) -
                parseInt(
                  (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
                )) *
                60
            ) +
            "秒";
        } else {
          time = parseInt(time) + "秒";
        }
      }
      return time;
    },
    handleUserRemove(item) {
      this.users = this.users.filter((v) => v.id !== item.id);
    },
    handleProductsRemove(item) {
      this.products = this.products.filter((v) => v.id !== item.id);
    },
    handleGoodChoose() {
      this.goodsChooseDialog = true;
    },
    saveGoods(val) {
      this.products = val.goods;
    },
  },
};
</script>

<style lang="less" scoped>
.user-item {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>