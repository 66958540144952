<template>
  <div>
    <el-table
      :data="tableData"
      :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
      :cell-style="{ fontSize: '13px' }"
      border
    >
      <!--       <el-table-column
        label="记录时间"
        width="150"
      >
        <template slot-scope="scope">
          {{$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")}}
        </template>
      </el-table-column> -->
      <el-table-column
        prop="content_text"
        label="记录内容"
        show-overflow-tooltip
        min-width="400"
        align="left"
      >
        <template slot-scope="scope">
          <div>
            <span class="ait_span">{{formateAit_user(scope.row.ait_user_names)}}</span>
            <span>{{scope.row.content_text}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="service_subject_name"
        label="服务科目"
        width="230"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="service_type_names"
        label="服务类型"
        width="180"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="student_realname"
        label="关联学生"
        width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="记录人"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{scope.row.creator?scope.row.creator.alias:""}}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="150"
      >
        <template slot-scope="scope">
          <el-link
            type="primary"
            :underline="false"
            @click="handleToRecord(scope.row)"
          >查看详情</el-link>
          <el-link
            type="primary"
            :underline="false"
            @click="handleToRead(scope.row.id)"
            v-if="!scope.row.is_ait_read"
          >未读</el-link>
          <el-link
            type="info"
            :underline="false"
            v-if="scope.row.is_ait_read"
          >已读</el-link>
          <!--           <el-link
            type="danger"
            :underline="false"
            @click="handleToRemove(scope.row.id)"
          >删除</el-link> -->
        </template>
      </el-table-column>
    </el-table>
    <TeachingAndResearchDetail
      :id="detailId"
      :isVisible="detailDialog"
      @close="detailDialog=false"
    />
  </div>
</template>

<script>




import TeachingAndResearchDetail from "./TeachingAndResearchDetail.vue";
import { teacherServicelogaitRead, teacherServicelist } from "@/request/api";
export default {
  components: {
    TeachingAndResearchDetail
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {

      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      detailId: null,
      detailDialog: false,
    };
  },
  mounted() {

  },
  watch: {


  },
  methods: {
    handleToDetail(id) {
      /*      this.detailId = id
           this.detailDialog = true */

    },
    handleToRecord(row) {
      teacherServicelist({ student_id: row.student_id }).then((res) => {
        if (res.data.list.length) {
          let routerData = this.$router.resolve({
            path: `/service/teachingandresearch/record?student_id=${row.student_id}&&service_id=${res.data.list[0].id}`,
          });
          window.open(routerData.href, '_blank')
        } else {
          this.$message.error('该学员无服务记录')
          return
        }
      });
    },
    handleToRead(id) {
      let query = {
        id,
        is_read: 1,
      }
      teacherServicelogaitRead(query).then((res) => {
        this.$emit('submit')
        this.$store.dispatch('getTaskMsgCount')
      });
    },
    formateAit_user(val) {
      if (val) {
        return val.split(',').map((v => { return `@${v}` })).join(' ')
      } else {
        return ""
      }
    }

  },
};
</script>

<style lang="less" scoped>
.ait_span {
  color: #1f86fe;
  margin-right: 4px;

}
</style>