<template>
  <div>
    <el-dialog
      title="库存管理"
      :visible.sync="dialogFormVisible"
      width="620px"
      @close="emitClose"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item
          label="资料名称："
          prop="materialChoose"
        >
          <div class="tag-wrap">
            <el-button
              type="text"
              @click="openmaterialChoose"
              v-if="!Object.keys(materialChoose).length"
            >选择商品</el-button>
            <el-button
              type="text"
              @click="openmaterialChoose"
              v-else
            >{{materialChoose.name}}</el-button>
          </div>
        </el-form-item>
        <el-form-item label="数量：">
          <el-input-number
            v-model="dataForm.quantity"
            label="描述文字"
            size="small"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="采购总价："
          prop="total_amount"
        >
          <el-input
            v-model="dataForm.total_amount"
            placeholder="请输入采购总价"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="合作厂商："
          prop="factory_name"
        >
          <el-input
            v-model="dataForm.factory_name"
            placeholder="请输入合作厂商"
          >
          </el-input>
        </el-form-item>
        <div class="fotm-item">
          <div class="label">
            <span
              class="red"
              v-if="dataForm.quantity<0"
            >*</span>
            备注：
          </div>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              v-model="dataForm.remark"
              placeholder="请输入备注"
            >
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>

    </el-dialog>
    <MaterialChoose
      :visible="chooseDialog"
      :content_single="materialChoose"
      @close="chooseDialog=false"
      @submit="chooseSubmit"
      :single="true"
    />
  </div>
</template>

<script>

import { stockslipEdit, stockslipOne } from "@/request/api";
import MaterialChoose from "@/components/DialogComponents/DataList/DataChoose";
export default {
  components: {
    MaterialChoose
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dataForm: {
        quantity: 0,
      },
      dialogFormVisible: this.isVisible,

      chooseDialog: false,
      materialChoose: {},
      rules: {
        total_amount: [{ required: true, message: "请输入采购总价", trigger: "blur" },
        { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }],
        factory_name: [{ required: true, message: "请输入合作厂商", trigger: "blur" }],
        materialChoose: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!Object.keys(this.materialChoose).length) {
              callback(new Error('请选择资料'))
            } else {
              callback()
            }
          },
        }],
        remark: [{
          required: true,
          validator: (rule, value, callback) => {
            if (this.dataForm.quantity < 0) {
              callback(new Error('请输入备注'))
            } else {
              callback()
            }
          },
        }],
      },
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail(this.id)

      }
    },

  },
  methods: {
    fetchDetail(id) {
      stockslipOne({ id }).then((res) => {
        this.dataForm = res.data
        this.materialChoose = res.data.product_content
      });
    },
    emitClose() {
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.dataForm, {
            product_id: this.materialChoose.id
          })
          stockslipEdit(params).then((res) => {
            if (res.code == 0) {
              this.$message.success(`保存成功`);
              this.emitClose()
              this.$emit('submit')
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSubjectChoose() {
      this.subjectChooseDialog = true;
    },
    handleSubjectRemove(item) {
      this.subjects = this.subjects.filter((v) => v.id !== item.id);
    },
    openmaterialChoose() {
      this.chooseDialog = true
    },
    chooseSubmit(data) {
      this.materialChoose = data
    }
  },
};
</script>

<style lang="less" scoped>
.tag-wrap {
  display: flex;
  flex-wrap: wrap;
  .subject-item {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
.fotm-item {
  display: flex;
  .label {
    width: 90px;
    text-align: right;
    .red {
      color: red;
    }
  }
  /deep/.el-form-item {
    flex: 1;
    .el-form-item__content {
      margin-left: 10px !important;
    }
  }
}
</style>