<template>
  <div class="content">
    <el-dialog
      title="权限分配"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-tree
        :data="Treedata"
        :props="defaultProps"
        show-checkbox
        @check="handleCheckChange"
        default-expand-all
        node-key="id"
        ref="tree"
        highlight-current
      ></el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary" @click="submit">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      info: {},
      Treedata: this.$store.state.UserRuleList,
      defaultProps: {
        children: "children",
        label: "name",
      },
      selectionsObj: {
        // 要修改的角色id
        id: "",
        // 选中数据做处理之后
        rules: "",
      },
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.info = val;
      this.Treedata = this.$store.state.UserRuleList;
      this.$nextTick(() => {
        // 回显
        if (this.info.rules) {
          const rules = this.computeSelections(
            this.Treedata,
            this.info.rules.split(",").map((v) => Number(v))
          );
          this.$refs.tree.setCheckedKeys(rules);
        } else {
          this.$refs.tree.setCheckedKeys([]);
        }
      });
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    // 层级依赖计算
    computeSelections(rules, selections) {
      const results = [];
      rules.forEach((item) => {
        if (item.children && item.children.length) {
          results.push(...this.computeSelections(item.children, selections));
        } else {
          if (selections.includes(item.id)) {
            results.push(item.id);
          }
        }
      });
      return results;
    },
    handleCheckChange(item, obj) {
      this.selectionsObj.rules = String(
        obj.checkedKeys.concat(obj.halfCheckedKeys)
      );
    },
    submit() {
      this.selectionsObj.id = this.info.id;
      this.$emit("submit", { obj: this.selectionsObj });
    },
  },
};
</script>

<style lang="less" scoped>
</style>