var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{attrs:{"data":_vm.tableData,"header-cell-style":{
        textAlign: 'center',
        color: '#909399',
        fontFamily: 'PingFang SC',
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: '#F2F6FC',
      },"cell-style":{ fontSize: '13px' },"border":""}},[_c('el-table-column',{attrs:{"prop":"content_text","label":"记录内容","show-overflow-tooltip":"","min-width":"400","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('span',{staticClass:"ait_span"},[_vm._v(_vm._s(_vm.formateAit_user(scope.row.ait_user_names)))]),_c('span',[_vm._v(_vm._s(scope.row.content_text))])])]}}])}),_c('el-table-column',{attrs:{"prop":"service_subject_name","label":"服务科目","width":"230","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"service_type_names","label":"服务类型","width":"180","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"student_realname","label":"关联学生","width":"150","align":"center"}}),_c('el-table-column',{attrs:{"label":"记录人","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.creator?scope.row.creator.alias:"")+" ")]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.handleToRecord(scope.row)}}},[_vm._v("查看详情")]),(!scope.row.is_ait_read)?_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.handleToRead(scope.row.id)}}},[_vm._v("未读")]):_vm._e(),(scope.row.is_ait_read)?_c('el-link',{attrs:{"type":"info","underline":false}},[_vm._v("已读")]):_vm._e()]}}])})],1),_c('TeachingAndResearchDetail',{attrs:{"id":_vm.detailId,"isVisible":_vm.detailDialog},on:{"close":function($event){_vm.detailDialog=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }