<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>员工</el-breadcrumb-item>
        <el-breadcrumb-item>员工管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="openDialogFormAdd('add')"
          :disabled="!$buttonPermission('companyUser:add')"
        >新建</el-button>
      </div>
    </div>
    <el-card class="operation-card">
      <el-form
        :inline="true"
        class="demo-form-inline"
      >
        <el-form-item label="搜索">
          <el-input
            v-model="query.keyword"
            size="small"
            placeholder="搜索"
            @input="inputSearch"
            clearable
          ></el-input>
        </el-form-item>
        <ScreenStaff @submit="screenTable"></ScreenStaff>
      </el-form>
    </el-card>
    <el-card
      class="table-card"
      style="margin-top: 20px"
    >
      <TableTittle :tittle="TableName"></TableTittle>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        >
        <el-table-column
          type="selection"
          width="55"
        > </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="alias"
          label="姓名"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
        > </el-table-column>
        <el-table-column
          prop="depts"
          label="部门"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.depts | formate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="roles"
          label="角色"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.roles | formateroles }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建日期"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="120"
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              :type="scope.row.status == 1 ? 'success' : 'danger'"
            >{{ scope.row.status == 1 ? "正常" : "下线" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="openDialogFormEdit('edit', scope.row)"
              :disabled="!$buttonPermission('companyUser:edit')"
            >编辑</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="danger
            "
              :underline="false"
              @click="deleteItem(scope.row)"
              :disabled="!$buttonPermission('companyUser:remove')"
            >删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog
      title="员工管理"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="570px"
      @close="dialogFormVisibleClose"
    >
      <el-form
        :model="dialogForm"
        label-width="auto"
        label-position="left"
        ref="dialogForm"
        :rules="rules"
      >
        <el-form-item
          label="员工姓名："
          prop="alias"
          align="center"
        >
          <el-input
            v-model="dialogForm.alias"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号（即登陆账号）："
          prop="phone"
        >
          <el-input
            v-model="dialogForm.phone"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="部门："
          prop="depts"
        >
          <el-select
            v-model="dialogForm.depts"
            multiple
            placeholder="请选择"
            style="width: 352px"
          >
            <el-option
              v-for="item in $store.state.DeptList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="角色："
          prop="roles"
        >
          <el-select
            v-model="dialogForm.roles"
            multiple
            placeholder="请选择"
            style="width: 352px"
          >
            <el-option
              v-for="item in $store.state.roleOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="状态"
          v-if="submitmethods == 'edit'"
        >
          <el-switch
            v-model="dialogForm.status"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit(submitmethods)"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import ScreenStaff from "../../../components/ScreenComponents/ScreenStaff/ScreenStaff.vue";
import {
  userdeptlist,
  userrolelist,
  createcompanyuser,
  companyuserlist,
  deletecompanyuser,
  editcompanyuser,
} from "../../../request/api";
export default {
  components: {
    TableTittle,
    ScreenStaff,
  },
  data() {
    return {
      formInline: {},
      dialogForm: { status: "1" },
      tableData: [],
      dialogFormVisible: false,
      ScreenStaffShow: false,
      TableName: "员工管理",
      submitmethods: "",
      // 部门options
      userdepOption: [],
      // 角色options
      userroleOption: [],
      rules: {
        alias: [{ required: true, message: "请输入员工姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        depts: [{ required: true, message: "请选择部门", trigger: "blur" }],
        roles: [{ required: true, message: "请选择角色", trigger: "blur" }],
      },
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      pageTotal: 0,
    };
  },
  filters: {
    formate: function (val) {
      var str = "";
      val.forEach((item) => {
        str += item.name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return str;
      }
    },
    formateroles: function (val) {
      var str = "";
      val.forEach((item) => {
        str += item.name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return str;
      }
    },
  },
  mounted() {
    // 获取部门列表和角色列表做select
    this.getOptions();
    // 获取列表
    this.getList(this.query);
  },
  methods: {
    getList(query) {
      companyuserlist(query).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    openDialogFormAdd(submitmethods) {
      this.submitmethods = submitmethods;
      this.dialogForm = { status: 1 };
      this.dialogFormVisible = true;
    },
    openDialogFormEdit(submitmethods, item) {
      // Select多选做回显
      this.dialogForm = JSON.parse(JSON.stringify(item)); // 利用JSON深拷贝
      if (this.dialogForm.depts) {
        this.dialogForm.depts = this.dialogForm.depts.map((v) => Number(v.id));
      }
      if (this.dialogForm.roles) {
        this.dialogForm.roles = this.dialogForm.roles.map((v) => Number(v.id));
      }
      // Select多选做回显
      this.submitmethods = submitmethods;
      this.dialogFormVisible = true;
    },
    getOptions() {
      this.$store.dispatch("getDeptList");
      this.$store.dispatch("getRoleList");
    },
    submit(submitmethods) {
      this.$refs["dialogForm"].validate((valid) => {
        if (valid) {
          this.dialogForm.depts = this.dialogForm.depts.join(",");
          this.dialogForm.roles = this.dialogForm.roles.join(",");
          let query = this.dialogForm;
          switch (submitmethods) {
            case "add":
              createcompanyuser(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false;
                  this.getList(this.query);
                });
              });
              break;
            case "edit":
              editcompanyuser(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false;
                  this.getList(this.query);
                });
              });
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteItem(item) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            id: item.id,
          };
          deletecompanyuser(query).then((res) => {
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    // 筛选组件传值
    screenTable({ screenForm }) {
      this.$set(this.query, "pageIndex", 1);
      this.query = {
        ...this.query,
        ...screenForm,
      };
      this.getList(this.query);
    },
    // 搜索框搜索
    inputSearch(e) {
      this.getList(this.query);
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    dialogFormVisibleClose() {
      this.dialogForm = {};
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.operation-card {
  width: 100%;
  margin-top: 20px;
  .demo-form-inline {
    height: 64px;
    border-bottom: 2px dashed #d2d5df;
  }
}
.operation-menu {
  display: flex;
  justify-content: space-between;
  .menu_left {
    margin-top: 25px;
  }
  .menu_right {
    margin-top: 25px;
  }
}
.block {
  margin-bottom: 14px;
  float: right;
}
</style>