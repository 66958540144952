<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>服务账单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px">
      <ScreenBill @submit="screenTable"></ScreenBill>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName"></TableTittle>
        <el-form
          :inline="true"
          label-width="70px"
          size="small"
        >
          <el-form-item label="只看关注">
            <el-switch
              v-model="query.follow"
              :active-value="1"
              inactive-value=" "
              @change="getList(query)"
            >
            </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="50"
        > </el-table-column>
        <el-table-column
          prop="date"
          label="关注"
          width="50"
          fixed="left"
        >
          <template slot-scope="scope">
            <img
              alt=""
              srcset=""
              class="star"
              src="../../../assets/images/star-fill.png"
              @click="followDelete(scope.row)"
              v-show="scope.row.follow"
            />
            <img
              src="../../../assets/images/star.png"
              alt=""
              srcset=""
              class="star"
              v-show="!scope.row.follow"
              @click="followCreate(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="detail_file_url"
          label="账单明细"
          width="100"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-link
              v-if="scope.row.detail_file_url"
              type="primary"
              :underline="false"
              @click="download(scope.row.detail_file_url)"
              :disabled="!$buttonPermission('companyServiceOrder:down')"
            >下载账单</el-link>
            <el-link
              v-else
              type="primary"
              :underline="false"
              @click="look(scope.row)"

              >查看</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="order_no"
          label="账单ID"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="账单状态"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              v-show="scope.row.status == 0"
            >待确定</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 1"
            >待支付</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 2"
            >已支付</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 3"
            >已完成</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 4"
            >已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="账单类型"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="date"
          label="账单周期"
          width="220"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.start_time | dateFormat("YYYY.MM.DD") }} -
              {{ scope.row.end_time | dateFormat("YYYY.MM.DD") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="total_amount"
          label="总费用"
          width="100"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.total_amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="service_students"
          label="结算人数"
          width="100"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.service_students + "人" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="service_amount"
          label="服务费用"
          width="100"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.service_amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="service_hours"
          label="结算课时"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="product_amount"
          label="课程费用"
          width="100"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.product_amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="other_amount"
          label="其他费用"
          width="100"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.other_amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="支付时间"
          width="150px"
        >
          <template slot-scope="scope">
            {{ scope.row.payorder_pay_time?$moment(scope.row.payorder_pay_time).format("YYYY-MM-DD HH:mm"):"" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="开票状态"
          width="100"
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              type="success"
              v-show="scope.row.invoice_status == 0"
            >待开票</el-tag>
            <el-tag
              size="small"
              type="success"
              v-show="scope.row.invoice_status == 1"
            >开票中</el-tag>
            <el-tag
              size="small"
              type="success"
              v-show="scope.row.invoice_status == 2"
            >已开票</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="操作"
          width="100"
          fixed="right"
        >
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link
                type="primary"
                :underline="false"
                @click="openConfirmBillsDialog(scope.row)"
                v-show="scope.row.status == 0"
                :disabled="!$buttonPermission('companyServiceOrder:edit')"
              >确定账单</el-link>
              <el-link
                type="primary"
                :underline="false"
                @click="openSettlementBillDialog(scope.row)"
                v-show="scope.row.status == 1"
                :disabled="!$buttonPermission('companyServiceOrder:edit')"
              >立即结算</el-link>
              <el-link
                type="success"
                :underline="false"
                v-show="scope.row.status == 2"
              >已支付</el-link>
              <el-link
                type="success"
                :underline="false"
                v-show="scope.row.status == 3"
              >已完成</el-link>
              <el-link
                type="info"
                :underline="false"
                v-show="scope.row.status == 4"
              >已取消</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button
          size="small"
          @click="openInvoicingDialog"
          :disabled="!$buttonPermission('companyInvoiceOrder:add')"
        >申请发票</el-button>
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <ConfirmBills
      :content="ConfirmContent"
      :isVisible="ConfirmBillsDialog"
      @submit="ConfirmBillsSubmit"
      @close="ConfirmBillsDialog = false"
    ></ConfirmBills>
    <SettlementBill
      :content="ConfirmContent"
      :isVisible="SettlementBillDialog"
      @close="SettlementBillDialogClose"
    ></SettlementBill>
    <InvoicingArr
      :content="InvoicingContent"
      :isVisible="InvoicingDialog"
      @close="InvoicingDialog = false"
      @submit="InvoicingDialogSubmit"
    ></InvoicingArr>

    <Examine
      :content="examineContent"
      :isVisible="examineIsVisible"
      :examineFlag="examineFlag"
        :type="type"
       @close="examineClose"
       @submit="getList()"
    ></Examine>
  </div>
</template>

<script>
import NP from "number-precision";
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import ScreenBill from "../../../components/ScreenComponents/ScreenBill/ScreenBill.vue";
import ConfirmBills from "../../../components/DialogComponents/ConfirmBills/ConfirmBills.vue";
import SettlementBill from "../../../components/DialogComponents/SettlementBill/SettlementBill.vue";
import InvoicingArr from "../../../components/DialogComponents/InvoicingArr/InvoicingArr.vue";
import Examine from "../../../components/DialogComponents/Examine/Examine.vue";
import {
  serviceorderlist,
  createinvoiceapply,
  createuserfollow,
  deleteuserfollow,
  editserviceorder,
} from "../../../request/api";
export default {
  components: {
    TableTittle,
    ScreenBill,
    ConfirmBills,
    SettlementBill,
    InvoicingArr,
    Examine
  },
  data() {
    return {
      TableName: "帐单列表",
      tableData: [],
      ConfirmContent: {},
      InvoicingContent: {},
      chooseList: [],
      ConfirmBillsDialog: false,
      SettlementBillDialog: false,
      InvoicingDialog: false,
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        follow: "",
      },
      lock: true,
      examineContent:{},
      examineIsVisible:false,
      examineFlag:false,
      type:false
    };
  },
  mounted() {
    this.getList(this.query);
    this.getTaxnolist();
    this.$store.dispatch("getCompanyAccount");
  },
  methods: {
    examineClose(){
      this.examineIsVisible = false
      this.examineFlag = false
    },
    look(row){
      if(row.type == '代签订单'){
        this.type = true
      }else{
        this.type = false
      }
      this.examineFlag = true
      this.examineContent = row
      this.examineIsVisible = true
    },


    // 表格选中取值
    handleSelectionChange(val) {
      this.chooseList = val;
    },
    getList(query) {
      serviceorderlist(query).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    download(url) {
      this.$store.commit("downLoad", url);
    },
    openConfirmBillsDialog(item) {
      this.ConfirmContent = item;
      this.ConfirmBillsDialog = !this.ConfirmBillsDialog;
    },
    openSettlementBillDialog(item) {
      this.ConfirmContent = item;
      this.SettlementBillDialog = !this.SettlementBillDialog;
    },
    openInvoicingDialog() {
      if (this.chooseList.length > 0) {
        // 多选账单数据做处理 开始
        let obj = {
          bills: [],
          order_no: [],
          invoice_amount: 0,
        };

        for (let i = 0; i < this.chooseList.length; i++) {
          if (
            [0, 1, 4].includes(this.chooseList[i].status) ||
            this.chooseList[i].invoice_status == 1
          ) {
            console.log("as");
            this.$notify.error({
              title: "提示",
              message: "您选中的部分账单不可申请开票,请重新勾选",
            });
            return false;
          }
        }
        let arr = this.chooseList;
        obj.invoice_amount = arr.reduce(
          (t, n) => NP.plus(t, n.total_amount),
          0
        );
        obj.bills = arr.map((v) => v.id).join(",");
        // 多选账单数据做处理 结束
        this.$nextTick(() => {
          this.InvoicingContent = obj;
          this.InvoicingDialog = !this.InvoicingDialog;
        });
      } else {
        this.$notify.error({
          title: "提示",
          message: "还没有选择账单",
        });
      }
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 3,
          follow_id: item.id,
        };
        createuserfollow(query).then((res) => {
          this.getList(this.query);
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 500);
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 3,
          follow_id: item.id,
        };
        deleteuserfollow(query).then((res) => {
          this.getList(this.query);
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 300);
      }
    },
    InvoicingDialogSubmit({ form }) {
      createinvoiceapply(form).then((res) => {
        this.InvoicingDialog = false;
      });
    },
    getTaxnolist() {
      this.$store.dispatch("getTaxnolist");
    },
    // 筛选
    screenTable({ screenForm }) {
      this.$set(this.query, "pageIndex", 1);
      this.query = {
        ...this.query,
        ...screenForm,
      };
      this.getList(this.query);
    },
    // 确定账单
    ConfirmBillsSubmit({ form }) {
      let query = {
        id: form.id,
        status: 1,
      };
      editserviceorder(query).then((res) => {
        this.getList(this.query);
        this.ConfirmBillsDialog = false;
      });
    },
    SettlementBillDialogClose() {
      // 支付后刷新列表
      this.SettlementBillDialog = false;
      this.getList(this.query);
      this.$store.dispatch("getCompanyAccount");
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 35px;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
}
.star {
  width: 18px;
  height: 18px;
}
.templateLink {
  .el-link {
    margin: 0px 5px;
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
}
</style>