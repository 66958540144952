<template>
  <div class="content">
    <div class="card-main">
      <div class="item-content">
        <el-checkbox-group v-model="checkedData" @change="handleChecked">
          <el-checkbox label="phone" border size="medium">手机号</el-checkbox>
          <el-checkbox label="wechat" border size="medium">微信号</el-checkbox>
          <el-checkbox label="qq" border size="medium">QQ号</el-checkbox>
        </el-checkbox-group>
      </div>
      <!--       <el-button
        class="button-new-tag"
        size="small"
        @click="openDialog"
        type="primary"
        >添加联系方式</el-button
      > -->
    </div>
    <el-dialog title="添加联系方式" :visible.sync="DialogShow" width="18.75%">
      <el-form>
        <el-form-item label="请输入联系方式类型">
          <el-input v-model="inputValue" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="DialogShow = false">取 消</el-button>
        <el-button type="primary" @click="handleInputConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { editcompanysetting } from "../../../request/api";
export default {
  data() {
    return {
      DialogShow: false,
      inputValue: "",
      checkedData: [],
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.$store.state.settingInfo.student_contact_types) {
        this.checkedData =
          this.$store.state.settingInfo.student_contact_types.split(",");
      }
    }, 300);
  },
  methods: {
    openDialog() {
      this.DialogShow = true;
    },
    handleChecked(e) {
      let query = {
        student_contact_types: e.join(","),
      };
      editcompanysetting(query).then((res) => {
        this.$store.dispatch("getCompanysetting");
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tagsArr.push(inputValue);
      }
      this.DialogShow = false;
      this.inputValue = "";
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 24px;
  height: 70px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-right: 24px;
  margin-top: 24px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  float: right;
  margin-bottom: 24px;
  margin-bottom: 18px;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>