<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料</el-breadcrumb-item>
        <el-breadcrumb-item>资料列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button
          type="primary"
          size="small"
          @click="createDialog=true"
        >新建</el-button>

      </div>

    </div>
    <el-card style="margin-top: 20px;padding-top:20px;">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="名称/标签">
          <el-input
            v-model.trim="query.name_tags_keyword"
            placeholder="输入名称/标签"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="年份">
          <el-select
            v-model="query.year"
            placeholder="请选择考研年份"
            clearable
            @change="getList(true)"
            style="width:150px;"
          >
            <el-option
              label="不限"
              value=""
            ></el-option>
            <el-option
              label="2022年"
              value="2022"
            ></el-option>
            <el-option
              label="2023年"
              value="2023"
            ></el-option>
            <el-option
              label="2024年"
              value="2024"
            ></el-option>
            <el-option
              label="2025年"
              value="2025"
            ></el-option>
            <el-option
              label="2026年"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="院校/专业">
          <el-input
            v-model.trim="query.college_major_keyword"
            placeholder="输入院校/专业关键字"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="库存预警线">
          <el-input
            oninput="value=value.replace(/[^0-9.]/g,'')"
            v-model="query.stock_min_line"
            placeholder="输入库存预警线"
            @input="getList(true)"
            clearable
            style="width:150px;"
          ></el-input>
          <!--    <el-select
            v-model="query.stock_min_line"
            placeholder="请选择"
            clearable
            @input="getList(true)"
            style="width:150px;"
          >
            <el-option
              label="低于10"
              value="10"
            ></el-option>
            <el-option
              label="低于30"
              value="30"
            ></el-option>
            <el-option
              label="低于50"
              value="50"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="创建人">
          <el-select
            v-model="query.creator"
            placeholder="请选择"
            @change="getList(true)"
            clearable
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            class="datetime"
            v-model="createDatetime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList(true)"
            clearable
            style="width:260px;"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
          style="margin-bottom:0px;display:flex;"
          label-position="right"
        >
          <el-form-item label-width="32px">
            <el-radio-group
              v-model="query.archive"
              @change="getList(true)"
            >
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button label="1">已归档</el-radio-button>
              <el-radio-button label="0">未归档</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="50"
        > </el-table-column>
        <el-table-column
          label="图片"
          width="100"
        >
          <template slot-scope="scope">
            <el-image
              style="max-height:40px;height:40px;padding:-8px 0px;"
              :src="scope.row.picture_url"
              @click="openViewer(scope.row.picture_url)"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span style="font-weight:800;color:#333;">
              《{{scope.row.name}}》
            </span>

          </template>
        </el-table-column>
        <el-table-column
          prop="year"
          label="年份"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.year?scope.row.year+"年":"不限"}}
          </template>
        </el-table-column>
        <el-table-column
          prop="sku"
          label="规格"
          width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="标签"
          width="300"
          show-overflow-tooltip
        >
          <template
            slot-scope="scope"
            v-if="scope.row.tags"
          >
            <el-tag
              type="info"
              v-for="(item,index) in scope.row.tags.split(',')"
              :key="index"
            >{{item}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="适用范围"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.college_names}}{{scope.row.major_names?','+scope.row.major_names:""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="stock"
          label="当前库存"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToGoRecord(scope.row.id)"
            >{{scope.row.stock}}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="260"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="创建人"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.creator.alias}}
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          width="160"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")}}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToEdit(scope.row.id)"
            >编辑</el-link>
            <el-link
              type="danger
            "
              @click="handleToRemove(scope.row.id)"
              :underline="false"
            >删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button
          size="mini"
          type="primary"
          @click="chooseAllEdit(1, '您确定要归档吗')"
          v-show="query.archive == 0&&query.archive != ''"
        >归档</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="chooseAllEdit(0, '您确定要取消归档吗')"
          v-show="query.archive == 1"
        >取消归档</el-button>
        <el-button
          size="small"
          type="danger"
          @click="handleToDeleteAll"
        >批量删除</el-button>
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddDataList
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="getList()"
    />
    <EditDataList
      :id="editId"
      :isVisible="editDialog"
      @close="editDialog=false"
      @submit="getList()"
    />
    <el-image-viewer
      v-if="showViewer"
      :url-list="tableImg"
      :on-close="closeViewer"
    />
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddDataList from "@/components/DialogComponents/DataList/AddDataList.vue"
import EditDataList from "@/components/DialogComponents/DataList/EditDataList.vue"
// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";


import {
  materiallist,
  materialDelete,
  materiallistDelete,
  shipliplistExport,
  materiallistEdit
} from "@/request/api";
export default {
  components: {
    TableTittle,
    AddDataList,
    EditDataList,
    ElImageViewer,
  },
  data() {
    return {
      TableName: "资料列表",
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        archive: "0",
        cid: localStorage.getItem("cid"),
      },
      pageTotal: 0,
      tableData: [],
      createDialog: false,
      fileList: [],
      editId: null,
      editDialog: false,
      chooseList: [],
      createDatetime: "",
      tableImg: [],
      showViewer: false,
    };
  },
  filters: {

  },
  watch: {
    // 时间数据监听
    createDatetime(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
        this.getList(true)
      }
    },
  },
  mounted() {
    this.parseQuery()
    this.getList();
    this.$store.dispatch('getStaffList')
  },
  methods: {

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList();
    },


    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach(key => {
        this.query[key] = this.$route.query[key]
      })
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    getList(query, isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query)
      materiallist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
        this.syncQuery()
      });
    },
    handleToGoRecord(id) {
      this.$router.push(`/dataInventory/dataList/record/${id}`)
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.tableImg = [url];
      this.showViewer = true;
    },
    handleToEdit(id) {
      this.editId = id
      this.editDialog = true
    },
    handleToRemove(id) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        materialDelete({ id }).then((res) => {
          this.$nextTick(() => {
            this.getList();
          });
        });
      })
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm("确定要删除这些信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map(v => { return v.id })
        materiallistDelete({ ids }).then((res) => {
          this.getList();
        });
      })
    },
    chooseAllEdit(archive_status, text) {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            rows: this.chooseList.map(v => {
              return {
                id: v.id,
                archive: archive_status,
              }
            }),
          };
          materiallistEdit(query).then((res) => {
            this.getList();
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消操作",
          });
        });
    },

  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.card-header {
  display: flex;
  justify-content: space-between;
  .el-button {
    height: 35px;
    line-height: 10px;
  }
}
.block {
  margin-bottom: 14px;
  float: right;
}
.el-input {
  width: 200px;
}

/deep/.el-table__cell {
  padding: 8px 0px !important;
}
</style>