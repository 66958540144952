<template>
  <div class="content">
    <el-dialog
      top="5vh"
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="dialogFormVisible = false"
      :close-on-click-modal="false"
      width="620px"
    >
        <div v-html="renderedMarkdown" class="word"></div>
    </el-dialog>
  </div>
</template>

<script>

import MarkdownIt from 'markdown-it';
import { manageUrl } from "@/utils/helper";
export default {
  data() {
    return {
      title: "答案",
      dialogFormVisible: false,
      id: "",
      renderedMarkdown:''
    };
  },
  created() {},
  methods: {
      
    open(id) {
        console.log("id",id)
      this.id = id;
      this.dialogFormVisible = true;
      this.getDetail();
    },
    getDetail() {
      let str = manageUrl();
      this.$axios({
        url: `${str}/api/v1/gpt/gptQA`,
        method: "get", //可以省略 不写默认是get
        params: {
          id: this.id,
        },
      }).then((res) => {
        if (res.code == 0) {
             const md = new MarkdownIt();
            this.renderedMarkdown = md.render(res.data.answer_content)
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
    .word{
        padding: 26px;
        font-size: 14px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #606266;
line-height: 28px;
    }
</style>