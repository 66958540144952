<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>模考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practicetest/management' }">模考管理</el-breadcrumb-item>
        <el-breadcrumb-item>模考详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button
          type="primary"
          size="small"
          @click="createDialog=true"
        >新建</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px;padding-top:20px;">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="试卷名称">
          <el-input
            v-model.trim="query.name_keyword"
            placeholder="试卷名称"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="院校专业">
          <el-input
            v-model.trim="query.college_major_keyword"
            placeholder="院校专业"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="出题人">
          <el-input
            v-model.trim="query.author_keyword"
            placeholder="学长名称/手机号"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName[0]"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
          style="margin-bottom:0px;display:flex;"
          label-position="right"
        >
          <el-form-item label-width="32px">
            <el-radio-group
              v-model="query.check_status"
              @change="getList(true,query)"
            >
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button label="0">未审核</el-radio-button>
              <el-radio-button label="1">已通过</el-radio-button>
              <el-radio-button label="2">已驳回</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column
          prop="id"
          label="ID"
          show-overflow-tooltip
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="试卷名称"
          show-overflow-tooltip
          width="260"
        >
        </el-table-column>
        <el-table-column
          prop="year"
          label="年份"
          show-overflow-tooltip
          width="100"
        >
        </el-table-column>
        <el-table-column
          label="院校专业"
          show-overflow-tooltip
          width="260"
        >
          <template slot-scope="scope">
            {{scope.row.college_name}}/({{scope.row.major_code}}){{scope.row.major_name}}
          </template>
        </el-table-column>
        <el-table-column
          label="科目"
          show-overflow-tooltip
          width="260"
        >
          <template slot-scope="scope">
            {{scope.row.subjects&&scope.row.subjects.length?scope.row.subjects.map(v=>{
              return `(${v.subject_code})${v.subject_name}`
            }).join(','):''}}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="审核状态"
          show-overflow-tooltip
          width="80"
        >
          <template slot-scope="scope">
            <el-tag
              type="warning"
              size="mini"
              v-if="scope.row.check_status==0"
            >未审核</el-tag>
            <el-tag
              type="success"
              size="mini"
              v-if="scope.row.check_status==1"
            >已通过</el-tag>
            <el-tag
              type="danger"
              size="mini"
              v-if="scope.row.check_status==2"
            >已驳回</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="出题人"
          show-overflow-tooltip
          width="200"
        >
          <template slot-scope="scope">
            {{scope.row.author_realname}}/{{scope.row.author_mobile}}
          </template>
        </el-table-column>
        <el-table-column
          prop="refuse_reason"
          label="拒绝理由"
          show-overflow-tooltip
          width="260"
        >
        </el-table-column>
        <el-table-column
          label="提交时间"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="审核时间"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToDetail(scope.row)"
            >查看详情</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddPaper
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="getList()"
    />
    <PaperDetail
      :id="detailId"
      :isVisible="detailDialog"
      @close="detailDialog=false"
      @submit="getList()"
    />
  </div>
</template>

<script>

import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddPaper from "@/components/DialogComponents/PracticeTest/AddPaper.vue"
import PaperDetail from "@/components/DialogComponents/PracticeTest/PaperDetail.vue"




import {
  mockexampaperlist,
} from "@/request/api";
export default {
  components: {
    TableTittle,
    AddPaper,
    PaperDetail
  },
  data() {
    return {
      TableName: ["审核列表"],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: 'null',
        mock_exam_id: this.$route.params.id,
        check_status: 0,
      },
      pageTotal: 0,
      tableData: [],
      createDialog: false,
      editId: null,
      editDialog: false,
      detailDialog: false,
      detailId: null,
    };
  },
  filters: {

  },
  watch: {

  },
  created() {
    this.parseQuery();

    this.getList();
  },
  mounted() {

  },
  methods: {

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList();
    },

    getList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      mockexampaperlist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    handleToEdit(id) {
      this.editId = id
      this.editDialog = true
    },
    handleToDetail(item) {
      this.detailId = item.id
      this.detailDialog = true
    },



  },
};
</script>

<style lang="less" scoped>
</style>