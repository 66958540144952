<template>
  <div>
    <el-dialog
      title="发货清单"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :width="!isfixed?'375px':''"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item
          label="发货主题："
          prop="theme"
        >
          <el-input
            v-model="dataForm.theme"
            placeholder="请输入发货主题"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="预发日期："
          prop="plan_express_time"
        >
          <div class="">
            <el-date-picker
              v-model="dataForm.plan_express_time"
              type="date"
              placeholder="请选择日期时间"
              clearable
              value-format="timestamp"
              :disabled="isNow"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <el-checkbox
              v-model="isNow"
              style="margin-left:16px;"
            >立即发出</el-checkbox>
          </div>

        </el-form-item>
        <el-form-item label="选择用户：">
          <div class="user-wrap">
            <div class="top">
              <el-link
                type="primary"
                :underline="false"
                @click="openStudentChoose"
                :disabled="is_user_temp==1||![0,2,4].includes(dataForm.express_status)"
              >选择用户</el-link>
              <el-checkbox
                v-model="is_user_temp"
                :disabled="![0,2,4].includes(dataForm.express_status)"
              >非报名用户</el-checkbox>
            </div>
            <!-- 非报名用户 -->
            <div
              class="bottom"
              v-if="is_user_temp==1"
            >
              <TemporaryUser
                @submit="syncTemporaryUser"
                :content="TemporaryUserContent"
                :single="true"
                :disabled="![0,2,4].includes(dataForm.express_status)"
              />
            </div>
            <!-- 系统学生 -->
            <div
              class="
                bottom"
              v-else
            >
              <el-tag
                :key="index"
                v-for="(item,index) in students"
                :closable="[0,2,4].includes(dataForm.express_status)"
                :disable-transitions="false"
                @close="removeStudents(index)"
              >
                {{item.realname}}
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="选择资料："
          prop="product_list"
        >
          <div class="user-wrap">
            <div class="bottom">
              <DataListCount
                @submit="syncDataListCount"
                :content="DataListCountContent"
                :disabled="![0,2,4].includes(dataForm.express_status)"
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="备注："
          prop="remark"
        >
          <el-input
            v-model="dataForm.remark"
            placeholder="请输入备注"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>

    </el-dialog>
    <StudentChoose
      :visible="chooseDialog"
      :content="students"
      @close="chooseDialog=false"
      @submit="chooseSubmit"
      :single="true"
    />
    <HandleDeliverData
      :content="result"
      :visible="handleDialog"
      @close="handleDialog=false"
      @submit="$emit('submit'),emitClose()"
      :single="true"
    />
  </div>
</template>

<script>

import { shipslipOne, shipslipEdit } from "@/request/api";
import StudentChoose from "@/components/DialogComponents/DataInventoryStudent/StudentChoose";
import DataListCount from "@/components/DialogComponents/DeliverList/DataListCount";
import HandleDeliverData from "@/components/DialogComponents/DeliverList/HandleDeliverData";
import TemporaryUser from "@/components/DialogComponents/DeliverList/TemporaryUser";

export default {
  components: {
    StudentChoose,
    DataListCount,
    HandleDeliverData,
    TemporaryUser,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dataForm: {

      },
      dialogFormVisible: this.isVisible,
      is_user_temp: false,
      chooseDialog: false,
      StudentChoose: {},
      students: [],
      rules: {
        theme: [{ required: true, message: "请输入发货主题", trigger: "blur" }],
        user_list: [{
          required: true,
          validator: (rule, value, callback) => {
            if (this.is_user_temp == 1) {
              if (!this.TemporaryUserContent.length) {
                callback(new Error('请输入非报名用户'))
              } else {
                callback()
              }
            } else {
              if (!this.students.length) {
                callback(new Error('请选择用户'))
              } else {
                callback()
              }
            }
          },
        }],
        /*        product_list: [{
                 required: true,
                 validator: (rule, value, callback) => {
                   if (!this.DataListCountContent.length) {
                     callback(new Error('请选择资料'))
                   } else {
                     callback()
                   }
                 },
               }], */
        plan_express_time: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.isNow) {
              if (!this.dataForm.plan_express_time) {
                callback(new Error('请选择预发时间'))
              } else {
                callback()
              }
            } else {
              callback()

            }
          },

        }]

      },
      TemporaryUserContent: [],
      DataListCountContent: [],
      user: {},
      handleDialog: false,
      result: {},
      isfixed: document.body.clientWidth < 820 ? false : true,
      isNow: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      }
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail(this.id)
      }
    },
  },
  methods: {
    fetchDetail(id) {
      shipslipOne({ id }).then((res) => {
        this.dataForm = res.data
        this.DataListCountContent = res.data.product_list.map((v) => {
          return {
            id: v.product_id,
            quantity: v.quantity,
            year: v.year,
            name: v.product_name,
            sku: v.product_sku,
          }
        })
        this.is_user_temp = res.data.student_id ? false : true
        this.students = res.data.student_id ? [{
          id: res.data.student_id,
          realname: res.data.student_realname,
        }] : []
        this.TemporaryUserContent = res.data.student_id ? [] : [{
          receiver_name: res.data.receiver_name,
          receiver_phone: res.data.receiver_phone,
          receiver_address: res.data.receiver_address,
        }]
        this.isNow = res.data.plan_express_time == 0 ? true : false

      });
    },
    emitClose() {
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            cid: localStorage.getItem("cid"),
            theme: this.dataForm.theme,
            /*    express_status: this.dataForm.express_status, */
            is_user_temp: this.is_user_temp ? 1 : 0,
            user_obj: this.is_user_temp ? {
              ...this.TemporaryUserContent[0]
            } : {
              student_id: this.students[0].id
            }
            ,
            product_list: this.DataListCountContent.map((v) => {
              return {
                product_id: v.id,
                quantity: v.quantity,
              }
            }),
            remark: this.dataForm.remark,
            plan_express_time: this.isNow ? "0" : this.dataForm.plan_express_time
          }
          shipslipEdit(query).then((res) => {
            if (res.code == 0) {
              if (!res.data.is_finish) {
                this.handleDialog = true
                this.result = Object.assign({}, res.data.check_data)
              } else {
                this.$message.success(`保存成功`);
                this.emitClose()
                this.$emit('submit')
              }
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSubjectChoose() {
      this.subjectChooseDialog = true;
    },
    handleSubjectRemove(item) {
      this.subjects = this.subjects.filter((v) => v.id !== item.id);
    },
    openStudentChoose() {
      this.chooseDialog = true
    },
    chooseSubmit({ data }) {
      this.students = data
    },
    syncTemporaryUser(data) {
      this.TemporaryUserContent = data
    },
    syncDataListCount(data) {
      this.DataListCountContent = data
    },
    removeStudents(index) {
      this.students.splice(index, 1)
    }
  },
};
</script>

<style lang="less" scoped>
.tag-wrap {
  display: flex;
  flex-wrap: wrap;
  .subject-item {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
.user-wrap {
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bottom {
  }
}
</style>