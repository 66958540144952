<template>
  <div>
    <el-dialog
      title="待办事项"
      :visible.sync="dialogFormVisible"
      width="620px"
      @close="emitClose"
      append-to-body
    >
      <el-descriptions
        class="margin-top"
        :column="1"
        border
        :labelStyle="labelStyle"
      >
        <el-descriptions-item>
          <template slot="label">
            记录内容
          </template>
          {{dataForm.content}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            服务类型
          </template>
          {{dataForm.service_type?dataForm.service_type.value:""}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            关联学员
          </template>
          {{dataForm.student?dataForm.student.realname:""}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            截止日期
          </template>
          {{$moment(dataForm.stop_time).format("YYYY-MM-DD HH:mm")}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            优先级
          </template>
          {{dataForm.priority_level}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            执行老师
          </template>
          {{dataForm.do_teacher?dataForm.do_teacher.alias:""}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            发布人
          </template>
          {{dataForm.publish_teacher?dataForm.publish_teacher.alias:""}}

        </el-descriptions-item>
      </el-descriptions>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="emitClose"
        >确 定</el-button>
      </div>

    </el-dialog>
    <EmployeeChoose
      :visible="chooseDialog"
      :content_array="staffs"
      @close="chooseDialog=false"
      @submit="saveStaff"
    />
    <StudentChoose
      :visible="studentDialog"
      :content="students"
      @close="studentDialog=false"
      @submit="chooseStudent"
    />
  </div>
</template>

<script>


import EmployeeChoose from "@/components/DialogComponents/Employee/EmployeeChoose"
import { editteacherServicetask, teacherServicetaskOne } from "@/request/api";
import StudentChoose from "./StudentChoose.vue";


export default {
  components: {
    EmployeeChoose,
    StudentChoose
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      /*      rules: {
             service_subject_id: [{ required: true, message: "请选择服务科目", trigger: "blur" }],
             service_type_id: [{ required: true, message: "请选择服务类型", trigger: "blur" }],
             content: [{ required: true, message: "请输入记录内容", trigger: "blur" }],
             stop_time: [{ required: true, message: "请选择截止日期", trigger: "blur" }],
             priority_level: [{ required: true, message: "请选择优先级", trigger: "blur" }],
             do_teacher_id_list: [{
               required: true,
               validator: (rule, value, callback) => {
                 if (!this.staffs.length) {
                   callback(new Error('请选择执行老师'))
                 } else {
                   callback()
     
                 }
               },
             }],
             student_id_list: [{
               required: true,
               validator: (rule, value, callback) => {
                 if (!this.students.length) {
                   callback(new Error('请选择关联学员'))
                 } else {
                   callback()
     
                 }
               },
             }],
     
           }, */

      chooseDialog: false,
      staffs: [],
      studentDialog: false,
      students: [],
      labelStyle: {
        width: '100px'
      }
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail(this.id)
      }
    },

  },
  methods: {
    fetchDetail(id) {
      teacherServicetaskOne({ id }).then((res) => {
        this.dataForm = res.data
        this.students = [res.data.student]
        this.staffs = [res.data.do_teacher]
      });
    },
    emitClose() {

      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            content: this.dataForm.content,
            service_type_id: this.dataForm.service_type_id,
            stop_time: this.dataForm.stop_time,
            priority_level: this.dataForm.priority_level,
            student_id_list: this.students.map(v => { return v.id }),
            do_teacher_id_list: this.staffs.map(v => { return v.id }),
          }
          editteacherServicetask(query).then((res) => {
            this.$message.success('提交成功')
            this.$emit('submit')
            this.emitClose()
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    saveStaff({ data }) {
      this.staffs = data
    },
    chooseStudent({ data }) {
      this.students = data
    },
    removeStudents(index) {
      this.students.splice(index, 1)
    },
    removeStaffs(index) {
      this.staffs.splice(index, 1)

    }
  },
};
</script>

<style lang="less" scoped>
.student-tag {
}
.el-tag {
  margin: 5px;
}
.form-item {
  padding-left: 8px;
}
</style>