<template>
  <div class="content">
    <div class="card-main">
      <draggable
        v-model="list.values"
        chosenClass="chosen"
        forceFallback="true"
        group="people"
        animation="1000"
        @start="onStart"
        @end="onEnd"
      >
        <transition-group>
          <el-tag
            :key="item.id"
            v-for="item in list.values"
            :closable="item._default == 0"
            :disable-transitions="false"
            @close="handleClose(item)"
            type="success"
          >
            {{ item.value }}
          </el-tag>
        </transition-group>
      </draggable>
      <el-button
        class="button-new-tag"
        size="small"
        @click="openDialog"
        type="primary"
      >添加服务类型</el-button>
    </div>
    <el-dialog
      title="添加服务类型"
      :visible.sync="DialogShow"
      width="18.75%"
    >
      <el-form
        ref="dialogform"
        :rules="rules"
        :model="form"
      >
        <el-form-item
          label="请输入服务类型类型"
          prop="value"
        >
          <el-input
            v-model="form.value"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="DialogShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleInputConfirm"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  dictionary,
  createdictionaryvalue,
  deletedictionaryvalue,
  editdictionaryvaluelist,
  createdictionary

} from "../../../request/api";
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  data() {
    return {
      list: {},
      DialogShow: false,
      form: {},
      rules: {
        value: [
          { required: true, message: "请输入服务类型", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let query = {
        code: "ServiceType",
      };
      dictionary(query).then((res) => {
        if (res.data) {
          this.list = res.data || {};
        } else {
          const param = {
            name: "服务类型",
            code: "ServiceType",
          }
          createdictionary(param).then((res) => {
            this.getList()
          })
        }
      });
    },
    handleClose(item) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            cid: localStorage.getItem("cid"),
            code: item.code,
            value: item.value,
          };
          deletedictionaryvalue(query).then((res) => {
            this.$nextTick(() => {
              this.getList();
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    openDialog() {
      this.DialogShow = true;
    },
    handleInputConfirm() {
      this.$refs["dialogform"].validate((valid) => {
        if (valid) {
          let query = {
            cid: localStorage.getItem("cid"),
            code: "ServiceType",
            value: this.form.value,
          };
          createdictionaryvalue(query).then((res) => {
            this.$nextTick(() => {
              this.DialogShow = false;
              this.getList();
              this.form = {};
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //开始拖拽事件
    onStart() {

    },
    //拖拽结束事件
    onEnd() {
      let rows = this.list.values.map((v, ind) => {
        console.log(ind, v.value);
        return {
          id: v.id,
          sort: ind,
        }
      })
      let query = {
        cid: localStorage.getItem("cid"),
        code: "ServiceSubject",
        rows: rows,
      }
      editdictionaryvaluelist(query).then((res) => {
        this.$nextTick(() => {
          this.DialogShow = false;
          this.getList();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 24px;
  height: 70px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-right: 24px;
  margin-top: 24px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  margin-bottom: 24px;
  padding-bottom: 0;
  float: right;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>