<template>
  <div class="content">
    <el-dialog
      title="账单列表"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="654px"
      @close="emitClose"
    >
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        height="460"
        border
      >
        <el-table-column
          prop="order_no"
          label="账单ID"
          width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="type"
          label="账单类型"
          width="116"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="账单周期"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.start_time | dateFormat("YYYY.MM.DD") }}-{{
              scope.row.end_time | dateFormat("YYYY.MM.DD")
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="total_amount"
          label="总费用"
          width="116"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.total_amount + "元" }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary" @click="emitClose">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { serviceorderlist } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      tableData: [],
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      console.log(val);
      let query = {
        ids: val.bills,
      };
      serviceorderlist(query).then((res) => {
        this.tableData = res.data.list || [];
      });
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
</style>