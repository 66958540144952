<template>
  <div>
    <el-dialog
      title="拒绝理由"
      :visible.sync="dialogFormVisible"
      width="700px"
      :close-on-click-modal="false"
      @close="emitClose"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="拒绝理由："
          prop="text"
        >
          <el-input
            type="textarea"
            v-model.trim="dataForm.text"
            placeholder="请输入拒绝理由"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>





export default {
  components: {

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      rules: {
        text: [{ required: true, message: "请输入拒绝理由", trigger: "blur" }]
      }
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    emitClose() {
      this.dataForm = {}
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.$emit('submit', { text: this.dataForm.text })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>