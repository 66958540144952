<template>
  <el-dialog
    title="账单信息"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="700px"
  >
    <span class="tips">总费用</span>
    <div class="count-content">
      <span>￥{{ billInfo.total_amount }}</span>
    </div>
    <el-descriptions :column="2" border>
      <el-descriptions-item
        label="账单ID"
        :contentStyle="contentStyle"
        :labelStyle="labelStyle"
        >{{ billInfo.order_no }}</el-descriptions-item
      >
      <el-descriptions-item
        label="账单类型"
        :contentStyle="contentStyle"
        :labelStyle="labelStyle"
        >{{ billInfo.type }}</el-descriptions-item
      >
      <el-descriptions-item
        label="账单周期"
        :contentStyle="contentStyle"
        :labelStyle="labelStyle"
      >
        {{ billInfo.start_time | dateFormat("YYYY.MM.DD") }}-{{
          billInfo.end_time | dateFormat("YYYY.MM.DD")
        }}</el-descriptions-item
      >
      <el-descriptions-item
        label="结算人数"
        :contentStyle="contentStyle"
        :labelStyle="labelStyle"
        >{{ billInfo.service_students + "人" }}</el-descriptions-item
      >
      <el-descriptions-item
        label="服务费用"
        :contentStyle="contentStyle"
        :labelStyle="labelStyle"
        >{{ billInfo.service_amount + "元" }}</el-descriptions-item
      >
      <el-descriptions-item
        label="课程费用"
        :contentStyle="contentStyle"
        :labelStyle="labelStyle"
        >{{ billInfo.product_amount + "元" }}</el-descriptions-item
      >
      <el-descriptions-item
        label="其他费用"
        :labelStyle="labelStyle"
        :span="2"
        >{{ billInfo.other_amount + "元" }}</el-descriptions-item
      >
      <el-descriptions-item label="备注" :labelStyle="labelStyle">{{
        billInfo.remark
      }}</el-descriptions-item>
    </el-descriptions>
    <!--     <div class="tax-rate">
      <el-form>
        <el-form-item label="是否申请开票">
          <el-switch
            v-model="isOpen"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
          <span v-show="isOpen">申请开票需承担发票纳税金额¥500</span>
        </el-form-item>
      </el-form>
    </div> -->
    <div slot="footer" class="dialog-footer">
      <el-button @click="emitClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {} from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      billInfo: this.content,
      contentStyle: {
        width: "230px",
      },
      contentStyle2: {
        width: "524px",
      },
      labelStyle: {
        width: "120px",
      },
      isOpen: true,
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.billInfo = val;
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit", { form: this.billInfo });
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  width: 128px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #303133;
  opacity: 1;
}
.count-content {
  height: 55px;
  text-align: center;
  margin-bottom: 36px;
  span {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 55px;
    color: #e6a23c;
    opacity: 1;
  }
}
.tax-rate {
  margin-top: 24px;
  span {
    margin-left: 16px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 18px;
    color: #c0c4cc;
    opacity: 1;
  }
}
</style>