<template>
  <div>
    <el-dialog
      title="发放试卷"
      :visible.sync="dialogFormVisible"
      width="700px"
      @close="emitClose"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item label="发放学员：" prop="student_id_list">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <el-link
              type="primary"
              :underline="false"
              :disabled="is_user_temp"
              @click="studentDialog = true"
              >选择用户</el-link
            >
            <el-checkbox v-model="is_user_temp">非报名用户</el-checkbox>
          </div>

          <div class="bottom" v-if="is_user_temp">
            <TestPaperUser @submit="syncTemporaryUser" :content="content"/>
          </div>

          <div class="student-tag" v-if="!is_user_temp">
            <el-tag
              :key="index"
              v-for="(item, index) in students"
              closable
              :disable-transitions="false"
              @close="removeStudents(index)"
            >
              {{ item.realname }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="考试时间：" prop="exam_time">
          <el-date-picker
            v-model="dataForm.exam_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="timestamp"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <div
            v-for="(item, index) in defaultTimeList"
            :key="index"
            @click="setTime(item.value)"
            style="cursor: pointer; color: #409eff"
          >
            {{ item.label }}
          </div>
          <!-- <div
            @click="setTime(['2023-10-28 13:50', '2023-10-28 17:00'])"
            style="cursor: pointer; color: #409eff"
          >
            2023年10月28日 下午13:50~17:00
          </div>
          <div
            @click="setTime(['2023-11-25 08:20', '2023-11-25 11:30'])"
            style="cursor: pointer; color: #409eff"
          >
            2023年11月25日 上午8:20~11:30
          </div>
          <div
            @click="setTime(['2023-11-25 13:50', '2023-11-25 17:00'])"
            style="cursor: pointer; color: #409eff"
          >
            2023年11月25日 下午13:50~17:00
          </div> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary" @click="submit('formRules')">确 定</el-button>
      </div>
    </el-dialog>
    <StudentChoose
      :visible="studentDialog"
      :content="students"
      @close="studentDialog = false"
      @submit="chooseStudent"
    />
  </div>
</template>

<script>
import StudentChoose from "@/components/DialogComponents/Service/StudentChoose.vue";
import TestPaperUser from "@/components/DialogComponents/DeliverList/TestPaperUser";
import { mockexamprocessCreate } from "@/request/api";

export default {
  components: {
    StudentChoose,
    TestPaperUser,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content:[
        {
          name: "",
          phone: ""
        }
      ],
      defaultTimeList: [
        {
          label: "2023年11月25日 上午08:30~11:30",
          value: ["2023-11-25 08:30", "2023-11-25 11:30"],
        },
        {
          label: "2023年11月25日 下午14:00~17:00",
          value: ["2023-11-25 14:00", "2023-11-25 17:00"],
        },

        {
          label: "2023年11月26日 上午08:30~11:30",
          value: ["2023-11-26 08:30", "2023-11-26 11:30"],
        },
        {
          label: "2023年11月26日 下午14:00~17:00",
          value: ["2023-11-26 14:00", "2023-11-26 17:00"],
        },

        {
          label: "2023年12月2日 上午08:30~11:30",
          value: ["2023-12-2 08:30", "2023-12-2 11:30"],
        },
        {
          label: "2023年12月2日 下午14:00~17:00",
          value: ["2023-12-2 14:00", "2023-12-2 17:00"],
        },
        {
          label: "2023年12月3日 上午08:30~11:30",
          value: ["2023-12-3 08:30", "2023-12-3 11:30"],
        },

        {
          label: "2023年12月9日 上午08:30~11:30",
          value: ["2023-12-9 08:30", "2023-12-9 11:30"],
        },
        {
          label: "2023年12月9日 下午14:00~17:00",
          value: ["2023-12-9 14:00", "2023-12-9 17:00"],
        },

        {
          label: "2023年12月10日 上午08:30~11:30",
          value: ["2023-12-10 08:00", "2023-12-10 11:30"],
        },
      ],
      TemporaryUserContent: [],
      is_user_temp: false,
      dataForm: {},
      dialogFormVisible: this.isVisible,
      fileList: [],
      rules: {
        exam_time: [
          { required: true, message: "请选择考试时间", trigger: "blur" },
        ],
        student_id_list: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let testE = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
              if (this.is_user_temp) {
                if (!this.TemporaryUserContent.length && this.is_user_temp) {
                  callback(new Error("请输入临时学生"));
                }
                if (this.TemporaryUserContent.length > 0 && this.is_user_temp) {
                  for (let i = 0; i < this.TemporaryUserContent.length; i++) {
                    // if (!this.TemporaryUserContent[i].temp_student_realname) {
                    //   callback(new Error("请输入姓名"));
                    // }
                    if (
                      !testE.test(
                        this.TemporaryUserContent[i].temp_student_phone
                      )
                    ) {
                      callback(new Error(`第${i+1}行请输入正确手机号`));
                    }
                  }
                }

                callback();
              } else {
                if (!this.students.length) {
                  callback(new Error("请选择发放学员"));
                } else {
                  callback();
                }
              }
            },
          },
          // {
          //   required: true,
          //   validator: (rule, value, callback) => {
          //     if (!this.students.length) {
          //       callback(new Error("请选择发放学员"));
          //     } else {
          //       callback();
          //     }
          //   },
          // },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      studentDialog: false,
      students: [],
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.is_user_temp = false
      this.TemporaryUserContent = [];
      this.students = []
      this.$set(this.dataForm,'exam_time',[])
      this.dialogFormVisible = val;
    },
  },
  methods: {
    syncTemporaryUser(data) {
      this.TemporaryUserContent = [];
      // console.log(data, "data");
      if (data[0].phone) {
        let phoneList = data[0].phone.split("\n").slice(0, data[0].phone.split("\n").length)
        phoneList.map((element) => {
          this.TemporaryUserContent.push({
            temp_student_realname: "暂无",
            temp_student_phone: element,
          });
        });
      }
      if (data[0].name) {
        let nameList = data[0].name.split("\n").slice(0, data[0].name.split("\n").length)
        nameList.map((element, index) => {
          if (this.TemporaryUserContent[index]) {
            this.TemporaryUserContent[index].temp_student_realname = element;
          } else {
            this.TemporaryUserContent.push({
              temp_student_realname: element,
              temp_student_phone: "",
            });
          }
        });
      }
      // console.log("Data", this.TemporaryUserContent);
      // this.TemporaryUserContent = data;
      this.$refs.formRules.clearValidate();
    },
    setTime(timeArr) {
      // console.log(timeArr,'cc')
      this.$set(this.dataForm, "exam_time", [
        this.$moment(timeArr[0]).unix() * 1000,
        this.$moment(timeArr[1]).unix() * 1000,
      ]);
      // dataForm.exam_time
    },
    emitClose() {
      this.$emit("close");
    },

    submit(rules) {
      // console.log(this.dataForm.exam_time);
      // return
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {};
          if (this.is_user_temp) {
            query = {
              temp_student_info_list: this.TemporaryUserContent,
              start_exam_time: this.dataForm.exam_time[0],
              end_exam_time: this.dataForm.exam_time[1],
              exam_paper_id: this.$route.params.id,
            };
          } else {
            query = {
              student_id_list: this.students.map((v) => {
                return v.id;
              }),
              start_exam_time: this.dataForm.exam_time[0],
              end_exam_time: this.dataForm.exam_time[1],
              exam_paper_id: this.$route.params.id,
            };
          }

          mockexamprocessCreate(query).then((res) => {
            this.$message.success("提交成功");
            this.$emit("submit");
            this.emitClose();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    chooseStudent({ data }) {
      this.students = data;
    },
    removeStudents(index) {
      this.students.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.el-input,
.el-select {
  width: 100%;
}

.upload-demo {
  overflow: hidden;
  height: 35px;
}

.file-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .file-item {
    & + .file-item {
      margin-top: 16px;
    }

    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .btn {
        width: 56px;
        height: 24px;
        border: 1px solid #dcdfe6;
        border-radius: 20px;
        color: #606266;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }
    }
  }
}
</style>