<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>产品</el-breadcrumb-item>
        <el-breadcrumb-item>产品列表</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button
        type="primary"
        @click="opendialogAdd"
        size="small"
        :disabled="!$buttonPermission('productCourse:add')"
      >新建</el-button>
    </div>
    <el-card style="margin-top: 20px">
      <ScreenProducts @submit="scrrenTable"></ScreenProducts>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName"></TableTittle>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="50"
        > </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          width="50"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          width="320"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="type"
          label="课程类型"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.type == '1'">课堂</span>
            <span v-show="scope.row.type == '2'">题库</span>
            <span v-show="scope.row.type == '3'">阅读资料</span>
            <span v-show="scope.row.type == '4'">实体资料</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="price"
          label="课程价格"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.price + "元" }}
            </span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="year"
          label="课程年份"
        ></el-table-column>
        <el-table-column
          prop="hours"
          label="课程课时"
        >
          <template slot-scope="scope">
            <span>{{scope.row.hours}}小时</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="watcher"
          label="产品经理"
          width="139"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.watcher | formateWatcher }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          label="状态"
        >
          <template slot-scope="scope">
            <el-tag
              type="danger"
              size="small"
              v-show="scope.row.status == '-1'"
            >下 架</el-tag>
            <el-tag
              type="info"
              size="small"
              v-show="scope.row.status == '0'"
            >未上架</el-tag>
            <el-tag
              type="success"
              size="small"
              v-show="scope.row.status == '1'"
            >上 架</el-tag>
            <el-tag
              type="warning"
              size="small"
              v-show="scope.row.status == '2'"
            >预 售</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="opendialogEdit(scope.row)"
              :disabled="!$buttonPermission('productCourse:edit')"
            >编辑</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button
          size="small"
          type="danger"
          @click="deleteItem"
          :disabled="!$buttonPermission('productCourse:remove')"
        >批量删除</el-button>
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog
      title="产品管理"
      :visible.sync="dialogFormVisible"
      width="570px"
      @close="dialogFormVisibleClose"
    >
      <el-form
        :model="form"
        label-position="right"
        label-width="80px"
        size="small"
        ref="dialogform"
        :rules="rules"
      >
        <el-form-item
          label="商品类型"
          prop="type"
        >
          <el-select
            v-model="form.type"
            placeholder="请选择"
          >
            <el-option
              label="课堂"
              value="1"
            ></el-option>
            <el-option
              label="题库"
              value="2"
            ></el-option>
            <el-option
              label="阅读资料"
              value="3"
            ></el-option>
            <el-option
              label="实体资料"
              value="4"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="课程名称"
          prop="name"
        >
          <el-input
            v-model="form.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="课程年份"
          prop="year"
        >
          <el-select
            v-model="form.year"
            placeholder="请选择"
          >
            <el-option
              label="2022年"
              value="2022"
            ></el-option>
            <el-option
              label="2023年"
              value="2023"
            ></el-option>
            <el-option
              label="2024年"
              value="2024"
            ></el-option>
            <el-option
              label="2025年"
              value="2025"
            ></el-option>
            <el-option
              label="2026年"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item
          label="课时数"
          prop="hours"
        >
          <el-input
            placeholder="请输入课时数"
            v-model="form.hours"
          >
            <template slot="append">小时</template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item
          label="课程价格"
          prop="price"
        >
          <el-input
            placeholder="请输入课程价格"
            v-model="form.price"
          >
          </el-input>
        </el-form-item> -->
        <el-form-item
          label="产品经理"
          prop="watcher"
        >
          <el-select
            v-model="form.watcher"
            placeholder="请选择"
            style="width: 450px"
            multiple
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.alias"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="商品状态"
          prop="status"
        >
          <el-radio-group v-model="form.status">
            <el-radio-button label="-1">下架</el-radio-button>
            <!--             <el-radio-button label="0">未上架</el-radio-button> -->
            <el-radio-button label="1">上架</el-radio-button>
            <!--             <el-radio-button label="2">预售</el-radio-button> -->
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit(submitmethods)"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import ScreenProducts from "../../../components/ScreenComponents/ScreenProducts/ScreenProducts.vue";
import {
  createcompanycourse,
  courselist,
  editcompanycourse,
  deletecourselist,
} from "../../../request/api";
export default {
  components: {
    TableTittle,
    ScreenProducts,
  },
  data() {
    return {
      TableName: "产品列表",
      dialogFormVisible: false,
      submitmethods: "add",
      form: {},
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      pageTotal: 0,
      tableData: [],
      rules: {
        name: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择课程类型", trigger: "blur" }],
        price: [{ required: true, message: "请输入课程价格", trigger: "blur" }],
        status: [
          { required: true, message: "请选择课程状态", trigger: "blur" },
        ],
        year: [{ required: true, message: "请选择课程年份", trigger: "blur" }],
        hours: [
          { required: true, message: "请输入课时", trigger: "blur" },
          { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }],
      },
      deleteList: [],
    };
  },
  filters: {
    formateWatcher: function (val) {
      if (!val) {
        return "";
      }
      var str = "";
      val.forEach((item) => {
        str += item.alias + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
  },
  mounted() {
    this.getList(this.query);
    this.$store.dispatch('getStaffList')
  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    opendialogAdd() {
      this.submitmethods = "add";
      this.form = {};
      this.dialogFormVisible = true;
    },
    opendialogEdit(item) {
      this.submitmethods = "edit";
      this.dialogFormVisible = true;
      this.form = JSON.parse(JSON.stringify(item)); // 利用JSON深拷贝
      delete this.form.creator; // 把创建者的参数delete掉，否则修改时会报错
      if (this.form.watcher) {
        // Select多选做回显
        this.form.watcher = this.form.watcher.map((v) => Number(v.id));
      }
    },
    getList(query) {
      courselist(query).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    submit(method) {
      this.$refs["dialogform"].validate((valid) => {
        if (valid) {
          if(this.form.watcher){
            this.form.watcher = this.form.watcher.join(",");
          }
          this.form.hours = parseFloat(this.form.hours)
          this.form.price = ""
          this.form.year = ""
          switch (method) {
            case "add":
              createcompanycourse(this.form).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false;
                  this.getList(this.query);
                });
              });
              break;
            case "edit":
              editcompanycourse(this.form).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false;
                  this.getList(this.query);
                });
              });
              break;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val;
    },
    deleteItem() {
      if (this.deleteList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要删除的数据",
        });
        return false;
      }
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            ids: [],
          };
          this.deleteList.forEach((item) => {
            query.ids.push(item.id);
          });
          deletecourselist(query).then((res) => {
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    dialogFormVisibleClose() {
      this.form = {};
    },
    //筛选组件
    scrrenTable({ screenForm }) {
      this.$set(this.query, "pageIndex", 1);
      this.query = {
        ...this.query,
        ...screenForm,
      };
      this.getList(this.query);
    },
    onInput(e) {
      // 通过正则过滤小数点后两位
      this.form.hours = this.form.hours.match(/^\d*(\.?\d{0,2})/g)[0]
      this.$message({
        message: '课时最多只能输入两位小数',
        type: 'warning'
      });
    }
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.card-header {
  display: flex;
  justify-content: space-between;
  .el-button {
    height: 35px;
    line-height: 10px;
  }
}
.block {
  margin-bottom: 14px;
  float: right;
}
</style>