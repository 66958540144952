<template>
  <el-dialog
    title="新增记录"
    :visible.sync="addRecordDialog"
    width="570px"
  >
    <el-form
      :model="addRecordForm"
      label-position="right"
      ref="formRules"
      :rules="rules"
    >
      <el-form-item
        label="学员状态"
        prop="status"
      >
        <el-select
          v-model="addRecordForm.status"
          placeholder="请选择"
          style="width: 443px"
        >
          <el-option
            v-for="item in $store.state.statusOptions"
            :key="item.id"
            :label="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="跟进内容"
        prop="content"
      >
        <el-input
          type="textarea"
          v-model="addRecordForm.content"
          autocomplete="off"
          style="width: 443px"
        ></el-input>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="emitClose">取 消</el-button>
      <el-button
        type="primary"
        @click="submit('formRules')"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { dictionary } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      addRecordForm: {},
      addRecordDialog: this.isVisible,
      statusOptions: [],
      recordInfo: this.content,
      rules: {
        status: [
          { required: true, message: "请选择学员状态", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入跟进内容", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    isVisible(val) {
      this.addRecordDialog = val;
    },
    content(val) {
      this.addRecordForm = val;
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.$emit("submit", { form: this.addRecordForm });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>