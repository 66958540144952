<template>
  <div>
    <el-dialog
      title="发货"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :width="!isfixed?'375px':''"
      :close-on-click-modal="false"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="发货服务："
          class="inline-item"
          style="height:40px;"
          prop="is_service"
        >
          <el-radio-group v-model="is_all">
            <el-radio :label="1">全部发货</el-radio>
            <el-radio :label="0">部分发货<span
                class="tips"
                v-if="is_all==0"
              >(请选择当前发货的资料名称及数量)</span></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-table
          v-if="is_all==0"
          :data="product_list"
          :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
          :cell-style="{ textAlign: 'center', fontSize: '13px' }"
          size="mini"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="50"
            fixed="left"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            width="300"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              《{{scope.row.product_name}}》
            </template>
          </el-table-column>
          
          <el-table-column
            prop="year"
            label="年份"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{scope.row.year?scope.row.year+"年":"不限"}}
            </template>
          </el-table-column>
          <el-table-column
            prop="product_sku"
            label="规格"
            show-overflow-tooltip
          >
          </el-table-column>

          <el-table-column
            label="数量"
            show-overflow-tooltip
            width="200px"
          >
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.new_quantity"
                :min="1"
                :max="scope.row.quantity"
              ></el-input-number>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item
          label="上传照片："
          prop="pictures"
          ref="image"
          style="margin-top:16px;"
        >
          <el-upload
            :action="imgUploadUrl"
            list-type="picture-card"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="快递名称："
          prop="express"
        >
          <el-select
            v-model="dataForm.express"
            placeholder="请选择快递名称"
            filterable
            clearable
            style="width:100%;"
          >
            <el-option
              v-for="(item,index) in $store.state.expressList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="快递单号："
          prop="express_no"
        >
          <div class="express-no-wrap">
            <el-input
              v-model="dataForm.express_no"
              placeholder="请输入快递单号"
            >
            </el-input>
            <el-upload
              :action="imgUploadUrl"
              multiple
              :on-success="handleExpressSuccess"
              :show-file-list="false"
              v-if="isfixed"
            >
              <div class="upload-wrap">
                <img
                  src="@/assets/images/photo_icon.png"
                  alt=""
                  srcset=""
                >
              </div>
            </el-upload>
            <div
              class="upload-wrap"
              v-else
              @click="scanCode"
            >
              <img
                src="@/assets/images/photo_icon.png"
                alt=""
                srcset=""
              >
            </div>
          </div>

        </el-form-item>
          <el-form-item
        >
         <div class="hint">
           <i class="el-icon-warning" style="color:#ED7B2F ;margin-right: 5px;font-size: 18px;"></i>
            <div> 快递信息同步展示在学员端，请正确填写。</div>
         </div>
        </el-form-item>


      </el-form>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>

    </el-dialog>

  </div>
</template>

<script>


import { shipshipping, expressocr, shipslipOne, shipslipsplitUpdate } from "@/request/api";
import { imgUploadUrl, fileUploadUrl } from "@/request/http";
import { adminUrl } from "@/utils/helper.js";
const wx = require("weixin-js-sdk");


export default {
  components: {

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dataForm: {
      },
      dialogFormVisible: this.isVisible,
      fileList: [],
      imgUploadUrl: imgUploadUrl(),
      rules: {
        express: [{ required: true, message: "请选择快递名称", trigger: "blur" }],
        express_no: [{ required: true, message: "请输入快递单号", trigger: "blur" }],
        pictures: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.valiIconFlag) {
              callback(new Error('请上传图片'))
            } else {
              callback()
            }
          },
        }],
      },
      isfixed: document.body.clientWidth < 820 ? false : true,
      is_all: 1,
      product_list: [],
      chooseList: [],
      shipslipOne: null,
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.is_all = 1
        this.dataForm = {
          express: {
            value: "ems",
            label: "EMS"
          }
        }
        this.fileList = []
        this.fetchDetail(this.id)
      }
    },

  },
  methods: {
    fetchDetail(id) {
      shipslipOne({ id }).then((res) => {
        this.shipslipOne = Object.assign([], res.data)
        this.product_list = res.data.product_list
        this.product_list.forEach(v => {
          this.$set(v, 'new_quantity', v.quantity)
        })
      });
    },
    emitClose() {
      this.$set(this.dataForm, "express", {
        express: {
          value: "ems",
          label: "EMS"
        }
      })
      this.$set(this.dataForm, "express_no", "")
      this.$emit("close");
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
      if (this.fileList.length == 0) {
        this.valiIconFlag = false;
        this.$refs.image.validate();
      }
    },
    handleSuccess(response, file, fileList) {
      this.fileList.push({
        url: response.data.url
      })
      this.valiIconFlag = true
      this.$refs.image.clearValidate()
      this.$message.success('上传成功')
    },
    handleExpressSuccess(response, file, fileList) {
      this.$set(this.dataForm, "express_no", "单号识别中，请稍后...")
      console.log(file.response.data.url);
      expressocr({ img_url: file.response.data.url }).then((res) => {
        if (res.code == 0) {
          this.$set(this.dataForm, "express_no", res.data.data.barcode[0])
        } else {
          this.$set(this.dataForm, "express_no", "单号识别失败")
        }
      });
      /*    this.$base64Img(file.response.data.url).then(url => {
           console.log(url);
         }) */

    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          if (this.is_all == 1) {
            this.send()
          } else {
            // 拆分更新再发货
            this.shipslipsplitUpdate()
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    send() {
      let query = {
        express_code: this.dataForm.express.value,
        express_no: this.dataForm.express_no,
        express_pictures: this.fileList.map((v) => { return v.url }).join(','),
        id: this.id,
      }
      shipshipping(query).then((res) => {
        if (res.code == 0) {
          this.$message.success(`保存成功`);
        }
        this.emitClose()
        this.$emit('submit')
      });
    },
    shipslipsplitUpdate() {
      if (this.chooseList.length) {
        let last_arr = this.product_list.filter(v => {
          return this.chooseList.findIndex(t => {
            return v.product_id == t.product_id
          }) == -1
        })
        let choose_list = this.chooseList.map(v => {
          return {
            product_id: v.product_id,
            quantity: Number(v.quantity) - Number(v.new_quantity)
          }
        }).filter(v => { return v.quantity > 0 })
        let un_choose_list = last_arr.map(v => {
          return {
            product_id: v.product_id,
            /*  quantity: Number(v.new_quantity) < Number(v.quantity) ? Number(v.quantity) - Number(v.new_quantity) : 0 */
            quantity: Number(v.new_quantity)
          }
        }).filter(v => { return v.quantity > 0 })
        const params = {
          id: this.id,
          cid: localStorage.getItem("cid"),
          is_user_temp: this.shipslipOne.student_id ? 0 : 1,
          theme: this.shipslipOne.theme + '-拆分单',
          user_obj: this.shipslipOne.student_id ? {
            student_id: this.shipslipOne.student_id
          } : {
            receiver_name: this.shipslipOne.receiver_name,
            receiver_phone: this.shipslipOne.receiver_phone,
            receiver_address: this.shipslipOne.receiver_address
          },
          product_list: un_choose_list.concat(choose_list),
          plan_express_time: this.shipslipOne.plan_express_time,
        }
        shipslipsplitUpdate(params).then((res) => {
          if (res.code == 0) {
            this.send()
          } else {
            this.$message.error(`发货拆分失败`);
          }
        });
      } else {
        this.$message.error(`请选择资料`);
        return
      }

    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file);
        //开始转
        reader.onload = function () {
          fileResult = reader.result.replace(/data.+?;base64,/, "");

        };
        //转 失败
        reader.onerror = function (error) {
          reject(error);
        };
        //转 结束  咱就 resolve 出去
        reader.onloadend = function () {
          resolve(fileResult);
        };
      });
    },
    // 微信扫一扫
    scanCode() {
      let str = adminUrl()
      const url = location.href.split("#")[0];
      this.$axios({
        url: `${str}/api/v1/wechat/sign`,
        method: 'post',//可以省略 不写默认是get
        data: { url: url },
      }).then(res => {
        let _this = this;
        if (res.code == 0) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名，见附录1
            jsApiList: ["scanQRCode"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          wx.ready(function () {
            // 9.1.2 扫描二维码并返回结果
            wx.scanQRCode({
              needResult: 1,
              desc: "scanQRCode desc",
              success: function (res) {
                console.log(666666666666, res);
                _this.$set(_this.dataForm, 'express_no', res.resultStr.split(",")[1])
                console.log(_this.dataForm, 'dataForm');
              }
            });
          });
          //初始化jsapi接口 状态
          wx.error(function (res) {
            alert("调用微信jsapi返回的状态:" + res.errMsg);
          });
        }
      })
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
  },
};
</script>

<style lang="less" scoped>
.hint {
  width: 380px;
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  background-color: #F9E0C7;

  
}
/deep/.el-upload {
  width: 96px;
  height: 96px;
  line-height: 96px;
}
/deep/.el-upload-list__item {
  width: 96px !important;
  height: 96px !important;
}
.express-no-wrap {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  /deep/.el-input__inner {
    border: none;
  }
  /deep/.el-upload {
    width: 30px;
    height: 32px;
    line-height: 40px;
  }
}
.upload-wrap {
  img {
    width: 16px;
    height: 16px;
  }
}
.tips {
  margin-left: 8px;
  font-size: 12px;
}
</style>