import Administration from '../views/RecruitStudents/Administration/main.vue'
import Examine from '../views/RecruitStudents/Examine/main.vue'
import RecycleBin from "../views/RecruitStudents/RecycleBin/main.vue";



import Record from "../views/RecruitStudents/Administration/Record/Record.vue";

export default [
    /*** 招  生 ***/
    {
        path: '/recruitstudents/administration',
        component: Administration,
        meta: { title: '招生管理' },
    },
    /*** 招生管理-查看跟进记录 ***/
    {
        path: '/recruitstudents/administration/record',
        component: Record,
        name: 'record',
        meta: { title: '跟进记录' }
    },
    {
        path: '/recruitstudents/examine',
        component: Examine,
        meta: { title: '招生审批' }
    },
    {
        path: '/recruitstudents/recycle',
        component: RecycleBin,
        meta: { title: '回收站' }
    },
]