<template>
  <div class="content">
    <el-dialog
      top="5vh"
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="dialogFormVisible = false"
      :close-on-click-modal="false"
      width="620px"
    >
      <div class="main">
        <div class="line">
          <div>院校：</div>
          <div>{{ detail.apply_college_name }}</div>
        </div>
        <div class="line">
          <div>学院：</div>
          <div>{{ detail.apply_academy_name }}</div>
        </div>
        <div class="line">
          <div>专业：</div>
          <div>{{ detail.apply_major_name }}</div>
        </div>

        <div v-if="type == '教学计划'">
          <div class="line">
            <div>科目：</div>
            <div>{{ detail.apply_subject_names }}</div>
          </div>
          <div class="line">
            <div>参考书：</div>
            <div v-if="detail.apply_reference_list">
              <div
                v-for="(item, index) in JSON.parse(detail.apply_reference_list)"
                :key="index"
              >
                {{ item.apply_reference_name }}
              </div>
            </div>
          </div>
          <div class="line">
            <div>辅导小时：</div>
            <div>{{ detail.lesson_hours }}小时</div>
          </div>
          <div class="line">
            <div>规划分布：</div>
            <div v-if="detail.plan_area_list">
              <div
                v-for="(item, index) in JSON.parse(detail.plan_area_list)"
                :key="index"
                class="plan"
              >
                {{ getName(index + 1) }} -- {{ item.area_percent }}%
              </div>
            </div>
          </div>
          <div class="line">
            <div>课程大纲：</div>
            <div v-html="detail.plan_course_outline"></div>
          </div>
          <div class="line">
            <div>其他：</div>
            <div v-html="detail.plan_others"></div>
          </div>
        </div>

        <div v-else-if="type == '考试大纲' || type == '命题趋势'">
          <div class="line">
            <div>科目：</div>
            <div>{{ detail.apply_subject_names }}</div>
          </div>
          <div class="line">
            <div>参考书：</div>
            <div v-if="detail.apply_reference_list">
              <div
                v-for="(item, index) in JSON.parse(detail.apply_reference_list)"
                :key="index"
              >
                {{ item.apply_reference_name }}
              </div>
            </div>
          </div>
          <div class="line">
            <div>题型：</div>
            <div>{{ detail.ground_topic_types }}</div>
          </div>
        </div>


        <div v-else-if="type == '招生预测' ">
          <div class="line">
          <div>预测年份：</div>
          <div>{{ detail.enrol_predict_target_year }}</div>
        </div>

          <div class="line"  v-for="(item, index) in JSON.parse(detail.enrol_predict_history_year_list)"
                :key="index">
            <div>{{item.year}}录取情况：</div>
            <div >
              <div class="plan" >
                分数线 -- {{ item.line }}分
              </div>
               <div class="plan" >
                招生人数 -- {{ item.plan }}人
              </div>
              <div class="plan" >
                录取比例 -- {{ item.ratio_basic+':'+ item.ratio_recruit}}
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <!-- <div class="footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="submit()" plain>
                    {{ btnName }}
                </el-button>
                <el-button type="primary" @click="nextSubmit()" v-if="title == '新建计划'">确定并创建下一条</el-button>
            </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { manageUrl } from "@/utils/helper";
export default {
  data() {
    return {
      title: "问题",
      dialogFormVisible: false,
      id: "",
      detail: {},
      type: "",
    };
  },
  created() {},
  methods: {
    getName(index) {
      switch (index) {
        case 1:
          return "知识点梳理";
        case 2:
          return "重难点讲解";
        case 3:
          return "往年真题精讲";
        case 4:
          return "命题趋势分析";
        case 5:
          return "必考点预测";
        case 6:
          return "模拟评测";
      }
    },
    open(id, type) {
      this.type = type;
      this.id = id;
      this.dialogFormVisible = true;
      this.getDetail();
    },
    getDetail() {
      let str = manageUrl();
      this.$axios({
        url: `${str}/api/v1/gpt/gptQA`,
        method: "get", //可以省略 不写默认是get
        params: {
          id: this.id,
        },
      }).then((res) => {
        if (res.code == 0) {
          this.detail = res.data;
          console.log("res", res);
        }
        // console.log(res)
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .line {
    border: none;
    display: flex;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    & > div:nth-of-type(1) {
      flex-shrink: 0;
      width: 120px;
      line-height: 16px;
      color: #606266;
    }
    & > div:nth-of-type(2) {
      color: #303133;
    }
  }
}

.plan {
  margin-bottom: 14px;
}
.plan:last-child {
  margin-bottom: 0;
}
</style>