import DataList from "@/views/DataInventory/DataList/index.vue";
import Record from "@/views/DataInventory/DataList/record.vue";
import Stock from "@/views/DataInventory/Stock/index.vue";
import DeliverList from "@/views/DataInventory/DeliverList/index.vue";
import Student from "@/views/DataInventory/Student/index.vue";
import StudentCreate from "@/views/DataInventory/Student/create.vue";
import StudentEdit from "@/views/DataInventory/Student/edit.vue";
import StudentDeliverList from "@/views/DataInventory/Student/deliverlist.vue";
import Exteriorstudent from "@/views/DataInventory/Student/exteriorStudent.vue";


export default [

    {
        path: '/dataInventory/dataList',
        component: DataList,
        meta: { title: '资料管理' }
    },
    {
        path: '/dataInventory/dataList/record/:id',
        component: Record,
        meta: { title: '供销存明细' }
    },
    {
        path: '/dataInventory/stock',
        component: Stock,
        meta: { title: '库存管理' }
    },
    {
        path: '/dataInventory/deliverList',
        component: DeliverList,
        meta: { title: '发货清单' }
    },
    {
        path: '/dataInventory/student',
        component: Student,
        meta: { title: '学员列表' }
    },
   
    {
        path: '/dataInventory/student/create',
        component: StudentCreate,
        meta: { title: '创建学员' }
    },
    {
        path: '/dataInventory/student/edit/:id',
        component: StudentEdit,
        meta: { title: '编辑学员' }
    },
    {
        path: '/service/exteriorstudent',
        component: Exteriorstudent,
        meta: { title: '学员管理' }
    },
  
    {
        path: '/dataInventory/student/deliverlist',
        component: StudentDeliverList,
        meta: { title: '学员发货单列表' }
    },

]