<template>
  <div class="content">
    <el-dialog
      title="确定院校"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="620px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="auto"
        label-position="left"
        size="small"
        ref="formRules"
        :rules="rules"
      >

        <el-form-item
          label="考研年份"
          prop="apply_year"
        >
          <el-select
            v-model="form.apply_year"
            placeholder="请选择"
            style="width: 304px"
          >
            <el-option
              label="2022年"
              value="2022"
            ></el-option>
            <el-option
              label="2023年"
              value="2023"
            ></el-option>
            <el-option
              label="2024年"
              value="2024"
            ></el-option>
            <el-option
              label="2025年"
              value="2025"
            ></el-option>
            <el-option
              label="2026年"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考院校"
          prop="apply_college"
        >
          <el-select
            v-model="form.apply_college"
            filterable
            placeholder="请选择"
            style="width: 493px"
            @change="collegeChange"
            clearable
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考专业"
          prop="apply_major"
        >
          <el-select
            v-model="form.apply_major"
            filterable
            placeholder="请选择"
            style="width: 493px"
            @change="majorChange"
            clearable
          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
              <span>{{ `（${item.code}）${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考学院"
          prop="apply_academy"
        >
          <el-select
            v-model="form.apply_academy"
            filterable
            placeholder="请选择"
            style="width: 493px"
            @change="academyChange"
            clearable
          >
            <el-option
              v-for="(item,index) in academyOptions"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
              <span>{{ `（${item.code}）${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="辅导科目"
          prop="subjects_val"
        >
          <el-select
            v-model="form.subjects_val"
            multiple
            placeholder="请选择"
            style="width: 493px"
            :multiple-limit="4"
            @change="$forceUpdate()"
          >
            <el-option
              v-for="(item,index) in subjectsOptions"
              :key="index"
              :label="item.subject_name"
              :value="{ value: item.subject_code, label: item.subject_name}"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="课程课时"
          prop="apply_year"
        >
          <el-input
            v-model="form.service_hours"
            clearable
            style="width: 304px"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          > <template slot="append">小时</template></el-input>
        </el-form-item>
        <el-form-item label="参考书目">
          <el-input
            v-model="form.refer_book"
            type="textarea"
            placeholder="请输入参考书目"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="定校备注">
          <el-input
            v-model="form.choose_remark"
            type="textarea"
            placeholder="请输入定校备注"
            clearable
          ></el-input>
        </el-form-item>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  majorlist,
  subjectlist,
  academylist,
  studentBasiccollegechoose
} from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      form: this.content,
      majorOptions: [],
      academyOptions: [],
      subjectsOptions: [],
      rules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入你的手机号", trigger: "blur" },
        ],
        wechat: [
          { required: true, message: "请输入你的微信号", trigger: "blur" },
        ],
        qq: [{ required: true, message: "请输入你的QQ号", trigger: "blur" }],
        tags: [
          { required: true, message: "请选择你的学员标签", trigger: "blur" },
        ],
        apply_year: [
          { required: true, message: "请选择你的考研年份", trigger: "blur" },
        ],
        source: [{ required: true, message: "请选择来源", trigger: "blur" }],
        products: [
          { required: true, message: "请选择报名课程", trigger: "blur" },
        ],
        apply_college: [
          { required: true, message: "请选择报考院校", trigger: "blur" },
        ],
        apply_academy: [
          { required: true, message: "请选择报考学院", trigger: "blur" },
        ],
        apply_major: [
          { required: true, message: "请选择报考专业", trigger: "blur" },
        ],
        watcher: [{ required: true, message: "请选择负责人", trigger: "blur" }],
        subjects_val: [
          { required: true, message: "请选择辅导科目", trigger: "blur" },
        ],
        is_choose: [
          { required: true, message: "请选择是否定校", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    isVisible(val) {

      this.dialogFormVisible = val;
    },
    content(val) {
      if (val) {
        this.form = val
        if (this.form.apply_college_name && this.form.apply_college_code) {
          this.$set(this.form, 'apply_college', {
            label: this.form.apply_college_name,
            value: this.form.apply_college_code,
          })
          this.getMajor(this.form.apply_college_code)
        }
        if (this.form.apply_major_name && this.form.apply_major_code) {
          this.$set(this.form, 'apply_major', {
            label: this.form.apply_major_name,
            value: this.form.apply_major_code,
          })
          this.getAcedemy(this.form.apply_college_code, this.form.apply_major_code)
        }
        if (this.form.apply_academy_name && this.form.apply_academy_code) {
          this.$set(this.form, 'apply_academy', {
            label: this.form.apply_academy_name,
            value: this.form.apply_academy_code,
          })
          this.getSubjects(this.form.apply_college_code, this.form.apply_major_code);
        }
        if (this.form.subjects) {
          this.$set(this.form, 'subjects_val', this.form.subjects.map((v) => {
            return {
              value: v.subject_code,
              label: v.subject_name,
            }
          }))
        }
      }

    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    // 因为传科目时要上传对象所以在这里做一些处理

    submit(rules) {
    
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.form.id,
            apply_year: this.form.apply_year,
            apply_college_code: this.form.apply_college.value,
            apply_college_name: this.form.apply_college.label,
            apply_major_code: this.form.apply_major.value,
            apply_major_name: this.form.apply_major.label,
            apply_academy_code: this.form.apply_academy.value,
            apply_academy_name: this.form.apply_academy.label,
            subjects: this.form.subjects_val.map((v) => {
              return {
                subject_code: v.value,
                subject_name: v.label
              }
            }),
            is_choose: 1,
            service_hours: this.form.service_hours,
            choose_remark: this.form.choose_remark,
            refer_book: this.form.refer_book,
          }
          studentBasiccollegechoose(query).then((res) => {
            if (res.code == 0) {
              this.$message.success('提交成功')
              this.$emit('submit')
              this.$emit('close')
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    collegeChange(e) {
      this.form.apply_major = {}
      this.majorOptions = []
      this.form.apply_academy = {}
      this.academyOptions = []
      this.form.subjects = []
      this.form.service_subjects = []
      this.subjectsOptions = []
      if (e) {
        this.getMajor(e.value);
      }
    },
    academyChange(e) {
      console.log(e);
      this.form.subjects = []
      this.form.service_subjects = []
      this.subjectsOptions = []
      if (e) {
        this.getSubjects(this.form.apply_college.value, this.form.apply_major.value);
      }
      this.$forceUpdate()
    },
    majorChange(e) {
      this.form.apply_academy = {}
      this.academyOptions = []
      this.form.subjects = []
      this.form.service_subjects = []
      this.subjectsOptions = []
      if (e) {
        this.getAcedemy(this.form.apply_college.value, e.value);
      }
      this.$forceUpdate()
    },
    getAcedemy(college_code, major_code) {
      let query = {
        isPaging: 0,
        college_code,
        major_code,
      };
      academylist(query).then((res) => {
        this.academyOptions = res.data.list || [];
      });
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code,
      };
      majorlist(query).then((res) => {
        this.majorOptions = res.data.list || [];

      });
    },
    getSubjects(college_code, major_code) {
      let query = {
        isPaging: 0,
        college_code,
        major_code,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程"
        }
        this.subjectsOptions = res.data.list || [];
        this.subjectsOptions.push(obj)
      });
    },
  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
</style>