<template>
  <el-dialog
    title="结算"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="570px"
    @close="emitClose"
    id="dialog"
  >
    <span class="tips">合计金额 </span>
    <div class="count-content">
      <span>￥{{ billInfo.order_amount }}</span>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      v-if="payMethodsIndex == 0"
    >
      <el-button @click="emitClose">取 消</el-button>
      <el-button
        type="primary"
        @click="accountPay"
      >确 定</el-button>
    </div>
    <el-form>
      <el-form-item label="支付方式">
        <div class="paymethod-content">
          <div
            class="paymethod-item"
            v-for="(item, index) in paymethodsArr"
            :key="item.value"
            :class="{ 'active-item': index == payMethodsIndex }"
            @click="chooseMethods(index)"
          >
            <img
              :src="item.img"
              alt=""
              class="payimg"
            />
            <span>{{ item.name }}</span>
            <img
              src="../../../assets/images/5@2x.png"
              alt=""
              srcset=""
              class="gridimg"
              v-show="index == payMethodsIndex"
            />
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <!--  微信支付和支付宝支付 开始 -->
        <div
          class="payqrcode-content"
          v-if="payMethodsIndex >= 0 && payMethodsIndex != 0"
        >
          <div class="payqrcode-title">
            <img
              :src="paymethodsArr[payMethodsIndex].img"
              alt=""
              srcset=""
            />
            <!-- 开始 -->
            <span>请使用{{ paymethodsArr[payMethodsIndex].name }}，支付{{
                billInfo.order_amount
              }}元</span>
            <!-- 结束 -->
          </div>
          <img
            :src="payWechatImg"
            alt=""
            srcset=""
            class="qrcodeimg"
            v-show="payMethodsIndex == 1"
          />
          <iframe
            :src="payAlipayImg"
            class="qrcodeimg"
            v-show="payMethodsIndex == 2"
            scrolling="no"
            frameborder="0"
          ></iframe>
        </div>
        <!--  微信支付和支付宝支付 结束 -->
        <!--  转账 开始 -->
        <div v-if="payMethodsIndex >= 0 && payMethodsIndex == 0">
          <div class="otherpaymethod-content">
            <span class="balance-span">当前余额：￥{{ $store.state.AccountInfo.balance }}</span>
            <span
              class="balance-tips"
              v-show="$store.state.AccountInfo.balance < billInfo.order_amount"
            >余额不足，请使用其他方式支付</span>
          </div>
        </div>
        <!--  转账 结束 -->
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { pay, paycheck, helpFindedit } from "../../../request/api";
import { formateObjDaiZhao } from "../../../utils/helper";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      billInfo: this.content,
      contentStyle: {
        width: "200px",
      },
      paymethodsArr: [
        {
          img: require("../../../assets/images/thirdpay.png"),
          value: 3, // 支付方式：0无，1支付宝，2微信，3余额，4转账',
          name: "余额支付",
        },
        {
          img: require("../../../assets/images/wechatpay.png"),
          value: 2,
          name: "微信支付",
        },
        {
          img: require("../../../assets/images/alipay.png"),
          value: 1,
          name: "支付宝支付",
        },
      ],
      payMethodsIndex: -1,
      payWechatImg: "", // 微信二维码
      payAlipayImg: "", // 支付宝二维码
      payment_id: "",
      timer: null,
    };
  },
  mounted() { },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.billInfo = val;

    },
  },
  methods: {
    emitClose() {
      this.payMethodsIndex = -1;
      clearTimeout(this.timer);
      this.timer = null;
      this.$emit("close");
    },
    chooseMethods(index) {
      this.payMethodsIndex = index;
      this.payWechatImg = "";
      this.payAlipayImg = "";
      switch (index) {
        case 0:
          clearTimeout(this.timer);
          this.timer = null;
          break;
        case 1:
          this.wechatAndAlipay();
          break;
        case 2:
          this.wechatAndAlipay();
          break;

        default:
          break;
      }
    },
    // 余额支付
    accountPay() {
      let query = {
        ref_id: this.billInfo.id,
        pay_type: this.paymethodsArr[this.payMethodsIndex].value,
        ref_type: 3,
      };
      pay(query).then((res) => {
        this.payment_id = res.data.payment_id;
        if (res.code == 0) {
          this.$notify.success({
            title: "提示",
            message: "支付成功",
          });
          if (this.billInfo == null) {
            this.emitClose();
            return false
          }
          // 修改成功之后还要再调一次老系统的接口同步数据
          let obj = formateObjDaiZhao(this.billInfo, 'edit')
          obj.paytag = "已收"
          helpFindedit(obj).then(response => {
            if (response.data.data.message == "ok") {
              this.emitClose();
            } else {
              this.$notify.error({
                title: "提示",
                message: response.data.data.message,
              });
            }
          })
        }
      });
    },
    wechatAndAlipay() {
      let query = {
        ref_id: this.billInfo.id,
        pay_type: this.paymethodsArr[this.payMethodsIndex].value,
        ref_type: 3,
      };
      const loading = this.$loading({
        lock: true,
        text: '二维码加载中...',
        spinner: 'el-icon-loading',
        background: 'transparent',
      });
      pay(query).then((res) => {
        this.payment_id = res.data.payment_id;

        switch (this.paymethodsArr[this.payMethodsIndex].value) {
          case 1:
            this.payAlipayImg = res.data.url;
            setTimeout(() => {
              loading.close();
            }, 600);
            this.IsDone();
            break;
          case 2:
            this.payWechatImg = res.data.qr_url;
            loading.close();
            this.IsDone();
            break;
          default:
            break;
        }
      });
    },
    // 每n秒判断支付是否完成
    IsDone() {
      let query = {
        id: this.payment_id,
      };
      paycheck(query).then((res) => {
        if (res.data.pay_status == 1) {
          this.$message.success('支付成功');
          // 修改成功之后还要再调一次老系统的接口同步数据
          let obj = formateObjDaiZhao(this.billInfo, 'edit')
          obj.paytag = "已收"
          helpFindedit(obj).then(response => {
            if (response.data.data.message == "ok") {
              this.emitClose();
            } else {
              this.$notify.error({
                title: "提示",
                message: response.data.data.message,
              });
            }
          })
        } else {
          this.timer = setTimeout(() => {
            this.IsDone();
          }, 2000);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  width: 128px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #303133;
  opacity: 1;
}
.count-content {
  height: 55px;
  text-align: center;
  margin-bottom: 36px;
  span {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 55px;
    color: #e6a23c;
    opacity: 1;
  }
}
.paymethod-content {
  height: 40px;
  display: flex;
  .paymethod-item {
    border: solid 1px #dcdfe6;
    height: 36px;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 8px;
    cursor: pointer;
    .payimg {
      width: 18px;
      height: 18px;
      margin-left: 12px;
      margin-right: 8px;
    }
    span {
      display: block;
      margin-right: 16px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 16px;
      color: #606266;
      opacity: 1;
    }
  }
}
.gridimg {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.payqrcode-content {
  height: 200px;
  width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .payqrcode-title {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 18px;
      height: 18px;
      margin-left: 12px;
      margin-right: 8px;
    }
    span {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 16px;
      color: #606266;
      opacity: 1;
    }
  }
  .qrcodeimg {
    width: 160px;
    height: 160px;
  }
}
.otherpaymethod-content {
  width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  opacity: 1;
  border-radius: 4px;
  padding-left: 77px;
  .balance-span {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 16px;
    color: #606266;
    opacity: 1;
  }
  .balance-tips {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #f56c6c;
    opacity: 1;
  }
}
.active-item {
  border: solid 1px #67c23a !important;
}
</style>