<template>
  <div>
    <el-dialog
      title="物流信息"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :width="!isfixed?'375px':'900px'"
    >
      <div class="info-wrap">
        <div class="info-expressInfo">
          <div class="info">
            <div class="label">快递单号</div>
            <div class="value">
              <div>
                <el-input
                  v-model="content.express_no"
                  placeholder="请输入内容"
                  style="width:150px;"
                  size="mini"
                  v-if="express_noStatus=='edit'"
                ></el-input>
                <span v-if="express_noStatus==''">
                  {{expressInfo.express_no}}
                </span>
              </div>
              <div class="btn-wrap">
                <div
                  class="btn"
                  @click="changeStatus('express_noStatus')"
                  v-if="express_noStatus==''"
                >修改</div>
                <div
                  class="btn"
                  @click="handleToEdit('express_noStatus')"
                  v-if="express_noStatus=='edit'"
                >确定</div>
                <div
                  class="btn btn-red"
                  @click="changeStatus('express_noStatus')"
                  v-if="express_noStatus=='edit'"
                >取消</div>

              </div>
            </div>
          </div>
          <div class="info">
            <div class="label">物流公司</div>
            <div class="value">
              <div>
                <el-select
                  v-model="content.express"
                  placeholder="请选择快递名称"
                  filterable
                  style="width:150px;"
                  size="mini"
                  v-if="express_nameStatus=='edit'"
                >
                  <el-option
                    v-for="(item,index) in $store.state.expressList"
                    :key="index"
                    :label="item.name"
                    :value="{ value: item.code, label: item.name}"
                  ></el-option>
                </el-select>
                <span v-if="express_nameStatus==''">
                  {{expressInfo.express_name}}
                </span>
              </div>
              <div class="btn-wrap">
                <div
                  class="btn"
                  @click="changeStatus('express_nameStatus')"
                  v-if="express_nameStatus==''"
                >修改</div>
                <div
                  class="btn"
                  @click="handleToEdit('express_nameStatus')"
                  v-if="express_nameStatus=='edit'"
                >确定</div>
                <div
                  class="btn btn-red"
                  @click="changeStatus('express_nameStatus')"
                  v-if="express_nameStatus=='edit'"
                >取消</div>

              </div>

            </div>
          </div>
          <!--      <div class="info">
            <div class="label">客服电话</div>
            <div class="value">{{expressInfo.receiver_address}}</div>
          </div> -->
          <div class=""></div>
        </div>
        <!--       <div
          class="info-expressInfo"
          style="border-top: solid 1px #dcdfe6;width:100%;"
        >
          <div class="info">
            <div class="label">发货地址</div>
            <div class="value">{{expressInfo.receiver_address}}</div>  
          </div>
        </div> -->
        <div
          class="info-expressInfo"
          style="border-top: solid 1px #dcdfe6;width:100%;"
        >
          <div class="info">
            <div class="label">收货地址</div>
            <div class="value">
              {{content.receiver_address}}

            </div>
          </div>
        </div>
      </div>
      <div class="time-line">
        <el-timeline>
          <el-timeline-item
            v-for="(item,index) in expressInfo.list"
            :key="index"
          >
            <div class="time-line-item">
              <span class="time">{{item.time}}</span>
              <span class="main">{{item.context}}</span>
            </div>
          </el-timeline-item>
        </el-timeline>

      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="emitClose"
        >确 定</el-button>
      </div>

    </el-dialog>

  </div>
</template>

<script>


import { shipexpressOne, shipslipEdit } from "@/request/api";



export default {
  components: {

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dataForm: {},
      expressInfo: {},
      dialogFormVisible: this.isVisible,
      isfixed: document.body.clientWidth < 820 ? false : true,
      express_nameStatus: "",
      express_noStatus: "",
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail(this.id)
      }
    },
    content(val) {
      if (val) {
        this.content = val
        this.content.express = {
          value: this.content.express_code,
          name: this.content.express_name

        }
      }
    }

  },
  methods: {
    fetchDetail(id) {
      shipexpressOne({ slip_id: id }).then((res) => {
        if (res.code == 0) {
          this.expressInfo = res.data
        }
      });
    },
    emitClose() {
      this.express_nameStatus = ''
      this.express_noStatus = ''
      this.$emit("close");
    },
    changeStatus(value) {
      switch (value) {
        case 'express_nameStatus':
          this.express_nameStatus == 'edit' ? this.express_nameStatus = '' : this.express_nameStatus = 'edit'
          break;
        case 'express_noStatus':
          this.express_noStatus == 'edit' ? this.express_noStatus = '' : this.express_noStatus = 'edit'
          break;
        default:
          break;
      }
    },
    handleToEdit() {
      let query = {
        id: this.content.id,
        cid: localStorage.getItem("cid"),
        express_code: this.content.express.value,
        express_name: this.content.express.name,
        express_no: this.content.express_no,
        express_pictures: this.content.express_pictures,
      }
      shipslipEdit(query).then((res) => {
        if (res.code == 0) {
          this.$message.success(`保存成功`);
          this.fetchDetail(this.id)
          this.express_nameStatus = ''
          this.express_noStatus = ''
          /*           this.emitClose()
                    this.$emit('submit') */
        }
      });
    }

  },
};
</script>

<style lang="less" scoped>
.info-wrap {
  border: solid 1px #dcdfe6;
  .info-expressInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .info {
      /*  min-width: 33%; */
      min-width: 50%;
      display: flex;
      color: #333;
      font-size: 14px;
      align-items: center;

      .label {
        width: 122px;
        padding: 12px 16px;
        background-color: #f5f5f5;
        border-right: solid 1px #dcdfe6;
        border-left: solid 1px #dcdfe6;
        font-size: 14px;
      }
      .value {
        padding: 0px 16px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-wrap {
          display: flex;
          align-items: center;
          .btn {
            & + .btn {
              margin-left: 8px;
            }
            color: #409eff;
            cursor: pointer;
            font-size: 12px;
          }
          .btn-red {
            color: red;
          }
        }

        .express_pictures {
          & + .express_pictures {
            margin-left: 16px;
          }
          width: 104px;
        }
      }
    }
  }
}
.time-line {
  margin-top: 32px;
  .time-line-item {
    display: flex;
    flex-wrap: wrap;
    span {
      font-size: 14px;
      color: #333;
    }
    .time {
      margin-right: 24px;
      font-weight: 600;
    }
    .main {
    }
  }
}
</style>