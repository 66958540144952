<template>
  <div class="dialog-wrap">
    <el-dialog
      title="科目选择"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="980px"
    >
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
      >
        <el-form-item
          label="科目名称"
          prop="subject"
        >
          <el-input
            v-model="query.subject"
            size="mini"
            placeholder="输入科目名称/代码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="院校名称"
          prop="college"
        >
          <el-input
            v-model="query.college"
            size="mini"
            placeholder="输入院校名称/代码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="统考"
          prop="type"
        >
          <el-select
            style="width: 100px"
            v-model="query.type"
            size="mini"
            placeholder="请选择"
          >
            <el-option
              v-for="item in filter.types"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-select
            style="width: 100px"
            v-model="query.status"
            size="mini"
            placeholder="请选择"
          >
            <el-option
              v-for="item in filter.status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            @click="handleSearch"
          >搜索</el-button>
          <el-button
            class="submit"
            size="mini"
            @click="handleFormReset('searchForm')"
          >重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        class="result"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        size="mini"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        > </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="subject_name"
          label="科目名称"
          min-width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="subject_code"
          label="科目代码"
          min-width="100"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          small
          layout="total, prev, pager, next,sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="handleSubmit"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { adminUrl } from "@/utils/helper.js";
import { subjectlist } from "@/request/api"

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subjects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      query: {
        subject: "",
        college: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 10,
      filter: {
        status: [
          { label: "全部", value: "" },
          { label: "正常", value: "1" },
          { label: "下线", value: "0" },
        ],
        types: [
          { label: "全部", value: "" },
          { label: "统考", value: "1" },
          { label: "非统考", value: "0" },
        ],
      },
      results: [],
      selections: [],
      countSelections: [],
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
      if (val) {
        this.fetchSubjectList();
      }
    },
    subjects(val) {
      this.countSelections = val;
    },
  },
  methods: {
    // 科目列表请求
    fetchSubjectList() {
      const param = Object.assign({}, this.query)
      subjectlist(param).then((res) => {
        this.results = res.data.list || [];
        this.pageTotal = res.data.count;

        this.$nextTick(this.setRowSelection)
      });
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchSubjectList();
    },
    // 设置选中行
    setRowSelection() {
      if (this.results.length) {
        this.results.forEach((row) => {
          if (this.subjects.find((item) => item.id === row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageIndex", 1);
      this.fetchSubjectList();
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields();
      this.fetchSubjectList();
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.fetchSubjectList();
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val;
      this.diffSelection();
    },
    handleSubmit() {
      this.$emit("submit", { subjects: this.countSelections });
      this.handleClose();
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        subject: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      };
      this.$emit("close");
    },
    // 选中项差异化计算
    diffSelection() {
      let diffs = [];
      // 减
      this.countSelections.forEach((item, index) => {
        if (!this.selections.find((v) => v.id === item.id)) {
          if (this.results.find((v2) => v2.id === item.id)) {
            diffs.push(item);
          }
        }
      });
      if (diffs.length) {
        this.countSelections = this.countSelections.filter(
          (item) => !diffs.find((v) => v.id === item.id)
        );
      }
      // 加
      this.selections.forEach((item) => {
        if (!this.countSelections.find((v) => v.id === item.id)) {
          this.countSelections.push(item);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    margin-top: 10px;
  }
}
</style>
