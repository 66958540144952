<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/dataInventory/student' }">学员列表</el-breadcrumb-item>
        <el-breadcrumb-item>创建学员</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div
      class="main"
      style="margin-top: 20px"
    >
      <el-form
        :model="dataForm"
        ref="formRules"
        :rules="rules"
        label-position="right"
        label-width="85px"
        size="small"
      >
        <div class="container">
          <div
            class="main-item"
            style="padding-right:8px;"
          >
            <div class="card">
              <TableTittle
                :tittle="titleName[0]"
                class="tabletitle"
              ></TableTittle>
              <div class="line"></div>
              <el-form-item
                label="考研年份"
                prop="apply_year"
              >
                <el-select
                  v-model="dataForm.apply_year"
                  placeholder="请选择考研年份"
                  clearable
                >
                  <!-- <el-option
                    label="2022"
                    value="2022"
                  ></el-option>
                  <el-option
                    label="2023"
                    value="2023"
                  ></el-option> -->
                  <el-option
                    label="2024"
                    value="2024"
                  ></el-option>
                  <el-option
                    label="2025"
                    value="2025"
                  ></el-option>
                  <el-option
                    label="2026"
                    value="2026"
                  ></el-option>
                  <el-option
                    label="2027"
                    value="2027"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="学生姓名"
                prop="realname"
              >
                <el-input
                  v-model="dataForm.realname"
                  placeholder="请输入学生姓名"
                  @blur="realnameblur"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="联系方式"
                prop="phone"
              >
                <el-input
                  v-model="dataForm.phone"
                  placeholder="请输入手机号"
                  @blur="phoneblur"
                >
                  <template slot="prepend">手机号</template>
                </el-input>
              </el-form-item>
              <el-form-item label="">
                <el-input
                  v-model="dataForm.wechat"
                  placeholder="请输入微信号"
                >
                  <template slot="prepend">微信号</template>
                </el-input>
              </el-form-item>
              <el-form-item label="">
                <el-input
                  v-model="dataForm.qq"
                  placeholder="请输入QQ号"
                >
                  <template slot="prepend">Q Q 号</template>
                </el-input>
              </el-form-item>
              <el-form-item
                label="收货人"
                prop="receiver"
              >
                <el-input
                  v-model="dataForm.receiver"
                  placeholder="请输入收货人姓名"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                label="收货号码"
                prop="receiver_mobile"
              >
                <el-input
                  v-model="dataForm.receiver_mobile"
                  placeholder="请输入收货号码"
                >
                </el-input>
              </el-form-item>

              <el-form-item
                label="详细地址"
                prop="city"
              >
                <div class="form-item">
                  <el-select
                    v-model="dataForm.province_name"
                    placeholder="省"
                    @change="provinceChange"
                    clearable
                    style="margin-right:16px;"
                  >
                    <el-option
                      v-for="(item,index) in $store.state.cityList"
                      :key="index"
                      :label="item.name"
                      :value="{ value: item.code, label: item.name}"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-model="dataForm.city_name"
                    placeholder="市"
                    clearable
                    @change="cityChange"
                    style="margin-right:16px;"
                  >
                    <el-option
                      v-for="(item,index) in cityList"
                      :key="index"
                      :label="item.name"
                      :value="{ value: item.code, label: item.name}"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-model="dataForm.district_name"
                    placeholder="区/县"
                    v-if="districtList.length>1"
                    clearable
                  >
                    <el-option
                      v-for="(item,index) in districtList"
                      :key="index"
                      :label="item.name"
                      :value="{ value: item.code, label: item.name}"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item
                label=""
                prop="address"
              >
                <el-input
                  style="flex:1;"
                  v-model="dataForm.address"
                  placeholder="详细地址"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="其他备注">
                <el-input
                  type="textarea"
                  v-model="dataForm.remark"
                  placeholder="请输入其他备注"
                  style="width:100%;"
                ></el-input>
              </el-form-item>
            </div>
            <div
              class="card"
              style="margin-top:24px;"
            >
              <TableTittle
                :tittle="titleName[1]"
                class="tabletitle"
              ></TableTittle>
              <div class="line"></div>
              <el-form-item
                label="是否服务"
                class="inline-item"
                style="height:40px;"
                prop="is_service"
              >
                <el-radio-group v-model="dataForm.is_service">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <template v-if="dataForm.is_service">
                <el-form-item
                  label="服务科目"
                  prop="service_subject_ids"
                >
                  <el-select
                    v-model="dataForm.service_subject_ids"
                    placeholder="请选择服务科目"
                    clearable
                    style="width:100%;"
                    multiple
                  >
                    <el-option
                      v-for="(item) in $store.state.servicesubjectOptions"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="服务老师"
                  prop="teacher_ids"
                >
                  <el-select
                    v-model="dataForm.teacher_ids"
                    placeholder="请选择服务老师"
                    multiple
                    @change="$forceUpdate()"
                    style="width:100%;"
                    clearable
                  >
                    <el-option
                      v-for="(item,index) in $store.state.staffOptions"
                      :key="index"
                      :label="item.alias"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="服务备注"
                  style="width:100%;"
                >
                  <el-input
                    type="textarea"
                    v-model="dataForm.service_remark"
                    placeholder="请输入服务备注"
                    style="width:100%;"
                  ></el-input>
                </el-form-item>
              </template>
            </div>
          </div>
          <div
            class="main-item"
            style="padding-left:8px;"
          >
            <div class="card">
              <TableTittle
                :tittle="titleName[2]"
                class="tabletitle"
              ></TableTittle>
              <div class="line"></div>
              <el-form-item
                label="报名时间"
                prop="apply_time"
              >
                <el-date-picker
                  v-model="dataForm.apply_time"
                  type="date"
                  placeholder="请选择日期时间"
                  style="width:100%"
                  clearable
                  value-format="timestamp"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="报名班次"
                prop="product_names"
              >
                <el-select
                  filterable
                  v-model="dataForm.product_names"
                  placeholder="请选择报名班次"
                  multiple
                  value-key="id"
                  collapse-tags
                  clearable
                >
                  <el-option
                    v-for="(item) in $store.state.productOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="开通课程"
                prop="company_normal_course_ids"
              >
                <el-select
                  v-model="dataForm.company_normal_course_ids"
                  placeholder="请选择课程"
                  clearable
                  style="width:100%;"
                  multiple
                >
                  <el-option
                    v-for="(item,index) in $store.state.normalCourseList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="实收金额"
                prop="receive_amount"
              >
                <el-input
                  v-model="dataForm.receive_amount"
                  placeholder="请输入实收金额"
                >
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <el-form-item
                label="基本情况"
                style="width:100%;"
                prop="coach_need"
              >
                <el-input
                  type="textarea"
                  v-model="dataForm.coach_need"
                  placeholder="请输入基本情况"
                  style="width:100%;"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="教务管理"
                prop="watcher"
              >
                <el-select
                  v-model="dataForm.watcher"
                  placeholder="请选择服务老师"
                  multiple
                  @change="$forceUpdate()"
                  style="width:100%;"
                  clearable
                >
                  <el-option
                    v-for="(item,index) in $store.state.staffOptions"
                    :key="index"
                    :label="item.alias"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="咨询老师"
                prop="consult_teacher_id"
              >
                <el-select
                filterable 
                  v-model="dataForm.consult_teacher_id"
                  placeholder="请选择咨询老师"
                  @change="$forceUpdate()"
                  style="width:100%;"
                  clearable
                >
                  <el-option
                    v-for="(item,index) in $store.state.staffOptions"
                    :key="index"
                    :label="item.alias"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

            </div>
            <div
              class="card"
              style="margin-top:24px;"
            >
              <TableTittle
                :tittle="titleName[3]"
                class="tabletitle"
              ></TableTittle>
              <div class="line"></div>
              <el-form-item
                label="是否定校"
                class="inline-item"
                style="height:40px;"
                prop="is_choose"
              >
                <el-radio-group v-model="dataForm.is_choose">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <template v-if="dataForm.is_choose">
                <el-form-item
                  label="报考院校"
                  prop="apply_college"
                >
                  <el-select
                    v-model="dataForm.apply_college"
                    placeholder="请选择报考院校"
                    filterable
                    clearable
                    @change="collegeChange"
                  >
                    <el-option
                      v-for="(item,index) in $store.state.collegeList"
                      :key="index"
                      :label="item.name"
                      :value="{ value: item.code, label: item.name}"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="报考专业"
                  prop="apply_major"
                >
                  <el-select
                    v-model="dataForm.apply_major"
                    placeholder="请选择报考专业"
                    @change="majorChange"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item,index) in majorOptions"
                      :key="index"
                      :label="item.name"
                      :value="{ value: item.code, label: item.name}"
                    >
                      <span>{{ `（${item.code}）${item.name}` }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="报考学院"
                  prop="apply_academy"
                >
                  <el-select
                    v-model="dataForm.apply_academy"
                    placeholder="请选择报考学院"
                    clearable
                    @change="academyChange"
                  >
                    <el-option
                      v-for="(item,index) in academyOptions"
                      :key="index"
                      :label="item.academy_name"
                      :value="{ value: item.academy_code, label: item.academy_name,directions:item.directions}"
                    >
                      <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="考试方向">
                  <el-select
                    v-model="dataForm.apply_direction"
                    placeholder="请选择考试方向"
                    clearable
                    @change="$forceUpdate()"
                  >
                    <el-option
                      v-for="(item,index) in directionsOptions"
                      :key="index"
                      :label="item.direction"
                      :value="{ value: item.direction_code, label: item.direction}"
                    >
                      <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="辅导科目"
                  prop="subjects"
                >
                  <el-select
                    v-model="dataForm.subjects"
                    placeholder="请选择辅导科目"
                    :multiple-limit="4"
                    multiple
                    @change="$forceUpdate()"
                    clearable
                  >
                    <el-option
                      v-for="(item,index) in subjectsOptions"
                      :key="index"
                      :label="item.subject_name"
                      :value="{ value: item.subject_code, label: item.subject_name}"
                    >
                      <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="辅导小时"
                  prop="service_hours"
                  type="number"
                >
                  <el-input
                    v-model="dataForm.service_hours"
                    placeholder="请输入辅导小时"
                    type="number"
                  >
                    <template slot="append">小时</template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="参考书目"
                  style="width:100%;"
                >
                  <el-input
                    type="textarea"
                    v-model="dataForm.refer_book"
                    placeholder="请输入参考书目"
                    style="width:100%;"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="定校备注"
                  style="width:100%;"
                >
                  <el-input
                    type="textarea"
                    v-model="dataForm.choose_remark"
                    placeholder="请输入定校备注"
                    style="width:100%;"
                  ></el-input>
                </el-form-item>
              </template>
            </div>
          </div>
        </div>
      </el-form>
      <div class="line"></div>
      <div class="bottom-wrap">
        <el-button
          type="primary"
          @click="submit('formRules',true)"
        >保存并新建</el-button>
        <div>
          <el-button @click="handleBack()">取消</el-button>
          <el-button
            type="primary"
            @click="submit('formRules')"
          >保存</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TableTittle from "@/components/TableTittle/TableTittle.vue";
import citylist from "@/utils/area.json";
import {
  academylist,
  majorlist,
  subjectlist,
  studentBasicCreate
} from "@/request/api";

import { adminUrl } from "@/utils/helper";


export default {
  components: {
    TableTittle
  },
  data() {
    return {
      titleName: ['新建学员', '服务信息', '报名信息', '定校信息',],
      dataForm: {
        is_service: 0,
        is_choose: 0,
      },
      rules: {
        apply_year: [{ required: true, message: "请选择考研年份", trigger: "change" }],
        realname: [{ required: true, message: "请输入学生姓名", trigger: "blur" }],

        phone: [{ required: true, message: "请输入手机号", trigger: "blur" },
        { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号' }],
        apply_time: [{ required: true, message: "请选择报名时间", trigger: "change" }],
        product_names: [{ required: true, message: "请选择报名班次", trigger: "blur" }],
        receive_amount: [{ required: true, message: "请输入实收金额", trigger: "blur" },
        { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }],
        is_service: [{ required: true, message: "请选择是否服务", trigger: "change" }],
        is_choose: [{ required: true, message: "请选择是否定校", trigger: "change" }],
        coach_need: [{ required: true, message: "请输入基本情况", trigger: "blur" }],
        apply_college: [{ required: true, message: "请选择报考院校", trigger: "change" }],
        apply_major: [{ required: true, message: "请选择报考专业", trigger: "change" }],
        apply_academy: [{ required: true, message: "请选择报考学院", trigger: "change" }],
        service_subject_ids: [{ required: true, message: "请选择服务科目", trigger: "change" }],
        teacher_ids: [{ required: true, message: "请选择服务老师", trigger: "blur" }],
        subjects: [{ required: true, message: "请选择辅导科目", trigger: "blur" }],
        service_hours: [{ required: true, message: "请输入辅导小时", trigger: "blur" }],
        // address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        // city: [{
        //   required: true,
        //   validator: (rule, value, callback) => {
        //     if (!this.dataForm.province_name || !this.dataForm.city_name) {
        //       callback(new Error('请输入省市区'))
        //     } else {
        //       callback()
        //     }

        //   },
        // }],
        /*   consult_teacher_id: [{ required: true, message: "请选择咨询老师", trigger: "change" }], */
        watcher: [{ required: true, message: "请选择教务管理", trigger: "blur" }],
        product_watchers: [{ required: true, message: "请选择产品经理", trigger: "blur" }],
        last_concat_time: [{ required: true, message: "请选择最后联系时间", trigger: "change" }],
        receiver_mobile: [
          // { required: true, message: "请输入收货人手机号", trigger: "blur" },
        { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号' }],
        // receiver: [{ required: true, message: "请输入收货人姓名", trigger: "blur" }],
      },
      majorOptions: [],// 专业数组
      academyOptions: [],// 学员数组
      subjectsOptions: [], // 科目数组
      cityList: [], // 市数组
      areaList: [], // 区数组
      subjectChooseDialog: false,
      districtList: [],
      directionsOptions: [],

    };
  },
  created() {
    this.$store.dispatch('getAllColleges')
    this.$store.dispatch('getProducts')
    this.$store.dispatch('getServiceSubjectDictionary')
    this.$store.dispatch('getStaffList')
    this.$store.dispatch('fetchCityList')
    this.$store.dispatch('fetchNormalCourse')
    this.dataForm.watcher = [
      JSON.parse(localStorage.getItem('companyUser')).id
    ]
  },
  computed: {

  },
  watch: {
    /*   'dataForm.province_name': {
        handler(value) {
          this.dataForm.province = value.value
          if (this.$store.state.cityList.length) {
            this.cityList = this.$store.state.cityList.find((v) => { return v.code == value.value }).children
          }
        }
      },
      'dataForm.city_name': {
        handler(value) {
          this.dataForm.city = value.value
          if (this.cityList.length) {
            this.areaList = this.cityList.find((v) => { return v.code == value.value }).children
          }
        }
      },
   */
  },
  methods: {
    collegeChange(e) {
      this.dataForm.apply_major = {}
      this.majorOptions = []
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.subjects = []
      this.subjectsOptions = []
      this.directionsOptions = []
      this.dataForm.apply_direction = []
      if (e) {
        this.getMajor(e.value);
      }
    },
    majorChange(e) {
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.subjects = []
      this.subjectsOptions = []
      this.directionsOptions = []
      this.dataForm.apply_direction = []
      /*    if (e) {
           this.getAcedemy(this.dataForm.apply_college.value, e.value);
         } */
      if (e) {
        this.getMajorInfo(this.dataForm.apply_college.value, e.value);
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get',//可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then(response => {
        this.academyOptions = response.data.academys
        this.directionsOptions = this.academyOptions.find(v => {
          return this.dataForm.apply_academy.value == v.academy_code
        }).directions
      })

    },
    academyChange(e) {
      this.dataForm.subjects = []
      this.subjectsOptions = []
      this.directionsOptions = []
      this.dataForm.apply_direction = []
      if (e) {
        this.directionsOptions = e.directions

        this.getSubjects(this.dataForm.apply_college.value, this.dataForm.apply_major.value);
      }
    },
    provinceChange(e) {
      if (e) {
        this.cityList = this.$store.state.cityList.find((v) => { return v.code == e.value }).children
      }
    },
    cityChange(e) {
      this.districtList = this.cityList.find((v) => { return v.code == e.value }).children
      if (this.districtList.length <= 1) {
        this.dataForm.district_name = ""
      }
    },
    getMajor(college_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        major_code: major_code,
      };
      majorlist(query).then((res) => {
        this.majorOptions = res.data.list || [];
      });
    },
    getAcedemy(college_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        major_code: major_code,
      };
      academylist(query).then((res) => {
        this.academyOptions = res.data.list || [];
      });
    },
    getSubjects(college_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程"
        }
        this.subjectsOptions = res.data.list || [];
        this.subjectsOptions.push(obj)
      });
    },
    submit(rules, isBack = false) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          console.log(this.dataForm);
          let query = {
            cid: localStorage.getItem("cid"),
            realname: this.dataForm.realname,
            apply_year: this.dataForm.apply_year,
            phone: this.dataForm.phone,
            apply_college_code: this.dataForm.apply_college ? this.dataForm.apply_college.value : "",
            apply_college_name: this.dataForm.apply_college ? this.dataForm.apply_college.label : "",
            apply_major_code: this.dataForm.apply_major ? this.dataForm.apply_major.value : "",
            apply_major_name: this.dataForm.apply_major ? this.dataForm.apply_major.label : "",
            apply_academy_code: this.dataForm.apply_academy ? this.dataForm.apply_academy.value : "",
            apply_academy_name: this.dataForm.apply_academy ? this.dataForm.apply_academy.label : "",
            apply_direction_code: this.dataForm.apply_direction ? this.dataForm.apply_direction.value : "",
            apply_direction_name: this.dataForm.apply_direction ? this.dataForm.apply_direction.label : "",
            products: this.dataForm.product_names.map((v) => {
              return {
                product_id: v.id,
                product_name: v.name
              }
            }),
            subjects: this.dataForm.subjects ? this.dataForm.subjects.map((v) => {
              return {
                subject_code: v.value,
                subject_name: v.label
              }
            }) : [],
            course_status: 1,
            is_service: this.dataForm.is_service,
            is_choose: this.dataForm.is_choose,

            apply_time: this.dataForm.apply_time,
            receive_amount: this.dataForm.receive_amount,
            coach_need: this.dataForm.coach_need,
            service_subject_ids: this.dataForm.service_subject_ids ? this.dataForm.service_subject_ids.join(',') : "",
            watcher: this.dataForm.watcher ? this.dataForm.watcher.join(',') : "",
            teacher_ids: this.dataForm.teacher_ids ? this.dataForm.teacher_ids.join(',') : "",
            consult_teacher_id: this.dataForm.consult_teacher_id,
            service_hours: this.dataForm.service_hours,
            province: this.dataForm.province_name?this.dataForm.province_name.value:'',
            city:this.dataForm.city_name?this.dataForm.city_name.value:'',
            district: this.dataForm.district_name ? this.dataForm.district_name.value : '',
            area:this.dataForm.province_name? `${this.dataForm.province_name.label || ""}${this.dataForm.city_name.label || ""}${this.dataForm.district_name.label || ""}`:'',
            address: this.dataForm.address,
            last_concat_time: new Date().getTime(),
            receiver_mobile: this.dataForm.receiver_mobile,
            receiver: this.dataForm.receiver,
            remark: this.dataForm.remark,
            wechat: this.dataForm.wechat,
            qq: this.dataForm.qq,
            choose_remark: this.dataForm.choose_remark,
            refer_book: this.dataForm.refer_book,
            service_remark: this.dataForm.service_remark,
            company_normal_course_ids: this.dataForm.company_normal_course_ids.join(',') || '',
          }
          studentBasicCreate(query).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$message.success(`保存成功`);

              if (isBack) {
                this.$router.go(0)
              } else {
                this.handleBack()
              }
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleBack() {
      this.$router.go(-1);
    },
    saveSubject({ subjects }) {
      this.service_subjects = subjects;
    },
    handleSubjectChoose() {
      this.subjectChooseDialog = true;
    },
    handleSubjectRemove(item) {
      this.service_subjects = this.service_subjects.filter((v) => v.subject_code !== item.subject_code);
    },
    realnameblur(e) {
      if (this.dataForm.realname) {
        this.$set(this.dataForm, 'receiver', this.dataForm.realname)
      }
    },
    phoneblur(e) {
      if (this.dataForm.phone) {
        this.$set(this.dataForm, 'receiver_mobile', this.dataForm.phone)
      }
    },
  }
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  .main-item {
    flex: 1;

    .card {
      background: #fff;
      padding: 16px 24px;
      border-radius: 4px;
    }
  }
}
.line {
  margin-bottom: 16px;
}
.tabletitle {
  margin-bottom: 16px;
}
.bottom-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
