<template>
  <div class="content">
    <el-dialog
      top="5vh"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="1280px"
      title="账单详情"
      @close="emitClose"
    >
      <div class="header">
        <TableTitle :tittle="`${content.order_name}`" style="flex-shrink: 0"/>
        <div class="info-wrap">
          <el-descriptions
            :column="2"
            :contentStyle="contentStyle"

          >
          <!-- style="width: 500px; flex-shrink: 0" -->
            <el-descriptions-item label="机构名称" v-if="content.company">{{
              content.company.name
            }}</el-descriptions-item>
            <el-descriptions-item label="账单名称">
              {{ content.order_name }}
            </el-descriptions-item>
            <el-descriptions-item label="账单id">{{
              content.order_no
            }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{
              $moment(content.create_time).format("YYYY-MM-DD HH:mm")
            }}</el-descriptions-item>
          </el-descriptions>
          <div class="info">
            <div class="item">
              <div class="label">状态</div>
              <div class="value">
                <div
                  class="status"
                  style="color: #e6a23c"
                  v-if="content.manage_audit_status == 0"
                >
                  待审核
                </div>
                <div
                  class="status"
                  style="color: #67c23a"
                  v-if="content.manage_audit_status == 1"
                >
                  已通过
                </div>
                <div
                  class="status"
                  style="color: #f56c6c"
                  v-if="content.manage_audit_status == 2"
                >
                  已拒绝
                </div>
              </div>
            </div>
            <div class="item">
              <div class="label">*本次账单金额</div>
              <div class="value">
                <div class="count">￥{{ content.total_amount }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="info" style="margin-left: 80px" v-if="!examineFlag">
            <el-button
              type="primary"
              size="small"
              style="margin-left: 16px"
              @click="handleCheckAccess(content.id)"
              v-if="content.manage_audit_status == 0"
              >审核通过</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="handleCheckRefuse(content.id)"
              v-if="content.manage_audit_status == 0"
              >审核拒绝</el-button
            >
          </div> -->
        </div>
      </div>
      <div class="wrap"></div>
      <div class="table" style="padding: 16px">
        <div class="card-header">
          <TableTitle :tittle="'课酬课时核对'" />

          <el-form
            label-width="70px"
            size="small"
            style="margin-bottom: 0px; display: flex"
            label-position="right"
          >
            <el-form-item label-width="32px">
              <el-button
                v-if="studentHourType == 2"
                style="margin-right: 20px"
                icon="el-icon-download"
                @click="handleExport"
                size="small"
                >导出</el-button
              >
              <el-radio-group
                v-model="studentHourType"
                @change="radioGgroupChange"
              >
                <el-radio-button label="2">学生</el-radio-button>
                <el-radio-button label="1">课时</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="studentHourType == 1">
          <el-table
            v-if="!type"
            :data="tableData"
            key="table1"
            :header-cell-style="{
              textAlign: 'center',
              color: '#909399',
              fontFamily: 'PingFang SC',
              fontSize: '14px',
              fontWeight: '400',
              backgroundColor: '#F2F6FC',
            }"
            :cell-style="{ fontSize: '13px' }"
            border
            height="300px"
          >
            <el-table-column
              prop="id"
              label="ID"
              align="center"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column width="160" align="center" show-overflow-tooltip>
              <template slot="header">
                <el-popover ref="popover">
                  <span class="el-dropdown-link" slot="reference">
                    {{ unitConversion == 1 ? "计划上课时间" : "实际上课时间"
                    }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <div class="popover-item" @click="handleCommand(1)">
                    计划上课时间
                  </div>
                  <div class="popover-item" @click="handleCommand(2)">
                    实际上课时间
                  </div>
                </el-popover>
              </template>
              <template slot-scope="scope">
                <div v-if="unitConversion == 1">
                  {{
                    scope.row.plan_start_time
                      ? $moment(scope.row.plan_start_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : ""
                  }}
                </div>
                <div v-if="unitConversion == 2">
                  <span v-if="scope.row.final_real_lesson_start_time">{{
                    $moment(scope.row.final_real_lesson_start_time).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  }}</span>
                  <el-tag type="info" size="mini" v-else>暂未上课</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="plan_course_theme"
              label="课时主题"
              width="200"
              align="left"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="plan_course_content"
              label="课程内容"
              width="300"
              align="left"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              width="120"
              show-overflow-tooltip
              align="center"
              label="课时时长"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.final_real_lesson_hour }}小时</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="机构签约费"
              width="150"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.final_real_company_hour_amount ||
                    scope.row.ref_hour_income_company_amount
                  "
                >
                  {{
                    scope.row.final_real_company_hour_amount
                      ? scope.row.final_real_company_hour_amount
                      : scope.row.ref_hour_income_company_amount
                  }}元/小时
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column
              label="机构附加费"
              align="center"
              width="150"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{
                  scope.row.final_real_company_service_amount
                    ? scope.row.final_real_company_service_amount + "元"
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="课时状态"
              width="80"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.lesson_status == 0"
                  size="mini"
                  type="warning"
                  >待确认</el-tag
                >
                <el-tag v-if="scope.row.lesson_status == 1" size="mini"
                  >待回访</el-tag
                >
                <el-tag
                  v-if="scope.row.lesson_status == 2"
                  size="mini"
                  type="danger"
                  >已拒绝</el-tag
                >
                <el-tag
                  v-if="scope.row.lesson_status == 3"
                  size="mini"
                  type="success"
                  >已完成</el-tag
                >
                <el-tag
                  v-if="scope.row.lesson_status == 5"
                  size="mini"
                  type="success"
                  >已完结</el-tag
                >
                <el-tag v-if="scope.row.lesson_status == 6" size="mini"
                  >已暂停</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              label="学员评分"
              width="100"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{
                  scope.row.student_apprise_teacher_average_score
                    ? `${scope.row.student_apprise_teacher_average_score}分`
                    : "暂无"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="相关学员"
              width="220"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ formateMember(scope.row.student_realnames) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="grade_name"
              label="所在班级"
              align="center"
              width="260"
              show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column
              label="课程阶段"
              width="100"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ formateCourse_stage(scope.row.plan_course_stage) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="plan_course_target"
              label="课程目标"
              width="200"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              label="课酬状态"
              width="80"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div v-if="scope.row.senior_course_order_status">
                  <el-tag
                    size="small"
                    v-if="scope.row.senior_course_order_status == 0"
                    type="warning"
                    >待审核</el-tag
                  >
                  <el-tag
                    size="small"
                    v-else-if="scope.row.senior_course_order_status == 1"
                    type="success"
                    >已通过</el-tag
                  >
                  <el-tag
                    size="small"
                    v-else-if="scope.row.senior_course_order_status == 2"
                    type="danger"
                    >已拒绝</el-tag
                  >
                  <el-tag
                    size="small"
                    v-else-if="scope.row.senior_course_order_status == 3"
                    type="success"
                    >已提现</el-tag
                  >
                </div>
                <div v-else>暂无</div>
              </template>
            </el-table-column>
            <el-table-column
              label="授课老师"
              width="120"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.teacher_realname }}
              </template>
            </el-table-column>
            <el-table-column label="学管师" align="center" width="130">
              <template slot-scope="scope">
                {{
                  scope.row.learn_manager
                    ? scope.row.learn_manager.nickname
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column label="创建日期" align="center" width="150">
              <template slot-scope="scope">
                {{
                  scope.row.create_time
                    ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="修改日期" width="150">
              <template slot-scope="scope">
                {{
                  scope.row.update_time
                    ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                    : ""
                }}
              </template>
            </el-table-column>
          </el-table>

          <el-table
            v-else
            :data="tableData"
            :header-cell-style="{
              textAlign: 'center',
              color: '#909399',
              fontFamily: 'PingFang SC',
              fontSize: '14px',
              fontWeight: '400',
              backgroundColor: '#F2F6FC',
            }"
            :cell-style="{ fontSize: '13px' }"
            border
            height="300px"
            key="table2"
          >
            <!-- <el-table-column
              type="selection"
              width="50"
              fixed="left"
              align="center"
            >
            </el-table-column> -->
            <el-table-column
              prop="id"
              label="ID"
              align="center"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="dispatch_log_manage_creator.nickname"
              label="登记人"
              align="center"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column align="center" label="登记日期" width="120">
              <template slot-scope="scope">
                {{
                  scope.row.create_time
                    ? $moment(scope.row.create_time).format("YYYY-MM-DD")
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="dispatch_log_course_theme"
              label="课时主题"
              width="200"
              align="left"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              width="120"
              show-overflow-tooltip
              align="center"
              label="课时时长"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.current_lesson_hour }}小时</div>
              </template>
            </el-table-column>
            <el-table-column
              label="课时状态"
              width="120"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.lesson_status == 0"
                  size="mini"
                  type="warning"
                  >待确认</el-tag
                >
                <el-tag v-if="scope.row.lesson_status == 1" size="mini"
                  >待回访</el-tag
                >
                <el-tag
                  v-if="scope.row.lesson_status == 2"
                  size="mini"
                  type="danger"
                  >已拒绝</el-tag
                >
                <el-tag
                  v-if="scope.row.lesson_status == 3"
                  size="mini"
                  type="success"
                  >已完成</el-tag
                >
                <el-tag
                  v-if="scope.row.lesson_status == 5"
                  size="mini"
                  type="success"
                  >已完结</el-tag
                >
                <el-tag v-if="scope.row.lesson_status == 6" size="mini"
                  >已暂停</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column
              prop="company_name"
              label="机构简称"
              align="center"
              width="120"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.company_name || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              label="学生姓名"
              width="220"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.dispatch_log_student_name }}
              </template>
            </el-table-column>
            <el-table-column
              label="授课老师"
              width="130"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.teacher_realname }}
              </template>
            </el-table-column>
            <el-table-column
              label="课时费"
              width="150"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.final_real_company_hour_amount || 0 }}元/小时
              </template>
            </el-table-column>
            <el-table-column
              label="附加费"
              width="150"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.final_real_company_service_amount || 0 }}元
              </template>
            </el-table-column>

            <el-table-column
              label="课时备注"
              width="150"
              align="center"
              prop="remark"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              label="课酬状态"
              width="80"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div v-if="scope.row.senior_course_order_status">
                  <el-tag
                    size="small"
                    v-if="scope.row.senior_course_order_status == 0"
                    type="warning"
                    >待审核</el-tag
                  >
                  <el-tag
                    size="small"
                    v-else-if="scope.row.senior_course_order_status == 1"
                    type="success"
                    >已通过</el-tag
                  >
                  <el-tag
                    size="small"
                    v-else-if="scope.row.senior_course_order_status == 2"
                    type="danger"
                    >已拒绝</el-tag
                  >
                  <el-tag
                    size="small"
                    v-else-if="scope.row.senior_course_order_status == 3"
                    type="success"
                    >已提现</el-tag
                  >
                </div>
                <div v-else>暂无</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="senior_course_order_name"
              label="课酬单名称"
              align="center"
              width="200"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{
                  scope.row.senior_course_order_name
                    ? scope.row.senior_course_order_name
                    : "暂无"
                }}
              </template>
            </el-table-column>

            <el-table-column
              label="账单状态"
              width="120"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-tag
                  size="small"
                  v-if="scope.row.company_service_order_status == 0"
                  type="warning"
                  >待确定</el-tag
                >
                <el-tag
                  size="small"
                  v-else-if="scope.row.company_service_order_status == 1"
                  >待支付</el-tag
                >
                <el-tag
                  size="small"
                  v-else-if="scope.row.company_service_order_status == 2"
                  type="success"
                  >已支付</el-tag
                >
                <el-tag
                  size="small"
                  v-else-if="scope.row.company_service_order_status == 3"
                  type="success"
                  >已完成</el-tag
                >
                <el-tag
                  size="small"
                  v-else-if="scope.row.company_service_order_status == 4"
                  type="info"
                  >已取消</el-tag
                >
                <div v-else>暂无</div>
              </template>
            </el-table-column>

            <el-table-column
              prop="senior_course_order_name"
              label="机构账单名称"
              align="center"
              width="200"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{
                  scope.row.company_service_order_name
                    ? scope.row.company_service_order_name
                    : "暂无"
                }}
              </template>
            </el-table-column>
            <el-table-column label="匹配师" align="center" width="130">
              <template slot-scope="scope">
                {{
                  scope.row.allocation_manage_nickname
                    ? scope.row.allocation_manage_nickname
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="修改日期" width="150">
              <template slot-scope="scope">
                {{
                  scope.row.update_time
                    ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                    : ""
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div v-else>
          <el-table
            :data="tableData"
            :header-cell-style="{
              textAlign: 'center',
              color: '#909399',
              fontFamily: 'PingFang SC',
              fontSize: '14px',
              fontWeight: '400',
              backgroundColor: '#F2F6FC',
            }"
            :cell-style="{ fontSize: '13px' }"
            border
            height="300px"
            key="table3"
          >
            <el-table-column
              prop="data_index"
              label="序号"
              align="center"
              width="60"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="allocation_success_time"
              label="匹配成功日期"
              align="center"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="college_type"
              label="学校类型"
              align="center"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="data_id"
              label="ID"
              align="center"
              width="60"
              show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column
              prop="apply_college_info"
              label="学校"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column
              prop="apply_academy_info"
              label="院系"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column
              prop="apply_major_info"
              label="专业"
              align="center"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column
              prop="apply_subject_info"
              label="专业课"
              align="center"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column
              prop="student_realnames"
              label="学生姓名"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column
              prop="teacher_realnames"
              label="研究生姓名"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>

            <el-table-column label="总小时" align="center" width="100">
              <template slot-scope="scope">
                {{ scope.row.total_sum_lesson_hour || 0 }}小时
              </template>
            </el-table-column>

            <el-table-column label="签约课时费" align="center" width="120">
              <template slot-scope="scope">
                <div>{{ scope.row.company_hour_amounts }}元/小时</div>
              </template>
            </el-table-column>

            <el-table-column
              prop="total_pre_exist_lesson_hour"
              label="已上小时"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                {{ scope.row.total_pre_exist_lesson_hour || 0 }}小时
              </template>
            </el-table-column>

            <el-table-column
              prop="total_lesson_hour"
              label="本次结算小时"
              align="center"
              width="120"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.total_lesson_hour || 0 }}小时
              </template>
            </el-table-column>

            <el-table-column
              prop="total_company_service_amount"
              label="学管师服务费"
              align="center"
              width="120"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.total_company_service_amount || 0 }}元
              </template>
            </el-table-column>

            <el-table-column
              prop="total_final_proxy_amount"
              label="研盒代理费"
              align="center"
              width="120"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.total_final_proxy_amount || 0 }}元
              </template>
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              align="center"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </div>

        <div class="block" style="margin-top: 16px">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>

    <!-- <el-dialog title="驳回" :visible.sync="dialogVisible" width="30%">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入驳回理由"
        v-model="refuse_reason"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkRefuseSubmit">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>


import { manageUrl } from "@/utils/helper";
import TableTitle from "../../../components/TableTittle/TableTittle.vue";
export default {
  components: {
    TableTitle,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Boolean,
      default: false,
    },
    examineFlag: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      order_id: "",
      form: {},
      refuseForm: {},
      dialogVisible: false,
      refuse_reason: "",
      codedisabled: false,
      refuseVisible: false,
      yzcodetext: "获取验证码",
      codeTime: 0,
      phone: "",
      labelStyle: {
        width: "62px",
        textAlign: "right",
        fontSize: "13px",
      },
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      contentStyle: {
        color: "#606266",
      },
      unitConversion: 1,
      studentHourType: 2,
    };
  },
  watch: {
    isVisible(val) {
      // console.log("99",this.content)
      this.dialogFormVisible = val;
      if (val) {
        if (this.type) {
          this.query.force_delete_types = 2;
        } else {
          if (this.query.force_delete_types) {
            delete this.query.force_delete_types;
          }
        }
        this.studentHourType = 2;
        this.tableData = [];
        this.studentFetchList();
      }
    },
  },
  mounted() {
    // 获取全部院校列表
  },
  methods: {
    handleExport() {
      this.$confirm("是否导出数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let str = manageUrl();
        this.$axios({
          url: `${str}/api/v1/company/service/order/getOrderStudentExportXlsx`,
          method: "post", //可以省略 不写默认是get
          data: {
            company_service_order_id: this.content.id,
          },
        }).then((res) => {
          if (res.code == 0) {
            this.$message.success("导出成功");
            var str = res.data.url.replace(
              /http:/,
              window.location.protocol
            );
            this.$store.commit("downLoad", str);
          }
        });
      });
    },

    radioGgroupChange(e) {
      if (e == 1) {
        this.tableData = [];
        this.fetchList();
      } else {
        this.tableData = [];
        this.studentFetchList();
      }
      // console.log('e',e,this.studentHourType)
    },
    studentFetchList() {
      let str = manageUrl();
      this.$axios({
        url: `${str}/api/v1/company/service/order/getOrderStudentDataView`,
        method: "post", //可以省略 不写默认是get
        data: {
          company_service_order_id: this.content.id,
        },
      }).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.arr_student_data_view;
        }
      });
      //   getOrderStudentDataView({
      //     company_service_order_id: this.content.id,
      //   }).then((res) => {
      //     if (res.data.code == 0) {
      //       this.tableData = res.data.data.arr_student_data_view;
      //     }
      //   });
    },
    formateMember(val) {
      if (val) {
        let val_arr = val.split(",");
        if (val_arr.length > 3) {
          return val.split(",").slice(0, 3).join("，") + "等";
        } else {
          return val.split(",").slice(0, 3).join("，");
        }
      } else {
        return "无";
      }
    },
    // handleCheckAccess(id) {
    //   this.$confirm("是否通过审核?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       checkAccess({
    //         order_id: id,
    //       }).then((res) => {
    //         if (res.data.code == 0) {
    //           this.$emit("submit");
    //           this.emitClose();
    //           this.dialogVisible = false;
    //           this.$message({
    //             type: "success",
    //             message: "审核成功!",
    //           });
    //         }
    //       });


    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消",
    //       });
    //     });
    // },
    // handleCheckRefuse(id) {
    //   this.order_id = id;
    //   this.dialogVisible = true;
    // },
    // checkRefuseSubmit() {
    //   checkRefuse({
    //     order_id: this.order_id,
    //     refuse_reason: this.refuse_reason,
    //   }).then((res) => {
    //     if (res.data.code == 0) {
    //       this.$emit("submit");
    //       this.emitClose();
    //       this.dialogVisible = false;
    //       this.$message({
    //         type: "success",
    //         message: "驳回成功!",
    //       });
    //       this.dialogVisible = false;
    //     }
    //   });
    // },

    fetchList() {
      const params = Object.assign({}, this.query, {
        company_service_order_ids: this.content.id,
        is_statistic_lesson_company: 1,
      });

      let str = manageUrl();
      this.$axios({
        url: `${str}/api/v1/lesson/lessonLog/list`,
        method: "get", //可以省略 不写默认是get
        params: params,
      }).then((response) => {
        // console.log(response)
        let res = response.data;
        this.tableData = res.list || [];
        this.pageTotal = res.count;
      });
      //   lessonLoglist(params).then((response) => {
      //     let res = response.data;
      //     this.tableData = res.data.list || [];
      //     this.pageTotal = res.data.count;
      //   });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.fetchList();
    },
    emitClose() {
      this.$emit("close");
    },

    formateMember(val) {
      if (val) {
        let val_arr = val.split(",");
        if (val_arr.length > 3) {
          return val.split(",").slice(0, 3).join("，") + "等";
        } else {
          return val.split(",").slice(0, 3).join("，");
        }
      } else {
        return "无";
      }
    },
    formateCourse_stage(val) {
      let stages = {
        0: "基础",
        1: "强化",
        2: "冲刺",
      };
      return stages[val];
    },
    handleCommand(e) {
      this.unitConversion = e;
      this.$refs["popover"].doClose();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .table-title[data-v-c59d6aee]{
    width: 300px;
}
/deep/.el-descriptions-item__label:not(.is-bordered-label){
    flex-shrink: 0 !important;
}
/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell{
    padding-right: 10px !important;
}


.input-with-select {
  margin-bottom: 16px;
}
.button-wrap {
  display: flex;
}
.header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .info-wrap {
    display: flex;
    padding-left: 6px;
    padding-right: 16px;
    .info {
      display: flex;
      align-items: flex-start;
      .item {
        min-width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .label {
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #606266;
          margin-bottom: 4px;
        }
        .value {
          .status {
            font-size: 20px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #e6a543;
          }
          .count {
            font-size: 22px;
            font-family: DIN Condensed-Bold, DIN Condensed;
            font-weight: bold;
            color: #303133;
          }
        }
      }
    }
  }
}
.wrap {
  height: 16px;
  background: #f2f6fc;
  opacity: 1;
}
// /deep/.el-dialog__body {
//   padding: 0px;
//   padding-bottom: 16px;
// }
/deep/.el-dialog__header {
  background-color: #ebeef5;
}
/deep/.el-dialog__title {
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #303133;
}
</style>