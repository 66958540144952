<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料</el-breadcrumb-item>
        <el-breadcrumb-item>发货清单</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">

        <el-button
          icon="el-icon-download"
          @click="handleExport"
          size="small"
        >导出</el-button>
        <el-button
          type="primary"
          size="small"
          @click="createDialog=true"
        >新建</el-button>
      </div>

    </div>
    <el-card style="margin-top: 20px;padding-top:20px;">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="产品名称">
          <el-input
            v-model.trim="query.product_names_keyword"
            placeholder="输入产品名称"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="学员信息">
          <el-input
            v-model.trim="query.user_keyword"
            placeholder="手机号/姓名"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="快递单号">
          <el-input
            v-model.trim="query.express_no_keyword"
            placeholder="输入快递单号"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="发货人">
          <el-select
            v-model="query.express_creator"
            placeholder="请选择"
            @change="getList(query,true)"
            clearable
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建人">
          <el-select
            v-model="query.creator"
            placeholder="请选择"
            @change="getList(query,true)"
            clearable
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预发日期">
          <el-select
            v-model="query.start_plan_express_time"
            placeholder="请选择"
            @change="plan_express_timeChange"
            clearable
            @clear="plan_express_timeClear"
          >
            <el-option
              label="立即发出"
              :value="0"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="发货主题">
          <el-input
            v-model.trim="query.theme_keyword "
            placeholder="输入发货主题"
            @input="getList(query,true)"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="物流状态">
          <el-select
            v-model="query.delivery_status"
            placeholder="请选择"
            @change="getList(query,true)"
            clearable
            multiple
          >
            <el-option
              v-for="(item,index) in filter.delivery_status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
          style="margin-bottom:0px;"
          label-position="top"
        >
          <el-form-item style="">
            <el-radio-group
              v-model="query.express_status"
              @change="getList(query,true)"
              size="small"
            >
              <el-radio-button
                v-for="(item, index) in filter.express_status"
                :key="index"
                :label="item.value"
              >
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="50"
        > </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="student_realname"
          label="学员姓名"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.student_realname?scope.row.student_realname:scope.row.receiver_name}}
          </template>
        </el-table-column>
        <el-table-column
          prop="theme"
          label="发货主题"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          label="发货状态"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              type="warning"
              size="mini"
              v-if="scope.row.express_status==0"
              @click="changeExpress_status('确定要将待发货改为已取消吗',2,scope.row)"
            >待发货</el-tag>
            <el-tag
              size="mini"
              v-if="scope.row.express_status==1"
              @click="changeExpress_status('确定要将已发货改为已退回吗',4,scope.row)"
            >已发货</el-tag>
            <el-tag
              type="info"
              size="mini"
              v-if="scope.row.express_status==2"
            >已取消</el-tag>
            <el-tag
              type="success"
              size="mini"
              v-if="scope.row.express_status==3"
              @click="changeExpress_status('确定要将已送达改为已退回吗',4,scope.row)"
            >已送达</el-tag>
            <el-tag
              type="info"
              size="mini"
              v-if="scope.row.express_status==4"
            >已退回</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="发货次数"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.student_express_count_order?'第'+scope.row.student_express_count_order+"次":"暂无"}}
          </template>
        </el-table-column>
        <el-table-column
          label="收件信息"
          width="350"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="left-align">
              {{scope.row.receiver_name||"--"}}/{{scope.row.receiver_phone||"--"}}/{{scope.row.receiver_address||"--"}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="预发时间"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.plan_express_time==0">立即发出</span>
            <span v-else> {{$moment(scope.row.plan_express_time).format("YYYY-MM-DD")}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="express_name"
          label="快递名称"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="express_no"
          label="快递单号"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="物流状态"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{formateDelivery_status(scope.row.delivery_status)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="发货截图"
          width="100"
          show-overflow-tooltip
        >
          <template
            slot-scope="scope"
            v-if="scope.row.express_pictures"
          >
            <img
              src="@/assets/images/128@2x.png"
              alt=""
              srcset=""
              style="width:20px;height:20px;"
              @click="openViewer(scope.row.express_pictures)"
            >
          </template>
        </el-table-column>
        <el-table-column
          label="创建人"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.creator.alias}}
          </template>
        </el-table-column>
        <el-table-column
          label="发货人"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.express_creator?scope.row.express_creator.alias:""}}

          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          width="160"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")}}
          </template>
        </el-table-column>
        <el-table-column
          label="发货时间"
          width="160"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.express_time?$moment(scope.row.express_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="180"
          v-if="isfixed"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToDetail(scope.row.id)"
            >详情</el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="handleToEdit(scope.row.id)"
            >编辑</el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="deliver(scope.row.id)"
              v-if="scope.row.express_status==0"
            >发货</el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="logistics(scope.row)"
              v-if="[3,1].includes(scope.row.express_status)"
            >查看物流</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label=""
          width="40"
          align="center"
          fixed="right"
          v-else
        >
          <template slot-scope="scope">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                ...
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="a"
                  style="margin-bottom:6px;"
                >
                  <span
                    @click="handleToDetail(scope.row.id)"
                    style="font-size:16px;color: #409EFF;"
                  >
                    详情
                  </span>
                </el-dropdown-item>
                <el-dropdown-item
                  command="a"
                  style="margin-bottom:6px;"
                  v-if="scope.row.pay_status === 0"
                ><span
                    @click="handleToEdit(scope.row.id)"
                    style="font-size:16px;color: #409EFF;"
                  >
                    编辑
                  </span></el-dropdown-item>
                <el-dropdown-item
                  command="a"
                  style="margin-bottom:6px;"
                  v-if="scope.row.express_status==0"
                ><span
                    @click="deliver(scope.row.id)"
                    style="font-size:16px;color: #409EFF;"
                  >
                    发货
                  </span></el-dropdown-item>
                <el-dropdown-item
                  command="a"
                  style="margin-bottom:6px;"
                  v-if="[3,1].includes(scope.row.express_status)"
                ><span
                    @click="logistics(scope.row)"
                    style="font-size:16px;color: #409EFF;"
                  >
                    查看物流
                  </span></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button
          size="small"
          type="danger"
          @click="handleToDeleteAll()"
        >批量删除</el-button>
        <div class="block">
          <el-pagination
            v-if="isfixed"
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
          <el-pagination
            v-else
            small
            layout="prev, pager, next"
            :total="Number(pageTotal)"
            :current-page="Number(query.pageIndex)"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <AddDeliverData
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="getList(query)"
      :single="single"
    />
    <DeliverDetail
      :isVisible="detailDialog"
      @close="detailDialog=false"
      :id="detailId"
    />
    <Send
      :isVisible="sendDialog"
      @close="sendDialog=false"
      :id="detailId"
      @submit="getList(query)"
    />
    <Logistics
      :isVisible="logisticsDialog"
      @close="logisticsDialog=false"
      :id="detailId"
      :content="rowContent"
      @submit="getList(query)"
    />
    <EditDeliverData
      :isVisible="editDialog"
      @close="editDialog=false"
      :id="detailId"
      @submit="getList(query)"
    />
    <el-image-viewer
      v-if="showViewer"
      :url-list="tableImg"
      :on-close="closeViewer"
    />
  </div>

</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddDeliverData from "@/components/DialogComponents/DeliverList/AddDeliverData.vue"
import EditDeliverData from "@/components/DialogComponents/DeliverList/EditDeliverData.vue"
import DeliverDetail from "@/components/DialogComponents/DeliverList/DeliverDetail.vue"
import Send from "@/components/DialogComponents/DeliverList/Send.vue"
import Logistics from "@/components/DialogComponents/Logistics.vue"

// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

import {
  shipliplist,
  shipsliplistDelete,
  shipslipEdit,
  shipliplistExport
} from "../../../request/api";
export default {
  components: {
    TableTittle,
    AddDeliverData,
    ElImageViewer,
    DeliverDetail,
    Send,
    Logistics,
    EditDeliverData
  },
  data() {
    return {
      TableName: "清单列表",
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        express_status: 0
      },
      pageTotal: 0,
      tableData: [],
      createDialog: false,
      detailDialog: false,
      sendDialog: false,
      logisticsDialog: false,
      editDialog: false,
      detailId: null,
      tableImg: [],
      showViewer: false,
      rowContent: {},
      chooseList: [],
      filter: {
        express_status: [
          { label: "全部", value: "" },
          { label: "待发货", value: 0 },
          { label: "已发货", value: 1 },
          { label: "已送达", value: 3 },
        ],
        delivery_status: [
          { label: "在途", value: "0" },
          { label: "揽收", value: "1" },
          { label: "疑难", value: "2" },
          { label: "签收", value: "3" },
          { label: "退签", value: "4" },
          { label: "派件", value: "5" },
          { label: "退回", value: "6" },
          { label: "转投", value: "7" },
          { label: "清关", value: "8" },
          { label: "拒签", value: "14" },
        ]
      },
      createDatetime: "",
      single: false,
      isfixed: document.body.clientWidth < 820 ? false : true,
    };
  },
  filters: {

  },
  watch: {
    // 时间数据监听
    createDatetime(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
        this.getList(this.query, true)
      }
    },
  },
  mounted() {
    if (this.$route.query.student_id) {
      this.query.student_id = this.$route.query.student_id
      this.single = true
    }
    this.$store.dispatch('getStaffList')
    this.$store.dispatch('getExpressList')
    this.getList(this.query);
  },
  methods: {

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    getList(query, isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query)
      if (params.delivery_status) {
        params.delivery_status = params.delivery_status.filter((v) => v).join(",");
      }
      shipliplist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.tableImg = url.split(',');
      this.showViewer = true;
    },
    handleToDetail(id) {
      this.detailId = id
      this.detailDialog = true
    },
    deliver(id) {
      this.detailId = id
      this.sendDialog = true
    },
    logistics(row) {
      this.rowContent = Object.assign({}, row)
      this.detailId = row.id
      this.logisticsDialog = true
    },
    handleToEdit(id) {
      this.detailId = id
      this.editDialog = true
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm("确定要删除这些信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map(v => { return v.id })
        shipsliplistDelete({ ids }).then((res) => {
          this.getList(this.query);
        });
      })
    },
    changeExpress_status(text, status, row) {
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let query = {
          id: row.id,
          express_status: status,
          cid: localStorage.getItem("cid"),
        }
        shipslipEdit(query).then((res) => {
          this.getList(this.query);
        });
      })
    },
    handleExport() {
      this.$confirm("是否导出数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        shipliplistExport(this.query).then((res) => {
          if (res.code == 0) {
            this.$message.success('导出成功')
            //创建a标签用于跳转至下载链接
            var str = res.data.url.replace(/http:/, window.location.protocol)
            this.$store.commit("downLoad", str);
          }
        });
      })
    },
    formateDelivery_status(val) {
      if (val == null) {
        return '待发'
      }
      const delivery_status = {
        0: "在途",
        1: "揽收",
        2: "疑难",
        3: "签收",
        4: "退签",
        5: "派件",
        6: "退回",
        7: "转投",
        8: "清关",
        14: "拒签",
      };
      return delivery_status[val] || "";
    },
    plan_express_timeChange(e) {
      if (e == 0 && e !== "") {
        this.query.end_plan_express_time = this.$moment().endOf('day').format('x')
        this.getList(this.query, true)
      }
    },
    plan_express_timeClear() {
      this.query.start_plan_express_time = ""
      this.query.end_plan_express_time = ""
      this.getList(this.query, true)
    }
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .el-button {
    height: 35px;
    line-height: 10px;
  }
}
.block {
  margin-bottom: 14px;
  float: right;
}
</style>