<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>设置</el-breadcrumb-item>
        <el-breadcrumb-item>关于学员</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 24px">
      <TittleText :tittle="titleArr[0]"></TittleText>
      <el-form
        ref="form"
        :inline="true"
        label-position="right"
      >
        <el-form-item
          :label="item.name"
          v-for="item in switchArr"
          :key="item.value"
          :label-width="item.labelWidth"
        >
          <el-switch
            v-model="item.isOpen"
            @change="switchChange"
          ></el-switch>
        </el-form-item>
      </el-form>
      <span class="tips-class">开启冲突管理，系统将进行排查，将不可重复添加相同联系方式的学员。</span>
    </el-card>
    <el-card style="margin-top: 24px">
      <TittleText :tittle="titleArr[1]"></TittleText>
      <TagContect></TagContect>
      <span class="tips-class">勾选相应的联系方式，该联系方式则会被设为必填。</span>
    </el-card>
    <el-card style="margin-top: 24px">
      <TittleText :tittle="titleArr[2]"></TittleText>
      <TagStatus></TagStatus>
    </el-card>
    <el-card style="margin-top: 24px">
      <TittleText :tittle="titleArr[3]"></TittleText>
      <TagSource></TagSource>
    </el-card>
    <el-card style="margin-top: 24px">
      <TittleText :tittle="titleArr[4]"></TittleText>
      <TagLabel></TagLabel>
    </el-card>
    <el-card style="margin-top: 24px">
      <TittleText :tittle="titleArr[5]"></TittleText>
      <TagServiceSubject></TagServiceSubject>
    </el-card>
    <el-card style="margin-top: 24px">
      <TittleText :tittle="titleArr[6]"></TittleText>
      <TagServiceType></TagServiceType>
    </el-card>
  </div>
</template>

<script>
import TittleText from "../../../components/TableTittle/TableTittle.vue";
import TagContect from "../../../components/AddTag/TagContect/TagContect.vue";
import TagStatus from "../../../components/AddTag/TagStatus/TagStatus.vue";
import TagSource from "../../../components/AddTag/TagSource/TagSource.vue";
import TagLabel from "../../../components/AddTag/TagLabel/TagLabel.vue";
import TagServiceSubject from "../../../components/AddTag/TagServiceSubject/TagServiceSubject.vue";
import TagServiceType from "../../../components/AddTag/TagServiceType/TagServiceType.vue";

import { editcompanysetting } from "../../../request/api";
export default {
  components: {
    TittleText,
    TagContect,
    TagStatus,
    TagSource,
    TagLabel,
    TagServiceSubject,
    TagServiceType
  },
  data() {
    return {
      titleArr: ["冲突管理", "联系方式", "学员状态", "学员来源", "学员标签", "服务科目", "服务类型"],
      switchArr: [
        {
          name: "手机号唯一",
          isOpen: false,
          labelWidth: "100px",
          value: "phone",
        },
        {
          name: "微信号唯一",
          isOpen: false,
          labelWidth: "120px",
          value: "wechat",
        },
        {
          name: "QQ号唯一",
          isOpen: false,
          labelWidth: "120px",
          value: "qq",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("getCompanysetting");
  },
  mounted() {
    setTimeout(() => {
      this.formateData();
    }, 300);
  },
  methods: {
    formateData() {
      if (this.$store.state.settingInfo.student_distinct_types) {
        this.switchArr.forEach((item) => {
          this.$store.state.settingInfo.student_distinct_types
            .split(",")
            .forEach((litem) => {
              if (litem == item.value) {
                item.isOpen = true;
              }
            });
        });
      } else {
        /*     this.formateData(); */
      }
    },
    switchChange(e) {
      let arr = [];
      this.switchArr.forEach((item) => {
        if (item.isOpen) {
          arr.push(item.value);
        }
      });
      let query = {
        student_distinct_types: arr.join(","),
      };

      editcompanysetting(query).then((res) => {
        this.$store.dispatch("getCompanysetting");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 35px;
}
.tips-class {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 16px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #909399;
  opacity: 1;
}
</style>