<template>
  <div class="box">
    <!-- 题目 -->
    <div class="topic">
      <div class="topicItem"
           v-for="(item,index) in tabList"
           :key="index">
        <div class="topicTitle"><span>{{item.number}}、</span> <span>{{item.answer_type_text}}</span><span v-if="item.answer_type_text == '材料题'"></span></div>
        <div v-for="(items,indexs) in item.children"
             :key="indexs">
          <div v-if="item.answer_type_text == '判断题'">
            <div class="descript">
              <span v-html="items.dts"
                    class="descritAnswer"></span>
            </div>
            <div class="option">A、正确 <br>B、错误</div>
          </div>
          <div v-if="item.answer_type_text == '单选题'">
            <div class="descript">
              <span v-html="items.dts"
                    class="descritAnswer"></span>
            </div>
            <div class="option">
              <div style="display:flex"><span>A、</span> <span v-html='items.options[0].description'></span></div>
              <div style="display:flex"> <span>B、</span><span v-html='items.options[1].description'></span></div>
              <div style="display:flex"> <span>C、</span><span v-html='items.options[2].description'></span></div>
              <div style="display:flex"> <span>D、</span><span v-html='items.options[3].description'></span></div>
            </div>
          </div>
          <div v-if="item.answer_type_text == '多选题'">
            <div class="descript">
              <span v-html="items.dts"
                    class="descritAnswer"></span>
            </div>
            <div class="option">
              <div style="display:flex"
                   v-for='(optionItem,index) in items.options'
                   :key="index"><span>{{optionItem.number}}</span>、 <span v-html='optionItem.description'></span></div>
            </div>
          </div>
          <div v-if="item.answer_type_text == '填空题'">
            <div class="descript">
              <span v-html="items.dts"
                    class="descritAnswer"></span>
            </div>
          </div>
          <div v-if="item.answer_type_text == '问答题'">
            <div class="descript">
              <span v-html="items.dts"
                    class="descritAnswer"></span>
            </div>
          </div>
          <div v-if="item.answer_type_text == '名词解释'">
            <div class="descript">
              <span v-html="items.dts"
                    class="descritAnswer"></span>
            </div>
          </div>
          <div v-if="item.answer_type_text == '简答题'">
            <div class="descript">
              <span v-html="items.dts"
                    class="descritAnswer"></span>
            </div>
          </div>
          <div v-if="item.answer_type_text == '论述题'">
            <div class="descript">
              <span v-html="items.dts"
                    class="descritAnswer"></span>
            </div>
          </div>
          <div v-if="item.answer_type_text == '材料题'">
            <div class="descript">
              <span v-html="items.rich_text"
                    class="descritAnswer"></span>
            </div>
            <div v-for="(optionChild,indexChild) in items.children"
                 :key="indexChild"
                 class="dstContent">
              <div v-if="optionChild.answer_type_text == '单选题'">
                <div class="descriptChild">
                  <span v-html="optionChild.dts"></span>
                </div>
                <div class="option">
                  <div style="display:flex"><span>A、</span> <span v-html='optionChild.options[0].description'></span></div>
                  <div style="display:flex"> <span>B、</span><span v-html='optionChild.options[1].description'></span></div>
                  <div style="display:flex"> <span>C、</span><span v-html='optionChild.options[2].description'></span></div>
                  <div style="display:flex"> <span>D、</span><span v-html='optionChild.options[3].description'></span></div>
                </div>
              </div>

              <div v-if="optionChild.answer_type_text == '多选题'">
                <div class="descriptChild">
                  <span>{{optionChild.num}}、</span> <span v-html="optionChild.dt"></span>
                </div>
                <div class="option">
                  <div style="display:flex"
                       v-for='(childItem,index) in optionChild.options'
                       :key="index"><span>{{childItem.number}}</span> 、<span v-html='optionChild.options[index].description'></span>
                  </div>
                </div>
              </div>
              <div v-if="optionChild.answer_type_text == '填空题'">
                <div class="descriptChild">
                  <span v-html="optionChild.dts"></span>
                </div>
              </div>
              <div v-if="optionChild.answer_type_text == '问答题'">
                <div class="descriptChild">
                  <span v-html="optionChild.dts"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  data() {
    return {
      list: [],
      courseId: null,
      correct_status: null,
      name: '',
      score: 0,
      count: 0,
      number: ['一', '二', '三', '四', '五', '六', '七', '八', '九'],
      multipleChoice: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
      dataList: [],
      tabList: [],
      num: 1,
      topics: [
        {
          answer_type_text: '判断题',
          children: [],
        },
        {
          answer_type_text: '单选题',
          children: [],
        },
        {
          answer_type_text: '多选题',
          children: [],
        },
        {
          answer_type_text: '填空题',
          children: [],
        },
        {
          answer_type_text: '问答题',
          children: [],
        },
        {
          answer_type_text: '材料题',
          children: [],
        },
        {
          answer_type_text: '名词解释',
          children: [],
        },
        {
          answer_type_text: '论述题',
          children: [],
        },
        {
          answer_type_text: '简答题',
          children: [],
        },
      ],
      singleText: {
        answer_type_text: '单选题',
        children: [],
      }, //单选题
      MultipleText: {
        answer_type_text: '多选题',
        children: [],
      }, //多选题
      MaterialText: {
        answer_type_text: '材料题',
        children: [],
      }, //材料题
      essayQuesttion: {
        answer_type_text: '问答题',
        children: [],
      }, //问答提
      trueFalse: {
        answer_type_text: '判断题',
        children: [],
      }, //判断题
      gapFilling: {
        answer_type_text: '填空题',
        children: [],
      }, //填空题
    }
  },
  props: {
    testList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  created() {
    setTimeout(() => {
      this.paperList()
    }, 3000)
  },
  deactivated() {
    this.topics = [
      {
        answer_type_text: '判断题',
        children: [],
      },
      {
        answer_type_text: '单选题',
        children: [],
      },
      {
        answer_type_text: '多选题',
        children: [],
      },
      {
        answer_type_text: '填空题',
        children: [],
      },
      {
        answer_type_text: '问答题',
        children: [],
      },
      {
        answer_type_text: '材料题',
        children: [],
      },
      {
        answer_type_text: '名词解释',
        children: [],
      },
      {
        answer_type_text: '论述题',
        children: [],
      },
      {
        answer_type_text: '简答题',
        children: [],
      },
    ]
    this.num = 1
  },
  methods: {
    //将分数放进数组里面
    scortArray() {
      //将分数放到一个新的
      this.tabList.map((items) => {
        items.children.map((k) => {
          // 查找最后一个 span 标签的结束标签 </span>
          if (k.answer_type_text != '材料题') {
            k.description = k.description.replace(
              /<span[^>]*>(.*?)<\/span>/g,
              '$1'
            )
            const lastSpanIndex = k.description.lastIndexOf('</p>')
            if (k.description != '') {
              if (k.score != null && k.score != 0) {
                if (lastSpanIndex !== -1) {
                  k.dt =
                    k.description.slice(0, lastSpanIndex) +
                    '(' +
                    k.score +
                    '分)' +
                    k.description.slice(lastSpanIndex)
                }
              } else {
                k.dt = k.description
              }

              k.dts = k.dt.replace(
                /(<p[^>]*>)(.*?)(<\/p>)/,
                (match, p1, p2, p3) => `${p1}${k.num}、${p2}${p3}`
              )
            } else {
              k.dts = ''
            }
          } else {
            k.children.map((p) => {
              p.description = p.description.replace(
                /<span[^>]*>(.*?)<\/span>/g,
                '$1'
              )
              // 将分数存入字段前面
              const lastSpanIndex = p.description.lastIndexOf('</p>')
              if (p.score != null && p.score != 0) {
                if (lastSpanIndex !== -1) {
                  p.dt =
                    p.description.slice(0, lastSpanIndex) +
                    '        (' +
                    p.score +
                    '分)' +
                    p.description.slice(lastSpanIndex)
                }
              } else {
                p.dt = p.description
              }

              p.dts = p.description.replace(
                /(<p[^>]*>)(.*?)(<\/p>)/,
                (match, p1, p2, p3) => `${p1}${p.num}、${p2}${p3}`
              )
            })
          }
        })
      })
    },
    paperList() {
      this.testList.map((item) => {
        this.dataList.push(item.answer_type_text)
        if (item.answer_type_text == '单选题') {
          this.topics[1].children.push(item)
        } else if (item.answer_type_text == '多选题') {
          this.topics[2].children.push(item)
        } else if (item.answer_type_text == '材料题') {
          this.topics[5].children.push(item)
        } else if (item.answer_type_text == '问答题') {
          this.topics[4].children.push(item)
        } else if (item.answer_type_text == '判断题') {
          this.topics[0].children.push(item)
        } else if (item.answer_type_text == '填空题') {
          this.topics[3].children.push(item)
        } else if (item.answer_type_text == '名词解释') {
          this.topics[6].children.push(item)
        } else if (item.answer_type_text == '论述题') {
          this.topics[7].children.push(item)
        } else if (item.answer_type_text == '简答题') {
          this.topics[8].children.push(item)
        }
      })
      this.dataList = [...new Set(this.dataList)]
      this.dataList.map((items, index) => {
        this.tabList[index] = {
          children: [],
        }
        this.$set(this.tabList[index], 'answer_type_text', items)
      })
      this.topics.map((itenh) => {
        this.tabList.map((itemp) => {
          if (itenh.answer_type_text == itemp.answer_type_text) {
            itemp.children = itenh.children
          }
        })
      })

      this.tabList = this.tabList.filter((item) => item.children.length > 0)
      this.tabList.map((item, index) => {
        this.number.map((items, indexs) => {
          if (index == indexs) {
            this.$set(item, 'number', items)
          }
        })
        this.num = 1
        this.tabList.map((item) => {
          item.children.map((k) => {
            if (k.answer_type_text != '材料题') {
              this.$set(k, 'num', this.num++)
            }
            if (k.children) {
              k.children.map((i) => {
                this.$set(i, 'num', this.num++)
                if (i.answer_type_text == '多选题') {
                  i.options.map((intenH, index) => {
                    this.multipleChoice.map((items, indexs) => {
                      if (index == indexs) {
                        this.$set(intenH, 'number', items)
                      }
                    })
                  })
                }
              })
            }
            if (k.answer_type_text == '多选题') {
              this.multipleChoice.map((items, indexs) => {
                k.options.map((k, p) => {
                  if (p == indexs) {
                    this.$set(k, 'number', items)
                  }
                })
              })
            }
          })
        })
      })
      this.scortArray()
    },
  },
}
</script>
<style lang="less">
.descritAnswer {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
}

.descriptChild p {
  font-size: 14px;
  line-height: 20px;
}
.descriptChild span {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  font-family: PingFangSC, PingFang SC;
}
</style>
<style scoped  lang="less">
.box {
  padding: 0;

  .title {
    margin-top: 24px;
    text-align: center;
    padding: 0;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
  }
  .paperDetail {
    margin: 30px auto;
    width: 93%;
    padding: 16px;
    background: url('https://upload.kaoyanbox.net/c63252f2ceeb98f247bc0077b5524ac6.png')
      no-repeat;
    background-size: 100% 100%;
    border-radius: 16px;
    .name {
      font-size: 16px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 8px;
      .blod {
        font-weight: 700 !important;
        color: rgba(0, 0, 0, 0.9);
      }
    }
    .count {
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.9);
      .blod {
        font-weight: 700 !important;
      }
    }
    .score {
      color: rgba(0, 0, 0, 0.9);
      .blod {
        font-weight: 700 !important;
      }
    }
  }
  .topic {
    width: 93%;
    margin: 0 auto;
    padding-bottom: 90px;
    min-height: 500px;
    .topicItem {
      width: 100%;
      min-height: 90px;
      margin-top: 24px;
      border-bottom: 1px solid #e7e7e7;
      .topicTitle {
        font-family: PingFangSC, PingFang SC;
        font-weight: 700;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.9);
      }
      .descript {
        display: flex;
        min-height: 20px;
        margin-top: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
      }
      .descriptChild {
        display: flex;
        min-height: 20px;
        margin-top: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
      }
      .option {
        margin-top: 12px;
        padding-bottom: 24px;
        line-height: 24px;
        font-family: Helvetica;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  .footer {
    width: 100%;
    height: 80px;
    background: #fff;
    position: fixed;
    bottom: 0;
    .upload {
      margin: 10px auto;
      text-align: center;
      width: 93%;
      height: 40px;
      line-height: 40px;
      color: #fff;
      background: #1f86fe;
      border-radius: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 14px;
    }
    .viewAnswer {
      border: 1px solid #1f86fe;
      margin: 10px auto;
      text-align: center;
      width: 93%;
      height: 40px;
      line-height: 40px;
      color: #1f88fe;
      // background: #1f86fe;
      border-radius: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 14px;
    }
    .modifiy {
      width: 100%;
      margin: 10px auto;
      display: flex;
      justify-content: space-around;
      .modeBox {
        border: 1px solid #1f86fe;
        text-align: center;
        width: 45%;
        height: 40px;
        line-height: 40px;
        color: #1f88fe;
        border-radius: 4px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
      }
    }
  }
}
</style>