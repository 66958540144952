<template>
  <div>
    <el-dialog
      title="发放试卷"
      :visible.sync="dialogFormVisible"
      width="700px"
      @close="emitClose"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="发放学员："
          prop="student_id_list"
        >
           <el-link
              type="primary"
              :underline="false"
              :disabled="is_user_temp"
              @click="studentDialog = true"
              >选择用户</el-link
            >

          <div class="student-tag" v-if="!is_user_temp">
            <el-tag
              :key="index"
              v-for="(item,index) in students"
              closable
              :disable-transitions="false"
              @close="removeStudents(index)"
            >
              {{item.realname}}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item
          label="考试时间："
          prop="exam_time"
        >
          <el-date-picker
            v-model="dataForm.exam_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="timestamp"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
    <StudentChoose
      :visible="studentDialog"
      :content="students"
      @close="studentDialog=false"
      @submit="chooseStudent"
      :single="true"
    />
  </div>
</template>

<script>




import StudentChoose from "@/components/DialogComponents/Service/StudentChoose.vue";
import TestPaperUser from "@/components/DialogComponents/DeliverList/TestPaperUser";
import { mockexamprocessEdit, mockexamprocessOne } from "@/request/api";

export default {
  components: {
    StudentChoose,
    TestPaperUser
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      fileList: [],
      content:[],
      rules: {
        exam_time: [{ required: true, message: "请选择考试时间", trigger: "blur" }],
        student_id_list: [
          
          {
          required: true,
          validator: (rule, value, callback) => {
            if (!this.students.length) {
              callback(new Error('请选择发放学员'))
            } else {
              callback()
            }
          },
        }
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      studentDialog: false,
      students: [],
      is_user_temp:false

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
 
    fetchDetail() {
      mockexamprocessOne({ id: this.id }).then((res) => {
        this.dataForm = res.data
        // if(this.dataForm.student.id){
        //   this.is_user_temp = false
        // }else{
        //   this.content = []

        //   this.is_user_temp = true
        // }
        this.students = [
          res.data.student
        ]
        this.$set(this.dataForm, 'exam_time', [res.data.start_exam_time, res.data.end_exam_time])
      });
    },
    emitClose() {
      this.$emit("close");
    },

    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            student_id: this.students.map(v => {
              return v.id
            }).join(','),
            start_exam_time: this.dataForm.exam_time[0],
            end_exam_time: this.dataForm.exam_time[1],
            exam_paper_id: this.$route.params.id
          }
          mockexamprocessEdit(query).then((res) => {
            this.$message.success('提交成功')
            this.$emit('submit')
            this.emitClose()
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    chooseStudent({ data }) {
      this.students = data
    },
    removeStudents(index) {
      this.students.splice(index, 1)
    },


  },
};
</script>

<style lang="less" scoped>
.el-input,
.el-select {
  width: 100%;
}
.upload-demo {
  overflow: hidden;
  height: 35px;
}
.file-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .file-item {
    & + .file-item {
      margin-top: 16px;
    }
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .btn {
        width: 56px;
        height: 24px;
        border: 1px solid #dcdfe6;
        border-radius: 20px;
        color: #606266;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }
    }
  }
}
</style>