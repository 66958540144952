<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>服务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/service/teachingandresearch' }">教研服务</el-breadcrumb-item>
        <el-breadcrumb-item>服务记录</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button
          type="primary"
          size="small"
          @click="createDialog=true"
        >新建</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px;padding-top:20px;">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="服务科目">
          <el-input
            v-model.trim="query.service_subject_keyword"
            placeholder="请输入服务科目"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="服务老师">
          <el-input
            v-model.trim="query.teacher_keyword"
            placeholder="请输入服务老师"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="服务类型"
          prop="service_type_id"
        >
          <el-select
            v-model="query.service_type_keyword"
            placeholder="请选择服务类型"
            clearable
            style="width:100%;"
            @change="getList(true)"
          >
            <el-option
              v-for="(item) in $store.state.servicetypeOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="记录内容">
          <el-input
            v-model.trim="query.content_keyword"
            placeholder="请输入记录内容"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="学员信息">
          <el-input
            v-model.trim="query.student_keyword"
            placeholder="学员姓名/学员手机号"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="报名时间">
          <el-date-picker
            class="datetime"
            v-model="createDatetime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList(true)"
            clearable
          >
          </el-date-picker>
        </el-form-item>

      </el-form>
    </el-card>
    <el-card
      style="margin-top: 20px"
      v-if="task_tableData.length"
    >
      <div class="card-header">
        <TableTittle :tittle="TableName[0]"></TableTittle>
      </div>
      <el-table
        :data="task_tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column
          prop="content"
          label="记录内容"
          show-overflow-tooltip
        >

        </el-table-column>
        <el-table-column
          prop="do_teacher_name"
          label="服务老师"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="service_type_name"
          label="服务类型"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="截止时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{$moment(scope.row.stop_time).format("YYYY-MM-DD HH:mm")}}
          </template>
        </el-table-column>
        <el-table-column
          label="优先级"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="red">{{formatePriority_level(scope.row.priority_level)}}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="任务状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              size="mini"
              v-if="scope.row.status==1"
            >进行中</el-tag>
            <el-tag
              size="mini"
              type="info"
              v-if="scope.row.status==2"
            >已取消</el-tag>
            <el-tag
              size="mini"
              type="success"
              v-if="scope.row.status==3"
            >已完成</el-tag>
            <el-tag
              size="mini"
              type="danger"
              v-if="scope.row.status==4"
            >已逾期</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="发布人"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.publish_teacher_name}}
          </template>
        </el-table-column>
        <el-table-column
          label="发布日期"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")}}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="150"
        >
          <template slot-scope="scope">
            <!--       <el-link
              type="primary"
              :underline="false"
              @click="handleToDetail(scope.row.id)"
            >查看详情</el-link> -->
            <el-link
              type="primary"
              :underline="false"
              v-if="scope.row.status!==3"
              @click="changeStatus(scope.row.id)"
            >已完成</el-link>
            <el-link
              type="info"
              :underline="false"
              v-if="scope.row.status==3"
            >已完成</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName[1]"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
          style="margin-bottom:0px;"
          label-position="top"
        >
          <el-form-item style="">
            <el-checkbox
              v-model="is_mine"
              @change="checkboxChange"
            >仅查看自己记录</el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          label="记录时间"
          width="150"
        >
          <template slot-scope="scope">
            {{$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")}}
          </template>
        </el-table-column>
        <el-table-column
          prop="teacher_names"
          label="服务老师"
          width="130"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="service_subject_name"
          label="服务科目"
          width="230"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="service_type_names"
          label="服务类型"
          width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="content_text"
          label="记录内容"
          show-overflow-tooltip
          min-width="350"
        >
          <template slot-scope="scope">
            <div>
              <span class="ait_span">{{formateAit_user(scope.row.ait_user_names)}}</span>
              <span>{{scope.row.content_text}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="记录人"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.creator.alias}}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="150"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToEdit(scope.row.id)"
            >编辑</el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="handleToDetail(scope.row.id)"
            >详情</el-link>
            <el-link
              type="danger"
              :underline="false"
              @click="handleToRemove(scope.row.id)"
            >删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddTeachingAndResearch
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="getList()"
      :id="service_id"
      :service_subject="student_serviceSubject"
    />
    <EditTeachingAndResearch
      :id="editId"
      :isVisible="editDialog"
      @close="editDialog=false"
      @submit="getList()"
      :service_subject="student_serviceSubject"
    />
    <TeachingAndResearchDetail
      :id="detailId"
      :isVisible="detailDialog"
      @close="detailDialog=false"
    />
    <TaskDetail
      :id="detailId"
      :isVisible="TaskDetailDialog"
      @close="TaskDetailDialog=false"
    />
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddTeachingAndResearch from "@/components/DialogComponents/Service/AddTeachingAndResearch.vue"
import TeachingAndResearchDetail from "@/components/DialogComponents/Service/TeachingAndResearchDetail.vue";
import EditTeachingAndResearch from "@/components/DialogComponents/Service/EditTeachingAndResearch.vue"
import TaskDetail from "@/components/DialogComponents/Service/TaskDetail.vue"




import {
  teacherServiceloglist,
  teacherServicelogDelete,
  teacherServicelistDelete,
  editteacherServicelist,
  teacherServicetasklist,
  editteacherServicetask,
  studentBasicOne
} from "@/request/api";
import loginVue from '../../login.vue';
export default {
  components: {
    TableTittle,
    AddTeachingAndResearch,
    EditTeachingAndResearch,
    TeachingAndResearchDetail,
    TaskDetail
  },
  data() {
    return {
      TableName: ["待办事项", "记录列表"],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        teacher_service_id: this.$route.query.service_id || ""
      },
      pageTotal: 0,
      tableData: [],
      task_tableData: [],
      createDialog: false,
      fileList: [],
      editId: null,
      editDialog: false,
      chooseList: [],
      createDatetime: "",
      tableImg: [],
      showViewer: false,
      detailId: null,
      detailDialog: false,
      TaskDetailDialog: false,
      is_mine: false,
      user_id: "",
      service_id: null,
      student_serviceSubject: [],

    };
  },
  filters: {

  },
  watch: {
    // 时间数据监听
    createDatetime(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
        this.getList(true)
      }
    },
  },
  created() {
    this.service_id = Number(this.$route.query.service_id)
    this.parseQuery();

    this.user_id = JSON.parse(localStorage.getItem("companyUser")).id
    this.fetchStudentDetail()
    this.getList();
    this.getTaskList()
    this.$store.dispatch('getServiceSubjectDictionary')
    this.$store.dispatch('getServiceTypeDictionary')
  },
  mounted() {

  },
  methods: {

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList();
    },

    getList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      teacherServiceloglist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    fetchStudentDetail() {
      if (this.$route.query.student_id) {
        studentBasicOne({ cid: localStorage.getItem("cid"), id: this.$route.query.student_id }).then((res) => {

          if (res.data) {
            this.student_serviceSubject = res.data.teacher_service.service_subjects
          }
        });
      }

    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    getTaskList() {
      teacherServicetasklist({ do_teacher_id: this.user_id, student_id: this.$route.query.student_id, status: [1, 4].join(',') }).then((res) => {
        this.task_tableData = res.data.list || [];
      });
    },
    handleToGoRecord(id) {
      this.$router.push(`/dataInventory/dataList/record/${id}`)
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.tableImg = [url];
      this.showViewer = true;
    },
    handleToEdit(id) {
      this.editId = id
      this.editDialog = true
    },
    handleToRemove(id) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        teacherServicelogDelete({ id }).then((res) => {
          this.$nextTick(() => {
            this.getList();
          });
        });
      })
    },
    product_watchersFormate(value) {
      if (value && value.length) {
        let map_val = value.map((v) => { return v.alias })
        return map_val.join(',')
      } else {
        return '无'
      }
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    chooseAllEdit(archive_status, text) {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            rows: this.chooseList.map((v) => {
              return {
                id: v.id,
                archive: archive_status,
              }
            }),
          };
          editteacherServicelist(query).then((res) => {
            this.$nextTick(() => {
              this.getList();
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm("确定要删除这些信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map(v => { return v.id })
        teacherServicelistDelete({ ids }).then((res) => {
          this.getList();
        });
      })
    },
    handleToDetail(id) {
      this.detailId = id
      /* this.TaskDetailDialog = true */
      this.detailDialog = true
    },
    formatePriority_level(val) {
      if (val == 1) {
        return "I"
      } else if (val == 2) {
        return "II"
      } else if (val == 3) {
        return "III"
      } else if (val == 4) {
        return "IV"
      } else if (val == 5) {
        return "V"
      }
    },
    changeStatus(id) {
      this.$confirm("确定要修改此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let query = {
          id,
          status: 3,
        }
        editteacherServicetask(query).then((res) => {
          this.getTaskList()
        });
      })
    },
    checkboxChange(e) {
      if (e) {
        this.query.teacher_id = this.user_id
      } else {
        this.query.teacher_id = ""
      }
      this.getList(true)
    },
    formateAit_user(val) {
      if (val) {
        return val.split(',').map((v => { return `@${v}` })).join(' ')
      } else {
        return ""
      }
    }
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.card-header {
  display: flex;
  justify-content: space-between;
  .el-button {
    height: 35px;
    line-height: 10px;
  }
}
.block {
  margin-bottom: 14px;
  float: right;
}
.el-input {
  width: 200px;
}
.red {
  color: red;
}
.ait_span {
  color: #1f86fe;
  margin-right: 4px;
}
</style>