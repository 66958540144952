import { render, staticRenderFns } from "./enrollmentForecastAdd.vue?vue&type=template&id=4dd56f63&scoped=true&"
import script from "./enrollmentForecastAdd.vue?vue&type=script&lang=js&"
export * from "./enrollmentForecastAdd.vue?vue&type=script&lang=js&"
import style0 from "./enrollmentForecastAdd.vue?vue&type=style&index=0&id=4dd56f63&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dd56f63",
  null
  
)

export default component.exports