<template>
  <div>
    <el-dialog
      title="模考管理"
      :visible.sync="dialogFormVisible"
      width="448px"
      :close-on-click-modal="false"
      @close="emitClose"
    >
      <div class="bcg-container">
        <div class="info">
          <div class="text">{{dataForm.name}}</div>
          <div class="text large">({{dataForm.major_code}}){{dataForm.major_name}}</div>
          <div class="text">{{dataForm.college_name}}</div>
        </div>
        <div class="bottom-wrap">
          <div
            class="qrcode-img"
            id="qrcode"
            ref="qrcode"
          ></div>
          <div class="tips">
            长按识别二维码编辑试题
          </div>
        </div>
      </div>
      <div class="down-div">
        <el-link
          type="primary"
          :underline="false"
          @click="draw"
        >下载图片</el-link>
        <el-link
          type="primary"
          :underline="false"
          @click="downloadQrcode"
        >下载二维码</el-link>
      </div>
      <div style="margin-top: 15px;">
        <el-input
          placeholder="请输入内容"
          v-model="web_url"
          class="input-with-select"
          readonly
        >
          <el-button
            slot="append"
            type="primary"
            @click="copyUrl"
          >复制链接</el-button>
        </el-input>
      </div>
    </el-dialog>
    <div class="content_container">
      <vue-canvas-poster
        :painting="painting"
        @success="success"
        @fail="fail"
      ></vue-canvas-poster>
    </div>
  </div>
</template>

<script>

import QRCode from "qrcodejs2"
import { mockexamOne } from "@/request/api";


export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      qrcode_url: null,
      web_url: "",
      posterImg: '',//生成的海报
      painting: {},
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail(this.id)
      }
    },

  },
  methods: {
    emitClose() {
      document.querySelector("#qrcode").innerHTML = "";
      this.$emit("close");
    },
    fetchDetail(id) {
      mockexamOne({ id }).then((res) => {
        this.dataForm = res.data
        this.$nextTick(() => {
          let url = location.host.includes('crm.yanshizi.com') ? `https://h5.kaoyanbox.com/mock/commit/${this.dataForm.id}?cid=${localStorage.getItem('cid')}` : `https://h5.kaoyanbox.net/mock/commit/${this.dataForm.id}?cid=${localStorage.getItem('cid')}`
          this.drawPic(url)
        })
      });
    },
    qrcode(url) {
      let qrcode = new QRCode("qrcode", {
        width: 100, // 二维码宽度，单位像素
        height: 100, // 二维码高度，单位像素
        /*  text: `https://h5.kaoyanbox.com/activity/coupon/${this.content.id}`, // 生成二维码的链接 */
        text: url,
      });
    },
    draw() {
      var a = document.createElement('a')
      a.download = 'poster'
      // 设置图片地址
      a.href = this.posterImg;
      a.click();
    },
    drawPic(url) {
      this.web_url = url
      this.qrcode(url);
      setTimeout(() => {
        this.painting = {
          width: '400px',
          height: '471px',
          background: require('../../../assets/images/mock_bcg.png'),
          views: [{
            type: 'text',
            text: this.dataForm.name,
            css: {
              width: '288px',
              textAlign: 'center',
              fontSize: "16px",
              color: "#fff",
              fontWeight: '400',
              top: '128px',
              left: '56px',
            },
          }, {
            type: 'text',
            text: `（${this.dataForm.major_code}）${this.dataForm.major_name}`,
            css: {
              width: '288px',
              textAlign: 'center',
              fontSize: "18px",
              color: "#fff",
              fontWeight: '400',
              top: '166px',
              left: '56px',
            },
          }, {
            type: 'text',
            text: this.dataForm.college_name,
            css: {
              width: '288px',
              textAlign: 'center',
              fontSize: "14px",
              color: "#fff",
              fontWeight: '400',
              top: '210px',
              left: '56px',
            },
          }, {
            type: 'image',
            url: document.querySelectorAll('img')[11].currentSrc,
            css: {
              left: '140px',
              width: '120px',
              height: '120px',
              top: '270px'
            }
          }, {
            type: 'text',
            text: '长按识别二维码领取',
            css: {
              width: '288px',
              fontSize: "12px",
              color: "#CCCCCC",
              top: '406px',
              textAlign: 'center',
              left: '56px',
            },
          },],

        }
      }, 800);
    },
    copyUrl() {
      const input = document.createElement("textarea");
      input.setAttribute("id", "__mouse__position_input");
      input.value = this.web_url
      this.$copyText(input.value).then((e) => {
        this.$notify.success({
          title: "提示",
          message: "复制成功",
          type: "success",
        });
      });
    },
    downloadQrcode() {
      let img = document.querySelectorAll('img')[11].currentSrc
      var a = document.createElement('a')
      a.download = 'qrcode'
      // 设置图片地址
      a.href = img;
      a.click();
    },
    success(src) {
      this.posterImg = src
    },
    fail(err) {
      alert(err)
    },
  },
};
</script>

<style lang="less" scoped>
.bcg-container {
  width: 400px;
  height: 471px;
  background-image: url("https://upload.kaoyanbox.net/70b65dedca20dbf749eb95e5cb3d4d07.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  .info {
    position: absolute;
    top: 112px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 320px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .large {
      font-size: 20px;
      font-weight: 600;
      line-height: 48px;
    }
  }
  .bottom-wrap {
    position: absolute;
    bottom: 61px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrcode-img {
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .tips {
      margin-top: 16px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      color: #999999;
      line-height: 18px;
    }
  }
}
.down-div {
  margin-top: 16px;
  .el-link {
    font-size: 12px;
    margin: 0px 8px;
  }
}
</style>