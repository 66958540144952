<template>
  <div>
    <el-dialog
      title="重复提示"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      append-to-body
      :width="!isfixed?'375px':''"
    >
      <el-radio-group
        v-model="user_list_index"
        @change="radioChange"
        size="small"
        v-if="Object.keys(content).length"
      >
        <el-radio-button
          v-for="(item,index) in content.user_list.filter(v=>{ return v.is_handle})"
          :key="index"
          :label="index"
        >{{item.receiver_name|| item.student_id}}</el-radio-button>
      </el-radio-group>
      <div class="info-wrap">
        <div class="title">待创建发货单信息</div>
        <div class="info-item">
          收货人：{{content_Index.receiver_name||content_Index.student_id}}
        </div>
        <div class="info-item">
          手机号：{{content_Index.receiver_phone||"无"}}
        </div>
        <div class="info-item">
          收货地址：{{content_Index.receiver_address||"无"}}
        </div>
      </div>
      <div class="product-wrap">
        <el-table
          :data="content.product_list"
          :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
          size="small"
          :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        >
          <div slot="empty">
            <img
              src="@/assets/images/repeat_clear.png"
              style="width: 108px; height: 86px;margin-top:16px;"
              v-if="activeName=='repeat-material'"
            />
            <img
              src="@/assets/images/repeat_merge.png"
              style="width: 108px; height: 86px;margin-top:16px;"
              v-else
            />
          </div>
          <el-table-column
            prop="id"
            label="名称"
            show-overflow-tooltip
          >

          </el-table-column>
          <el-table-column
            prop="product_name"
            label="名称"
            width="230"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              《{{scope.row.product_name}}》
            </template>
          </el-table-column>
          <el-table-column
            prop="year"
            label="年份"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="product_sku"
            label="规格"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="数量"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="状态"
            show-overflow-tooltip
            width="300"
          >
            <template slot-scope="scope">
              <!--               <div v-if="!content_Index.merge_slip.merge_product_list.length&&content_Index.cleared_product_list.length"></div> -->
              <el-tag
                size="mini"
                v-if="content_Index.merge_slip&&content_Index.merge_slip.is_merge&&!content_Index.merge_slip.merge_product_list.find(v=>{ return v.product_id==scope.row.product_id })"
              >被去重</el-tag>
              <el-tag
                size="mini"
                v-else-if="content_Index.merge_slip&&!content_Index.merge_slip.is_merge&&!content_Index.cleared_product_list.find(v=>{ return v.product_id==scope.row.product_id })"
              >被去重</el-tag>
              <el-tag
                size="mini"
                v-else-if="content_Index.merge_slip==null&&!content_Index.cleared_product_list.find(v=>{ return v.product_id==scope.row.product_id })"
              >被去重</el-tag>
              <el-tag
                size="mini"
                type="success"
                v-else-if="!content_Index.cleared_product_list.find(v=>{ return v.product_id==scope.row.product_id })"
              >被合并</el-tag>

            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="line"></div>
      <div class="main-wrap">
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane
            label="与以下发货单中的资料重复"
            name="repeat-material"
            v-if="this.content_Index.clear_slip_list&&this.content_Index.clear_slip_list.length"
          >
          </el-tab-pane>
          <el-tab-pane
            label="与以下待发货单的收货人重复"
            name="repeat-receiver"
            v-if="this.content_Index.merge_slip"
          >
          </el-tab-pane>
        </el-tabs>
        <el-table
          :data="tableData"
          :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
          :cell-style="{ textAlign: 'center', fontSize: '13px' }"
          border
          size="small"
        >
          <el-table-column
            label="发货单详情"
            width="100"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                :underline="false"
                @click="handleToDetail(scope.row.id)"
              >查看详情</el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="student_realname"
            label="学员姓名"
            width="130"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="theme"
            label="发货主题"
            width="260"
            show-overflow-tooltip
          >
          </el-table-column>

          <el-table-column
            label="收件信息"
            width="350"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{scope.row.receiver_name||"--"}}/{{scope.row.receiver_phone||"--"}}/{{scope.row.receiver_address||"--"}}
            </template>

          </el-table-column>

          <el-table-column
            label="物流状态"
            width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag
                type="warning"
                size="mini"
                v-if="scope.row.express_status==0"
              >待发货</el-tag>
              <el-tag
                size="mini"
                v-if="scope.row.express_status==1"
              >已发货</el-tag>
              <el-tag
                type="info"
                size="mini"
                v-if="scope.row.express_status==2"
              >已取消</el-tag>
              <el-tag
                type="success"
                size="mini"
                v-if="scope.row.express_status==3"
              >已送达</el-tag>
              <el-tag
                type="info"
                size="mini"
                v-if="scope.row.express_status==4"
              >已退回</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="express_name"
            label="快递名称"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="express_no"
            label="快递单号"
            width="180"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            width="180"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="创建人"
            width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{scope.row.creator.alias}}
            </template>
          </el-table-column>
          <el-table-column
            label="发货人"
            width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{scope.row.express_creator?scope.row.express_creator.alias:""}}

            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            width="160"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")}}
            </template>
          </el-table-column>
          <el-table-column
            label="发货时间"
            width="160"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{$moment(scope.row.express_time).format("YYYY-MM-DD HH:mm")}}
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="状态"
            width="80"
          >
            <template slot-scope="scope">
              <!--         <el-link
                type="primary"
                :underline="false"
                @click="handleToDetail(scope.row.id)"
              >查看详情</el-link> -->
              <el-link
                type="primary"
                :underline="false"
                v-if="activeName=='repeat-material'&&scope.row.is_clear"
                @click="clearData(false,scope)"
              >已去重</el-link>
              <el-link
                type="info"
                :underline="false"
                v-if="activeName=='repeat-material'&&!scope.row.is_clear"
                @click="clearData(true,scope)"
              >不去重</el-link>
              <el-link
                type="primary"
                :underline="false"
                @click="mergeData(false,scope)"
                v-if="activeName=='repeat-receiver'&&scope.row.is_merge"
              >已合并</el-link>
              <el-link
                type="info"
                :underline="false"
                @click="mergeData(true,scope)"
                v-if="activeName=='repeat-receiver'&&!scope.row.is_merge"
              >不合并</el-link>
            </template>
          </el-table-column>
        </el-table>
        <!--       <div
          class="product-wrap"
          v-if="activeName=='repeat-receiver'"
        >
          <el-table
            :data="content_Index.merge_slip.merge_product_list"
            :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
            size="small"
            :cell-style="{ textAlign: 'center', fontSize: '13px' }"
          >
            <div slot="empty">
              <img
                src="@/assets/images/repeat_clear.png"
                style="width: 108px; height: 86px;margin-top:16px;"
                v-if="activeName=='repeat-material'"
              />
              <img
                src="@/assets/images/repeat_merge.png"
                style="width: 108px; height: 86px;margin-top:16px;"
                v-else
              />
            </div>
            <el-table-column
              prop="product_name"
              label="名称"
              width="300"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                《{{scope.row.product_name}}》
              </template>
            </el-table-column>
            <el-table-column
              prop="year"
              label="年份"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="product_sku"
              label="规格"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="quantity"
              label="数量"
              show-overflow-tooltip
            >
            </el-table-column>

          </el-table>
        </div> -->
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>

    </el-dialog>
    <DeliverDetail
      :isVisible="detailDialog"
      @close="detailDialog=false"
      :id="detailId"
    />
  </div>
</template>

<script>

import { createshipslip, shipslipEdit } from "@/request/api";
import DeliverDetail from "@/components/DialogComponents/DeliverList/DeliverDetail.vue"


export default {
  components: {
    DeliverDetail
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
    single: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    content_Index: function () {
      if (Object.keys(this.content).length && this.content.user_list) {
        let arr = this.content.user_list.filter(v => { return v.is_handle })
        return arr[this.user_list_index]
      } else {
        return {}
      }
    },
    tableData: function () {
      if (Object.keys(this.content_Index).length) {
        if (this.activeName == "repeat-material") {
          return this.content_Index.clear_slip_list || []
        } else {
          return this.content_Index.merge_slip ? [this.content_Index.merge_slip] : []
        }
      } else {
        return []
      }
    },
  },

  data() {
    return {
      dialogFormVisible: this.visible,
      user_list_index: 0,
      activeName: "repeat-material",
      detailDialog: false,
      detailId: null,
      isfixed: document.body.clientWidth < 820 ? false : true,
    };
  },
  watch: {
    visible(val) {
      this.dialogFormVisible = val;



    },
    content(val) {
      if (this.single) {
        this.$set(this.content, 'user_list', [this.content.user_obj])
      }
      this.activeName = this.content.user_list[0].clear_slip_list.length ? 'repeat-material' : 'repeat-receiver'
      this.content.user_list.forEach(item => {
        item.clear_slip_list.forEach(citem => {
          this.$set(citem, "clear_products_ids", citem.clear_products.map(v => { return v.product_id }))
        })
      });
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit() {
      if (this.single) {
        let query = {
          cid: this.content.cid,
          id: this.content.id,
          is_checked: this.content.is_checked,
          is_user_temp: this.content.is_user_temp,
          product_list: this.content.product_list,
          remark: this.content.remark,
          theme: this.content.theme,
          user_obj: this.content.user_list[0],
        }
        shipslipEdit(query).then((res) => {
          if (res.code == 0) {
            this.$message.success(`保存成功`);
            this.emitClose()
            this.$emit('submit')
          }
        });
        return
      } else {
        createshipslip(this.content).then((res) => {
          if (res.code == 0) {
            this.$message.success(`保存成功`);
            this.emitClose()
            this.$emit('submit')
          }
        });
      }

    },
    radioChange(e) {
      console.log(e);
    },
    handleClick(tab, event) {
      if (tab.name == "repeat-material") {
        console.log('1');
      } else if (tab.name == "repeat-receiver") {
        console.log('2');
      }
    },
    handleToDetail(id) {
      this.detailId = id
      this.detailDialog = true
    },
    clearData(clear, scope) {
      this.$set(scope.row, 'is_clear', clear)
      if (clear) {
        if (this.content.user_list[this.user_list_index].merge_slip && this.content.user_list[this.user_list_index].merge_slip.is_merge) {
          let clear_products_ids = scope.row.clear_products_ids
          let val = []
          let merge_product_list = this.content.user_list[this.user_list_index].merge_slip.merge_product_list
          clear_products_ids.forEach(item => {
            let product_item = merge_product_list.find((v) => { return v.product_id == item })
            val.push(product_item)
            merge_product_list.splice(merge_product_list.findIndex((v) => { return v.product_id == item }), 1)
          });
          this.$set(scope.row, 'clear_products', val)
        } else {
          let clear_products_ids = scope.row.clear_products_ids
          let val = []
          let cleared_product_list = this.content.user_list[this.user_list_index].cleared_product_list
          clear_products_ids.forEach(item => {
            let product_item = cleared_product_list.find((v) => { return v.product_id == item })
            val.push(product_item)
            cleared_product_list.splice(cleared_product_list.findIndex((v) => { return v.product_id == item }), 1)
          });
          this.$set(scope.row, 'clear_products', val)
        }
      } else {
        let clear_products = scope.row.clear_products
        if (this.content.user_list[this.user_list_index].merge_slip && this.content.user_list[this.user_list_index].merge_slip.is_merge) {
          let cleared_product_list = this.content.user_list[this.user_list_index].merge_slip.merge_product_list.concat(clear_products)
          this.$set(this.content.user_list[this.user_list_index].merge_slip, 'merge_product_list', cleared_product_list)
          this.$set(scope.row, 'clear_products', [])
        } else {
          let cleared_product_list = this.content.user_list[this.user_list_index].cleared_product_list.concat(clear_products)
          this.$set(this.content.user_list[this.user_list_index], 'cleared_product_list', cleared_product_list)
          this.$set(scope.row, 'clear_products', [])
        }
        /*  let clear_products = scope.row.clear_products
         let cleared_product_list = this.content.user_list[this.user_list_index].cleared_product_list.concat(clear_products)
         this.$set(this.content.user_list[this.user_list_index], 'cleared_product_list', cleared_product_list)
         this.$set(scope.row, 'clear_products', []) */
      }
      setTimeout(() => {
        console.log(this.content.user_list[this.user_list_index]);
      }, 1000);
    },
    mergeData(merge, scope) {
      this.$set(scope.row, 'is_merge', merge)
      let merge_product_list = this.content.user_list[this.user_list_index].merge_slip.merge_product_list
      let cleared_product_list = this.content.user_list[this.user_list_index].cleared_product_list.concat(merge_product_list)
      if (merge) {
        this.$set(this.content.user_list[this.user_list_index], 'cleared_product_list', [])
        this.$set(this.content.user_list[this.user_list_index].merge_slip, 'merge_product_list', cleared_product_list)
      } else {
        this.$set(this.content.user_list[this.user_list_index], 'cleared_product_list', cleared_product_list)
        this.$set(this.content.user_list[this.user_list_index].merge_slip, 'merge_product_list', [])
      }
      setTimeout(() => {
        console.log(this.content.user_list[this.user_list_index]);
      }, 1000);
    }
  },
};
</script>

<style lang="less" scoped>
.info-wrap {
  margin-top: 24px;

  .title {
    color: #606266;
    font-weight: 600;
    font-size: 14px;
  }
  .info-item {
    font-size: 13px;
    color: #606266;
    margin-top: 16px;
    & + .info-item {
      margin-top: 8px;
    }
  }
}
.product-wrap {
  margin-top: 20px;
}
.line {
  border: dashed 1px #dcdfe6;
  margin-top: 20px;
}
</style>