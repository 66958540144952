<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>服务</el-breadcrumb-item>
        <el-breadcrumb-item>师资匹配</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="openAddMatchingOrderDialog()"
          :disabled="!$buttonPermission('teacherMatchOrder:add')"
        >新建</el-button>
      </div>
    </div>
    <el-card class="operation-card">
      <el-form
        :inline="true"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="搜索">
          <el-input
            v-model="query.keyword"
            placeholder="姓名/院校/学院/专业/备注"
            @input="inputSearch"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item style="float: right">
          <el-radio-group
            v-model="query.archive"
            @change="getList(query)"
          >
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="1">已归档</el-radio-button>
            <el-radio-button label="0">未归档</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <ScreenMatchingOrder @submit="screenTable"></ScreenMatchingOrder>
    </el-card>
    <el-card
      class="table-card"
      style="margin-top: 20px"
    >
      <div class="card-header">
        <TableTittle :tittle="TableName"></TableTittle>
        <el-form
          :inline="true"
          label-width="70px"
          size="small"
        >
          <el-form-item label="只看关注">
            <el-switch
              v-model="query.follow"
              :active-value="1"
              inactive-value=" "
              @change="getList(query)"
            >
            </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column
          type="selection"
          width="50"
        > </el-table-column>
        <el-table-column
          prop="date"
          label="关注"
          width="50"
          fixed="left"
        >
          <template slot-scope="scope">
            <img
              alt=""
              srcset=""
              class="star"
              src="../../../assets/images/star-fill.png"
              @click="followDelete(scope.row)"
              v-show="scope.row.follow"
            />
            <img
              src="../../../assets/images/star.png"
              alt=""
              srcset=""
              class="star"
              v-show="!scope.row.follow"
              @click="followCreate(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="师资信息"
          width="130"
        >
          <template slot-scope="scope">
            <div class="link-template">
              <div
                class="red-bradge"
                v-if="scope.row.unread_status == 1 && scope.row.status != 1"
              >
                <span>new</span>
              </div>
              <el-link
                type="success"
                :underline="false"
                @click="openResultDialog(scope.row)"
                v-if="scope.row.result"
                :disabled="!$buttonPermission('teacherMatchOrder:result')"
              >查看</el-link>
              <el-link
                type="info"
                :underline="false"
                v-else
              >查看</el-link>
              <el-link
                type="primary"
                :underline="false"
                @click="
                  editTeacherOrder(scope.row, '确定要推送吗？', 2, '已取消推送')
                "
                :disabled="!$buttonPermission('teacherMatchOrder:edit')"
                v-if="scope.row.status == 1"
              >推送</el-link>
              <!--               <el-link
                type="success"
                :underline="false"
                v-else-if="scope.row.status == 4 || scope.row.status == 5"
                @click="goRecord(scope.row)"
                >查看</el-link
              > -->
              <el-link
                type="success"
                :underline="false"
                @click="goRecord(scope.row)"
                :disabled="!$buttonPermission('teacherMatchOrderRecord:view')"
                v-else
              >跟进</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="order_no"
          label="订单号"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="订单状态"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              type="warning"
              v-show="scope.row.match_order_sync_status == 1"
            >待服务</el-tag>
            <el-tag
              size="small"
              type="warning"
              v-show="scope.row.match_order_sync_status == 2"
            >待对接</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.match_order_sync_status == 3"
            >匹配中</el-tag>
            <el-tag
              size="small"
              type="success"
              v-show="scope.row.match_order_sync_status == 4"
            >已完成</el-tag>
            <el-tag
              size="small"
              type="danger"
              v-show="scope.row.match_order_sync_status == 5"
            >已取消</el-tag>
            <!--   <el-tag
              size="small"
              v-show="scope.row.status == 0"
            >无</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 1"
            >待推送</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 2"
            >正在对接</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 3"
            >正在匹配</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 4"
            >匹配成功</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 5"
            >订单完成</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.status == 6"
            >订单取消</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="apply_college_name"
          label="报考院校"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="
                scope.row.apply_college_name && scope.row.apply_college_code
              ">
              {{ `${scope.row.apply_college_name}` }}</span>
            <span v-else> 无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="报考学院"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="
                scope.row.apply_academy_name && scope.row.apply_academy_code
              ">
              {{
                `(${scope.row.apply_academy_code})${scope.row.apply_academy_name}`
              }}</span>
            <span v-else> 无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="报考专业"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.apply_major_name && scope.row.apply_major_code">
              {{
                `(${scope.row.apply_major_code})${scope.row.apply_major_name}`
              }}</span>
            <span v-else> 无</span>
          </template>
        </el-table-column>
        <el-table-column
          label="考试方向"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.apply_direction_code&&scope.row.apply_direction_name">
              {{
                `(${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
              }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="辅导科目"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.subjects | formateSub }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="订单价格"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.order_amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="280"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="支付状态"
          width="120"
          type="success"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              type="success"
              v-show="scope.row.payment_status == 0"
            >未支付</el-tag>
            <el-tag
              size="small"
              type="success"
              v-show="scope.row.payment_status == 1"
            >已支付</el-tag>
            <el-tag
              size="small"
              type="success"
              v-show="scope.row.payment_status == 2"
            >已退款</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="支付时间"
          width="150px"
        >
          <template slot-scope="scope">
            {{ scope.row.payorder_pay_time?$moment(scope.row.payorder_pay_time).format("YYYY-MM-DD HH:mm"):"" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          width="120px"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="watcher"
          label="负责人"
          width="130"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.watcher | formateWatcher }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="creator"
          label="创建人"
          width="90"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.creator">{{ scope.row.creator.alias }} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="操作"
          width="160"
          fixed="right"
        >
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link
                type="primary"
                :underline="false"
                @click="openEditMatchingOrderDialog(scope.row)"
                v-if="scope.row.status == 1"
                :disabled="!$buttonPermission('teacherMatchOrder:edit')"
              >编辑</el-link>
              <el-link
                type="info"
                :underline="false"
                v-else
              >编辑</el-link>
              <el-link
                type="primary"
                :underline="false"
                v-if="scope.row.payment_status == 0"
                @click="openPayMatchingOrderDialog(scope.row)"
                :disabled="!$buttonPermission('teacherMatchOrder:pay')"
              >支付</el-link>
              <el-link
                type="info"
                :underline="false"
                v-else
              >已支付</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button
          size="mini"
          type="success"
          @click="filterArr"
        >推送</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="chooseAllEdit(1, '您确定要归档吗')"
          :disabled="!$buttonPermission('teacherMatchOrder:edit')"
          v-show="query.archive == 0 && query.archive != ''"
        >归档</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="chooseAllEdit(0, '您确定要取消归档吗')"
          :disabled="!$buttonPermission('teacherMatchOrder:edit')"
          v-show="query.archive == 1"
        >取消归档</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="chooseAllDelete"
          :disabled="!$buttonPermission('teacherMatchOrder:delete')"
        >删除</el-button>
        <el-button
          size="small"
          type="primary"
          @click="openBatchEditWatcherDialog"
          v-show="$buttonPermission('teacherMatchOrder:batchWatcher')"
        >分配</el-button>
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddMatchingOrder
      :isVisible="AddMatchingOrderDialog"
      @close="AddMatchingOrderDialog = false"
      @submit="AddMatchingOrderSubmit"
    ></AddMatchingOrder>
    <EditMatchingOrder
      :isVisible="EditMatchingOrderDialog"
      @close="EditMatchingOrderDialog = false"
      @submit="EditMatchingOrderSubmit"
      :content="EditMatchingOrderContent"
    ></EditMatchingOrder>
    <PayMatchingOrder
      :content="PayMatchingOrderContent"
      :isVisible="PayMatchingOrderDialog"
      @close="PayMatchingOrderClose"
    ></PayMatchingOrder>
    <el-dialog
      title="师资信息"
      :visible.sync="resultVisible"
      width="600px"
    >
      <el-descriptions
        :column="2"
        border
        v-if="resultContent.result"
      >
        <el-descriptions-item
          label="姓名"
          :contentStyle="contentStyle"
          :labelStyle="labelStyle"
        >{{ resultContent.result.name || "无" }}</el-descriptions-item>
        <el-descriptions-item
          label="性别"
          :contentStyle="contentStyle"
          :labelStyle="labelStyle"
        >{{ resultContent.result.gender || "无" }}</el-descriptions-item>
        <el-descriptions-item
          label="手机号"
          :contentStyle="contentStyle"
          :labelStyle="labelStyle"
        >
          {{ resultContent.result.phone || "无" }}
        </el-descriptions-item>
        <el-descriptions-item
          label="微信号"
          :contentStyle="contentStyle"
          :labelStyle="labelStyle"
        >{{ resultContent.result.wechat || "无" }}</el-descriptions-item>
        <el-descriptions-item
          label="QQ号"
          :contentStyle="contentStyle"
          :labelStyle="labelStyle"
        >{{ resultContent.result.qq || "无" }}</el-descriptions-item>
        <el-descriptions-item
          label="年级"
          :contentStyle="contentStyle"
          :labelStyle="labelStyle"
        >{{ resultContent.result.apply_year || "无" }}</el-descriptions-item>
        <el-descriptions-item
          label="方向"
          :contentStyle="contentStyle"
          :labelStyle="labelStyle"
        >{{ resultContent.result.direction || "无" }}</el-descriptions-item>
        <el-descriptions-item
          label="分数"
          :contentStyle="contentStyle"
          :labelStyle="labelStyle"
        >{{ resultContent.result.fraction || "无" }}</el-descriptions-item>
        <el-descriptions-item
          label="备注"
          :labelStyle="labelStyle"
        >{{
          resultContent.result.remark || "无"
        }}</el-descriptions-item>
      </el-descriptions>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="resultVisible = false">取 消</el-button>
        <el-button
          @click="onCopy"
          type="primary"
        >一键复制</el-button>
      </span>
    </el-dialog>
    <BatchEditWatcher
      :isVisible="BatchEditWatcherDialog"
      @close="BatchEditWatcherDialog = false"
      @submit="BatchEditWatcherSubmit"
    ></BatchEditWatcher>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import ScreenMatchingOrder from "../../../components/ScreenComponents/ScreenMatchingOrder/ScreenMatchingOrder.vue";
import AddMatchingOrder from "../../../components/DialogComponents/AddMatchingOrder/AddMatchingOrder.vue";
import EditMatchingOrder from "../../../components/DialogComponents/EditMatchingOrder/EditMatchingOrder.vue";
import PayMatchingOrder from "../../../components/DialogComponents/PayMatchingOrder/PayMatchingOrder.vue";
import BatchEditWatcher from "../../../components/DialogComponents/BatchEditWatcher/BatchEditWatcher.vue";
import {
  createteacherorder,
  teacherorderlist,
  editeacherorder,
  createuserfollow,
  deleteuserfollow,
  editteacherorderlist,
  deleteteacherorderlist,
  editteacherorder,
  helpFindedit,
  helpFinddetail
} from "../../../request/api";
import { formateObjDaiZhao } from "../../../utils/helper";
export default {
  components: {
    TableTittle,
    ScreenMatchingOrder,
    AddMatchingOrder,
    EditMatchingOrder,
    PayMatchingOrder,
    BatchEditWatcher
  },
  data() {
    return {
      tableData: [],
      TableName: "订单列表",
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        archive: "0",
      },
      pageTotal: 0,
      EditMatchingOrderDialog: false, // 编辑订单弹窗
      EditMatchingOrderContent: {},
      AddMatchingOrderDialog: false, // 添加订单弹窗
      resultVisible: false, // 师资信息弹窗
      PayMatchingOrderContent: {},
      PayMatchingOrderDialog: false, // 师资订单支付弹窗
      resultContent: {},
      chooseList: [],
      lock: true,
      // 批量修改watcher弹窗
      BatchEditWatcherDialog: false,
      contentStyle: {
        width: "230px",
      },
      labelStyle: {
        width: "120px",
      },

    };
  },
  filters: {
    formateSub: function (val) {
      if (val == null || val == 1) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += `(${item.subject_code})${item.subject_name}` + "，";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formateWatcher: function (val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.alias + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
  },
  mounted() {
    // 获取列表
    this.getList(this.query);
    this.$store.dispatch("getCompanyAccount");
    this.$store.dispatch("getCompanyInfo");
    this.$store.dispatch("getAllColleges");
    this.$store.dispatch("getStaffList");
  },
  methods: {
    getList() {
      const params = Object.assign({}, this.query);
      if (params.match_order_sync_statuses) {
        params.match_order_sync_statuses = params.match_order_sync_statuses.join(',')
      }
      teacherorderlist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
 /*        this.tableData.forEach((item, index) => {
          //this.$set(item, 'learnhours', 0) // 设置属性
          if (item.old_id == null) {
            return false
          }
          let forQuery = {
            id: item.old_id,
            newId: item.id,
          }
          // 请求每一条数据的详情
          helpFinddetail(forQuery).then((response) => {
            if (response.data.data.message == "ok") {
                   let arr = response.data.data.data.records
                   // 判断，为空则不计算
                   if (arr.length > 0) {
                     // 求和
                     let a = arr.reduce((acc, cur) => {
                       return acc + cur.hours
                     }, 0)
                     // 赋值
                     item.learnhours = a
                   }
            }
            this.$set(this.hoursObj, index, item.learnhours)
          })
        }) */
      }).then(() => {
   /*      setTimeout(() => {
          this.getListTwice(params)
        }, 100); */
      })
    },
    getListTwice(query) {
      teacherorderlist(query).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
        /*     if (JSON.stringify(this.hoursObj) != '{}') {
              this.tableData.forEach((item, index) => {
                this.$set(item, 'learnhours', this.hoursObj[index])
              })
            } */
      })
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseList = val;
    },
    // 筛选组件传值
    screenTable({ screenForm }) {
      this.$set(this.query, "pageIndex", 1);
      this.query = {
        ...this.query,
        ...screenForm,
      };
      this.getList(this.query);
    },
    // 搜索框搜索
    inputSearch(e) {
      this.getList(this.query);
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    // 跳转沟通记录
    goRecord(item) {
      this.$router.push({
        name: `communicationrecord`,
        query: item,
      });
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 4,
          follow_id: item.id,
        };
        createuserfollow(query).then((res) => {
          this.getList(this.query);
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 500);
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 4,
          follow_id: item.id,
        };
        deleteuserfollow(query).then((res) => {
          this.getList(this.query);
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 300);
      }
    },
    formateOrder() {
      // 把subjects参数转换为处理并转换字符串，必须要number格式
      if (this.EditMatchingOrderContent.subjects) {
        let arr = [];
        this.EditMatchingOrderContent.subjects.forEach((item) => {
          arr.push(item.subject_code);
        });
        this.EditMatchingOrderContent.subjects = arr;
      }
    },
    // 打开添加订单弹窗
    openAddMatchingOrderDialog() {
      this.AddMatchingOrderDialog = !this.AddMatchingOrderDialog;
    },
    // 打开编辑订单弹窗
    openEditMatchingOrderDialog(item) {
      this.EditMatchingOrderContent = JSON.parse(JSON.stringify(item));
      this.formateOrder();
      this.EditMatchingOrderDialog = !this.EditMatchingOrderDialog;
    },
    // 添加订单组件提交
    AddMatchingOrderSubmit({ form }) {
      createteacherorder(form).then((res) => {
        if (res.code == 0) {
          this.getList(this.query);
          this.AddMatchingOrderDialog = false;
        }
      });
    },
    // 修改订单组件提交
    EditMatchingOrderSubmit({ form }) {
      editeacherorder(form).then((res) => {
        if (res.code == 0) {
          this.$notify.success({
            title: "提示",
            message: "修改成功",
          });
          this.EditMatchingOrderDialog = false;
          this.getList(this.query);

     /*      if (form.old_id == null) {
            this.getList(this.query);
            return false
          }
          // 总端修改成功之后还要再调一次老系统的接口同步数据
          helpFindedit(formateObjDaiZhao(form, 'edit')).then(response => {
            if (response.data.data.message == "ok") {
              this.getList(this.query);
            } else {
              this.$notify.error({
                title: "提示",
                message: response.data.data.message,
              });
            }
          }) */
        }
      });
    },
    // 对多选数组做判断处理
    filterArr() {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      let flag = this.chooseList.findIndex((item) => item.status != 1);
      if (flag == -1) {
        this.$confirm("您确定要推送吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let query = {
              rows: [],
            };
            query.rows = this.chooseList.map((v) => {
              let obj = {
                id: v.id,
                status: 2,
              };
              return obj;
            });
            editteacherorderlist(query).then((res) => {
              if (res.code == 0) {
                this.$notify.success({
                  title: "提示",
                  message: "推送成功",
                });
                this.getList(this.query);
              }
            });
          })
          .catch(() => {
            this.$notify.error({
              title: "提示",
              message: "已取消操作",
            });
          });
      } else {
        this.$notify.error({
          title: "提示",
          message: "选中订单中部分订单不处于待推送状态",
        });
      }
    },
    chooseAllEdit(archive_status, text) {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            rows: [],
          };
          this.chooseList.forEach((item) => {
            let obj = {
              id: item.id,
              archive: archive_status,
            };
            query.rows.push(obj);
          });
          editteacherorderlist(query).then((res) => {
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    // 选中数据后做处理删除
    chooseAllDelete() {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要删除的数据",
        });
        return false;
      }
      this.$confirm("确定要删除选中的数据吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            ids: [],
          };
          this.chooseList.forEach((item) => {
            query.ids.push(item.id);
          });
          deleteteacherorderlist(query).then((res) => {
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    // 修改订单状态
    editTeacherOrder(item, text, status, canceltext) {
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            id: item.id,
            status: status,
          };
          console.log(item);
          editteacherorder(query).then((res) => {
            this.$nextTick(() => {
              this.dialogVisible = false;
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: canceltext,
          });
        });
    },
    // 打开师资信息弹窗
    openResultDialog(item) {
      if (item.result) {
        this.resultContent = item;
      } else {
        this.resultContent = {};
      }
      this.resultVisible = true;
    },
    openPayMatchingOrderDialog(item) {
      this.PayMatchingOrderContent = JSON.parse(JSON.stringify(item));
      this.PayMatchingOrderDialog = !this.PayMatchingOrderDialog;
    },
    PayMatchingOrderClose() {
      // 支付后刷新列表
      this.PayMatchingOrderDialog = false;
      this.getList(this.query);
      this.$store.dispatch("getCompanyAccount");
    },
    onCopy(e) {
      const input = document.createElement("textarea");
      input.setAttribute("id", "__mouse__position_input");
      input.value = `
【订单信息】
订单ID：${this.resultContent.order_no}
报考院校：${this.resultContent.apply_college_code}${this.resultContent.apply_college_name
        }
报考专业：${this.resultContent.apply_major_code}${this.resultContent.apply_major_name
        }



【研盒-师资信息】
姓名：${this.resultContent.result.name ? this.resultContent.result.name : "无"}
性别：${this.resultContent.result.gender
          ? this.resultContent.result.gender
          : "无"
        }
手机号：${this.resultContent.result.phone ? this.resultContent.result.phone : "无"
        }
微信号：${this.resultContent.result.wechat
          ? this.resultContent.result.wechat
          : "无"
        }
QQ号：${this.resultContent.result.qq ? this.resultContent.result.qq : "无"}
年级：${this.resultContent.result.apply_year
          ? this.resultContent.result.apply_year
          : "无"
        }
方向：${this.resultContent.result.direction
          ? this.resultContent.result.direction
          : "无"
        }
分数：${this.resultContent.result.fraction
          ? this.resultContent.result.fraction
          : "无"
        }
备注：${this.resultContent.result.remark
          ? this.resultContent.result.remark
          : "无"
        }
      `;
      this.$copyText(input.value).then((e) => {
        this.$notify.success({
          title: "提示",
          message: "复制成功",
          type: "success",
        });
        this.resultVisible = false;
      });
    },
    openBatchEditWatcherDialog() {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.BatchEditWatcherDialog = true
    },
    BatchEditWatcherSubmit(obj) {
      let query = {
        rows: this.chooseList.map((v) => {
          return {
            id: v.id,
            watcher: obj.watcher,
          }
        })
      }
      editteacherorderlist(query).then((res) => {
        this.$nextTick(() => {
          this.getList(this.query);
          this.$notify.success({
            title: "提示",
            message: "修改成功",
          })
          this.BatchEditWatcherDialog = false
        });
      });
    }
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.operation-card {
  width: 100%;
  margin-top: 20px;
  .demo-form-inline {
    height: 64px;
    border-bottom: 2px dashed #d2d5df;
  }
}
.operation-menu {
  display: flex;
  justify-content: space-between;
  .menu_left {
    margin-top: 25px;
  }
  .menu_right {
    margin-top: 25px;
  }
}
.block {
  float: right;
  display: flex;
  justify-content: center;
}
.star {
  width: 18px;
  height: 18px;
}
.templateLink {
  .el-link {
    margin: 0px 5px;
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
}
.item {
  margin-top: 10px;
  margin-right: 40px;
}
.link-template {
  position: relative;
  .red-bradge {
    position: absolute;
    width: 25px;
    height: 13px;
    background: #f56c6c;
    border-radius: 6px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -4px;
    top: 0;
    span {
      font-size: 10px;
      color: #ffffff;
    }
  }
  .el-link {
    margin: 0px 5px;
  }
}
</style>