<template>
  <div class="content">
    <div class="demo-drawer__content">
      <el-form
        :label-position="labelPosition"
        :model="form"
        :inline="true"
        size="small"
      >
        <!--   <el-form-item label="订单状态">
          <el-select
            v-model="form.status"
            placeholder="请选择"
            @change="submit"
            clearable
          >
            <el-option label="待服务" value="1"></el-option>
            <el-option label="正在对接" value="2"></el-option>
            <el-option label="正在匹配" value="3"></el-option>
            <el-option label="匹配成功" value="4"></el-option>
            <el-option label="订单完成" value="5"></el-option>
            <el-option label="订单取消" value="6"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="订单状态">
          <el-select
            v-model="form.match_order_sync_statuses"
            placeholder="请选择"
            @change="submit"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in filters.match_order_sync_statuses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select
            v-model="form.payment_status"
            placeholder="请选择"
            @change="submit"
            clearable
          >
            <el-option
              label="未支付"
              value="0"
            ></el-option>
            <el-option
              label="已支付"
              value="1"
            ></el-option>
            <el-option
              label="已退款"
              value="2"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="创建时间"
          prop="last_advance_time"
        >
          <el-date-picker
            v-model="create_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="submit"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="demo-drawer__footer">
        <!--         <el-button @click="emitClose" class="elbutton" size="small"
          >取 消</el-button
        >
        <el-button type="primary" class="elbutton" size="small">确定</el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      labelPosition: "left",
      create_time: "",
      filters: {
        match_order_sync_statuses: [
          { value: 1, label: '待服务' },
          { value: 2, label: '待对接' },
          { value: 3, label: '匹配中' },
          { value: 4, label: '已完成' },
          { value: 5, label: '已取消' },
        ]
      }
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit() {
      // 创建时间区间处理
      if (this.create_time) {
        this.form.start_create_time = this.create_time[0];
        this.form.end_create_time = this.create_time[1] + 86399000;
      } else {
        this.form.start_create_time = "";
        this.form.end_create_time = "";
      }
      this.$emit("submit", { screenForm: this.form });
    },
  },
};
</script>

<style lang="less" scoped>
.demo-drawer__content {
  padding-top: 24px;
  height: 100%;
}
.el-form {
}
.demo-drawer__footer {
  margin-bottom: 24px;
  float: right;
}
</style>