<template>
  <div class="dialog-wrap">
    <el-dialog
      title="资料选择"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      append-to-body
      width="75%"
    >
      <div>
        <el-form
          class="search-wrap"
          ref="searchForm"
          :inline="true"
          :model="query"
          size="mini"
          label-position="left"
        >
          <el-form-item label="名称/标签">
            <el-input
              v-model.trim="query.name_tags_keyword"
              placeholder="输入名称/标签"
              @input="fetchList(true)"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="年份">
            <el-select
              v-model="query.year"
              placeholder="请选择考研年份"
              clearable
              @change="fetchList(true)"
            >
              <el-option
                label="不限"
                value=""
              ></el-option>
              <el-option
                label="2022年"
                value="2022"
              ></el-option>
              <el-option
                label="2023年"
                value="2023"
              ></el-option>
              <el-option
                label="2024年"
                value="2024"
              ></el-option>
              <el-option
                label="2025年"
                value="2025"
              ></el-option>
              <el-option
                label="2026年"
                value="2026"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="院校/专业">
            <el-input
              v-model.trim="query.college_major_keyword"
              placeholder="输入院校/专业关键字"
              @input="fetchList(true)"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="库存预警线">
            <el-select
              v-model="query.stock_min_line"
              placeholder="请选择"
              clearable
              @input="fetchList(true)"
              style="width:150px;"
            >
              <el-option
                label="低于10"
                value="10"
              ></el-option>
              <el-option
                label="低于30"
                value="30"
              ></el-option>
              <el-option
                label="低于50"
                value="50"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建人">
            <el-select
              v-model="query.creator"
              placeholder="请选择"
              @change="fetchList(true)"
              clearable
            >
              <el-option
                v-for="item in $store.state.staffOptions"
                :key="item.id"
                :label="item.alias"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              class="datetime"
              v-model="createDatetime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="fetchList(true)"
              clearable
              style="width:260px;"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="归档状态">
            <el-select
              v-model="query.archive"
              placeholder="请选择归档状态"
              clearable
              @change="fetchList(true)"
            >
              <el-option
                label="全部"
                value=""
              ></el-option>
              <el-option
                label="已归档"
                value="1"
              ></el-option>
              <el-option
                label="未归档"
                value="0"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="result"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
        ref="multipleTable"
      >
        <el-table-column
          type="selection"
          width="50"
          fixed="left"
          v-if="!single"
        >
        </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="图片"
          width="100"
        >
          <template slot-scope="scope">
            <el-image
              style="max-height:40px;height:40px;padding:-8px 0px;"
              :src="scope.row.picture_url"
              @click="openViewer(scope.row.picture_url)"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span style="font-weight:800;color:#333;">
              《{{scope.row.name}}》
            </span>
          </template>

        </el-table-column>
        <el-table-column
          prop="year"
          label="年份"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.year?scope.row.year+"年":"不限"}}
          </template>
        </el-table-column>
        <el-table-column
          prop="sku"
          label="规格"
          width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="标签"
          width="200"
          show-overflow-tooltip
        >
          <template
            slot-scope="scope"
            v-if="scope.row.tags"
          >
            <el-tag
              type="info"
              size="mini"
              v-for="(item,index) in scope.row.tags.split(',')"
              :key="index"
            >{{item}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="适用范围"
          width="260"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.college_names}}{{scope.row.major_names?','+scope.row.major_names:""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="stock"
          label="当前库存"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="260"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="创建人"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.creator.alias}}
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          width="160"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")}}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120"
          v-if="single"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToChoose(scope.row)"
            >选择商品</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          small
          layout="total, prev, pager, next,sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </div>
    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      :url-list="tableImg"
      :on-close="closeViewer"
      :zIndex="999999999"
    />
  </div>
</template>

<script>


/* 这是单选 *//* 这是单选 *//* 这是单选 *//* 这是单选 *//* 这是单选 *//* 这是单选 */
import { materiallist } from "@/request/api";

// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: {
    ElImageViewer
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => { [] },
    },
    content_single: {
      type: Object,
    },
    single: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      result: this.content,
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        archive: '0'
      },
      createDatetime: "",
      tableImg: [],
      showViewer: false,
      countSelections: [],
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
      if (val) {
        this.query = {
          pageIndex: 1,
          pageSize: 10,
          isPaging: 1,
          archive: '0'

        }
        this.fetchList();
      }
    },
    // 时间数据监听
    createDatetime(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
        this.fetchList(true)
      }
    },
    content(val) {
      this.countSelections = val;

    },
    content_single(val) {
      console.log(val);
    }

  },
  created() {

  },
  computed: {

  },
  methods: {
    // 科目列表请求
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex,  1')
      }
      materiallist(this.query).then((res) => {
        this.result = res.data.list || [];
        this.pageTotal = res.data.count;

        if (!this.single) {
          this.$nextTick(this.setRowSelection);
        }

      });
    },
    // 设置选中行
    setRowSelection() {
      if (this.result.length) {
        this.result.forEach((row) => {
          if (this.content.find((item) => item.id == row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      }
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.fetchList();
    },
    handleClose() {
      this.$emit("close");
    },
    handleToChoose(row) {
      this.$emit('submit', row)
      this.handleClose()
    },
    handleSelectionChange(val) {
      this.selections = val;
      this.diffSelection();
    },
    // 选中项差异化计算
    diffSelection() {
      let diffs = [];
      // 减
      this.countSelections.forEach((item, index) => {
        if (!this.selections.find((v) => v.id === item.id)) {
          if (this.result.find((v2) => v2.id === item.id)) {
            diffs.push(item);
          }
        }
      });
      if (diffs.length) {
        this.countSelections = this.countSelections.filter(
          (item) => !diffs.find((v) => v.id === item.id)
        );
      }
      // 加
      this.selections.forEach((item) => {
        if (!this.countSelections.find((v) => v.id === item.id)) {
          this.countSelections.push(item);
        }
      });
    },
    submit() {
      this.$emit('submit', { data: this.countSelections })
      this.handleClose()
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.tableImg = [url];
      this.showViewer = true;
    },
  }
};
</script>

<style lang="less" scoped>
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
}
.footer {
  margin-top: 16px;
}
</style>
