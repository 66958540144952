<template>
  <div class="dialog-wrap">
    <el-dialog
      title="院校选择"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="980px"
    >
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
      >
        <el-form-item
          label="地区"
          prop="province_code"
        >
          <el-select
            v-model="query.province_code"
            placeholder="请选择"
            size="mini"
          >
            <el-option
              v-for="item in provinces"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="院校名称"
          prop="keyword"
        >
          <el-input
            v-model="query.keyword"
            size="mini"
            placeholder="输入院校名称/代码"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="状态"
          prop="status"
        >
          <el-select
            style="width: 100px"
            v-model="query.status"
            size="mini"
            placeholder="请选择"
          >
            <el-option
              v-for="item in filter.status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            @click="handleSearch"
          >搜索</el-button>
          <el-button
            class="submit"
            size="mini"
            @click="handleFormReset('searchForm')"
          >重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        class="result"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        size="mini"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        > </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="院校名称"
          min-width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="code"
          label="院校代码"
          min-width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="code"
          label="院校标签"
          min-width="160"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.categorys | formateCategorys }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="province_name"
          label="地区"
          min-width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="状态"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">{{
              scope.row.status == 1 ? "正常" : "下线"
            }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          small
          layout="total, prev, pager, next,sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="handleSubmit"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { collegeList } from "@/request/api";
import { adminUrl } from "@/utils/helper.js";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    colleges: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      query: {
        keyword: "",
        college: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 10,
      filter: {
        status: [
          { label: "全部", value: "" },
          { label: "正常", value: "1" },
          { label: "下线", value: "0" },
        ],
      },
      results: [],
      selections: [],
      countSelections: [],
      provinces: [],
    };
  },
  filters: {
    formateCategorys: function (val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;

      if (val) {
        this.fetchCollegeList();
        this.fetchProvinceList();
      }
    },
    colleges(val) {
      this.countSelections = val;
    },
  },
  methods: {
    // 省份列表请求
    fetchProvinceList() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/province/list`,
        method: 'get',//可以省略 不写默认是get
        params: { isPaging: 0 },
      }).then(res => {
        this.provinces = res.data.list
      })
    },
    // 院校列表请求
    fetchCollegeList() {
      let params = { ...this.query };
      collegeList(params).then((res) => {
        this.results = res.data.list || [];
        this.pageTotal = res.data.count;

        this.$nextTick(this.setRowSelection);
      });
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchCollegeList();
    },
    // 设置选中行
    setRowSelection() {
      if (this.results.length) {
        this.results.forEach((row) => {
          if (this.colleges.find((item) => item.id === row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageIndex", 1);
      this.fetchCollegeList();
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields();
      this.fetchCollegeList();
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.fetchCollegeList();
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val;
      this.diffSelection();
    },
    handleSubmit() {
      this.$emit("submit", { colleges: this.countSelections });
      this.handleClose();
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        keyword: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      };
      this.$emit("close");
    },
    // 选中项差异化计算







    
    diffSelection() {
      let diffs = [];
      // 减
      this.countSelections.forEach((item, index) => {
        if (!this.selections.find((v) => v.id === item.id)) {
          if (this.results.find((v2) => v2.id === item.id)) {
            diffs.push(item);
          }
        }
      });
      if (diffs.length) {
        this.countSelections = this.countSelections.filter(
          (item) => !diffs.find((v) => v.id === item.id)
        );
      }
      // 加
      this.selections.forEach((item) => {
        if (!this.countSelections.find((v) => v.id === item.id)) {
          this.countSelections.push(item);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    margin-top: 10px;
  }
}
</style>
