<template>
  <div class="conte">
    <el-dialog
      title="账户充值"
      :visible.sync="visibleDialog"
      width="540px"
      @close="emitClose"
    >
      <el-form
        label-position="left"
        label-width="100px"
        ref="formRules"
      >
        <el-form-item label="充值金额">
          <div class="grid-content">
            <div
              class="grid-item"
              v-for="(item, index) in gridArr"
              :key="item.value"
              :class="{ 'active-item': index == payAmountIndex }"
              @click="chooseAmount(index)"
            >
              <span> {{ item.name }}</span>
              <img
                src="../../../assets/images/5@2x.png"
                alt=""
                srcset=""
                class="gridimg"
                v-show="index == payAmountIndex"
              />
            </div>
          </div>
          <el-input
            v-model.number="amount"
            @input="payAmountIndex = -1"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付方式">
          <div class="paymethod-content">
            <div
              class="paymethod-item"
              v-for="(item, index) in paymethodsArr"
              :key="item.value"
              :class="{ 'active-item': index == payMethodsIndex }"
              @click="chooseMethods(index)"
            >
              <img
                :src="item.img"
                alt=""
                class="payimg"
              />
              <span>{{ item.name }}</span>
              <img
                src="../../../assets/images/5@2x.png"
                alt=""
                srcset=""
                class="gridimg"
                v-show="index == payMethodsIndex"
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <!--  微信支付和支付宝支付 开始 -->
          <div
            class="payqrcode-content"
            v-if="payMethodsIndex >= 0 && payMethodsIndex != 2"
          >
            <div class="payqrcode-title">
              <img
                :src="paymethodsArr[payMethodsIndex].img"
                alt=""
                srcset=""
              />
              <!-- 开始 -->
              <span>请使用{{ paymethodsArr[payMethodsIndex].name }}，支付{{
                  amount || 0
                }}元</span>
              <!-- 结束 -->
            </div>
            <img
              :src="payWechatImg"
              alt=""
              srcset=""
              class="qrcodeimg"
              v-show="payMethodsIndex == 0"
              id="qrcodeimg"
            />
            <iframe
              :src="payAlipayImg"
              class="qrcodeimg"
              v-show="payMethodsIndex == 1"
              scrolling="no"
              frameborder="0"
              id="qrcodeimg"
            ></iframe>
          </div>
          <!--  微信支付和支付宝支付 结束 -->
          <!--  转账 开始 -->
          <div v-if="payMethodsIndex >= 0 && payMethodsIndex == 2">
            <div class="otherpaymethod-content">
              <el-descriptions
                :column="1"
                direction="horizontal"
                id="copyInfo"
                border
              >
                <el-descriptions-item label="账户名称">{{
                  JSON.parse($store.state.bankInfo.value).name
                }}</el-descriptions-item>
                <el-descriptions-item label="银行名称">{{
                  JSON.parse($store.state.bankInfo.value).bank_name
                }}</el-descriptions-item>
                <el-descriptions-item label="卡号">{{
                  JSON.parse($store.state.bankInfo.value).bank_num
                }}</el-descriptions-item>
                <el-descriptions-item label="开户行">{{
                  JSON.parse($store.state.bankInfo.value).bank_address
                }}</el-descriptions-item>
              </el-descriptions>
              <div class="button-content">
                <el-link
                  type="primary"
                  size="mini"
                  @click="copyInfo"
                  :underline="false"
                >复制</el-link>
              </div>
            </div>
            <span class="tipspan">请转账至当前帐户，我们将为您进行充值，3个工作日内到账</span>
          </div>
          <!--  转账 结束 -->
        </el-form-item>
        <!-- 这个也是要判断是不是转账的支付方式-->
        <el-form-item
          label="上传转账记录"
          v-if="payMethodsIndex >= 0 && payMethodsIndex == 2"
        >
          <el-upload
            class="upload-demo"
            action="https://crm-api.kaoyanbox.net/api/v1/upload/image"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-preview="handlePictureCardPreview"
            :limit="1"
            list-type="picture"
          >
            <el-button
              size="small"
              type="primary"
            >点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        v-if="payMethodsIndex == 2"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="TransferAccounts()"
        >确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img
        width="100%"
        :src="dialogImageUrl"
        alt=""
      />
    </el-dialog>
  </div>
</template>

<script>
import { createrechargeorder, pay, paycheck } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      amount: 0,
      visibleDialog: this.isVisible,
      gridArr: [
        {
          name: "1000元",
          value: 1000,
        },
        {
          name: "2000元",
          value: 2000,
        },
        {
          name: "3000元",
          value: 3000,
        },
        {
          name: "5000元",
          value: 5000,
        },
        {
          name: "10000元",
          value: 10000,
        },
        {
          name: "20000元",
          value: 20000,
        },
      ],
      paymethodsArr: [
        {
          // 支付方式：0无，1支付宝，2微信，3余额，4转账',
          img: require("../../../assets/images/wechatpay.png"),
          value: 2,
          name: "微信支付",
        },
        {
          img: require("../../../assets/images/alipay.png"),
          value: 1,
          name: "支付宝支付",
        },
        {
          img: require("../../../assets/images/thirdpay.png"),
          value: 4,
          name: "转账",
        },
      ],
      payAmountIndex: -1,
      payMethodsIndex: -1,
      payWechatImg: "", // 微信二维码
      payAlipayImg: "", // 支付宝二维码
      billInfo: {},
      dialogImageUrl: "",
      dialogVisible: false,
      payment_id: "",
      timer: null,
    };
  },
  watch: {
    isVisible(val) {
      this.visibleDialog = val;
    },
  },
  mounted() { },
  methods: {
    // 图片列表删除
    handleRemove(file, fileList) {
      console.log(fileList);
      this.fileList = fileList;
    },
    // 图片上传成功时放入数组
    handleSuccess(response, file, fileList) {
      console.log(fileList);
      this.fileList = fileList;
    },
    //预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    chooseAmount(index) {
      this.payAmountIndex = index;
      this.amount = this.gridArr[this.payAmountIndex].value;
    },
    chooseMethods(index) {
      if (!this.amount) {
        this.$notify.error({
          title: "提示",
          message: "请选择充值金额",
        });
        return false;
      }
      this.payMethodsIndex = index;
      this.payWechatImg = "";
      this.payAlipayImg = "";
      switch (index) {
        case 0:
          this.wechatAndAlipay();
          break;
        case 1:
          this.wechatAndAlipay();
          break;
        case 2:
          console.log("转账类型");
          break;

        default:
          break;
      }
    },
    // 转账支付（提交申请）
    TransferAccounts() {
      if (!this.fileList) {
        this.$notify.error({
          title: "提示",
          message: "请上传转账截图",
        });
        return false;
      }
      let query = {
        amount: this.amount,
      };
      createrechargeorder(query).then((res) => {
        this.billInfo = res.data;
        let resquery = {
          ref_id: this.billInfo.id,
          pay_type: this.paymethodsArr[this.payMethodsIndex].value,
          ref_type: 1,
          pay_voucher: this.fileList[0].response.data.url,
        };
        pay(resquery).then((res) => {
          this.payment_id = res.data.payment_id;
          this.$emit("close");
        });
      });
    },
    // 微信支付宝支付
    wechatAndAlipay() {
      let query = {
        amount: this.amount,
      };
      const loading = this.$loading({
        lock: true,
        text: '二维码加载中...',
        spinner: 'el-icon-loading',
        background: 'transparent',
      });
      createrechargeorder(query).then((res) => {
        if (res.code == 0) {
          this.billInfo = res.data;
          let resquery = {
            ref_id: this.billInfo.id,
            pay_type: this.paymethodsArr[this.payMethodsIndex].value,
            ref_type: 1,
          };
          pay(resquery).then((res) => {
            this.payment_id = res.data.payment_id;

            switch (this.paymethodsArr[this.payMethodsIndex].value) {
              case 1:
                this.payAlipayImg = res.data.url;
                setTimeout(() => {
                  loading.close();
                }, 600);
                this.IsDone();
                break;
              case 2:
                this.payWechatImg = res.data.qr_url;
                loading.close();
                this.IsDone();
                break;

              default:
                break;
            }
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: "创建订单失败",
          });
        }
      });
    },
    // 每n秒判断支付是否完成
    IsDone() {
      let query = {
        id: this.payment_id,
      };
      paycheck(query).then((res) => {
        if (res.data.pay_status == 1) {
          this.$message.success('支付成功');
          this.emitClose();
        } else {
          this.timer = setTimeout(() => {
            this.IsDone();
          }, 2000);
        }
      });
    },
    emitClose() {
      this.payMethodsIndex = -1; // 重置支付方式选项
      this.payAmountIndex = -1; // 重置金额选择选项
      clearTimeout(this.timer);
      this.timer = null;
      this.$emit("close");
    },
    copyInfo() {
      const input = document.createElement("textarea");
      input.setAttribute("id", "__mouse__position_input");
      input.value = `
【账户信息】
账户名称：${JSON.parse(this.$store.state.bankInfo.value).name
          ? JSON.parse(this.$store.state.bankInfo.value).name
          : "无"
        }
银行名称：${JSON.parse(this.$store.state.bankInfo.value).bank_name
          ? JSON.parse(this.$store.state.bankInfo.value).bank_name
          : "无"
        }
卡号：${JSON.parse(this.$store.state.bankInfo.value).bank_num
          ? JSON.parse(this.$store.state.bankInfo.value).bank_num
          : "无"
        }
开户行：${JSON.parse(this.$store.state.bankInfo.value).bank_address
          ? JSON.parse(this.$store.state.bankInfo.value).bank_address
          : "无"
        }
      `;
      this.$copyText(input.value).then((e) => {
        this.$notify.success({
          title: "提示",
          message: "复制成功",
          type: "success",
        });
        this.resultVisible = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.titlename {
  display: block;
  margin-bottom: 24px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 16px;
  color: #303133;
  opacity: 1;
}
.el-upload {
  width: 68px;
  height: 68px;
}
.grid-content {
  width: 320px;
  height: 71px;
  display: flex;
  flex-wrap: wrap;
  .grid-item {
    position: relative;
    height: 35px;
    width: 104px;
    border: solid 1px #dcdfe6;
    cursor: pointer;
    span {
      display: block;
      margin-left: 12px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #606266;
      opacity: 1;
    }
  }
}
.el-input {
  margin-top: 18px;
  width: 200px;
}
.gridimg {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.paymethod-content {
  height: 40px;
  display: flex;
  .paymethod-item {
    border: solid 1px #dcdfe6;
    height: 36px;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 8px;
    cursor: pointer;
    .payimg {
      width: 18px;
      height: 18px;
      margin-left: 12px;
      margin-right: 8px;
    }
    span {
      display: block;
      margin-right: 16px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 16px;
      color: #606266;
      opacity: 1;
    }
  }
}
.active-item {
  border: solid 1px #67c23a !important;
}
.payqrcode-content {
  height: 200px;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .payqrcode-title {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 18px;
      height: 18px;
      margin-left: 12px;
      margin-right: 8px;
    }
    span {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 16px;
      color: #606266;
      opacity: 1;
    }
  }
  .qrcodeimg {
    width: 160px;
    height: 160px;
  }
}
.otherpaymethod-content {
  width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  opacity: 1;
  border-radius: 4px;
  .button-content {
    margin-right: 18px;
  }
}
.tipspan {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 16px;
  color: #f56c6c;
  opacity: 1;
}
.el-descriptions {
  width: 100%;
  margin-top: 18px;
}
</style>