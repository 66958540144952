import TestMangent from "@/views/testPaper/testMangent/index.vue";
import TestDetail from "@/views/testPaper/testDetail/index.vue";
import SubmitCorrect from "@/views/testPaper/submitCorrect/index.vue";
export default [
    /*** 测试卷 ***/
    {
        path: '/testPaper/testMangent',
        component: TestMangent,
        meta: { title: '提交管理' }
  },
   {
        path: '/testPaper/testDetail/:id',
        component: TestDetail,
        meta: { title: '考试详情' }
  },
   {
        path: '/testPaper/submitCorrect/:id',
        component: SubmitCorrect,
        meta: { title: '提交批改' }
    },
]