<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>设置</el-breadcrumb-item>
        <el-breadcrumb-item>签单开票</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 10px">
      <TittleText :tittle="titleArr[1]"></TittleText>
      <div class="item-div">
        <div
          class="invoice-header"
          v-for="item in taxnoList"
          :key="item.id"
        >
          <div class="herder-left">
            <span>公司名称：</span>
            <span>税号：</span>
          </div>
          <div class="herder-middle">
            <span>{{ item.company_name }}</span>
            <span>{{ item.company_tax_no }}</span>
          </div>
          <img
            src="../../../assets/images/22@2x.png"
            alt=""
            srcset=""
            @click="deleteInvoice(item)"
          />
        </div>
      </div>
      <el-button
        type="primary"
        class="item-button"
        size="mini"
        @click="dialogFormVisible = true"
      >添加发票抬头</el-button>
    </el-card>
    <el-card style="margin-top: 10px">
      <TittleText :tittle="titleArr[2]"></TittleText>
      <el-form :inline="true">
        <el-switch
          v-model="settingform.audit_type"
          active-text="直接通过"
          inactive-text="需要审批"
          :active-value="0"
          :inactive-value="1"
          @change="switchChange"
        >
        </el-switch>
        <!--         <el-form-item>
          <el-radio
            v-model="settingform.audit_type"
            :label="0"
            @change="radioChange"
            >直接通过</el-radio
          >
        </el-form-item>
        <el-form-item style="margin-left: 64px">
          <el-radio
            v-model="settingform.audit_type"
            :label="1"
            @change="radioChange"
            >指定角色审批</el-radio
          >
          <el-select
            v-model="settingform.audit_roles"
            multiple
            collapse-tags
            placeholder="请选择"
            style="width: 284px"
            @change="selectChangeRoles"
          >
            <el-option
              v-for="item in $store.state.roleOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-radio
            v-model="settingform.audit_type"
            :label="2"
            @change="radioChange"
            >指定人审批</el-radio
          >
          <el-select
            v-model="settingform.audit_uids"
            multiple
            collapse-tags
            placeholder="请选择"
            style="width: 284px"
            @change="selectChangeUids"
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.uid"
              :label="item.alias"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
    </el-card>
    <el-dialog
      title="添加发票抬头"
      :visible.sync="dialogFormVisible"
      width="480px"
    >
      <el-form
        :model="form"
        label-position="right"
        label-width="100px"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item
          label="公司名称"
          prop="company_name"
        >
          <el-input v-model="form.company_name"></el-input>
        </el-form-item>
        <el-form-item
          label="税号"
          prop="company_tax_no"
        >
          <el-input v-model="form.company_tax_no"></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TittleText from "../../../components/TableTittle/TableTittle.vue";
import {
  createcompanytaxno,
  companytaxnolist,
  editcompanysetting,
  deletecompanytaxno,
} from "../../../request/api";
export default {
  components: {
    TittleText,
  },
  data() {
    return {
      titleArr: ["课程目录", "发票抬头", "指定审批人"],
      pageTotal: 0,
      subjectsDialog: false,
      subjectsForm: {},
      dialogFormVisible: false,
      form: {},
      taxnoList: [],
      rules: {
        company_name: [
          { required: true, message: "请输入抬头名称", trigger: "blur" },
        ],
        company_tax_no: [
          { required: true, message: "请输入公司税号", trigger: "blur" },
        ],
      },
      text: "直接通过",
      settingform: {

      },
    };
  },
  mounted() {
    this.getList();
    this.getAllOptions();

    this.$store.dispatch("getCompanysetting");
    setTimeout(() => {
      this.getCompanysetting();
    }, 20);
  },
  methods: {
    getAllOptions() {
      this.$store.dispatch("getOptions");
    },
    getCompanysetting() {

      if (this.$store.state.settingInfo) {
        this.settingform = this.$store.state.settingInfo;
        if (this.settingform.audit_uids) {
          this.settingform.audit_uids = this.settingform.audit_uids
            .split(",")
            .map((v) => Number(v));
        } else {
          this.settingform.audit_uids = "";
        }
        if (this.settingform.audit_roles) {
          this.settingform.audit_roles = this.settingform.audit_roles
            .split(",")
            .map((v) => Number(v));
        } else {
          this.settingform.audit_roles = "";
        }

      }
      this.$forceUpdate()
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = this.form;
          createcompanytaxno(query).then((res) => {
            this.$refs[rules].resetFields();
            this.getList();
            this.dialogFormVisible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getList() {
      let query = {
        isPaging: 0,
      };
      companytaxnolist(query).then((res) => {
        this.taxnoList = res.data.list || [];
      });
    },
    radioChange(e) {
      let query = {
        audit_type: this.settingform.audit_type,
      };
      editcompanysetting(query).then((res) => {
        this.$store.dispatch("getCompanysetting");
      });
    },
    switchChange(e) {
      let query = {
        audit_type: e,
      };
      editcompanysetting(query).then((res) => {
        this.$store.dispatch("getCompanysetting");
      });
    },
    selectChangeRoles(e) {
      let query = {
        audit_roles: e.join(","),
      };
      editcompanysetting(query).then((res) => {
        this.$store.dispatch("getCompanysetting");
      });
    },
    selectChangeUids(e) {
      let query = {
        audit_uids: e.join(","),
      };
      editcompanysetting(query).then((res) => {
        this.$store.dispatch("getCompanysetting");
      });
    },
    // 删除发票抬头
    deleteInvoice(item) {
      console.log(item);
      let query = {
        id: item.id,
      };
      deletecompanytaxno(query).then((res) => {
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 35px;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.item-div {
  min-height: 80px;
  display: flex;
  flex-wrap: wrap;
}
.item-button {
  margin: 12px;
  float: right;
}
.invoice-header {
  height: 64px;
  min-width: 267px;
  background: #ebeef5;
  opacity: 1;
  border-radius: 4px;
  margin: 20px;
  display: flex;
  align-items: center;
  img {
    width: 8px;
    height: 8px;
    margin: 0px 16px;
  }
  span {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #303133;
    opacity: 1;
  }
}
.herder-left {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  margin-left: 12px;
}
.herder-middle {
  min-width: 180px;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.card-header {
  display: flex;
  justify-content: space-between;
  .el-button {
    height: 30px;
    line-height: 5px;
  }
}
</style>