<template>
  <div class="dialog-wrap">
    <!--     <el-dialog
      title="科目选择"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="980px"
    >
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
      >
        <el-form-item label="部门">
          <el-select
            v-model="query.dept"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
          >
            <el-option
              v-for="item in $store.state.DeptList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色">
          <el-select
            v-model="query.role"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
          >
            <el-option
              v-for="item in $store.state.roleOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table
        class="result"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        size="mini"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        > </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="alias"
          label="姓名"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
        > </el-table-column>
        <el-table-column
          prop="depts"
          label="部门"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.depts | formate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="roles"
          label="角色"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.roles | formateroles }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建日期"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="120"
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              :type="scope.row.status == 1 ? 'success' : 'danger'"
            >{{ scope.row.status == 1 ? "正常" : "下线" }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          small
          layout="total, prev, pager, next,sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="handleSubmit"
        >确 定</el-button>
      </div>
    </el-dialog> -->
    <el-popover
      placement="left"
      width="400"
      trigger="click"
      @show="popoverShow"
      v-model="dialogVisible"
    >
      <el-table
        :data="results"
        ref="multipleTable"
        highlight-current-row
        @current-change="handleCurrentChange"
      >
        <el-table-column
          width="100"
          property="alias"
          label="姓名"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="roles"
          label="角色"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.roles | formateroles }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div
        slot="reference"
        class="btn"
      >@ 提醒谁关注</div>
    </el-popover>
  </div>
</template>

<script>

import { companyuserlist } from "@/request/api"

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    content_array: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      query: {
        subject: "",
        college: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 10,
      filter: {
        status: [
          { label: "全部", value: "" },
          { label: "正常", value: "1" },
          { label: "下线", value: "0" },
        ],
        types: [
          { label: "全部", value: "" },
          { label: "统考", value: "1" },
          { label: "非统考", value: "0" },
        ],
      },
      results: [],
      selections: [],
      countSelections: [],
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
    },
    content_array(val) {

      this.countSelections = val;
    },
  },
  filters: {
    formate: function (val) {
      var str = "";
      val.forEach((item) => {
        str += item.name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return str;
      }
    },
    formateroles: function (val) {
      var str = "";
      val.forEach((item) => {
        str += item.name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return str;
      }
    },
  },
  mounted() {

  },
  methods: {
    popoverShow() {
      this.fetchList();
      this.$store.dispatch("getRoleList");
    },
    // 科目列表请求
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const param = Object.assign({}, this.query)
      companyuserlist(param).then((res) => {
        this.results = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    // 设置选中行
    setRowSelection() {
      if (this.results.length) {
        this.results.forEach((row) => {
          if (this.content_array.find((item) => item.id === row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageIndex", 1);
      this.fetchList();
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields();
      this.fetchList();
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.fetchList();
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val;
      setTimeout(() => {
        this.diffSelection();
      }, 500);
    },
    handleSubmit() {
      this.$emit("submit", { data: this.countSelections });
      this.handleClose();
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        subject: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      };
      this.$emit("close");
    },
    // 选中项差异化计算
    diffSelection() {
      let diffs = [];
      // 减
      this.countSelections.forEach((item, index) => {
        if (!this.selections.find((v) => v.id === item.id)) {
          if (this.results.find((v2) => v2.id === item.id)) {
            diffs.push(item);
          }
        }
      });
      if (diffs.length) {
        this.countSelections = this.countSelections.filter(
          (item) => !diffs.find((v) => v.id === item.id)
        );
      }
      // 加
      this.selections.forEach((item) => {
        if (!this.countSelections.find((v) => v.id === item.id)) {
          this.countSelections.push(item);
        }
      });
      this.$emit("submit", { data: this.countSelections });
    },
    handleCurrentChange(val) {
      if (val) {
        this.$emit("submit", { data: val });
        this.dialogVisible = false
      }
    }
  },
};
</script>

<style lang="less" scoped>
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    margin-top: 10px;
  }
}
.btn {
  margin-right: 16px;
  padding: 6px 12px;
  border: 1px solid #dcdfe6;
  cursor: pointer;
}
</style>
