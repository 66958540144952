<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料</el-breadcrumb-item>
        <el-breadcrumb-item>学员列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button icon="el-icon-download" @click="handleExport" size="small"
          >导出</el-button
        >
        <el-button type="primary" size="small" @click="handleToCreate"
          >新建</el-button
        >
      </div>
    </div>
    <el-card style="margin-top: 20px; padding-top: 20px">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="学员信息">
          <el-input
            v-model.trim="query.user_keyword"
            placeholder="手机号/姓名"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="报名班次">
          <el-select
            v-model="query.product_ids"
            placeholder="请选择"
            @change="getList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in $store.state.productOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 
        <el-form-item label="课程状态">
          <el-select
            v-model="query.course_status"
            placeholder="请选择"
            @change="getList(true)"
            clearable
          >
            <el-option
              v-for="(item,index) in filters.course_status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="课程状态">
          <el-select
            v-model="query.student_sync_statuses"
            placeholder="请选择"
            @change="getList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item, index) in filters.student_sync_statuses"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务科目">
          <el-select
            v-model="query.service_subject_ids"
            placeholder="请选择"
            @change="getList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item, index) in $store.state.servicesubjectOptions"
              :key="index"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="院校">
          <el-input
            v-model.trim="query.college_keyword"
            placeholder="输入院校"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="专业">
          <el-input
            v-model.trim="query.major_keyword"
            placeholder="输入专业"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="咨询老师">
          <el-select
            v-model="query.consult_teacher_id"
            placeholder="请选择咨询老师"
            @change="getList(true)"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="(item, index) in $store.state.staffOptions"
              :key="index"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品经理">
          <el-select
            v-model="query.product_watchers"
            placeholder="请选择咨询老师"
            @change="getList(true)"
            style="width: 100%"
            clearable
            multiple
          >
            <el-option
              v-for="(item, index) in $store.state.staffOptions"
              :key="index"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!--  <el-input
            v-model.trim="query.product_watchers"
            placeholder="输入产品经理"
            @input="getList(true)"
            clearable
          ></el-input> -->
        </el-form-item>
        <el-form-item label="报名时间">
          <el-date-picker
            class="datetime"
            v-model="apply_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList(true)"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="地址更新时间">
          <el-date-picker
            class="datetime"
            v-model="receiver_update_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList(true)"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="年份">
          <el-select
            v-model="query.apply_year"
            placeholder="请选择考研年份"
            clearable
            @change="getList(true)"
            style="width: 150px"
          >
            <el-option label="不限" value=""></el-option>
            <el-option label="2022年" value="2022"></el-option>
            <el-option label="2023年" value="2023"></el-option>
            <el-option label="2024年" value="2024"></el-option>
            <el-option label="2025年" value="2025"></el-option>
            <el-option label="2026年" value="2026"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
          style="margin-bottom: 0px; display: flex"
          label-position="right"
        >
          <el-form-item label="只看关注">
            <el-switch
              v-model="query.follow"
              :active-value="1"
              :inactive-value="0"
              @change="getList(query)"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label-width="32px">
            <el-radio-group
              v-model="query.basic_archive"
              @change="getList(query)"
            >
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button label="1">已归档</el-radio-button>
              <el-radio-button label="0">未归档</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" fixed="left">
        </el-table-column>
        <el-table-column prop="date" label="关注" width="50" fixed="left">
          <template slot-scope="scope">
            <img
              alt=""
              srcset=""
              class="star"
              src="../../../assets/images/star-fill.png"
              @click="followDelete(scope.row)"
              v-show="scope.row.follow"
            />
            <img
              src="../../../assets/images/star.png"
              alt=""
              srcset=""
              class="star"
              v-show="!scope.row.follow"
              @click="followCreate(scope.row)"
            />
          </template>
        </el-table-column>
        <!--         <el-table-column
          prop="id"
          label="ID"
          width="50"
          fixed="left"
        ></el-table-column> -->
        <el-table-column fixed="left" label="课程安排" width="200">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToService(scope.row)"
              >服务</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              @click="handleToConfirm(scope.row)"
              >定校</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              @click="handleToDeliver(scope.row)"
              >资料</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              @click="handleToCalendar(scope.row)"
              >课程表</el-link
            >
          </template>
        </el-table-column>
        <el-table-column label="报名时间" width="100">
          <template slot-scope="scope">
            {{
              scope.row.apply_time
                ? $moment(scope.row.apply_time).format("YYYY-MM-DD")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="apply_year" label="考研年份" width="80">
          <template slot-scope="scope"> {{ scope.row.apply_year }}年 </template>
        </el-table-column>
        <el-table-column
          prop="realname"
          label="学生姓名"
          width="130"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="课程状态" width="130" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag
              size="small"
              v-if="scope.row.student_sync_status == 1"
              type="warning"
              >待对接</el-tag
            >
            <el-tag
              size="small"
              v-if="scope.row.student_sync_status == 2"
              type="warning"
              >待分配</el-tag
            >
            <el-tag size="small" v-if="scope.row.student_sync_status == 3"
              >匹配中</el-tag
            >
            <el-tag size="small" v-if="scope.row.student_sync_status == 4"
              >上课中</el-tag
            >
            <el-tag
              size="small"
              v-if="scope.row.student_sync_status == 5"
              type="info"
              >已终止</el-tag
            >
            <el-tag
              size="small"
              v-if="scope.row.student_sync_status == 6"
              type="danger"
              >已结束</el-tag
            >
            <!--   <el-tag
              v-if="scope.row.course_status==1"
              size="mini"
              @click="handleToChange(scope.row)"
            >等待上课</el-tag>
            <el-tag
              type="success"
              size="mini"
              v-else-if="scope.row.course_status==2"
              @click="handleToChange(scope.row)"
            >正在上课</el-tag>
            <el-tag
              type="info"
              size="mini"
              v-else-if="scope.row.course_status==3"
              @click="handleToChange(scope.row)"
            >课程结束</el-tag>
            <el-tag
              type="danger"
              size="mini"
              v-else-if="scope.row.course_status==4"
              @click="handleToChange(scope.row)"
            >课程终止</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="product_names"
          label="报名班次"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.product_names || "无" }}
          </template>
        </el-table-column>
        <el-table-column label="实收金额" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.receive_amount || 0 }}元
          </template>
        </el-table-column>
        <el-table-column
          prop="coach_need"
          label="基本情况"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="服务科目" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.service_subject_names
                ? scope.row.service_subject_names
                : "暂无服务科目"
            }}
          </template>
        </el-table-column>
        <el-table-column label="定校情况" width="300" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ collegeFormate(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="考试方向" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.apply_direction_code && scope.row.apply_direction_name
              "
              >（{{ scope.row.apply_direction_code }}）{{
                scope.row.apply_direction_name
              }}</span
            >
          </template>
        </el-table-column>
        <!--        <el-table-column
          prop="refer_book"
          label="参考书目"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <el-table-column
          prop="remark"
          label="其他备注"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="receiver_address"
          label="收货地址"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.receiver_area + scope.row.receiver_address || "无" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="consult_teacher"
          label="咨询老师"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{
              scope.row.consult_teacher_obj
                ? scope.row.consult_teacher_obj.alias
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="watcher"
          label="教务管理"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ product_watchersFormate(scope.row.watcher) }}
          </template>
        </el-table-column>
        <el-table-column label="产品经理" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ product_watchersFormate(scope.row.product_watchers) }}
          </template>
        </el-table-column>
        <el-table-column label="最后联系时间" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.last_concat_time
                ? $moment(scope.row.last_concat_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="地址更新时间" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.receiver_update_time
                ? $moment(scope.row.receiver_update_time).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column label="修改时间" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToEdit(scope.row.id)"
              >编辑</el-link
            >
            <el-link
              type="danger
            "
              @click="handleToRemove(scope.row.id)"
              :underline="false"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button
          size="mini"
          type="primary"
          @click="chooseAllEdit(1, '您确定要归档吗')"
          v-show="query.basic_archive == 0 && query.basic_archive != ''"
          >归档</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="chooseAllEdit(0, '您确定要取消归档吗')"
          v-show="query.basic_archive == 1"
          >取消归档</el-button
        >
        <el-button size="mini" type="danger" @click="handleToDeleteAll()"
          >批量删除</el-button
        >
        <el-button size="mini" type="primary" @click="openCourse"
          >开通课程</el-button
        >
        <el-button size="mini" type="primary" @click="openAppCourse"
          >开通App课程</el-button
        >
        <div class="block">
          <span class="total"
            >共{{ pageTotal }}条，共{{ sum_receive_amount }}元</span
          >
          <el-pagination
            background
            layout="prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog
      title="开通课程"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="620px"
      @close="dialogFormVisible = false"
    >
      <el-form
        :inline="true"
        size="small"
        label-position="left"
        ref="formRules"
        :model="companyForm"
        :rules="rules"
      >
        <el-form-item label="开通课程" prop="company_normal_course_ids">
          <el-select
            v-model="companyForm.company_normal_course_ids"
            placeholder="请选择课程"
            clearable
            style="width: 100%"
            multiple
          >
            <el-option
              v-for="(item, index) in $store.state.normalCourseList"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="courseSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <ConfirmCollege
      :isVisible="confirmDialog"
      :content="confirmContent"
      @close="confirmDialog = false"
      @submit="getList()"
    />
    <ChangeStatus
      :isVisible="changeDialog"
      :content="changeContent"
      @close="changeDialog = false"
      @submit="getList()"
    />
    <Service
      :isVisible="serviceDialog"
      :content="serviceContent"
      @close="serviceDialog = false"
      @submit="getList()"
    />
    <OpenAppCourse ref="openAppCourse" />
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";

import ConfirmCollege from "@/components/DialogComponents/DataInventoryStudent/ConfirmCollege.vue";
import ChangeStatus from "@/components/DialogComponents/DataInventoryStudent/ChangeStatus.vue";
import Service from "@/components/DialogComponents/DataInventoryStudent/Service.vue";
import OpenAppCourse from "../../../components/DialogComponents/OpenAppCourse/index.vue";
import {
  studentBasiclist,
  studentBasicDelete,
  studentBasiclistDelete,
  editstudentBasiclist,
  createuserfollow,
  deleteuserfollow,
  studentBasicExport,
  studentBasicListPut,
} from "@/request/api";
export default {
  components: {
    TableTittle,
    ConfirmCollege,
    ChangeStatus,
    Service,
    OpenAppCourse,
  },
  data() {
    return {
      dialogFormVisible: false,
      companyForm: {
        company_normal_course_ids: [],
      },
      rules: {
        company_normal_course_ids: [
          { required: true, message: "请选择开通课程", trigger: "blur" },
        ],
      },
      TableName: "学员列表",
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        basic_archive: "0",
      },
      pageTotal: 0,
      tableData: [],
      confirmDialog: false,
      confirmContent: {},
      changeContent: {},
      changeDialog: false,
      serviceDialog: false,
      serviceContent: {},
      filters: {
        course_status: [
          { label: "等待上课", value: 1 },
          { label: "正在上课", value: 2 },
          { label: "课程结束", value: 3 },
          { label: "课程终止", value: 4 },
        ],
        student_sync_statuses: [
          { label: "待对接", value: 1 },
          { label: "待分配", value: 2 },
          { label: "匹配中", value: 3 },
          { label: "上课中", value: 4 },
          { label: "已终止", value: 5 },
          { label: "已结束", value: 6 },
        ],
      },
      receiver_update_time: "",
      apply_time: "",
      chooseList: [],
      lock: true,
      sum_receive_amount: 0,
    };
  },
  watch: {
    // 时间数据监听
    receiver_update_time(value) {
      if (value) {
        this.query.start_receiver_update_time = this.$moment(
          value[0]
        ).valueOf();
        this.query.end_receiver_update_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_receiver_update_time = "";
        this.query.end_receiver_update_time = "";
        this.getList(true);
      }
    },
    // 时间数据监听
    apply_time(value) {
      if (value) {
        this.query.start_apply_time = this.$moment(value[0]).valueOf();
        this.query.end_apply_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_apply_time = "";
        this.query.end_apply_time = "";
        this.getList(true);
      }
    },
  },
  filters: {},
  mounted() {
    // this.parseQuery()
    this.$store.dispatch("getAllColleges");
    this.$store.dispatch("getStaffList");
    this.$store.dispatch("fetchCityList");
    this.$store.dispatch("getProducts");
    this.$store.dispatch("getServiceSubjectDictionary");
    this.$store.dispatch("fetchNormalCourse");
    this.getList();
  },
  methods: {
    openAppCourse() {
      // console.log("this.chooseList:", this.chooseList);
      let regularExpression = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
      if (this.chooseList.length == 0) {
        this.$message.error("还没有选择要开通App课程的数据");
        return;
      }
      for (let i = 0; i < this.chooseList.length; i++) {
        if (!this.chooseList[i].phone) {
          this.$message.error("学生没有手机号");
          return;
        }
        if (
          this.chooseList[i].phone &&
          !regularExpression.test(this.chooseList[i].phone)
        ) {
          this.$message.error("学生手机号错误");
          return;
        }
      }

      this.$refs.openAppCourse.open(this.chooseList);
    },
    courseSubmit() {
      this.$refs["formRules"].validate((valid) => {
        if (valid) {
          let formList = [];
          this.chooseList.map((item) => {
            formList.push({
              id: item.id,
              company_normal_course_ids:
                this.companyForm.company_normal_course_ids.toString(),
            });
          });
          // return
          studentBasicListPut({
            rows: formList,
          }).then((res) => {
            if (res.code == 0) {
              this.dialogFormVisible = false;
              this.$message.success("开通成功!");
              this.getList();
            }
            // console.log("Re",res)
          });
          // console.log("通过")
        }
      });
    },
    openCourse() {
      if (this.chooseList.length == 0) {
        this.$message.error("还没有选择要开通课程的数据");
        return;
      }
      this.dialogFormVisible = true;
      // this.$confirm("确定要删除这些信息吗", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   let ids = this.chooseList.map(v => { return v.id })
      //   studentBasiclistDelete({ ids }).then((res) => {
      //     this.getList();
      //   });
      // })
    },
    handleToCalendar(row) {
      this.$router.push(`/product/calendar?student_id=${row.id}`);
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList();
    },
    getList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      if (params.product_ids) {
        params.product_ids = params.product_ids.join(",");
      }
      if (params.service_subject_ids) {
        params.service_subject_ids = params.service_subject_ids.join(",");
      }
      if (params.product_watchers) {
        params.product_watchers = params.product_watchers.join(",");
      }
      if (["暂未定校", "未定校", "无"].includes(params.college_keyword)) {
        params.college_keyword = "null";
      }
      if (
        ["暂未确定", "未确定", "无"].includes(params.service_subjects_keyword)
      ) {
        params.service_subjects_keyword = "null";
      }
      if (params.student_sync_statuses) {
        params.student_sync_statuses = params.student_sync_statuses.join(",");
      }
      studentBasiclist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
        this.sum_receive_amount = res.data.sum_receive_amount;
      });
      this.syncQuery();
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      if (params.product_ids) {
        params.product_ids = params.product_ids.filter((v) => v).join(",");
      }
      if (params.service_subject_ids) {
        params.service_subject_ids = params.service_subject_ids
          .filter((v) => v)
          .join(",");
      }
      if (params.student_sync_statuses) {
        params.student_sync_statuses = params.student_sync_statuses.join(",");
      }
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
      if (this.query.product_ids && this.query.product_ids.length) {
        this.query.product_ids = this.query.product_ids.split(",").map((v) => {
          return Number(v);
        });
      }
      if (
        this.query.service_subject_ids &&
        this.query.service_subject_ids.length
      ) {
        this.query.service_subject_ids = this.query.service_subject_ids
          .split(",")
          .map((v) => {
            return Number(v);
          });
      }
      if (
        this.query.student_sync_statuses &&
        this.query.student_sync_statuses.length
      ) {
        this.query.student_sync_statuses = this.query.student_sync_statuses
          .split(",")
          .map((v) => {
            return Number(v);
          });
      }
      this.query.follow = Number(this.query.follow);
      this.query.pageIndex = Number(this.query.pageIndex);
      if (this.query.start_apply_time && this.query.end_apply_time) {
        this.createDatetime = [
          this.$moment(Number(this.query.start_apply_time)),
          this.$moment(Number(this.query.end_apply_time)),
        ];
      }
    },
    handleToCreate() {
      this.$router.push("/dataInventory/student/create");
    },
    service_subjectsFormate(value) {
      if (value && value.length) {
        let val = JSON.parse(value);
        let map_val = val.map((v) => {
          return `（${v.subject_code}）${v.subject_name}`;
        });
        return map_val.join(",");
      } else {
        return "暂无服务科目";
      }
    },
    consult_teacherFormate(value) {
      if (value) {
        return value.alias;
      } else {
        return "无";
      }
    },
    collegeFormate(row) {
      if (row.is_choose) {
        return `${row.apply_college_name},（${row.apply_major_code}）${row.apply_major_name}`;
      } else {
        return "暂无定校";
      }
    },
    product_watchersFormate(value) {
      if (value && value.length) {
        let map_val = value.map((v) => {
          return v.alias;
        });
        return map_val.join(",");
      } else {
        return "无";
      }
    },
    handleToEdit(id) {
      this.$router.push(`/dataInventory/student/edit/${id}`);
    },
    handleToRemove(id) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        studentBasicDelete({ id }).then((res) => {
          this.$nextTick(() => {
            this.getList();
          });
        });
      });
    },
    handleToConfirm(row) {
      this.confirmContent = Object.assign(row, {});
      this.confirmDialog = true;
    },
    handleToService(row) {
      this.serviceContent = Object.assign(row, {});
      this.serviceDialog = true;
    },
    handleToDeliver(row) {
      window.open(`#/dataInventory/student/deliverlist?student_id=${row.id}&student_name=${row.realname}`)
      // this.$router.push(
      //   `/dataInventory/student/deliverlist?student_id=${row.id}&student_name=${row.realname}`
      // );
    },
    handleToChange(row) {
      this.changeContent = Object.assign({}, row);
      this.changeDialog = true;
    },
    handleSelectionChange(val) {
      this.chooseList = val;
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error("还没有选择要删除的数据");
        return;
      }
      this.$confirm("确定要删除这些信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map((v) => {
          return v.id;
        });
        studentBasiclistDelete({ ids }).then((res) => {
          this.getList();
        });
      });
    },
    chooseAllEdit(archive_status, text) {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            rows: [],
          };
          this.chooseList.forEach((item) => {
            let obj = {
              id: item.id,
              basic_archive: archive_status,
            };
            query.rows.push(obj);
          });
          editstudentBasiclist(query).then((res) => {
            this.$nextTick(() => {
              this.getList();
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 1,
          follow_id: item.id,
        };
        createuserfollow(query).then((res) => {
          this.getList();
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 500);
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 1,
          follow_id: item.id,
        };
        deleteuserfollow(query).then((res) => {
          this.getList();
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 300);
      }
    },
    handleExport() {
      this.$confirm("是否导出数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        studentBasicExport(this.query).then((res) => {
          if (res.code == 0) {
            this.$message.success("导出成功");
            //创建a标签用于跳转至下载链接
            var str = res.data.url.replace(/http:/, window.location.protocol);
            this.$store.commit("downLoad", str);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}

.card-header {
  display: flex;
  justify-content: space-between;

  .el-button {
    height: 35px;
    line-height: 10px;
  }
}

.block {
  display: flex;
  margin-bottom: 14px;
  float: right;
  align-items: center;

  .total {
    font-size: 14px;
    margin-right: 8px;
    color: #666;
  }
}

.el-link {
  margin: 0px 5px;
}

.el-input,
.el-select {
  width: 200px;
}
</style>