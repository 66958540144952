<template>
  <div>
    <el-dialog
      title="教研服务"
      :visible.sync="dialogFormVisible"
      width="630px"
      @close="emitClose"
      append-to-body
    >
      <el-form
        :model="dataForm"
        label-position="left"
        ref="formRules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="服务科目："
          prop="service_subject_id"
        >
          <el-select
            v-model="dataForm.service_subject_id"
            placeholder="请选择服务科目"
            clearable
            style="width:100%;"
          >
            <el-option
              v-for="(item) in $store.state.servicesubjectOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="服务类型："
          prop="service_type_id"
        >
          <el-select
            v-model="dataForm.service_type_id"
            placeholder="请选择服务类型"
            clearable
            style="width:100%;"
          >
            <el-option
              v-for="(item) in $store.state.servicetypeOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="message-wrap">
          <div
            class="user-list"
            v-if="staffs.length"
          >{{staffs.map(v=>{ return `@${v.alias}` }).join(' , ')}}</div>
          <el-input
            v-model="dataForm.content_text"
            placeholder="请输入内容"
            type="textarea"
          ></el-input>
        </div>
        <!--         <div class="btn-wrap">
          <div
            class="btn"
            @click="chooseDialog=true"
          >@ 提醒谁关注</div>
          <el-upload
            class="upload-demo"
            :action="imgUploadUrl"
            multiple
            :on-success="handleImageSuccess"
            :limit="3"
            accept=".png,.jpg,.jpeg"
            :file-list="imageList"
          >
            <div class="btn"><i class="el-icon-link"></i> 图片</div>
          </el-upload>
          <el-upload
            class="upload-demo"
            :action="imgUploadUrl"
            multiple
            :on-success="handleFileSuccess"
            :limit="3"
            :file-list="fileList"
            accept=".pdf,.doc,.xlsx"
          >
            <div class="btn"><i class="el-icon-camera"></i> 附件</div>
          </el-upload>
        </div> -->
        <div
          class="line"
          v-if="imageList.length"
        ></div>
        <div class="image-wrap">
          <div
            class="image-item"
            v-for="(item,index) in imageList"
            :key="index"
          >
            <img
              :src="item.url"
              alt=""
              srcset=""
              class=""
              @click="openViewer(item.url)"
            >
            <!--   <img
              src="@/assets/images/close.png"
              alt=""
              srcset=""
              class="close"
              @click="handleImageRemove(index)"
            > -->
          </div>
        </div>
        <div
          class="file-wrap"
          v-if="fileList.length"
        >
          <div class="line"></div>
          <div
            class="file-item"
            v-for="(item,index) in fileList"
            :key="index"
          >
            <div class="left">
              <img
                src="@/assets/images/word_icon.png"
                alt=""
                srcset=""
                v-if="item.name.indexOf('.doc') != -1"
              >
              <img
                src="@/assets/images/pdf_icon.png"
                alt=""
                srcset=""
                v-if="item.name.indexOf('.pdf') != -1"
              >
              <img
                src="@/assets/images/excle_icon.png"
                alt=""
                srcset=""
                v-if="item.name.indexOf('.xlsx') != -1"
              >
              <span>{{item.name}}</span>
            </div>
            <div class="right">
              <div
                class="btn"
                @click="handleToPreview(item)"
              >预览</div>
              <!--     <img
                src="@/assets/images/close.png"
                alt=""
                srcset=""
                class="close"
                @click="handleFileRemove(index)"
              > -->
            </div>
          </div>
          <div class="line"></div>
        </div>
        <el-form-item label="记录人：">
          <div>
            {{dataForm.teacher?dataForm.teacher.alias:""}}
          </div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="emitClose"
        >确 定</el-button>
      </div>

    </el-dialog>
    <EmployeeChoose
      :visible="chooseDialog"
      :content_array="staffs"
      @close="chooseDialog=false"
      @submit="saveStaff"
    />
    <el-image-viewer
      v-if="showViewer"
      :url-list="tableImg"
      :on-close="closeViewer"
      style="z-index:9999;"
    />
  </div>
</template>

<script>


import { imgUploadUrl, fileUploadUrl } from "@/request/http";
import EmployeeChoose from "@/components/DialogComponents/Employee/EmployeeChoose"
import { editteacherServicelog, teacherServicelogOne } from "@/request/api";

// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {
    EmployeeChoose,
    ElImageViewer
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      /*      rules: {
             service_subject_id: [{ required: true, message: "请选择服务科目", trigger: "blur" }],
             service_type_id: [{ required: true, message: "请选择服务类型", trigger: "blur" }],
           }, */
      imgUploadUrl: imgUploadUrl(),
      imageList: [],
      fileList: [],
      chooseDialog: false,
      staffs: [],
      tableImg: [],
      showViewer: false,
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail(this.id)
      }
    },

  },
  methods: {
    fetchDetail(id) {
      teacherServicelogOne({ id }).then((res) => {
        this.dataForm = res.data
        this.staffs = res.data.ait_users ? res.data.ait_users : []
        this.fileList = res.data.content_file ? JSON.parse(res.data.content_file) : []
        this.imageList = res.data.content_img ? JSON.parse(res.data.content_img) : []
      });
    },
    emitClose() {
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            teacher_id: this.dataForm.teacher_id,
            teacher_service_id: this.$route.params.id,
            service_subject_id: this.dataForm.service_subject_id,
            service_type_id: this.dataForm.service_type_id,
            content_text: this.dataForm.content_text,
            content_file: this.fileList.length ? JSON.stringify(this.fileList) : "",
            content_img: this.imageList.length ? JSON.stringify(this.imageList) : "",
            ait_user_ids: this.staffs.map(v => { return v.id }).join(',')
          }
          editteacherServicelog(query).then((res) => {
            this.$message.success('提交成功')
            this.$emit('submit')
            this.emitClose()
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleImageRemove(index) {
      this.imageList.splice(index, 1)
    },
    handleImageSuccess(response, file, fileList) {
      this.imageList.push({
        name: response.data.name,
        url: response.data.url,
      })
    },
    handleFileRemove(index) {
      this.fileList.splice(index, 1)
    },
    handleFileSuccess(response, file, fileList) {
      this.fileList.push({
        name: file.raw.name,
        url: response.data.url,
      })
    },
    handleToPreview(item) {
      window.open(item.url)
    },
    saveStaff({ data }) {
      this.staffs = data
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.tableImg = [url];
      this.showViewer = true;
    },
  },
};
</script>

<style lang="less" scoped>
.message-wrap {
  padding: 16px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  .user-list {
    color: #409eff;
    font-size: 14px;
    line-height: 22px;
  }
  /deep/.el-textarea__inner {
    margin-top: 8px;
    border: none;
    padding: 0px;
  }
}
.btn-wrap {
  margin-top: 16px;
  display: flex;
  .btn {
    margin-right: 16px;
    padding: 6px 12px;
    border: 1px solid #dcdfe6;
    cursor: pointer;
  }
  .btn:active {
    border: 1px solid #409eff;
    color: #409eff;
  }
}
.line {
  /*   border: 0.5px solid #dcdfe6; */
  height: 0.1px;
  background: #dcdfe6;
  margin-bottom: 4px !important;
}
.image-wrap {
  display: flex;
  .image-item {
    padding: 8px;
    position: relative;
    transition: 0.5s;
    .close {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 16px;
      height: 16px;
    }
    img {
      width: 104px;
      height: 104px;
      border-radius: 4px;
    }
  }
}
.file-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .file-item {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .btn {
        padding: 3px 16px;
        border: 1px solid #dcdfe6;
        border-radius: 20px;
        color: #606266;
        font-size: 12px;
        cursor: pointer;
      }
      img {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }
    }
  }
}
.upload-demo {
  width: 90px;
  height: 36px;
  overflow: hidden;
}
</style>