<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/dataInventory/dataList' }">资料列表</el-breadcrumb-item>
        <el-breadcrumb-item>供销存明细</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName[mode]"></TableTittle>
        <el-form
          :inline="true"
          label-width="70px"
          size="small"
        >
          <el-form-item>
            <el-radio-group
              v-model="mode"
              @change="radioChange"
            >
              <el-radio-button :label="0">进货列表</el-radio-button>
              <el-radio-button :label="1">发货列表</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <DetailedTable v-if="mode==0" />
      <RecordTable v-else />
    </el-card>

  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import DetailedTable from "@/components/DataListRecordTable/DetailedTable.vue"
import RecordTable from "@/components/DataListRecordTable/RecordTable.vue"


import {

} from "../../../request/api";
export default {
  components: {
    TableTittle,
    DetailedTable,
    RecordTable
  },
  data() {
    return {
      TableName: ["记录列表", "清单列表"],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      pageTotal: 0,
      tableData: [],
      mode: 0,
    };
  },
  filters: {

  },

  mounted() {
    /* this.getList(this.query); */
  },
  methods: {

    radioChange(e) {
      console.log(e);
    }

  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.card-header {
  display: flex;
  justify-content: space-between;
  .el-button {
    height: 35px;
    line-height: 10px;
  }
}
.block {
  margin-bottom: 14px;
  float: right;
}
</style>