<template>
  <div class="content">
    <!-- 进货列表 -->
    <el-table
      :data="tableData"
      :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
      :cell-style="{ textAlign: 'center', fontSize: '13px' }"
      border
    >
      <el-table-column
        prop="product_name"
        label="资料名称"
        width="200"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="year"
        label="年份"
        width="80"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{scope.row.year}}年
        </template>
      </el-table-column>
      <el-table-column
        prop="product_sku"
        label="规格"
        width="160"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="quantity"
        label="采购数量"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="采购总价"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{scope.row.total_amount}}元
        </template>
      </el-table-column>
      <el-table-column
        prop="factory_name"
        label="合作厂家"
        width="200"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width="260"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="采购人"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{scope.row.creator.alias}}
        </template>
      </el-table-column>
      <el-table-column
        label="采购时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")}}
        </template>
      </el-table-column>
    </el-table>
    <div
      class="pagination"
      style="display: flex;align-items: center;justify-content: flex-end;margin-top:16px;"
    >
      <div class="block">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>

</template>

<script>


import {
  stocksliplist
} from "@/request/api";
export default {
  components: {

  },
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      pageTotal: 0,
      tableData: [],
    };
  },
  filters: {

  },
  mounted() {
    this.getList(this.query);
  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },

    getList(query) {
      const params = Object.assign({}, query)
      if (this.$route.params.id) {
        params.product_id = this.$route.params.id
      }
      stocksliplist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>