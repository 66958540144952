import CourseCatalogue from "../views/Product/CourseCatalogue/main.vue";
import Lesson from "../views/Product/Lesson/index.vue";
import Calendar from "../views/Product/Lesson/calendar.vue";


export default [
    /*** 产  品 ***/
    {
        path: '/product/coursecatalogue',
        component: CourseCatalogue,
        meta: { title: '产品列表' }
    },
    {
        path: '/product/lesson',
        component: Lesson,
        meta: { title: '课程列表' }
    },
    {
        path: '/product/calendar',
        component: Calendar,
        meta: { title: '课程列表课程表' }
    },
]