import axios from 'axios'
import { Message, Loading } from "element-ui";

const product = `${window.location.protocol}//crm-api.yanshizi.com`
const daily = `${window.location.protocol}//crm-api.kaoyanbox.net`




let host = daily
// 环境判断
if (location) {
    if (location.host.includes('crm.yanshizi.com')) {
        host = product
    } else if (location.host.includes('crm.kaoyanbox.net')) {
        host = daily
        if (location.host.includes('test')) {
            host = daily
        }
    }
}


export function imgUploadUrl() {
    return `${host}/api/v1/upload/image`
}

export function fileUploadUrl() {
    return `${host}/api/v1/upload/file`
}


axios.defaults.baseURL = host
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'

let loadingRequestCount = 0;
let loadingInstance;

const showLoading = () => {
    if (loadingRequestCount === 0) {
        // element的服务方式 target 我这边取的是表格 项目是后台系统 每个页面都有表格 类似整个表格loading
        // 和在表格配置v - loading一样的效果，这么做是全局实现了，不用每个页面单独去v - loading
        loadingInstance = Loading.service({
            background: 'transparent',
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
        });
    }
    loadingRequestCount++
}

const hideLoading = () => {
    if (loadingRequestCount <= 0) return
    loadingRequestCount--
    if (loadingRequestCount === 0) {
        setTimeout(() => {
            loadingInstance.close();
        }, 300)
    }
}

// // 请求拦截器
axios.interceptors.request.use(
    config => {
        /* showLoading(config) */
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = localStorage.getItem('token')
        token && (config.headers.Authorization = token)
        return config
    },
    error => {
        return Promise.error(error)
    })

//axios的拦截--response
axios.interceptors.response.use(response => {
    /*     setTimeout(() => {
            hideLoading()
        }, 200); */
    // TODO:具体的code对应的处理可继续添加修改
    if (response.data.code === 0) {
        console.log('请求成功')
    } else {
        Message({
            message: response.data.error || '服务器异常',
            type: 'error',
            duration: 2000
        })
    }
    if (response.data.code === 401) {
        /*         if (window.location.pathname == "/overview") {
                    return
                } */
        location.href = '/login'
    }
    return response.data;
}, err => {
    /*    setTimeout(() => {
           hideLoading()
       }, 200); */
    Message({
        message: err.data.msg || '服务器异常',
        type: 'error',
        duration: 2000
    })
    return Promise.reject(err);
})
export default axios //导出封装后的axios