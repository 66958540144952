import Management from "@/views/PracticeTest/Management/index.vue";
import PaperManagent from "@/views/PracticeTest/PaperManagent/index.vue";
import TestDetail from "@/views/PracticeTest/TestDetail/index.vue";
import ManagementDetail from "@/views/PracticeTest/Management/detail.vue";/* 模考详情 */
import PaperManagentDetail from "@/views/PracticeTest/PaperManagent/detail.vue";/* 试卷详情 */
import TestDetailCheck from "@/views/PracticeTest/TestDetail/detail.vue";/* 考试详情查看 */





export default [
    /*** 模 考 ***/
    {
        path: '/practicetest/management',
        component: Management,
        meta: { title: '模考管理' }
    }, {
        path: '/practicetest/management/detail/:id',
        component: ManagementDetail,
        meta: { title: '模考详情' }
    }, {
        path: '/practicetest/papermnagent',
        component: PaperManagent,
        meta: { title: '试卷管理' }
    }, {
        path: '/practicetest/papermnagent/detail/:id',
        component: PaperManagentDetail,
        meta: { title: '试卷详情' }
    }, {
        path: '/practicetest/testdetail',
        component: TestDetail,
        meta: { title: '考试详情' }
    },
    {
        path: '/practicetest/testdetail/detail/:id',
        component: TestDetailCheck,
        meta: { title: '考试详情查看' }
    }
]