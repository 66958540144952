<template>
  <div>
    <el-dialog
      title="课程列表"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="课程名称："
          prop="name"
        >
          <el-input
            v-model="dataForm.name"
            placeholder="请输入课程名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="课程课时："
          prop="hours"
        >
          <el-input
            v-model="dataForm.hours"
            placeholder="请输入课程课时"
          >
            <span slot="append">小时</span>
          </el-input>
        </el-form-item>
        <el-form-item
          label="课程年份："
          prop="year"
        >
          <el-select
            v-model="dataForm.year"
            placeholder="请选择课程年份"
            clearable
          >
            <el-option
              label="2023"
              value="2023"
            ></el-option>
            <el-option
              label="2024"
              value="2024"
            ></el-option>
            <el-option
              label="2025"
              value="2025"
            ></el-option>
            <el-option
              label="2026"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="授课老师："
          prop="teacher_name"
        >
          <el-input
            v-model="dataForm.teacher_name"
            placeholder="请输入授课老师"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="产品经理："
          prop="watcher"
        >
          <el-select
            v-model="dataForm.watcher"
            placeholder="请选择产品经理"
            clearable
            multiple
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.alias"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="课程状态："
          prop="status"
        >
          <el-switch
            v-model="dataForm.status"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import { normalCourseEdit, normalCourseOne } from "@/request/api";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      rules: {
        name: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        hours: [{ required: true, message: "请输入课程课时", trigger: "blur" }],
        year: [{ required: true, message: "请选择课程年份", trigger: "blur" }],
        watcher: [{ required: true, message: "请选择产品经理", trigger: "blur" }],
        teacher_name: [{ required: true, message: "请输入授课老师", trigger: "blur" }],
      },

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      normalCourseOne({ id: this.id }).then((res) => {
        this.dataForm = res.data
        if (this.dataForm.watcher && this.dataForm.watcher.length) {
          this.dataForm.watcher = this.dataForm.watcher.map(v => {
            return Number(v.id)
          })
        }
      });
    },
    emitClose() {
      this.$emit("close");
    },


    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          const param = Object.assign({}, this.dataForm)
          param.watcher = param.watcher.join(',')
          delete param.creator
          normalCourseEdit(param).then((res) => {
            if (res.code == 0) {
              this.emitClose()
              this.$emit('submit')
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
</script>

<style lang="less" scoped>
</style>