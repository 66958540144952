<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>账户充值</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card class="info-card">
      <TableTittle :tittle="TableName[0]"></TableTittle>
      <div class="card-myinfo">
        <img
          src="../../../assets/images/avatar.png"
          alt=""
        />
        <span>{{ userName | fomatename }},您好!</span>
      </div>
      <div class="card-money">
        <div class="card-money-title">
          <img
            src="../../../assets/images/75@2x.png"
            alt=""
          />
          <span>账户余额</span>
        </div>
        <span class="card-money-count">￥{{ $store.state.AccountInfo.balance }}</span>
        <!--       <div class="card-money-detail">
          <span
            >可用余额：{{
              $store.state.AccountInfo.balance
            }}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;冻结金额：{{ 0 }}</span
          >
        </div> -->
      </div>
      <div class="card-money">
        <div class="card-money-title">
          <img
            src="../../../assets/images/76@2x.png"
            alt=""
          />
          <span>账户保证金</span>
        </div>
        <span
          class="card-money-countother"
          v-if="$store.state.companyInfo.deposit > 0"
        >￥{{ $store.state.companyInfo.deposit }}</span>
        <div
          class="deposit-zero"
          v-else
        >
          <span>需缴纳<span class="span-span">
              {{ $store.state.AccountInfo.deposit }} </span>账户保证金</span>
        </div>
        <el-button
          type="primary"
          size="small"
          class="deposit-button"
          v-if="
            $store.state.companyInfo.deposit == $store.state.AccountInfo.deposit
          "
        >已缴纳</el-button>
        <el-button
          type="primary"
          size="small"
          class="deposit-button"
          v-else
          @click="openDepositPaymentDialog"
          :disabled="
            !$buttonPermission('companyAccount:edit') &&
            $store.state.companyInfo.deposit_status != 1
          "
        >缴纳保证金</el-button>
      </div>
      <div class="card-recharge">
        <el-button
          type="primary"
          @click="AccountRechargeDialog = !AccountRechargeDialog"
          :disabled="!$buttonPermission('companyRechargeOrder:add')"
        >账户充值</el-button>
      </div>
    </el-card>
    <el-card style="margin-top: 20px">
      <!-- 筛选加搜索 -->
      <div class="card-header">
        <TableTittle :tittle="TableName[1]"></TableTittle>
        <el-form
          :inline="true"
          label-width="70px"
          size="small"
        >
          <el-form-item label="审核状态">
            <el-select
              v-model="query.audit_status"
              placeholder="请选择审核状态"
              @change="screenFormSubmit"
              clearable
            >
              <el-option
                label="待审核"
                :value="0"
              ></el-option>
              <el-option
                label="审核通过"
                :value="1"
              ></el-option>
              <el-option
                label="审核失败"
                :value="2"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式">
            <el-select
              v-model="query.payment_type"
              placeholder="请选择支付方式"
              @change="screenFormSubmit"
              clearable
            >
              <el-option
                label="支付宝"
                :value="1"
              ></el-option>
              <el-option
                label="微信"
                :value="2"
              ></el-option>
              <el-option
                label="余额"
                :value="3"
              ></el-option>
              <el-option
                label="转账"
                :value="4"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="充值ID">
            <el-input
              v-model="query.order_no"
              placeholder="请输入充值ID"
              clearable
              @input="screenFormSubmit"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column
          prop="order_no"
          label="充值ID"
        > </el-table-column>
        <el-table-column
          prop="amount"
          label="充值金额"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.amount + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="支付方式"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.payment_type == 0">无</span>
            <span v-show="scope.row.payment_type == 1">支付宝</span>
            <span v-show="scope.row.payment_type == 2">微信</span>
            <span v-show="scope.row.payment_type == 3">余额</span>
            <span v-show="scope.row.payment_type == 4">转账</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="转账截图"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              v-if="scope.row.payment_type == 4"
              @click="openViewer(scope.row.payOrder.pay_voucher)"
            >查看</el-link>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          label="支付时间"
          width="150px"
        >
          <template slot-scope="scope">
            {{ scope.row.payorder_pay_time?$moment(scope.row.payorder_pay_time).format("YYYY-MM-DD HH:mm"):"" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="创建日期"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="审核状态"
        >
          <template slot-scope="scope">
            <el-link
              type="danger"
              :underline="false"
              @click="opendialogFormVisible(scope.row)"
              v-show="scope.row.audit_status == 2"
            >审核失败</el-link>
            <el-link
              type="success"
              :underline="false"
              v-show="scope.row.audit_status == 1"
            >审核成功</el-link>
            <el-link
              :underline="false"
              v-show="scope.row.audit_status == 0"
            >待审核</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AccountRecharge
      :isVisible="AccountRechargeDialog"
      @close="AccountRechargeDialogClose"
    >
    </AccountRecharge>
    <DepositPayment
      :isVisible="DepositPaymentDialog"
      @close="DepositPaymentDialogClose"
      @open="AccountRechargeDialog = !AccountRechargeDialog"
    >
    </DepositPayment>
    <el-dialog
      title="拒绝理由"
      :visible.sync="dialogFormVisible"
      width="570px"
    >
      <el-form :model="form">
        <el-form-item label="">
          <el-input
            v-model="form.name"
            type="textarea"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogFormVisible = false"
        >确 定</el-button>
      </div>
    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      :url-list="[tableImg]"
      :on-close="closeViewer"
    />
  </div>
</template>

<script>
// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AccountRecharge from "../../../components/DialogComponents/AccountRecharge/AccountRecharge.vue";
import DepositPayment from "../../../components/DialogComponents/DepositPayment/DepositPayment.vue";
import { rechargeorderlist } from "../../../request/api";
export default {
  components: {
    TableTittle,
    AccountRecharge,
    ElImageViewer,
    DepositPayment,
  },
  data() {
    return {
      TableName: ["我的账户", "充值记录"],
      cardStyle: {
        padding: "0px",
      },
      form: {},
      AccountRechargeDialog: false,
      dialogFormVisible: false,
      DepositPaymentDialog: false,
      // 预览图片数组
      showViewer: false,
      tableImg: [],
      srcUrl: require("../../../assets/images/128@2x.png"),
      userName: "",
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        archive: "0",
        keyword: "",
      },
      tableData: [],
    };
  },
  mounted() {
    this.getList(this.query);
    this.$store.dispatch("getCompanyAccount");
    this.$store.dispatch("getCompanyInfo");
    this.$store.dispatch("getBankInfo");
  },
  filters: {
    fomatename: function (val) {
      return JSON.parse(localStorage.getItem("userInfo")).nickname;
    },
  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    //页面列表刷新
    getList(query) {
      rechargeorderlist(query).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    opendialogFormVisible() {
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    openViewer(url) {
      this.tableImg = url;
      this.showViewer = true;
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    screenFormSubmit() {
      this.getList(this.query);
    },

    AccountRechargeDialogClose() {
      this.AccountRechargeDialog = false;
      this.getList(this.query);
      this.$store.dispatch("getCompanyAccount");
    },
    openDepositPaymentDialog() {
      this.DepositPaymentDialog = !this.DepositPaymentDialog;
    },
    DepositPaymentDialogClose() {
      this.DepositPaymentDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 35px;
}
.info-card {
  margin-top: 20px;
  height: 260px;
  position: relative;
  background-image: url("../../../assets/images/RechargeBcgImg.png");
  background-size: 100%;
  .card-myinfo {
    float: left;
    width: 19.39%;
    height: 142px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-right: solid 2px #e9ecf7;

    img {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      opacity: 1;
    }
    span {
      text-align: center;
      width: 128px;
      height: 22px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #303133;
      opacity: 1;
    }
  }
  .card-money {
    float: left;
    width: 22.19%;
    height: 142px;
    position: relative;
    border-right: solid 2px #e9ecf7;
    .card-money-title {
      position: absolute;
      display: flex;
      align-items: center;
      left: 10%;
      img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
      }
      span {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
        color: #303133;
        opacity: 1;
      }
    }
    .card-money-count {
      position: absolute;
      top: 32%;
      left: 10%;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #409eff;
      opacity: 1;
      word-break: break-all;
      word-wrap: break-word;
    }
    .card-money-countother {
      position: absolute;
      top: 32%;
      left: 10%;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #e6a23c;
      opacity: 1;
    }
    .card-money-detail {
      position: absolute;
      bottom: 0px;
      left: 69px;
      word-break: break-all;
      word-wrap: break-word;
      span {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #909399;
        opacity: 1;
      }
    }
    .deposit-button {
      position: absolute;
      bottom: 0px;
      left: 20%;
    }
    .deposit-zero {
      position: absolute;
      top: 53px;
      left: 90px;
      span {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #909399;
        opacity: 1;
      }
      .span-span {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 20px;
        color: #e6a23c;
        opacity: 1;
      }
    }
  }
  .card-recharge {
    float: left;
    height: 142px;
    position: relative;
    .el-button {
      position: absolute;
      left: 96px;
      top: 36px;
    }
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
}
.preimg {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.dangerLink {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
@media screen and (max-width: 1650px) {
  .content {
    .card-money-count,
    .card-money-countother {
      font-size: 1.5rem !important;
    }
    .card-money-detail {
      font-size: 0.5rem !important;
    }
    .card-money-title > span {
      font-size: 1.7rem !important;
    }
  }
}
@media screen and (max-width: 1200px) {
  .content {
    .card-money-count,
    .card-money-countother {
      font-size: 1.2rem !important;
    }
    .card-money-detail {
      font-size: 0.3rem !important;
    }
    .card-money-title > span {
      font-size: 1.4rem !important;
    }
  }
}
@media screen and (max-width: 900px) {
  .content {
    .card-money-count,
    .card-money-countother {
      font-size: 1rem !important;
    }
    .card-money-detail {
      font-size: 0.2rem !important;
    }
    .card-money-title > span {
      font-size: 1rem !important;
    }
  }
}
</style>