<template>
  <div>
    <el-dialog
      title="模考管理"
      :visible.sync="dialogFormVisible"
      width="700px"
      :close-on-click-modal="false"
      @close="emitClose"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="模考名称："
          prop="name"
        >
          <el-input
            v-model.trim="dataForm.name"
            placeholder="请输入模考名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="院校名称："
          prop="college"
        >
          <el-select
            v-model="dataForm.college"
            placeholder="请输入选择院校名称"
            filterable
            clearable
            @change="collegeChange"
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="专业名称："
          prop="major"
          v-if="dataForm.college"
        >
          <el-select
            v-model="dataForm.major"
            placeholder="请选择专业名称"
            clearable
            @change="majorChange"
            filterable

          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
              <span>{{ `（${item.code}）${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="专业名称："
          prop="major"
          v-else
        >
          <el-input
            v-model="dataForm.major_text"
            placeholder="请选择专业名称"
            readonly
            @focus="openMajorChoose"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="试卷要求："
          prop="paper_demand"
        >
          <el-input
            type="textarea"
            v-model="dataForm.paper_demand"
            placeholder="请输入试卷要求"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="考试说明："
          prop="exam_explain"
        >
          <el-input
            type="textarea"
            v-model="dataForm.exam_explain"
            placeholder="请输入考试说明"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="批改说明："
          prop="correct_explain"
        >
          <el-input
            type="textarea"
            v-model="dataForm.correct_explain"
            placeholder="请输入批改说明"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>

    </el-dialog>
    <MajorChoose
      :visible="MajorChooseVisible"
      @close="MajorChooseVisible=false"
      @select="MajorChooseSelect"
    />
  </div>
</template>

<script>


import { majorlist, mockexamCreate } from "@/request/api";
import MajorChoose from "../../DialogComponents/MajorChooseSingle.vue";



export default {
  components: {
    MajorChoose
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      majorOptions: [],
      rules: {
        name: [{ required: true, message: "请输入模考名称", trigger: "blur" }],
        paper_demand: [{ required: true, message: "请输入试卷要求", trigger: "blur" }],
        exam_explain: [{ required: true, message: "请输入考试说明", trigger: "blur" }],
        correct_explain: [{ required: true, message: "请输入批改说明", trigger: "blur" }],
        /*        college: [{
                 required: true,
                 validator: (rule, value, callback) => {
                   if (!this.dataForm.college_code || !this.dataForm.college_name) {
                     callback(new Error('请选择院校'))
                   } else {
                     callback()
                   }
                 },
               }], */
        major: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.dataForm.major_code || !this.dataForm.major_name) {
              callback(new Error('请选择专业'))
            } else {
              callback()

            }
          },
        }],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      MajorChooseVisible: false,
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.$store.dispatch('getAllColleges')
      }

    },

  },
  methods: {
    emitClose() {
      this.dataForm = {}
      this.$emit("close");
    },
    collegeChange(e) {
      this.majorOptions = []
      this.dataForm.major = null
      this.dataForm.major_code = null
      this.dataForm.major_name = null
      if (e) {
        this.dataForm.college_code = e.value
        this.dataForm.college_name = e.label
        this.getMajor(e.value);
      }
    },
    majorChange(e) {
      this.dataForm.major_code = e.value
      this.dataForm.major_name = e.label
      this.$forceUpdate()
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((res) => {
        this.majorOptions = res.data.list || [];
      });
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            name: this.dataForm.name,
            college_code: this.dataForm.college_code ? this.dataForm.college_code : '',
            college_name: this.dataForm.college_name ? this.dataForm.college_name : '',
            major_code: this.dataForm.major_code,
            major_name: this.dataForm.major_name,
            paper_demand: this.dataForm.paper_demand,
            exam_explain: this.dataForm.exam_explain,
            correct_explain: this.dataForm.correct_explain,
          }
          mockexamCreate(query).then((res) => {
            this.$message.success('提交成功')
            this.$emit('submit')
            this.emitClose()
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    openMajorChoose() {
      this.MajorChooseVisible = true
    },
    MajorChooseSelect(data) {
      this.dataForm.major = data
      this.dataForm.major_code = data.code
      this.dataForm.major_name = data.name
      this.dataForm.major_text = `（${data.code}）${data.name}`
    },

  },
};
</script>

<style lang="less" scoped>
.el-input,
.el-select {
  width: 100%;
}
</style>