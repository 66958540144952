import Bill from "../views/Finance/Bill/main.vue";
import Recharge from "../views/Finance/Recharge/main.vue";
import BillingRecord from "../views/Finance/BillingRecord/main.vue";
import RecordsOfConsumption from "../views/Finance/RecordsOfConsumption/main.vue";



export default [
    /*** 财 务 ***/
    {
        path: '/finance/bill',
        component: Bill,
        meta: { title: '服务账单' }
    }, {
        path: '/finance/recharge',
        component: Recharge,
        meta: { title: '账户充值' }
    }, {
        path: '/finance/billingrecord',
        component: BillingRecord,
        meta: { title: '开票记录' }
    }, {
        path: '/finance/recordsofconsumption',
        component: RecordsOfConsumption,
        meta: { title: '消费记录' }
    },
]