<template>
  <div>
    <el-dialog title="分配"
               :visible.sync="dialogFormVisible"
               width="400px"
               :close-on-click-modal="false"
               @close="emitClose">
      <el-form :model="dataForm"
               label-position="right"
               ref="formRules"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="选择老师">
          <el-select v-model="crm_correctors"
                     placeholder="请选择"
                     clearable>
            <el-option v-for="item in $store.state.staffOptions"
                       :key="item.id"
                       :label="item.alias"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { adminUrl } from '@/utils/helper.js'
export default {
  data() {
    return {
      dataForm: {},
      rules: {},
      dialogFormVisible: false,
      crm_correctors: '',
    }
  },
  created() {
    this.$store.dispatch('getStaffList')
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    chooseList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  watch: {
    visible(val) {
      this.dialogFormVisible = val
    },
  },
  methods: {
    emitClose() {
      this.$emit('emitClose')
    },
    submit() {
      console.log('submit', this.chooseList)
      if (this.crm_correctors == '') {
        this.$message({
          message: '请选择老师',
          type: 'error',
        })
      }
      let data = { rows: [] }
      this.chooseList.map((item) => {
        data.rows.push({
          id: item.id,
          crm_corrector: this.crm_correctors,
        })
      })
      console.log('data', data)

      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/measureScroll/userMeasureScroll/list`,
        method: 'put', //可以省略 不写默认是get
        data: data,
      }).then((res) => {
        if (res) {
          this.$message({
            message: '提交成功',
            type: 'sucess',
          })
          this.$emit('emitClose')
          this.$emit('getList')
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
::v-deep .el-dialog__header {
  background: #ebeef5;
}
</style>