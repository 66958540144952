<template>
  <div>
    <el-dialog
      title="待办事项"
      :visible.sync="dialogFormVisible"
      width="630px"
      @close="emitClose"
      append-to-body
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="记录内容："
          prop="content"
        >
          <el-input
            v-model="dataForm.content"
            placeholder="请输入内容"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="服务类型："
          prop="service_type_id"
        >
          <el-select
            v-model="dataForm.service_type_id"
            placeholder="请选择服务类型"
            clearable
            style="width:100%;"
          >
            <el-option
              v-for="(item) in $store.state.servicetypeOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="关联学员："
          prop="student_id_list"
        >
          <!--       <el-link
            type="primary"
            :underline="false"
            @click="studentDialog = true"
          >选择用户</el-link> -->
          <div class="student-tag">
            <el-tag
              :key="index"
              v-for="(item,index) in students"
              :disable-transitions="false"
              @close="removeStudents(index)"
            >
              {{item.realname}}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item
          label="截止日期："
          prop="stop_time"
        >
          <div class="">
            <el-date-picker
              v-model="dataForm.stop_time"
              type="datetime"
              placeholder="请选择日期时间"
              clearable
              value-format="timestamp"
            >
            </el-date-picker>
          </div>

        </el-form-item>
        <el-form-item
          label="优先级："
          prop="priority_level"
        >
          <el-select
            v-model="dataForm.priority_level"
            placeholder="请选择优先级"
            clearable
            style="width:100%;"
          >
            <el-option
              label="1"
              :value="1"
            >
            </el-option>
            <el-option
              label="2"
              :value="2"
            >
            </el-option>
            <el-option
              label="3"
              :value="3"
            >
            </el-option>
            <el-option
              label="4"
              :value="4"
            >
            </el-option>
            <el-option
              label="5"
              :value="5"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="执行老师："
          prop="do_teacher_id_list"
        >
          <!--      <el-link
            type="primary"
            :underline="false"
            @click="chooseDialog = true"
          >选择老师</el-link> -->
          <div class="student-tag">
            <el-tag
              :key="index"
              v-for="(item,index) in staffs"
              :disable-transitions="false"
              @close="removeStaffs(index)"
            >
              {{item.alias}}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="发布人：">
          <div style="padding-left:8px;">{{dataForm.publish_teacher?dataForm.publish_teacher.alias:""}}</div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>

    </el-dialog>
    <EmployeeChoose
      :visible="chooseDialog"
      :content_array="staffs"
      @close="chooseDialog=false"
      @submit="saveStaff"
    />
    <StudentChoose
      :visible="studentDialog"
      :content="students"
      @close="studentDialog=false"
      @submit="chooseStudent"
    />
  </div>
</template>

<script>


import EmployeeChoose from "@/components/DialogComponents/Employee/EmployeeChoose"
import { editteacherServicetask, teacherServicetaskOne } from "@/request/api";
import StudentChoose from "./StudentChoose.vue";


export default {
  components: {
    EmployeeChoose,
    StudentChoose
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      rules: {
        service_subject_id: [{ required: true, message: "请选择服务科目", trigger: "blur" }],
        service_type_id: [{ required: true, message: "请选择服务类型", trigger: "blur" }],
        content: [{ required: true, message: "请输入记录内容", trigger: "blur" }],
        stop_time: [{ required: true, message: "请选择截止日期", trigger: "blur" }],
        priority_level: [{ required: true, message: "请选择优先级", trigger: "blur" }],
        do_teacher_id_list: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.staffs.length) {
              callback(new Error('请选择执行老师'))
            } else {
              callback()

            }
          },
        }],
        student_id_list: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.students.length) {
              callback(new Error('请选择关联学员'))
            } else {
              callback()

            }
          },
        }],

      },

      chooseDialog: false,
      staffs: [],
      studentDialog: false,
      students: [],
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail(this.id)
      }
    },

  },
  methods: {
    fetchDetail(id) {
      teacherServicetaskOne({ id }).then((res) => {
        this.dataForm = res.data
        this.students = [res.data.student]
        this.staffs = [res.data.do_teacher]
      });
    },
    emitClose() {
      this.students = []
      this.staffs = []
      this.dataForm = {}
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            content: this.dataForm.content,
            service_type_id: this.dataForm.service_type_id,
            stop_time: this.dataForm.stop_time,
            priority_level: this.dataForm.priority_level,
            student_id_list: this.students.map(v => { return v.id }),
            do_teacher_id_list: this.staffs.map(v => { return v.id }),
          }
          editteacherServicetask(query).then((res) => {
            this.$message.success('提交成功')
            this.$emit('submit')
            this.emitClose()
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    saveStaff({ data }) {
      this.staffs = data
    },
    chooseStudent({ data }) {
      this.students = data
    },
    removeStudents(index) {
      this.students.splice(index, 1)
    },
    removeStaffs(index) {
      this.staffs.splice(index, 1)

    }
  },
};
</script>

<style lang="less" scoped>
.student-tag {
}
.el-tag {
  margin: 5px;
}
</style>