<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>服务</el-breadcrumb-item>
        <el-breadcrumb-item>学员管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="hanleAdd"
          :disabled="!$buttonPermission('studentOrder:add')"
          >新建</el-button
        >
        <el-button plain size="small" @click="goImportFile()">导入</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form :inline="true" class="demo-form-inline" size="mini">
        <!-- <el-form-item label="搜索">
          <el-input
            v-model="query.keyword"
            placeholder="姓名/院校名称/专业名称/考研年份"
            @input="inputSearch"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item label="学员信息">
          <el-input
            v-model="query.student_keyword"
            placeholder="输入姓名/手机号"
            @input="inputSearch"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="班级名称">
          <el-input
            v-model="query.grade_keyword"
            placeholder="班级名称"
            @input="inputSearch"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item style="float: right">
          <el-radio-group v-model="query.crm_archive" @change="radioChange">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="1">已归档</el-radio-button>
            <el-radio-button label="0">未归档</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <deviceLine></deviceLine>
      <ScreenServiceStudent @submit="screenTable"></ScreenServiceStudent>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName"></TableTittle>
        <el-radio-group v-model="filtrate" @change="filtrateChange" size="small">
            <el-radio-button label="1">全部</el-radio-button>
            <el-radio-button label="2">仅关注</el-radio-button>
          </el-radio-group>
        <!-- <el-form :inline="true" label-width="70px" size="small">
          <el-form-item label="只看关注">
            <el-switch
              v-model="query.follow"
              :active-value="1"
              inactive-value=" "
              @change="getList(query)"
            >
            </el-switch>
          </el-form-item>
        </el-form> -->
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
       
        @selection-change="handleSelectionChange"
        border
      >
       <!-- :cell-style="{ textAlign: 'center', fontSize: '12px' }" -->
      <!--   align="center" -->
        <el-table-column type="selection" width="50" fixed="left" align="center">
        </el-table-column>
        <el-table-column prop="date" label="关注" width="50" fixed="left" align="center">
          <template slot-scope="scope">
            <img
              alt=""
              srcset=""
              class="star"
              src="../../../assets/images/star-fill.png"
              @click="followDelete(scope.row)"
              v-show="scope.row.follow"
            />
            <img
              src="../../../assets/images/star.png"
              alt=""
              srcset=""
              class="star"
              v-show="!scope.row.follow"
              @click="followCreate(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="服务记录" width="80" fixed="left">
          <template slot-scope="scope">
            <div class="badge" v-if="student_unread_msg[scope.$index]">
              <el-badge
                v-if="student_unread_msg && student_unread_msg.length"
                :value="
                  student_unread_msg[scope.$index].unread_common_msg_count
                "
                class="item"
                :hidden="
                  !student_unread_msg[scope.$index].unread_common_msg_count
                "
              >
                <el-link
                  type="primary"
                  :underline="false"
                  @click="goRecord(scope.row)"
                  :disabled="!$buttonPermission('studentOrderAdvance:view')"
                  >查看</el-link
                >
              </el-badge>
            </div>
            <!--    <el-divider direction="vertical"></el-divider>
            <el-link
              type="primary"
              :underline="false"
              @click="openAddRecordDialog(scope.row)"
              >新增</el-link
            > -->
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="报名时间" width="110px" align="center">
          <template slot-scope="scope">
            {{ scope.row.apply_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="学员姓名"
          width="80"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="openStudentDialog(scope.row)"
              :disabled="!$buttonPermission('studentOrder:edit')"
              >{{ scope.row.student_realname }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column prop="apply_year" label="课程状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag
              size="small"
              v-show="scope.row.student_order_sync_status == 1"
              >待定校</el-tag
            >
            <el-tag
              size="small"
              type="warning"
              v-show="scope.row.student_order_sync_status == 2"
              >待服务</el-tag
            >
            <el-tag
              size="small"
              type="warning"
              v-show="scope.row.student_order_sync_status == 3"
              >待对接</el-tag
            >
            <el-tag
              size="small"
              v-show="scope.row.student_order_sync_status == 4"
              >匹配中</el-tag
            >
            <el-tag
              size="small"
              v-show="scope.row.student_order_sync_status == 5"
              >上课中</el-tag
            >
            <el-tag
              type="info"
              size="small"
              v-show="scope.row.student_order_sync_status == 6"
              >已终止</el-tag
            >
            <el-tag
              type="danger"
              size="small"
              v-show="scope.row.student_order_sync_status == 7"
              >已结束</el-tag
            >
            <!--   <el-tag
              size="small"
              v-show="scope.row.service_status == 0"
            >无</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.service_status == 1"
            >待定校</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.service_status == 2"
            >待推送</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.service_status == 3"
            >正在对接</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.service_status == 4"
            >正在匹配</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.service_status == 5"
            >匹配成功</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.service_status == 6"
            >匹配失败</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.service_status == 7"
            >开始上课</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.service_status == 8"
            >正在上课</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.service_status == 9"
            >课程结束</el-tag>
            <el-tag
              size="small"
              v-show="scope.row.service_status == 10"
            >课程终止</el-tag> -->
          </template>
        </el-table-column>

        <el-table-column label="所在班级" width="180" show-overflow-tooltip>
          <template slot-scope="scope">
            <div
              style="cursor: pointer;color:#409EFF"
              @click="
                (scope.row.grade_names || scope.row.grade_names_end) &&
                  opengradeDialog(scope.row)
              "
            >
              {{ scope.row.grade_names || scope.row.grade_names_end || "-" }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="learnhours" label="课程进度" width="120" align="center">
          <template slot-scope="scope">
            <div class="progress-div">
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="formatePrecent(scope.row)"
                :class="{ 'red-text': formatePrecent(scope.row) > 100 }"
              ></el-progress>
            </div>
          </template>
        </el-table-column>
        <!--     <el-table-column
          prop="name"
          label="报名课程"
          width="320"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.products | formateProducts }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="apply_year"
          label="报考信息"
          width="380"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.apply_year }}年/{{ scope.row.apply_college_name }}/
            {{
              scope.row.apply_academy_name && scope.row.apply_academy_code
                ? `(${scope.row.apply_academy_code})${scope.row.apply_academy_name}/`
                : ""
            }}
            {{
              scope.row.apply_major_name && scope.row.apply_major_code
                ? `(${scope.row.apply_major_code})${scope.row.apply_major_name}/`
                : ""
            }}
            {{
              scope.row.apply_direction_code && scope.row.apply_direction_name
                ? `(${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
                : ""
            }}
          </template>
        </el-table-column>

        <!-- <el-table-column prop="apply_year" label="考试年份"> </el-table-column>
        <el-table-column prop="name" label="报考院校" width="150">
          <template slot-scope="scope">
            <span> {{ `${scope.row.apply_college_name}` }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="报考专业"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.apply_major_name && scope.row.apply_major_code"
            >
              {{
                `(${scope.row.apply_major_code})${scope.row.apply_major_name}`
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="报考学院"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.apply_academy_name && scope.row.apply_academy_code
              "
            >
              {{
                `(${scope.row.apply_academy_code})${scope.row.apply_academy_name}`
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="考试方向" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.apply_direction_code && scope.row.apply_direction_name
              "
            >
              {{
                `(${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
              }}</span
            >
            <span v-else>无</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="name"
          label="辅导科目"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.subjects | formateSub }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="service_hours" label="报名课时" width="100" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.service_hours + "小时" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="服务备注"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="coach_need"
          label="复习基础"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="refer_book"
          label="参考书目"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="教务老师" width="150" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            {{ product_watchersFormate(scope.row.student_watcher) }}
          </template>
        </el-table-column>

        

        <!-- <el-table-column label="产品经理" width="150" show-overflow-tooltip >
          <template slot-scope="scope">
            {{ product_watchersFormate(scope.row.product_watchers) }}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="watcher"
          label="创建老师"
          width="130"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.watcher | formateWatcher }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="update_time" label="创建时间" width="160px" align="center">
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="160px" align="center">
          <template slot-scope="scope">
            {{ scope.row.update_time | dateFormat("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>

        <!-- <el-table-column prop="update_time" label="最新跟进日期" width="120px">
          <template slot-scope="scope">
            {{ scope.row.update_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column> -->
        <el-table-column prop="name" label="操作" width="120" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="templateLink">
              <div v-if="[1, 2].includes(scope.row.student_order_sync_status)">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="hanleUp(scope.row)"
                >
                  编辑
                </el-link>
                <el-link
                  type="warning"
                  :underline="false"
                  @click="editStatus(3, scope.row.id, '是否立即推送')"
                  :disabled="!$buttonPermission('studentOrder:edit')"
                  >推送</el-link
                >
              </div>
              <div v-else>
                <el-link
                  type="primary"
                  :underline="false"
                  @click="hanleUp(scope.row)"
                  :disabled="scope.row.student_order_sync_status==6"
                >
                  变更
                </el-link>

                <el-link
                  type="danger"
                  :underline="false"
                  @click="termination(scope.row)"
                  :disabled="scope.row.student_order_sync_status==6||scope.row.student_order_sync_status==7"
                  >终止</el-link
                >
              </div>

              <!-- <el-link
                type="primary"
                :underline="false"
                @click="openConfirmCollegesShow(scope.row)"
                v-if="scope.row.service_status == 1"
                :disabled="!$buttonPermission('studentOrder:edit')"
                >确定院校</el-link
              >

              <el-link
                type="success"
                :underline="false"
                @click="opendialogFormVisible(scope.row)"
                :disabled="!$buttonPermission('studentOrder:edit')"
                v-else
                >添加备注</el-link
              >
              <el-link
                type="primary"
                :underline="false"
                @click="hanleUp(scope.row)"
              >
                {{
                  [1, 2].includes(scope.row.service_status) ? "编辑" : "变更"
                }}
              </el-link> -->
              <!--               <el-link
                type="primary"
                :underline="false"
                @click="editStatus(10, scope.row.id, '是否终止服务')"
                v-if="scope.row.service_status != 10"
                >终止服务</el-link
              > -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <!-- <el-button
          size="small"
          type="success"
          @click="filterArr"
          :disabled="!$buttonPermission('studentOrder:edit')"
          >推送</el-button
        > -->
        <el-button
          size="small"
          type="primary"
          @click="chooseAllEdit(1, '您确定要归档吗')"
          v-show="query.crm_archive == 0 && query.crm_archive != ''"
          :disabled="!$buttonPermission('studentOrder:edit')"
          >归档</el-button
        >
        <!-- <el-button
          size="small"
          type="primary"
          @click="chooseAllEdit(0, '您确定要取消归档吗')"
          v-show="query.crm_archive == 1"
          :disabled="!$buttonPermission('studentOrder:edit')"
          >取消归档</el-button
        >
        <el-button
          type="danger"
          size="small"
          @click="deleteItem"
          :disabled="!$buttonPermission('studentOrder:remove')"
          >删除</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="openBatchEditWatcherDialog"
          v-show="$buttonPermission('studentOrder:batchWatcher')"
          >分配</el-button
        >
        <el-button size="small" type="warning" @click="termination"
          >终止</el-button
        > -->
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddServiceStudent
      :isVisible="AddServiceStudentShow"
      :id="id"
      @close="AddServiceStudentShow = false"
      @submit="addStudent"
      @upSubmit="upSubmit"
    ></AddServiceStudent>
    <el-dialog title="学员信息" :visible.sync="studentDialog" width="570px">
      <el-form
        :model="studentForm"
        label-width="auto"
        label-position="left"
        size="small"
      >
        <el-form-item label="姓名/昵称：" prop="student_nickname">
          <el-input
            v-model="studentForm.student_realname"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input
            placeholder=""
            v-model="studentForm.student_phone"
            class="input-with-select"
          >
            <template slot="prepend">手机号</template>
          </el-input>
          <el-input
            placeholder=""
            v-model="studentForm.student_wechat"
            class="input-with-select"
          >
            <template slot="prepend">微信号</template>
          </el-input>
          <el-input
            placeholder=""
            v-model="studentForm.student_qq"
            class="input-with-select"
          >
            <template slot="prepend">QQ号</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="studentDialog = false">取 消</el-button>
        <el-button type="primary" @click="editInfo">确 定</el-button>
      </div>
    </el-dialog>
    <AddRecord
      :isVisible="addRecordDialog"
      @close="addRecordDialog = false"
      @submit="addRecordSubmit"
    ></AddRecord>
    <ConfirmColleges
      :content="ConfirmCollegesContent"
      :isVisible="ConfirmCollegesShow"
      @close="ConfirmCollegesShow = false"
      @submit="ConfirmCollegesSubmit"
    ></ConfirmColleges>
    <el-dialog
      title="修改/添加备注"
      :visible.sync="dialogFormVisible"
      width="570px"
    >
      <el-form>
        <el-form-item label="">
          <el-input
            v-model="studentForm.remark"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editInfo">确 定</el-button>
      </div>
    </el-dialog>
    <BatchEditWatcher
      :isVisible="BatchEditWatcherDialog"
      @close="BatchEditWatcherDialog = false"
      @submit="BatchEditWatcherSubmit"
    ></BatchEditWatcher>

    <GradeList
      :isVisible="gradeDialog"
      @close="gradeDialog = false"
      :student_realname="student_realname"
      :student_id="nowStudent_id"
    />

    <el-dialog :title="title" :visible.sync="dialogVisible" width="40%">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
        <el-form-item
          :label="title == '删除' ? '删除理由' : '终止理由'"
          prop="delete_reason"
        >
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入"
            v-model="form.delete_reason"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delSubmit('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import deviceLine from "../../../components/Line/Line.vue";
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddServiceStudent from "../../../components/DialogComponents/AddServiceStudent/AddServiceStudent.vue";
import ScreenServiceStudent from "../../../components/ScreenComponents/ScreenServiceStudent/ScreenServiceStudent.vue";
import AddRecord from "../../../components/DialogComponents/AddRecord/AddRecord.vue";
import ConfirmColleges from "../../../components/DialogComponents/ConfirmColleges/ConfirmColleges.vue";
import BatchEditWatcher from "../../../components/DialogComponents/BatchEditWatcher/BatchEditWatcher.vue";

import GradeList from "../../../components/DialogComponents/ClassService/GradeList.vue";
import {
  createstudentorder,
  studentorderlist,
  createserviceadvance,
  deletestudentorderlist,
  editstudentorder,
  createuserfollow,
  deleteuserfollow,
  editstudentorderlist,
  outsourcingedit,
  recorddetail,
  upStudentOrder,
} from "../../../request/api";
import { formateObj } from "../../../utils/helper";

import { manageUrl } from "@/utils/helper";
export default {
  components: {
    deviceLine,
    TableTittle,
    AddServiceStudent,
    ScreenServiceStudent,
    AddRecord,
    ConfirmColleges,
    BatchEditWatcher,
    GradeList,
  },
  data() {
    return {
      title: "",
      dialogVisible: false,
      form: {
        delete_reason: "",
      },
      rules: {
        delete_reason: [
          { required: true, message: `理由不能为空`, trigger: "blur" },
        ],
      },
      formInline: {},
      studentForm: {},
      TableName: "学员列表",
      tableData: [],
      deleteList: [],
      AddServiceStudentShow: false,
      ScreenServiceStudentShow: false,
      ConfirmCollegesShow: false,
      dialogFormVisible: false,
      ConfirmCollegesContent: {},
      studentDialog: false,
      addRecordDialog: false,
      rowInfo: {},
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        keyword: "",
        crm_archive: "0",
        follow: "",
        audit_status: 1,
      },
      // 防抖节流锁
      lock: true,
      hoursObj: {},
      // 批量修改watcher弹窗
      BatchEditWatcherDialog: false,
      student_unread_msg: [],

      nowStudent_id: null,
      student_realname: null,
      gradeDialog: false,
      id: null,
      editId: null,
      filtrate:1
    };
  },
  mounted() {
    this.getList(this.query);
    this.getOptions();
  },
  filters: {
    formate: function (val) {
      if (val == null || val == 1) {
        return "";
      }
      var str = "";
      val.forEach((item) => {
        str += item.alias + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return str;
      }
    },
    formateSub: function (val) {
      if (val == null || val == 1) {
        return "";
      }
      var str = "";
      val.forEach((item) => {
        str += `(${item.subject_code})${item.subject_name}` + "，";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return str;
      }
    },
    formateProducts: function (val) {
      if (val == null || val == 1) {
        return "";
      }
      var str = "";
      val.forEach((item) => {
        str += item.product_name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return str;
      }
    },
    formateWatcher: function (val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.alias + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
  },
  methods: {
    filtrateChange(type){
        this.query.follow = ''
        if(type==2){
           this.query.follow = 1
        }
        this.getList(this.query)
    },
    hanleAdd() {
      this.id = null;
      this.AddServiceStudentShow = true;
    },
    hanleUp(row) {
      this.id = row.id;
      this.AddServiceStudentShow = true;
    },
    opengradeDialog(row) {
      this.nowStudent_id = row.student_id;
      this.student_realname = row.student_realname;
      this.gradeDialog = true;
    },
    getOptions() {
      this.$store.dispatch("getOptions");
      this.$store.dispatch("getAllColleges");
    },
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val;
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    // 获取表格列表
    getList() {
      const params = Object.assign({}, this.query);

      if (params.student_order_sync_statuses) {
        params.student_order_sync_statuses =
          params.student_order_sync_statuses.join(",");
      }
      studentorderlist(params)
        .then((res) => {
          this.tableData = res.data.list || [];
          this.pageTotal = res.data.count;
          if (this.tableData.length) {
            let student_ids = this.tableData.map((item) => item.student_id);
            this.userStudentUnreadMsg(student_ids);
          }

          this.tableData.forEach((item, index) => {
            this.$set(item, "learnhours", 0); // 设置属性
            if (item.old_id == null) {
              return false;
            }
            let forQuery = {
              id: item.old_id,
              newId: item.id,
            };
            // 请求每一条数据的详情
            recorddetail(forQuery).then((response) => {
              console.log(response);
              if (response.data.message == "ok") {
                let arr = response.data.data.records;
                // 判断，为空则不计算
                if (arr.length > 0) {
                  // 求和
                  let a = arr.reduce((acc, cur) => {
                    return acc + cur.hours;
                  }, 0);
                  // 赋值
                  item.learnhours = a;
                }
              }
              this.$set(this.hoursObj, index, item.learnhours);
            });
          });
        })
        .then(() => {
          setTimeout(() => {
            this.getListTwice(params);
          }, 100);
        });
    },
    userStudentUnreadMsg(ids) {
      let str = manageUrl();
      this.$axios({
        url: `${str}/api/v1/lesson/learnManageRecord/getUserStudentUnreadMsg`,
        method: "post", //可以省略 不写默认是get
        data: {
          arr_student_id: ids,
        },
      }).then((res) => {
        // console.log('res:', res)
        if (res.code == 0) {
          this.$nextTick(() => {
            this.student_unread_msg = res.data.arr_map_student_unread_msg.map(
              (item) => item[1]
            );
          });
          // console.log("this.student_unread_msg:", this.student_unread_msg);
        }
      });
    },

    getListTwice(query) {
      studentorderlist(query).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
        if (JSON.stringify(this.hoursObj) != "{}") {
          this.tableData.forEach((item, index) => {
            this.$set(item, "learnhours", this.hoursObj[index]);
          });
        }
      });
    },
    addStudent({ form }) {
      let query = form;
      createstudentorder(query).then((res) => {
        this.getList(this.query);
        this.AddServiceStudentShow = false;
      });
    },
    upSubmit({ form }) {
      let query = form;
      console.log("query:", query);
      upStudentOrder(query).then((res) => {
        this.getList(this.query);
        this.AddServiceStudentShow = false;
        if (![1, 2].includes(query.service_status)) {
          this.$notify.success({
            title: "提示",
            message: "变更申请已提交，等待对接人确认后操作",
          });
          // this.$message({
          //   message: "变更申请已提交，等待对接人确认后操作",
          //   type: "success",
          // });
        } else {
          this.$notify.success({
            title: "提示",
            message: "修改成功！",
          });
        }
      });
    },
    openStudentDialog(item) {
      this.studentForm = JSON.parse(JSON.stringify(item));
      this.studentDialog = true;
    },
    goRecord(item) {
   
      //  console.log('item:', window.location)
       window.open(
        `/login#/service/studentmanagement/record?name=${item.student_realname}&student_id=${item.student_id}&pageIndex=1&pageSize=10&isPaging=1`
      );
      // this.$router.push({
      //   name: `servicerecord`,
      //   query: {
      //     name: item.student_realname,
      //     student_id: item.student_id,
      //     pageIndex: 1,
      //     pageSize: 10,
      //     isPaging: 1,
      //   },
      // });
    },
    // 打开新增学生跟进记录组件
    openAddRecordDialog(item) {
      this.addRecordDialog = true;
      this.rowInfo = JSON.parse(JSON.stringify(item));
    },
    // 新增跟进记录提交
    addRecordSubmit({ form }) {
      let query = {
        cid: localStorage.getItem("cid"),
        student_id: this.rowInfo.student_id,
        order_id: this.rowInfo.id,
        content: form.content,
      };
      createserviceadvance(query).then((res) => {
        this.$nextTick(() => {
          this.getList(this.query);
          this.addRecordDialog = false;
        });
      });
    },
    termination(row) {
      this.editId = row.id;
      this.title = "终止";
      this.form = {};
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });

      // if (this.deleteList.length <= 0) {
      //   this.$notify.error({
      //     title: "提示",
      //     message: "还没有选择要终止的数据",
      //   });
      //   return;
      // }

      // let flag = this.deleteList.every((item) =>
      //   [1, 2].includes(item.service_status)
      // );
      // if (flag) {
      // let data = {
      //   id: row.id,
      //   service_status: 10,
      // };
      // this.$confirm("确定要终止选中的数据吗", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     editstudentorderlist({
      //       rows: data,
      //     }).then((res) => {
      //       if (res.code == 0) {
      //         this.$notify.success({
      //           title: "提示",
      //           message: "终止成功",
      //         });
      //         this.dialogVisible = false;
      //       }
      //       this.$nextTick(() => {
      //         this.getList(this.query);
      //       });
      //     });
      //   })
      //   .catch(() => {
      //     this.$notify.error({
      //       title: "提示",
      //       message: "已取消终止",
      //     });
      //   });
      // }

      // else {
      //   this.title = "终止";
      //   this.form = {};
      //   this.dialogVisible = true;
      //   this.$nextTick(() => {
      //     this.$refs.ruleForm.clearValidate();
      //   });
      // }
    },
    delSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // let row = this.deleteList.map((item) => {
          //   return {
          //     id: item.id,
          //     end_reason: [1, 2].includes(item.service_status)
          //       ? ""
          //       : this.form.delete_reason,
          //     service_status: 10,
          //   };
          // });
          let row = [
            {
              id: this.editId,
              end_reason: this.form.delete_reason,
              service_status: 10,
            },
          ];

          editstudentorderlist({
            rows: row,
          }).then((res) => {
            if (res.code == 0) {
              this.$notify.success({
                title: "提示",
                message: "终止服务申请已提交，等待对接人确认后操作",
              });
              this.dialogVisible = false;
            }
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        }
      });
      // if (this.title == "删除") {
      //   this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //       let ids = this.deleteList.map((item) => item.id);
      //       let query = {
      //         ids: ids,
      //         delete_reason: this.form.delete_reason,
      //       };
      //       deletestudentorderlist(query).then((res) => {
      //         if (res.code == 0) {
      //           this.$notify.success({
      //             title: "提示",
      //             message: "删除申请已提交，等待对接人确认后操作",
      //           });

      //           this.dialogVisible = false;
      //         }
      //         this.$nextTick(() => {
      //           this.getList(this.query);
      //         });
      //       });
      //     }
      //   });
      // } else {
      //   this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //       let row = this.deleteList.map((item) => {
      //         return {
      //           id: item.id,
      //           end_reason: [1, 2].includes(item.service_status)
      //             ? ""
      //             : this.form.delete_reason,
      //           service_status: 10,
      //         };
      //       });

      //       editstudentorderlist({
      //         rows: row,
      //       }).then((res) => {
      //         if (res.code == 0) {
      //           this.$notify.success({
      //             title: "提示",
      //             message: "终止服务申请已提交，等待对接人确认后操作",
      //           });
      //           this.dialogVisible = false;
      //         }
      //         this.$nextTick(() => {
      //           this.getList(this.query);
      //         });
      //       });
      //     }
      //   });
      // }
    },
    deleteItem() {
      if (this.deleteList.length <= 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要删除的数据",
        });
        return;
      }
      let flag = this.deleteList.every((item) =>
        [1, 2].includes(item.service_status)
      );
      let ids = this.deleteList.map((item) => item.id);
      if (flag) {
        this.$confirm("确定要删除选中的数据吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let query = {
              ids: ids,
            };
            deletestudentorderlist(query).then((res) => {
              if (res.code == 0) {
                this.$notify.success({
                  title: "提示",
                  message: "删除成功",
                });
              }
              this.$nextTick(() => {
                this.getList(this.query);
              });
            });
          })
          .catch(() => {
            this.$notify.error({
              title: "提示",
              message: "已取消删除",
            });
          });
      } else {
        this.form = {};
        this.title = "删除";

        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate();
        });
      }
      // for (let i = 0; i < this.deleteList.length; i++) {
      //   if (
      //     this.deleteList[i].service_status == 1 ||
      //     this.deleteList[i].service_status == 2 ||
      //     this.deleteList[i].service_status == 10
      //   ) {
      //     this.$confirm("确定要删除选中的数据吗", "提示", {
      //       confirmButtonText: "确定",
      //       cancelButtonText: "取消",
      //       type: "warning",
      //     })
      //       .then(() => {
      //         let query = {
      //           ids: [],
      //         };
      //         this.deleteList.forEach((item) => {
      //           query.ids.push(item.id);
      //         });
      //         deletestudentorderlist(query).then((res) => {
      //           if (res.code == 0) {
      //             this.$notify.success({
      //               title: "提示",
      //               message: "删除成功",
      //             });
      //           }
      //           this.$nextTick(() => {
      //             this.getList(this.query);
      //           });
      //         });
      //       })
      //       .catch(() => {
      //         this.$notify.error({
      //           title: "提示",
      //           message: "已取消删除",
      //         });
      //       });
      //   } else {
      //     this.$notify.error({
      //       title: "提示",
      //       message:
      //         "仅课程状态为“待定校”“待推送”“课程终止”的订单可删除，请重新选择",
      //     });
      //     return false;
      //   }
      // }
    },
    editStatus(status, id, text) {
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            service_status: status,
            id: id,
          };
          editstudentorder(query).then((res) => {
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消修改",
          });
        });
    },
    openConfirmCollegesShow(item) {
      this.ConfirmCollegesContent = JSON.parse(JSON.stringify(item));
      this.formateStudent();
      this.ConfirmCollegesShow = !this.ConfirmCollegesShow;
    },
    ConfirmCollegesSubmit({ form }) {
      form.service_status = 2;
      console.log(form);
      let query = form;
      editstudentorder(query).then((res) => {
        this.$nextTick(() => {
          this.getList(this.query);
          this.ConfirmCollegesShow = false;
        });
      });
    },
    screenTable({ screenForm }) {
      this.$set(this.query, "pageIndex", 1);
      this.query = {
        ...this.query,
        ...screenForm,
      };
      this.getList(this.query);
    },
    // 搜索框搜索
    inputSearch(e) {
      this.getList(this.query);
    },
    editInfo() {
      if (!/^1[123456789]\d{9}$/.test(this.studentForm.student_phone)) {
        this.$message({
          message: "请填写正确的手机号",
          type: "error",
          duration: 1000,
        });
        return false;
      }
      let query = {
        id: this.studentForm.id,
        student_realname: this.studentForm.student_realname,
        student_qq: this.studentForm.student_qq,
        student_wechat: this.studentForm.student_wechat,
        student_phone: this.studentForm.student_phone,
        remark: this.studentForm.remark,
      };
      editstudentorder(query).then((res) => {
        this.$nextTick(() => {
          this.studentDialog = false;
          this.dialogFormVisible = false;
          if (this.studentForm.old_id == null) {
            this.getList(this.query);
            return false;
          }
          // 总端修改成功之后还要再调一次老系统的接口同步数据
          outsourcingedit(formateObj(this.studentForm, "edit")).then(
            (response) => {
              if (response.data.data.message == "ok") {
                this.getList(this.query);
              } else {
                this.$notify.error({
                  title: "提示",
                  message: response.data.data.message,
                });
              }
            }
          );
        });
      });
    },
    formateStudent() {
      // 回显做处理
      // 把watcher参数转换为处理并转换字符串，必须要number格式
      if (this.ConfirmCollegesContent.watcher) {
        let arr = [];
        this.ConfirmCollegesContent.watcher.forEach((item) => {
          arr.push(item.uid);
        });
        arr = arr.map((v) => Number(v));
        this.ConfirmCollegesContent.watcher = arr;
      }
      // 把products参数转换为处理并转换字符串，必须要number格式
      if (this.ConfirmCollegesContent.products) {
        let arr2 = [];
        this.ConfirmCollegesContent.products.forEach((item) => {
          arr2.push(item.product_id);
        });
        arr2 = arr2.map((v) => Number(v));
        this.ConfirmCollegesContent.products = arr2;
      }
      // 把subjects参数转换为处理并转换字符串，必须要number格式
      if (this.ConfirmCollegesContent.subjects) {
        let arr3 = [];
        this.ConfirmCollegesContent.subjects.forEach((item) => {
          arr3.push(item.subject_code);
        });
        this.ConfirmCollegesContent.subjects = arr3;
      }
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 2,
          follow_id: item.id,
        };
        createuserfollow(query).then((res) => {
          this.getList(this.query);
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 500);
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 2,
          follow_id: item.id,
        };
        deleteuserfollow(query).then((res) => {
          this.getList(this.query);
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 300);
      }
    },
    // 右上角的全部 已归档 未归档
    radioChange(e) {
      this.getList(this.query);
    },
    opendialogFormVisible(item) {
      this.studentForm = JSON.parse(JSON.stringify(item));
      this.dialogFormVisible = true;
    },
    // 批量修改
    chooseAllEdit(crm_archive_status, text) {
      if (this.deleteList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            rows: [],
          };
          this.deleteList.forEach((item) => {
            let obj = {
              id: item.id,
              crm_archive: crm_archive_status,
            };
            query.rows.push(obj);
          });
          editstudentorderlist(query).then((res) => {
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消修改",
          });
        });
    },
    // 对多选数组做判断处理
    filterArr() {
      if (this.deleteList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      let flag = this.deleteList.findIndex((item) => item.service_status != 2);
      if (flag == -1) {
        this.$confirm("您确定要推送吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let query = {
              rows: [],
            };
            query.rows = this.deleteList.map((v) => {
              let obj = {
                id: v.id,
                service_status: 3,
              };
              return obj;
            });
            editstudentorderlist(query).then((res) => {
              if (res.code == 0) {
                this.$notify.success({
                  title: "提示",
                  message: "推送成功",
                });
                this.getList(this.query);
              }
            });
          })
          .catch(() => {
            this.$notify.error({
              title: "提示",
              message: "已取消操作",
            });
          });
      } else {
        this.$notify.error({
          title: "提示",
          message: "选中订单中部分订单不处于待推送状态",
        });
      }
    },
    // 跳转导入页面
    goImportFile() {
      this.$router.push({
        name: `importfile`,
        query: { postUrl: "/api/v1/student/order/list" },
      });
    },
    formatePrecent(row) {
      if (row.course_progress) {
        return Number((Number(row.course_progress) * 100).toFixed(1));
      } else {
        return 0;
      }
      // let num = (
      //   (Number(row.learnhours) / Number(row.service_hours)) *
      //   100
      // ).toFixed(0);
      // if (isNaN(num)) {
      //   return 0;
      // } else {
      //   return Number(num);
      // }
    },
    openBatchEditWatcherDialog() {
      if (this.deleteList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.BatchEditWatcherDialog = true;
    },
    BatchEditWatcherSubmit(obj) {
      let query = {
        rows: this.deleteList.map((v) => {
          return {
            id: v.id,
            watcher: obj.watcher,
          };
        }),
      };
      editstudentorderlist(query).then((res) => {
        this.$nextTick(() => {
          this.getList(this.query);
          this.$notify.success({
            title: "提示",
            message: "修改成功",
          });
          this.BatchEditWatcherDialog = false;
        });
      });
    },
    product_watchersFormate(value) {
      if (value && value.length) {
        let map_val = value.map((v) => {
          return v.alias;
        });
        return map_val.join(",");
      } else {
        return "无";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.badge {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.breadcrumb {
  display: flex;
  align-items: center;
  height: 35px;
  justify-content: space-between;
}
.operation-menu {
  display: flex;
  justify-content: space-between;
  .menu_left {
    margin-top: 25px;
  }
  .menu_right {
    margin-top: 25px;
  }
}
.star {
  width: 18px;
  height: 18px;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
}
.input-with-select {
  margin-bottom: 16px;
}
.templateLink {
  .el-link {
    margin: 0px 5px;
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
}
/deep/.el-progress-bar__inner {
  max-width: 100%;
}
/deep/.red-text {
  .el-progress-bar__inner {
    background-color: red;
    
  }
}
</style>