<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>测试卷</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">提交管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button type="primary"
                   size="small"
                   @click="add()">
          新建
        </el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px;padding-top:20px;">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="学生姓名">
          <el-input v-model.trim="query.user_student_keyword "
                    placeholder="请输入学生姓名"
                    @input="getList(query,true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="批改状态">
          <el-select v-model="query.correct_statuses "
                     placeholder="请选择批改状态"
                     @change="getList(query,true)"
                     clearable
                     multiple>
            <el-option v-for="(item,index) in filters.correct_status"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程名称">
          <el-input v-model.trim="query.course_keyword"
                    placeholder="请输入课程名称"
                    @input="getList(query,true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="章节名称">
          <el-input v-model.trim="query.course_chapter_keyword"
                    placeholder="请输入章节名称"
                    @input="getList(query,true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="测试卷名称">
          <el-input v-model.trim="query.measure_scroll_keyword "
                    placeholder="请输入测试卷名称"
                    @input="getList(query,true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="批改人">
          <el-select v-model="query.crm_correctors "
                     placeholder="请选择"
                     @change="getList(query,true)"
                     clearable
                     multiple>
            <el-option v-for="item in $store.state.staffOptions"
                       :key="item.id"
                       :label="item.alias"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提交时间">
          <el-date-picker class="datetime"
                          v-model="createDatetime"
                          value-format="timestamp"
                          format="yyyy-MM-dd HH:mm"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="getList(true)"
                          clearable
                          style="width:260px;">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="批改时间">
          <el-date-picker class="datetime"
                          v-model="correctDatetime"
                          value-format="timestamp"
                          format="yyyy-MM-dd HH:mm"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="getList(true)"
                          clearable
                          style="width:260px;">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName[0]"></TableTittle>
      </div>
      <el-table :data="tabList"
                :header-cell-style="{
            textAlign: 'center',
            color: '#909399',
            fontFamily: 'PingFang SC',
            fontSize: '14px',
            fontWeight: '400',
            backgroundColor: '#F2F6FC',
          }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                @selection-change="handleSelectionChange"
                border>
        <el-table-column type="selection"
                         width="55"> </el-table-column>
        <el-table-column type="index"
                         label="序号"
                         width="80">
        </el-table-column>
        <el-table-column prop="ref_student_realname"
                         label="学生姓名"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.ref_student_realname"> {{scope.row.ref_student_realname}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop=""
                         label="批改状态"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="success"
                    v-if="scope.row.correct_status ==2">已批改</el-tag>
            <el-tag type="warning"
                    v-if="scope.row.correct_status ==1">待批改</el-tag>
            <el-tag type="danger"
                    v-if="scope.row.correct_status ==3">已拒绝</el-tag>
          </template>

        </el-table-column>

        <el-table-column prop="ref_course_name"
                         label="课程名称"
                         width="260"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="ref_course_chapter_name"
                         label="章节名称"
                         width="260"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="ref_measure_scroll_name"
                         label="试卷名称"
                         width="260"
                         show-overflow-tooltip>
        </el-table-column>

        <el-table-column prop=""
                         label="批改人"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.ref_crm_corrector_obj"> {{scope.row.ref_crm_corrector_obj.alias}}</span>
            <span v-else>暂无</span>
          </template>
        </el-table-column>
        <el-table-column prop=""
                         label="考试分值"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.correct_score}}分</span>
          </template>
        </el-table-column>
        <el-table-column label="提交时间"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column label="批改时间"
                         width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.correct_time !=null"> {{scope.row.correct_time?$moment(scope.row.correct_time).format("YYYY-MM-DD HH:mm"):""}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToExtension(scope.row)">分配</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToDetail(scope.row)">查看</el-link>

          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px; justify-content: space-between;">
        <div class="blockLeft"> <span>已选{{checkLength}}条 |</span> <span>批量</span> <span class="distriute"
                @click="toDistrite()">分配</span></div>
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 分配 -->
    <disturite :chooseList="chooseList"
               :visible="visible"
               @getList="getList()"
               @emitClose="emitClose"></disturite>
    <!-- 新建 -->
    <addAnswer :addVisible="addVisible"
               @emitClose="addClose"
               @getList="getList">
    </addAnswer>

  </div>
</template>
<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import { paperList } from '../../../request/api'
import { adminUrl } from '@/utils/helper.js'
import disturite from '@/components/DialogComponents/testPaper/disturite.vue'
import addAnswer from '@/components/DialogComponents/testPaper/addAnswer.vue'

export default {
  components: { TableTittle, disturite, addAnswer },
  data() {
    return {
      checkLength: 0,
      chooseList: [],
      row: {},
      visible: false,
      addVisible: false,
      tabList: [],
      TableName: ['提交列表'],
      query: { pageIndex: 1, pageSize: 10, isPaging: 1 },
      createDatetime: [],
      correctDatetime: [],
      filters: {
        correct_status: [
          { label: '待批改', value: '1' },
          { label: '已批改', value: '2' },
          { label: '已拒绝', value: '3' },
        ],
      },
      pageTotal: 0,
    }
  },
  created() {
    this.$store.dispatch('getStaffList')
    this.getList()
  },
  watch: {
    chooseList(val) {
      this.checkLength = val.length
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.chooseList = val
      console.log('this.chooseList', this.chooseList)
    },
    //测试卷列表
    getList() {
      const params = Object.assign({}, this.query)
      if (this.query.correct_statuses) {
        params.correct_statuses = this.query.correct_statuses.join(',')
      }
      if (this.query.crm_correctors) {
        params.crm_correctors = this.query.crm_correctors.join(',')
      }
      if (this.createDatetime) {
        params.start_create_time = this.createDatetime[0]
        params.end_create_time = this.createDatetime[1]
      }
      if (this.correctDatetime) {
        params.start_correct_time = this.correctDatetime[0]
        params.end_correct_time = this.correctDatetime[1]
      }

      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/measureScroll/userMeasureScroll/list`,
        method: 'get', //可以省略 不写默认是get
        params: params,
      }).then((res) => {
        this.tabList = res.data.list
        this.pageTotal = res.data.count
      })
    },
    add() {
      this.addVisible = true
    },
    addClose() {
      this.addVisible = false
    },
    handleToExtension(row) {
      this.chooseList = []
      this.chooseList.push(row)
      this.visible = true
    },
    toDistrite() {
      if (this.chooseList.length == 0) {
        this.$message({
          message: '至少选择一条数据',
          type: 'warning',
        })
        return
      }
      this.visible = true
    },
    handleToDetail(row) {
      this.$router.push(`/testPaper/testDetail/${row.id}`)
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getList()
    },
    emitClose() {
      this.visible = false
    },
  },
}
</script>
<style lang="less" scoped>
.blockLeft {
  .distriute {
    cursor: pointer;
    margin-left: 8px;
    font-size: 12px;
    display: inline-block;
    width: 40px;
    text-align: center;
    line-height: 20px;
    height: 20px;
    border: 1px solid #409eff;
    color: #409eff;
    border-radius: 3px;
  }
}
// .box {
//   display: flex;
//   justify-content: space-between;
// }
</style>