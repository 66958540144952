<template>
  <div>
    <el-dialog
      title="考试详情"
      :visible.sync="dialogFormVisible"
      width="700px"
      @close="emitClose"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="考试学员："
          prop="student_id_list"
        >
          <el-link
            type="primary"
            :underline="false"
            @click="studentDialog=true"
          >选择用户</el-link>
          <div class="student-tag">
            <el-tag
              :key="index"
              v-for="(item,index) in students"
              closable
              :disable-transitions="false"
              @close="removeStudents(index)"
            >
              {{item.realname}}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item
          label="考试试卷："
          prop="exam_paper_id"
        >
          <el-select
            v-model="dataForm.exam_paper_id"
            placeholder="请选择考试试卷"
            clearable
          >
            <el-option
              v-for="(item,index) in paperlist"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="阅卷人："
          prop="corrector_"
        >
          <el-select
            v-model="dataForm.corrector_"
            placeholder="请输入手机号搜索学长信息"
            clearable
            filterable
            remote
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="(item,index) in seniorList"
              :key="index"
              :label="item.realname"
              :value="item.id"
            >
              <span>{{ `${item.realname}/${item.mobile}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="考试时间："
          prop="exam_time"
        >
          <el-date-picker
            v-model="dataForm.exam_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="timestamp"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="上传答题："
          prop="content_answer"
        >
          <el-upload
            :action="imgUploadUrl"
            multiple
            :on-success="handleFileSuccess"
            :limit="3"
            :file-list="fileList"
            accept=".png,.jpg,.jpeg,.pdf,.doc,.xlsx,.zip,.xls,.docx,.pptx,.ppt,"
            :on-remove="handleRemove"
          >
            <el-button
              slot="trigger"
              size="small"
              type="primary"
            >点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
    <StudentChoose
      :visible="studentDialog"
      :content="students"
      @close="studentDialog=false"
      @submit="chooseStudent"
      :single="true"
    />
  </div>
</template>

<script>




import StudentChoose from "@/components/DialogComponents/Service/StudentChoose.vue";
import { imgUploadUrl, fileUploadUrl } from "@/request/http";
import { mockexamprocessEdit, mockexampaperlist, mockexamprocessOne } from "@/request/api";

export default {
  components: {
    StudentChoose
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      fileList: [],
      rules: {
        exam_time: [{ required: true, message: "请选择考试时间", trigger: "blur" }],
        exam_paper_id: [{ required: true, message: "请选择考试试卷", trigger: "change" }],
        corrector: [{ required: true, message: "请选择阅卷人", trigger: "change" }],
        student_id_list: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.students.length) {
              callback(new Error('请选择发放学员'))
            } else {
              callback()
            }
          },
        }],
        content_answer: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.fileList.length) {
              callback(new Error('请上传答题'))
            } else {
              callback()
            }
          },
        }],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      studentDialog: false,
      students: [],
      paperlist: [],
      imgUploadUrl: imgUploadUrl(),
      loading: false,
      seniorList: [],

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchPaperList()
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      mockexamprocessOne({ id: this.id }).then((res) => {
        this.dataForm = res.data
        this.students = [
          res.data.student
        ]
        this.$set(this.dataForm, 'exam_time', [res.data.start_exam_time, res.data.end_exam_time])
        this.$set(this.dataForm, 'corrector_', res.data.corrector.id)
        this.seniorList.push(res.data.corrector)
        this.fileList = res.data.content_answer ? JSON.parse(res.data.content_answer) : []
      });
    },
    fetchPaperList() {
      mockexampaperlist({ isPaging: 1, check_status: 1 }).then((res) => {
        this.paperlist = res.data.list
      });
    },
    emitClose() {
      this.$emit("close");
    },

    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            student_id_list: this.students.map(v => {
              return v.id
            }),
            start_exam_time: this.dataForm.exam_time[0],
            end_exam_time: this.dataForm.exam_time[1],
            exam_paper_id: this.dataForm.exam_paper_id,
            corrector: this.dataForm.corrector_,
            content_answer: JSON.stringify(this.fileList),

          }
          if ([1,4].includes(this.dataForm.exam_status)) {
            query.exam_status = 3
          }
          mockexamprocessEdit(query).then((res) => {
            this.$message.success('提交成功')
            this.$emit('submit')
            this.emitClose()
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    chooseStudent({ data }) {
      this.students = data
    },
    removeStudents(index) {
      this.students.splice(index, 1)
    },
    remoteMethod(query) {
      let reg = /^(-|\+)?\d+$/
      if (reg.test(query)) {
        this.$store.dispatch('fetchAdminSeniorList', { mobile: query })
        setTimeout(() => {
          this.seniorList = this.$store.state.seniorList
        }, 500);
      } else {
        this.$message.error('请输入完整的手机号')
      }
    },
    handleFileSuccess(response, file, fileList) {
      this.fileList.push({
        name: file.raw.name,
        url: response.data.url,
      })
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
  },
};
</script>

<style lang="less" scoped>
.el-input,
.el-select {
  width: 100%;
}
.upload-demo {
  overflow: hidden;
  height: 35px;
}
.file-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .file-item {
    & + .file-item {
      margin-top: 16px;
    }
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .btn {
        width: 56px;
        height: 24px;
        border: 1px solid #dcdfe6;
        border-radius: 20px;
        color: #606266;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }
    }
  }
}
</style>