<template>
  <div class="content">
    <div class="demo-drawer__content">
      <el-form
        :label-position="labelPosition"
        :model="form"
        :inline="true"
        size="small"
      >
        <el-form-item label="账单状态">
          <el-select
            v-model="form.status"
            placeholder="请选择账单状态"
            clearable
            @change="submit"
          >
            <el-option label="待确定" value="0"></el-option>
            <el-option label="待支付" value="1"></el-option>
            <el-option label="已支付" value="2"></el-option>
            <el-option label="已完成" value="3"></el-option>
            <el-option label="已取消" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账单周期" label-width="70px">
          <el-date-picker
            v-model="bill_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="submit"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="demo-drawer__footer">
        <!--         <el-button @click="emitClose" class="elbutton" size="small"
          >取 消</el-button
        >
        <el-button type="primary" class="elbutton" size="small">确定</el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      labelPosition: "left",
      bill_time: "",
      status: [],
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit() {
      // 创建时间区间处理
      if (this.bill_time) {
        this.form.start_bill_time = this.bill_time[0];
        this.form.end_bill_time = this.bill_time[1] + 86399000;
      } else {
        this.form.start_bill_time = "";
        this.form.end_bill_time = "";
      }
      this.$emit("submit", { screenForm: this.form });
    },
  },
};
</script>

<style lang="less" scoped>
.demo-drawer__content {
  padding-top: 24px;
  height: 100%;
}
.demo-drawer__footer {
  margin-bottom: 24px;
  float: right;
}
</style>