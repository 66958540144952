<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>导入</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card>
      <div class="main">
        <div class="main-item">
          <div class="main-item-titleimg">
            <img
              src="../../assets/images/importstep1.png"
              alt=""
            />
          </div>
          <div class="html-content-div">
            <span v-html="textContent"></span>
          </div>
          <el-checkbox
            v-model="checked"
            style="margin-top: 24px"
          >我已阅读完上述关于模板的各个问题描述</el-checkbox>
          <div class="content-sub-div">
            <el-button
              type="primary"
              @click="downloadTemplate"
            >点击下载模板</el-button>
            <span>如已熟知或已下载 可跳过此步骤</span>
          </div>
        </div>
        <div
          class="main-item"
          style="border-right: dashed 1px; border-left: dashed 1px"
        >
          <div class="main-item-titleimg">
            <img
              src="../../assets/images/importstep2.png"
              alt=""
            />
          </div>
          <div style="margin: 0 auto; margin-top: 24px">
            <el-upload
              drag
              class="upload-demo"
              :action="actionsUrl + postUrl"
              :headers="myAuthorization"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :file-list="fileList"
              accept=".xlsx,.xls"
              :limit="1"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div
                class="el-upload__tip"
                slot="tip"
              >
                只能上传xlsx/xls文件，且不超过10M
              </div>
            </el-upload>
            <!--        <el-upload
              class="upload-demo"
              :action="actionsUrl + '/api/v1/student/list'"
              :headers="myAuthorization"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :file-list="fileList"
              accept=".xlsx"
            >
              <el-input
                placeholder="请上传表格"
                v-model="uploadValue"
                style="width: 120%"
              >
                <el-button size="small" type="primary" slot="append"
                  >点击上传</el-button
                >
              </el-input>
            </el-upload> -->
          </div>
        </div>
        <div class="main-item">
          <div class="main-item-titleimg">
            <img
              src="../../assets/images/importstep3.png"
              alt=""
            />
          </div>
          <el-form>
            <el-form-item style="margin-top: 20px">
              <el-radio-group
                v-model="tableRadio"
                @change="radioChange"
              >
                <el-radio-button label="1">上传成功({{ listnum.sucList }})</el-radio-button>
                <el-radio-button label="2">重复上传({{ listnum.repeatList }})</el-radio-button>
                <el-radio-button label="3">格式错误({{ listnum.errorList }})</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-table
                :data="tableData"
                style="width: 100%"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                :header-cell-style="{
                  textAlign: 'center',
                  color: '#909399',
                  fontFamily: 'PingFang SC',
                  fontSize: '14px',
                  fontWeight: '400',
                }"
                height="640px"
              >
                <el-table-column
                  prop="date"
                  label="序号"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.order }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="状态"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <el-link
                      size="small"
                      type="success"
                      v-show="tableRadio == '1'"
                      :underline="false"
                    >上传成功</el-link>
                    <el-link
                      size="small"
                      type="warning"
                      v-show="tableRadio == '2'"
                      :underline="false"
                    >{{ scope.row.errInfo }}</el-link>
                    <el-link
                      size="small"
                      type="danger"
                      v-show="tableRadio == '3'"
                      :underline="false"
                    >{{ scope.row.errInfo }}</el-link>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { hostUrl } from "../../utils/helper"; // 环境判断
let mytoken = {
  Authorization: localStorage.getItem("token"),
}; // 要保证取到token
// 模板说明，根据不同接口获取不同说明
import AdministrationText from "./AdministrationText";
import ServiceText from "./ServiceText";
export default {
  components: {},
  data() {
    return {
      fileList: [],
      actionsUrl: "", // 接口地址，判断是线上还是测试环境
      postUrl: "", // 请求地址
      myAuthorization: mytoken,
      tableData: [],
      tableRadio: "1",
      resposeData: {
        sucList: [],
        repeatList: [],
        errorList: [],
      },
      // 请求之后数组的长度
      listnum: {
        sucList: 0,
        repeatList: 0,
        errorList: 0,
      },
      uploadValue: "", // 上传之后文件名显示在输入框里
      textContent: "",
      checked: false,
    };
  },
  created() {
    this.actionsUrl = hostUrl();
    this.postUrl = this.$route.query.postUrl; // 判断请求接口，根据路由对象看
    this.$route.query.postUrl == "/api/v1/student/list"
      ? (this.textContent = AdministrationText.htmlContent)
      : (this.textContent = ServiceText.htmlContent); //根据接口判断下载模板的说明
  },
  computed: {},
  methods: {
    handleRemove(file) {
      this.uploadValue = "";
    },
    handleSuccess(file, fileList) {
      this.uploadValue = fileList.name;
      this.resposeData = file.data;
      this.tableData = this.resposeData.sucList;
      this.listnum = {
        sucList: file.data.sucList.length,
        repeatList: file.data.repeatList.length,
        errorList: file.data.errorList.length,
      };
    },
    radioChange(e) {
      switch (e) {
        case "1":
          this.tableData = this.resposeData.sucList;
          break;
        case "2":
          this.tableData = this.resposeData.repeatList;
          break;
        case "3":
          this.tableData = this.resposeData.errorList;
          break;
        default:
          break;
      }
    },
    downloadTemplate() {
      if (!this.checked) {
        this.$notify.error({
          title: "提示",
          message: "请勾选",
        });
        return false;
      }
      let a = document.createElement("a");
      switch (this.postUrl) {
        case "/api/v1/student/list":
          a.href =
            `${window.location.protocol}//upload.kaoyanbox.net/f3e622dfaa063837bc9be18ac2330778.xlsx`;
          break;
        case "/api/v1/student/order/list":
          a.href =
            `${window.location.protocol}//upload.kaoyanbox.net/d6456007c0d41d7a34fbdb9a64427ad0.xlsx`;
          break;
        default:
          break;
      }
      a.click();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
}
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.el-card {
  margin-top: 20px;
}
.main {
  flex: 1;
  display: flex;
  justify-content: space-around;
  height: 770px;

  .main-item {
    width: 32%;
    padding: 0px 15px;
    .main-item-titleimg {
      height: 6%;
      img {
        width: 128px;
        height: 40px;
      }
    }
  }
}
.el-radio-group {
  width: 100%;
  .el-radio-button {
  }
}
.upload-demo {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.upload-dragger {
  border: solid;
}
.html-content-div {
  margin-top: 24px;
  height: 70%;
  overflow: auto;
}
.content-sub-div {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .el-button {
    width: 45%;
  }
  span {
    margin-top: 12px;
    display: block;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #c0c4cc;
    opacity: 1;
  }
}
</style>