<template>
  <div class="content">
    <el-dialog
      title="设置登录密码"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="350px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-position="right"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item label="" prop="firstPwd">
          <el-input
            placeholder="请输入登录密码"
            v-model="form.firstPwd"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="secondPwd">
          <el-input
            placeholder="请再次输入登录密码"
            v-model="form.secondPwd"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">

        <el-button type="primary" @click="submit('formRules')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      form: {},
      firstPwd: "",
      secondPwd: "",
      rules: {
        firstPwd: [
          { required: true, message: "请填写设置的密码", trigger: "blur" },
        ],
        secondPwd: [
          { required: true, message: "请再次填写设置的密码", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          if (this.form.firstPwd !== this.form.secondPwd) {
            this.$message({
              message: "两次密码输入不一致",
              type: "error",
              duration: 1000,
            });
            return false;
          }
          this.$emit("submit", { form: this.form });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
.el-input {
  width: 318px;
}
</style>