
import Planning from "../views/QuestionAnswering/Planning/index.vue";
import PlanningAdd from "../views/QuestionAnswering/Planning/planningAdd.vue";
import ExaminationOutline from "../views/QuestionAnswering/ExaminationOutline/index.vue";
import ExaminationOutlineAdd from "../views/QuestionAnswering/ExaminationOutline/examinationOutlineAdd.vue";
import PropositionalTendency from "../views/QuestionAnswering/PropositionalTendency/index.vue";
import PropositionalTendencyAdd from "../views/QuestionAnswering/PropositionalTendency/propositionalTendencyAdd.vue";
import EnrollmentForecast from "../views/QuestionAnswering/EnrollmentForecast/index.vue";
import EnrollmentForecastAdd from "../views/QuestionAnswering/EnrollmentForecast/enrollmentForecastAdd.vue";
export default [
   
    {
        path: '/questionAnswering/planning',
        component: Planning,
        meta: { title: '教学计划' }
    },
    {
        path: '/questionAnswering/planning/planningAdd',
        component: PlanningAdd,
        meta: { title: '教学计划新增' }
    },
    {
        path: '/questionAnswering/examinationOutline',
        component: ExaminationOutline,
        meta: { title: '考试大纲' }
    },
    {
        path: '/questionAnswering/examinationOutline/examinationOutlineAdd',
        component: ExaminationOutlineAdd,
        meta: { title: '考试大纲新增' }
    },

    {
        path: '/questionAnswering/propositionalTendency',
        component: PropositionalTendency,
        meta: { title: '命题趋势' }
    },
    {
        path: '/questionAnswering/propositionalTendency/propositionalTendencyAdd',
        component: PropositionalTendencyAdd,
        meta: { title: '考试大纲新增' }
    },
    
    {
        path: '/questionAnswering/enrollmentForecast',
        component: EnrollmentForecast,
        meta: { title: '招生预测' }
    },
    {
        path: '/questionAnswering/enrollmentForecast/enrollmentForecastAdd',
        component: EnrollmentForecastAdd,
        meta: { title: '招生预测新增' }
    }


     // enrollmentForecastAdd
]