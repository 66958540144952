<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>提交管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">提交管理查看</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px;padding-top:5px;">
      <div class="card-header">
        <TableTittle :tittle="TableName[0]"></TableTittle>

      </div>
      <el-descriptions :column="3"
                       border>
        <el-descriptions-item labelClassName="my-label">
          <template slot="label">
            学员信息
          </template>
          <span v-if="detail.ref_student_obj"> {{detail.ref_student_obj ?detail.ref_student_obj.realname :''}}/ {{detail.ref_student_obj ?detail.ref_student_obj.phone : ''}}</span>
          <span v-else>暂无</span>

        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            批改状态
          </template>
          <el-tag size="small"
                  type="warning"
                  v-if="detail.correct_status==1">待批改</el-tag>
          <el-tag size="small"
                  type="success"
                  v-if="detail.correct_status==2">已批改</el-tag>
          <el-tag size="small"
                  type="danger"
                  v-if="detail.correct_status==3">已拒绝</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            课程名称
          </template>
          {{detail ? detail.ref_course_obj.name :''}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            章节名称
          </template>
          {{detail.ref_course_chapter_obj.name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            试卷名称
          </template>
          {{detail.ref_measure_scroll_obj.name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            考试成绩
          </template>
          <span v-if="detail.correct_status==1 || detail.correct_status==3">暂无</span>
          <span v-else> {{detail.correct_score}}</span>
        </el-descriptions-item>
      </el-descriptions>

    </el-card>
    <div class="footerBox">
      <el-card style="margin-top: 20px;padding-top:5px;width:49%;height:calc(100vh - 380px);overflow-y:scroll">
        <TableTittle :tittle="PreviewName[0]"></TableTittle>
        <div>
          <test :testList="testList"></test>
        </div>
      </el-card>
      <el-card style="margin-top: 20px;padding-top:5px;width:49%;height: calc(100vh - 380px);overflow-y:scroll">
        <div class="bt">
          <TableTittle :tittle="SubmitName[0]"></TableTittle>
          <div> <el-button type="primary"
                       size="small"
                       @click="toCorrect">批改</el-button>
            <el-button type="danger"
                       size="small"
                       @click="toRefuse">驳回</el-button>
          </div>
        </div>

        <div class="imglist"
             v-if="imgList.length > 0">
          <div>
            <img v-for="(item,index) in imgList"
                 :key="index"
                 :src="item"
                 alt=""
                 srcset=""
                 class="img-pre">
          </div>
        </div>

        <div v-if="fileList.length>0">
          <div v-for="(item,index) in fileList"
               :key="index">
            <iframe class="iframe-modular"
                    v-if="item.indexOf('.pdf') != -1"
                    :src="item"
                    frameborder="0"></iframe>
            <iframe class="iframe-modular"
                    :src="baseurl+item"
                    frameborder="0"
                    v-else></iframe>
          </div>
        </div>
        <div>
        </div>
        <div>
        </div>
      </el-card>

    </div>
    <refuse :id="detail.id"
            :visibles="devisible"
            @emitClose="emitClose"
            @getList="getpaperDetail()">
    </refuse>
  </div>
</template>
<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import refuse from '@/components/DialogComponents/testPaper/refuse.vue'
import test from '@/components/test/index.vue'
import { adminUrl } from '@/utils/helper.js'
let baseurl = `https://view.officeapps.live.com/op/view.aspx?src=`
export default {
  components: { TableTittle, refuse, test },
  data() {
    return {
      devisible: false,
      baseurl: baseurl,
      correct_status: null,
      imgList: [],
      fileList: [],
      // imgList: [
      //   'https://upload.kaoyanbox.net/bec53b7a8c421a6372c3174a63481834.png',
      //   'https://upload.kaoyanbox.net/bec53b7a8c421a6372c3174a63481834.png',
      // ],
      // fileList: [
      //   'https://upload.kaoyanbox.net/5c5acae9d8853ff2e793339337eddbd7.docx',
      //   'https://upload.kaoyanbox.net/fd7d241260d2943f4aed0f0869871626.pptx',
      // ],
      TableName: ['提交管理'],
      PreviewName: ['试卷预览'],
      SubmitName: ['学生答题'],
      detail: {},
      questionList: [],
      testList: [],
    }
  },

  created() {
    this.getpaperDetail()
  },
  mounted() {},
  methods: {
    emitClose() {
      this.devisible = false
    },
    toRefuse() {
      this.devisible = true
    },
    toCorrect() {
      this.$router.push(`/testPaper/submitCorrect/${this.detail.id}`)
    },
    //试卷详情
    getpaperDetail() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/measureScroll/userMeasureScroll`,
        method: 'get', //可以省略 不写默认是get
        params: { id: this.$route.params.id },
      }).then((res) => {
        this.detail = res.data

        this.imgList = res.data.answer_img_urls
          ? res.data.answer_img_urls.split(',')
          : []
        this.fileList = res.data.answer_file_urls
          ? res.data.answer_file_urls.split(',')
          : []
        this.rubricList()
      })
    },
    //题目预览
    rubricList() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/measureScroll/examItem/list`,
        method: 'get', //可以省略 不写默认是get
        params: { measure_scroll_id: this.detail.ref_measure_scroll_obj.id },
      }).then((res) => {
        this.testList = res.data.list
      })
    },
  },
}
</script>
<style lang="less" scoped>
.footerBox {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  min-height: 100px;
  .bt {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .imglist {
    margin-top: 20px;
    padding-bottom: 80px;
    .img-pre {
      width: 96%;
      margin-left: 3%;
      margin: 0 auto;
      margin-bottom: 5px;
    }
  }
  .iframe-modular {
    width: 100%;
    flex: 1;
    height: 729px;
  }
}
</style>