<template>
  <div>
    <el-dialog
      title="新建试卷"
      :visible.sync="dialogFormVisible"
      width="700px"
      :close-on-click-modal="false"
      @close="emitClose"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="试卷名称："
          prop="name"
        >
          <el-input
            v-model.trim="dataForm.name"
            placeholder="请输入试卷名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="考研年份："
          prop="year"
        >
          <el-select
            v-model="dataForm.year"
            placeholder="请选择考研年份"
            clearable
          >
            <el-option
              label="2022"
              value="2022"
            ></el-option>
            <el-option
              label="2023"
              value="2023"
            ></el-option>
            <el-option
              label="2024"
              value="2024"
            ></el-option>
            <el-option
              label="2025"
              value="2025"
            ></el-option>
            <el-option
              label="2026"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="院校名称："
          prop="college"
        >
          <el-select
            v-model="dataForm.college"
            placeholder="请输入选择院校名称"
            filterable
            clearable
            @change="collegeChange"
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="专业名称："
          prop="major"
        >
          <el-select
            v-model="dataForm.major"
            placeholder="请选择专业名称"
            clearable
            @change="majorChange"
            filterable
          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
              <span>{{ `（${item.code}）${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="科目名称："
          prop="subjects"
        >
          <el-select
            v-model="dataForm.subjects"
            placeholder="请选择科目名称"
            clearable
            :multiple-limit="1"
            multiple
           
          >
            <el-option
              v-for="(item,index) in subjectsOptions"

              :key="index"
              :label="item.subject_name"
              :value="{ value: item.subject_code, label: item.subject_name}"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="出题人："
          prop="author"
        >
        <!-- :remote-method="remoteMethod" -->
          <el-select
            v-model="dataForm.author"
            placeholder="请输入手机号搜索学长信息"
            clearable
            filterable
            :remote-method="remoteMethod"
            remote
            :loading="loading"
          >
            <el-option
              v-for="(item,index) in $store.state.seniorList"
              :key="index"
              :label="item.realname"
              :value="item.id"
            >
              <span>{{ `${item.realname}/${item.mobile}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件上传：">
          <div class="upload-demo">
            <el-upload
              :action="imgUploadUrl"
              multiple
              :on-success="handleFileSuccess"
              :limit="3"
              :file-list="fileList"
              :on-remove="handleRemove"
              accept=".pdf"
            >
              <el-button
                slot="trigger"
                size="small"
                type="primary"
              >点击上传</el-button>
            </el-upload>
            
          </div>
          <div >仅限于pdf文件上传</div>
        </el-form-item>
        <el-form-item prop="content">
          <div
            class="file-wrap"
            v-if="fileList.length"
          >

            <div
              class="file-item"
              v-for="(item,index) in fileList"
              :key="index"
            >
              <div class="left">
                <img
                  src="@/assets/images/pic_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.jpeg') != -1||item.name.indexOf('.png') != -1||item.name.indexOf('.jpg') != -1"
                >
                <img
                  src="@/assets/images/word_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.doc') != -1||item.name.indexOf('.docx') != -1"
                >
                <img
                  src="@/assets/images/pdf_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.pdf') != -1"
                >
                <img
                  src="@/assets/images/excle_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.xlsx') != -1||item.name.indexOf('.xls') != -1"
                >
                <img
                  src="@/assets/images/ppt_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.ppt') != -1||item.name.indexOf('.pptx') != -1"
                >
                <img
                  src="@/assets/images/zip_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.zip') != -1"
                >
                <span>{{item.name}}</span>
              </div>
              <div class="right">
                <div
                  class="btn"
                  @click="handleToPreview(item)"
                >预览</div>
                <img
                  src="@/assets/images/close.png"
                  alt=""
                  srcset=""
                  class="close"
                  @click="handleFileRemove(index)"
                >
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>




import { imgUploadUrl, fileUploadUrl } from "@/request/http";
import { majorlist, mockexampaperCreate, subjectlist } from "@/request/api";

export default {
  components: {

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataForm: {
        year:'2024',
        subjects:[]
      },
      dialogFormVisible: this.isVisible,
      majorOptions: [],
      subjectsOptions: [],
      imgUploadUrl: imgUploadUrl(),
      fileList: [],
      loading: false,
      rules: {
        name: [{ required: true, message: "请输入试卷名称", trigger: "blur" }],
        year: [{ required: true, message: "请选择年份", trigger: "change" }],
        author: [{ required: true, message: "请输入选择出题人", trigger: "change" }],
        college: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.dataForm.college_code || !this.dataForm.college_name) {
              callback(new Error('请选择院校'))
            } else {
              callback()
            }
          },
        }],
        major: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.dataForm.major_code || !this.dataForm.major_name) {
              callback(new Error('请选择专业'))
            } else {
              callback()

            }
          },
        }],
        subjects: [{ required: true, message: "请选择科目", trigger: "blur" }],
        content: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.fileList.length) {
              callback(new Error('请上传文件'))
            } else {
              callback()

            }
          },
        }],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      console.log('ppp')
      this.fileList = []
      this.dialogFormVisible = val;
      this.dataForm = {
         year:'2024',
        subjects:[]
      }
       this.$nextTick(() => {
          //  this.$refs.formRules.resetField()
          this.$refs.formRules.clearValidate();//清空表单
      })
      if (val) {
        this.$set(this.dataForm,'year','2024')
        this.$store.dispatch('getAllColleges')
        // console.log("列表",this.$store.state.seniorList)
      }
    },
  },
  methods: {
   
    collegeChange(e) {
      this.subjectsOptions = []
      this.majorOptions = []
      this.subjectsOptions = []
      this.dataForm.major = null
      this.dataForm.major_code = null
      this.dataForm.major_name = null
      this.dataForm.subjects = null
      if (e) {
        this.dataForm.college_code = e.value
        this.dataForm.college_name = e.label
        this.getMajor(e.value);
      }
    },
    majorChange(e) {
      // majorOptions
      this.dataForm.major_code = e.value
      this.dataForm.major_name = e.label
      // this.majorOptions = []
      this.subjectsOptions = []
      this.dataForm.subjects = null
      if (e) {
        this.getSubjects(this.dataForm.college_code, e.value);
      }
      this.$forceUpdate()
    },
    getSubjects(college_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        this.subjectsOptions = res.data.list || [];
      });
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((res) => {
        this.majorOptions = res.data.list || [];
      });
    },
    emitClose() {
      this.dataForm = {}
      this.$emit("close");
    },
    handleFileSuccess(response, file, fileList) {
      this.fileList.push({
        name: file.raw.name,
        url: response.data.url,
      })
    },
    handleFileRemove(index) {
      this.fileList.splice(index, 1)
    },
    handleToPreview(item) {
      // .png,.jpg,.jpeg,.pdf,.doc,.xlsx,.zip,.xls,.docx,.pptx,.ppt,
      // console.log("cc1111",item)
      let data = item.url.split(".")
      // console.log("data",data)
      if(data[data.length-1] == 'docx' ||data[data.length-1] == 'xls'||data[data.length-1] == 'ppt'||data[data.length-1] == 'pptx'||data[data.length-1] == 'xlsx'){
        window.open('https://view.officeapps.live.com/op/view.aspx?src='+ item.url)
      }else{
        window.open(item.url)
      }
      
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            cid: localStorage.getItem("cid"),
            name: this.dataForm.name,
            year: this.dataForm.year,
            mock_exam_id: this.$route.params.id,
            college_code: this.dataForm.college.value,
            college_name: this.dataForm.college.label,
            major_code: this.dataForm.major.value,
            major_name: this.dataForm.major.label,
            content: JSON.stringify(this.fileList),
            author: this.dataForm.author,
            subjects: this.dataForm.subjects.map(v => {
              return {
                subject_code: v.value,
                subject_name: v.label,
              }
            })
          }
          mockexampaperCreate(query).then((res) => {
            this.$message.success('提交成功')
            this.$emit('submit')
            this.emitClose()
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    remoteMethod(query) {
      this.$store.dispatch('fetchAdminSeniorList', { keyword: query })

      // let reg = /^(-|\+)?\d+$/
      // if (reg.test(query)) {
      //   this.$store.dispatch('fetchAdminSeniorList', { mobile: query })
      // } else {
      //   this.$message.error('请输入完整的手机号')
      // }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
  },
};
</script>

<style lang="less" scoped>
.el-input,
.el-select {
  width: 100%;
}
.upload-demo {
  overflow: hidden;
  height: 35px;
}
.file-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .file-item {
    & + .file-item {
      margin-top: 16px;
    }
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .btn {
        width: 56px;
        height: 24px;
        border: 1px solid #dcdfe6;
        border-radius: 20px;
        color: #606266;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }
    }
  }
}
</style>