import AboutStudent from "../views/Setting/AboutStudent/main.vue";
import SignBill from "../views/Setting/SignBill/main.vue";


export default [
    /*** 设  置 ***/
    {
        path: '/setting/aboutstudent',
        component: AboutStudent,
        meta: { title: '关于学员' }
    },
    {
        path: '/setting/signbill',
        component: SignBill,
        meta: { title: '签单开票' } 
    },
]