<template>
  <el-dialog
    title="用户协议"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="678px"
    append-to-body
    @close="emitClose"
  >
    <span v-html="Info.content"> </span>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="dialogFormVisible = false"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { agreement } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      Info: {},
    };
  },
  mounted() {
    this.getAgreement();
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    getAgreement() {
      agreement({ code: "institutionAgreement" }).then((res) => {
        this.Info = res;
      });
    },
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;
  span {
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #909399;
    opacity: 1;
  }
}
.elimg {
  width: 53px;
  height: 53px;
}
</style>