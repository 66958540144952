<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>员工</el-breadcrumb-item>
        <el-breadcrumb-item>部门管理</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="primary"
                 @click="opendialogAdd"
                 size="small"
                 :disabled="!$buttonPermission('companyDept:add')">新建</el-button>
    </div>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName"></TableTittle>
      </div>
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                border>
        <el-table-column type="index"
                         label="序号"
                         width="80">
        </el-table-column>
        <el-table-column prop="name"
                         label="部门"> </el-table-column>
        <el-table-column prop="address"
                         label="人数">
          <template slot-scope="scope">
            <span>{{ scope.row.user_count + "人" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="director"
                         label="负责人"
                         width="234">
          <template slot-scope="scope">
            <span v-if="scope.row.director">{{ scope.row.director }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column prop="description"
                         label="部门工作职责"
                         width="691">
          <template slot-scope="scope">
            <span v-if="scope.row.description">{{
              scope.row.description
            }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column prop="address"
                         label="操作"
                         width="172">
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link type="info"
                       :underline="false"
                       v-show="scope.row._default == 1">
                不可编辑
              </el-link>
              <el-link type="primary"
                       :underline="false"
                       v-show="scope.row._default == 0"
                       @click="opendialogEdit(scope.row)"
                       :disabled="!$buttonPermission('companyDept:edit')">编辑</el-link>
              <el-link type="danger"
                       :underline="false"
                       @click="deleteItem(scope.row)"
                       v-show="scope.row._default == 0"
                       :disabled="!$buttonPermission('companyDept:remove')">
                删除
              </el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination background
                       layout="total, prev, pager, next, sizes, jumper"
                       :current-page="Number(query.pageIndex)"
                       :page-sizes="[10, 20, 50, 100, 500]"
                       :page-size="Number(query.pageSize)"
                       :total="Number(pageTotal)"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>
      </div>
    </el-card>
    <el-dialog title="部门管理"
               :visible.sync="dialogFormVisible"
               width="570px"
               @close="dialogFormVisibleClose">
      <el-form :model="form"
               ref="dialogform"
               :rules="rules"
               label-position="right"
               label-width="110px">
        <el-form-item label="部门名称"
                      prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="部门负责人">
          <el-input v-model="form.director"></el-input>
        </el-form-item>
        <el-form-item label="部门工作职责">
          <el-input type="textarea"
                    v-model="form.description"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submit(submitmethods)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import {
  createuserdept,
  userdeptlist,
  deleteuserdept,
  edituserdept,
} from '../../../request/api'
export default {
  components: {
    TableTittle,
  },
  data() {
    return {
      TableName: '部门列表',
      dialogFormVisible: false,
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem('cid'),
      },
      pageTotal: 0,
      rules: {
        name: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
        director: [
          { required: true, message: '请输入部门负责人', trigger: 'blur' },
        ],
        description: [
          { required: true, message: '请输入部门工作职责', trigger: 'blur' },
        ],
      },
      form: {},
      submitmethods: 'add',
    }
  },
  mounted() {
    this.getList(this.query)
  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getList(this.query)
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getList(this.query)
    },
    opendialogAdd() {
      this.submitmethods = 'add'
      this.form = {}
      this.dialogFormVisible = true
    },
    opendialogEdit(item) {
      this.submitmethods = 'edit'
      this.dialogFormVisible = true
      this.form = JSON.parse(JSON.stringify(item))
    },
    getList(query) {
      userdeptlist(query).then((res) => {
        this.tableData = res.data.list || []
      })
    },
    submit(submitmethods) {
      this.$refs['dialogform'].validate((valid) => {
        if (valid) {
          let query = this.form
          switch (submitmethods) {
            case 'add':
              createuserdept(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false
                  this.getList(this.query)
                })
              })
              break
            case 'edit':
              edituserdept(query).then((res) => {
                this.$nextTick(() => {
                  this.dialogFormVisible = false
                  this.getList(this.query)
                })
              })
              break
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deleteItem(item) {
      this.$confirm('确定要删除此条信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            id: item.id,
          }
          deleteuserdept(query).then((res) => {
            this.$nextTick(() => {
              this.getList(this.query)
            })
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消删除',
          })
        })
    },
    dialogFormVisibleClose() {
      this.form = {}
    },
  },
}
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.card-header {
  display: flex;
  justify-content: space-between;
  .el-button {
    height: 30px;
    line-height: 5px;
  }
}
.block {
  margin-top: 20px;
  margin-bottom: 14px;
  float: right;
}
.templateLink {
  .el-link {
    margin: 0px 5px;
  }
}
</style>