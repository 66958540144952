import Vue from 'vue'
import VueRouter from 'vue-router'



import Layout from '../views/layout'
import Login from '../views/login'
import OverView from '../views/overview'
import ImportFile from '../views/ImportFile/main.vue'


import RecruitStudents from './RecruitStudentsRouter'
import Service from './ServiceRouter'
import Finance from './FinanceRouter'
import Employee from "./Employee";
import Setting from "./SettingRouter";
import Product from "./ProductRouter";
import DataInventory from "./DataInventory";
import PracticeTest from "./PracticeTest";
import testPaper from "./testPaper";
import QuestionAnswering from "./QuestionAnswering";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },

    {
      path: '/',
      name: 'Layout',
      component: Layout,
      children: [
        {
          path: '/overview',
          component: OverView,
          meta: { title: '总览' }
        },
        ...RecruitStudents,
        ...Service,
        ...Finance,
        ...Employee,
        ...Setting,
        ...Product,
        ...DataInventory,
        ...PracticeTest,
        ...testPaper,
        ...QuestionAnswering,
        {
          path: '/importfile',
          component: ImportFile,
          name: 'importfile',
          meta: { title: '导入' }
        },
      ]
    },
    {
      path: '/login',
      component: Login,
      name: 'Login',
    },
  ],
})

