<template>
  <div>
    <el-dialog
      title="发货清单"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      :width="!isfixed?'375px':'60%'"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item
          label="发货主题："
          prop="theme"
        >
          <el-input
            v-model="dataForm.theme"
            placeholder="请输入发货主题"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="预发日期："
          prop="plan_express_time"
        >
          <div class="">
            <el-date-picker
              v-model="dataForm.plan_express_time"
              type="date"
              placeholder="请选择日期时间"
              clearable
              value-format="timestamp"
              :disabled="isNow"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <el-checkbox
              v-model="isNow"
              style="margin-left:16px;"
            >立即发出</el-checkbox>
          </div>

        </el-form-item>
        <el-form-item
          label="选择用户："
          prop="user_list"
          v-if="!single"
          ref="user_list"
        >
          <div class="user-wrap">
            <div class="top">
              <el-link
                type="primary"
                :underline="false"
                @click="openStudentChoose"
                :disabled="is_user_temp==1"
              >选择用户</el-link>
              <el-checkbox v-model="is_user_temp">非报名用户</el-checkbox>
            </div>
            <!-- 非报名用户 -->
            <div
              class="bottom"
              v-if="is_user_temp==1"
            >
              <TemporaryUser @submit="syncTemporaryUser" />
            </div>
            <!-- 系统学生 -->
            <div
              class="bottom"
              v-else
            >
              <el-tag
                :key="index"
                v-for="(item,index) in students"
                closable
                :disable-transitions="false"
                @close="removeStudents(index)"
              >
                {{item.realname}}
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <template v-else>
          <el-form-item label="学员信息：">
            <el-input
              v-model="dataForm.student_info"
              placeholder="请输入学员信息"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="收货人：">
            <el-input
              v-model="dataForm.receiver"
              placeholder="请输入收货人"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号：">
            <el-input
              v-model="dataForm.receiver_mobile"
              placeholder="请输入手机号"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="收货地址：">
            <div class="address-wrap">
              <el-form-item
                label=""
                prop="province_name"
              >
                <el-select
                  v-model="dataForm.province_name"
                  placeholder="省"
                  style="width:160px;margin-right:16px;"
                  @change="provinceChange"
                  clearable
                >
                  <el-option
                    v-for="(item,index) in $store.state.cityList"
                    :key="index"
                    :label="item.name"
                    :value="{ value: item.code, label: item.name}"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label=""
                prop="city_name"
              >
                <el-select
                  v-model="dataForm.city_name"
                  placeholder="市"
                  style="width:160px;margin-right:16px;"
                  @change="cityChange"
                  clearable
                >
                  <el-option
                    v-for="(item,index) in cityList"
                    :key="index"
                    :label="item.name"
                    :value="{ value: item.code, label: item.name}"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label=""
                prop="district_name"
              >
                <el-select
                  v-model="dataForm.district_name"
                  placeholder="区/县"
                  style="width:160px;margin-right:16px;"
                  v-if="districtList.length>1"
                  clearable
                >
                  <el-option
                    v-for="(item,index) in districtList"
                    :key="index"
                    :label="item.name"
                    :value="{ value: item.code, label: item.name}"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="dataForm.address"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <div class="checkbox-wrap">
              <span>地址更新时间: {{$moment(address_update_time).format("YYYY-MM-DD HH:mm")}}</span>
              <span
                class="now"
                v-if="(new Date().getTime())-(address_update_time)>604800000"
              >地址近7天未确认</span>
            </div>

          </el-form-item>
        </template>
        <el-form-item
          label="选择资料："
          prop="product_list"
        >
          <div class="user-wrap">
            <div class="bottom">
              <DataListCount
                @submit="syncDataListCount"
                :content="DataListCountContent"
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="备注："
          prop="remark"
        >
          <el-input
            v-model="dataForm.remark"
            placeholder="请输入备注"
            type="textarea"
          ></el-input>
        </el-form-item>
         <el-form-item
        >
         <div class="hint">
           <i class="el-icon-warning" style="color:#ED7B2F ;margin-right: 5px;font-size: 18px;"></i>
            <div>发货标题和内容同步展示在学员端，请正确选择、规范操作</div>
         </div>
        </el-form-item>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>

    </el-dialog>
    <StudentChoose
      :visible="chooseDialog"
      :content="students"
      @close="chooseDialog=false"
      @submit="chooseSubmit"
      :single="single"
    />
    <HandleDeliverData
      :content="result"
      :visible="handleDialog"
      @close="handleDialog=false"
      @submit="$emit('submit'),emitClose()"
      :single="false"
    />
  </div>
</template>

<script>

import { createshipslip, studentBasicOne, studentBasicEdit } from "@/request/api";
import StudentChoose from "@/components/DialogComponents/DataInventoryStudent/StudentChoose";
import TemporaryUser from "@/components/DialogComponents/DeliverList/TemporaryUser";
import DataListCount from "@/components/DialogComponents/DeliverList/DataListCount";
import HandleDeliverData from "@/components/DialogComponents/DeliverList/HandleDeliverData";
import citylist from "@/utils/area.json";

export default {
  components: {
    StudentChoose,
    TemporaryUser,
    DataListCount,
    HandleDeliverData
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
    single: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataForm: {
      },
      dialogFormVisible: this.isVisible,
      is_user_temp: false,
      chooseDialog: false,
      StudentChoose: {},
      students: [],
      rules: {
        theme: [{ required: true, message: "请输入发货主题", trigger: "blur" }],
        user_list: [{
          required: true,
          validator: (rule, value, callback) => {
            if (this.is_user_temp == 1) {
              if (!this.TemporaryUserContent.length && this.is_user_temp == 1) {
                console.log(this.TemporaryUserContent.length);
                callback(new Error('请输入非报名用户'))
              } else {
                callback()
              }
            } else {
              if (!this.students.length) {
                callback(new Error('请选择用户'))
              } else {
                callback()
              }
            }
          },
        }],
        plan_express_time: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.isNow) {
              if (!this.dataForm.plan_express_time) {
                callback(new Error('请选择预发时间'))
              } else {
                callback()
              }
            } else {
              callback()
            }
          },
        }]
        /*       product_list: [{
                required: true,
                validator: (rule, value, callback) => {
                  let val = this.DataListCountContent.filter(v => { return v.quantity })
                  console.log(val);
                  if (!val.length) {
                    callback(new Error('请选择资料'))
                  } else {
                    callback()
                  }
                  if (!this.DataListCountContent.length) {
                    callback(new Error('请选择资料'))
                  } else {
                    callback()
                  }
                },
      
              }], */
      },
      TemporaryUserContent: [],
      DataListCountContent: [],
      handleDialog: false,
      result: {},
      singleSutdentInfo: {},
      cityList: [], // 市数组
      areaList: [], // 区数组
      address_update_time: "",
      studentDataForm: {},
      isfixed: document.body.clientWidth < 820 ? false : true,
      isNow: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      districtList: [],

    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        if (this.$route.query.student_id) {
          studentBasicOne({ id: this.$route.query.student_id, cid: localStorage.getItem("cid"), }).then((res) => {
            if (res.code == 0) {
              this.studentDataForm = Object.assign({}, res.data, {
                address: "",
              })
              this.is_user_temp = false
              this.students = [{
                id: res.data.id,
                realname: res.data.phone,
              }]
              this.$set(this.dataForm, 'student_info', `${res.data.realname}/${res.data.phone}`)
              this.$set(this.dataForm, 'student_phone', res.data.phone)
              if (res.data.address && this.$store.state.cityList.length) {
                this.$set(this.dataForm, 'receiver', res.data.address.receiver)
                this.$set(this.dataForm, 'receiver_mobile', res.data.address.mobile)
                this.$set(this.dataForm, 'area', res.data.address.area)
                this.$set(this.dataForm, 'province_name', {
                  value: res.data.address.province.length == 6 ? res.data.address.province.slice(0, 2) : res.data.address.province
                })
                this.$set(this.dataForm, 'city_name', {
                  value: res.data.address.city.length == 6 && res.data.address.district != "" ? res.data.address.city.slice(0, 4) : res.data.address.city
                })
                if (this.dataForm.province_name.value) {
                  this.cityList = this.$store.state.cityList.find((v) => { return v.code == this.dataForm.province_name.value }).children
                }
                if (this.dataForm.city_name.value) {
                  this.districtList = this.cityList.find((v) => { return v.code == this.dataForm.city_name.value }).children
                }
                this.$set(this.dataForm, 'district_name', {
                  value: res.data.address.district
                })
                this.$set(this.dataForm, 'address', res.data.address.address)
                this.address_update_time = res.data.address.update_time
              }
            }
          });

        }
      }
    },
    /*   'dataForm.province_name': {
        handler(value) {
          if (value) {
            this.dataForm.province = value.value
            if (value.value) {
              this.cityList = this.$store.state.cityList.find((v) => { return v.code == value.value }).children
            }
          }
        }
      },
      'dataForm.city_name': {
        handler(value) {
          if (value) {
            this.dataForm.city = value.value
            if (value.value) {
              this.areaList = this.cityList.find((v) => { return v.code == value.value }).children
            }
          }
        }
      }, */
  },
  methods: {
    emitClose() {
      this.$emit("close");
      this.dataForm = {}
      this.studentDataForm = {}
      this.students = []
      this.TemporaryUserContent = []
      this.DataListCountContent = []
      this.is_user_temp = false

    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          if (this.$route.query.student_id) {
            // 学员修改接口
            this.editStudentSubmit()
          }
          let query = {
            cid: localStorage.getItem("cid"),
            is_user_temp: this.is_user_temp ? 1 : 0,
            theme: this.dataForm.theme,
            remark: this.dataForm.remark,
            user_list: this.is_user_temp ?
              this.TemporaryUserContent
              :
              this.students.map((v) => {
                return {
                  student_id: v.id
                }
              }),
            product_list: this.DataListCountContent.map((v) => {
              return {
                product_id: v.id,
                quantity: v.quantity,
              }
            }),
            plan_express_time: this.isNow ? "0" : this.dataForm.plan_express_time
          }
          setTimeout(() => {
            createshipslip(query).then((res) => {
              if (res.code == 0) {
                if (!res.data.is_finish) {
                  this.handleDialog = true
                  this.result = Object.assign({}, res.data.check_data)
                } else {
                  this.$message.success(`保存成功`);
                  this.emitClose()
                  this.$emit('submit')
                }
              }
            });
          }, 500);

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editStudentSubmit() {

      let query = {
        cid: localStorage.getItem("cid"),
        id: this.$route.query.student_id,
        receiver: this.dataForm.receiver,
        receiver_mobile: this.dataForm.receiver_mobile,
        province: this.dataForm.province_name.value,
        city: this.dataForm.city_name.value,
        district: this.dataForm.district_name.value,
        area: `${this.dataForm.province_name.label || ""}${this.dataForm.city_name.label || ""}${this.dataForm.district_name.label || ""}`,
        address: this.dataForm.address,

        nickname: this.studentDataForm.nickname,
        realname: this.studentDataForm.realname,
        phone: this.studentDataForm.phone,
        wechat: this.studentDataForm.wechat,
        qq: this.studentDataForm.qq,
        tags: this.studentDataForm.tags,
        status: this.studentDataForm.status,
        apply_year: this.studentDataForm.apply_year,
        apply_college_code: this.studentDataForm.apply_college_code,
        apply_college_name: this.studentDataForm.apply_college_name,
        apply_major_code: this.studentDataForm.apply_major_code,
        apply_major_name: this.studentDataForm.apply_major_name,
        apply_academy_code: this.studentDataForm.apply_academy_code,
        apply_academy_name: this.studentDataForm.apply_academy_name,
        subjects: this.studentDataForm.subjects,
        products: this.studentDataForm.products,
        source: this.studentDataForm.source,
        remark: this.studentDataForm.remark,
        creator: this.studentDataForm.creator.id,

        watcher: this.studentDataForm.watcher ? this.studentDataForm.watcher.map((v) => {
          return v.id
        }).join(',') : "",
        last_advance_time: this.studentDataForm.last_advance_time,
        service_status: this.studentDataForm.service_status,
        archive: this.studentDataForm.archive,
        course_status: this.studentDataForm.course_status,
        apply_time: this.studentDataForm.apply_time,
        receive_amount: this.studentDataForm.receive_amount,
        coach_need: this.studentDataForm.coach_need,
        service_subjects: this.studentDataForm.service_subjects,
        service_teacher: this.studentDataForm.service_teacher,
        consult_teacher: this.studentDataForm.consult_teacher,

      }
      studentBasicEdit(query).then((res) => {
        if (res.code == 0) {
          console.log('asdadqwqwe');
        }
      });
    },
    handleSubjectChoose() {
      this.subjectChooseDialog = true;
    },
    handleSubjectRemove(item) {
      this.subjects = this.subjects.filter((v) => v.id !== item.id);
    },
    openStudentChoose() {
      this.chooseDialog = true
    },
    chooseSubmit({ data }) {
      this.students = data
    },
    syncTemporaryUser(data) {
      this.TemporaryUserContent = data
      this.$refs.user_list.clearValidate();
    },

    syncDataListCount(data) {
      this.DataListCountContent = data

    },
    removeStudents(index) {
      this.students.splice(index, 1)
    },
    provinceChange(e) {
      if (e) {
        this.cityList = this.$store.state.cityList.find((v) => { return v.code == e.value }).children
      }
    },
    cityChange(e) {
      this.districtList = this.cityList.find((v) => { return v.code == e.value }).children
      if (this.districtList.length <= 1) {
        this.dataForm.district_name = ""
      }
    },
  },
};
</script>

<style lang="less" scoped>
.hint {
  width: 500px;
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  background-color: #F9E0C7;

  
}
.tag-wrap {
  display: flex;
  flex-wrap: wrap;
  .subject-item {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
.user-wrap {
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bottom {
  }
}
.address-wrap {
  display: flex;
  /deep/.el-select {
    width: 157px !important;
  }
}
.checkbox-wrap {
  display: flex;
  align-items: center;
  .now {
    margin-left: 16px;
    color: red;
  }
}
</style>