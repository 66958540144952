<template>
  <div class="content">
    <el-dialog
      title="新建学员"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="570px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="auto"
        label-position="left"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item
          label="姓名"
          prop="realname"
        >
          <el-input
            v-model="form.realname"
            clearable
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="考研年份"
          prop="apply_year"
        >
          <el-select
            v-model="form.apply_year"
            placeholder="请选择"
            style="width: 284px"
          >
            <el-option
              label="2022"
              value="2022"
            ></el-option>
            <el-option
              label="2023"
              value="2023"
            ></el-option>
            <el-option
              label="2024"
              value="2024"
            ></el-option>
            <el-option
              label="2025"
              value="2025"
            ></el-option>
            <el-option
              label="2026"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input
            placeholder="请输入内容"
            v-model="form.phone"
            class="input-with-select"
            clearable
          >
            <template slot="prepend">手机号</template>
          </el-input>
          <el-input
            placeholder="请输入内容"
            v-model="form.wechat"
            class="input-with-select"
            clearable
          >
            <template slot="prepend">微信号</template>
          </el-input>
          <el-input
            placeholder="请输入内容"
            v-model="form.qq"
            class="input-with-select"
            clearable
          >
            <template slot="prepend">&nbsp;QQ号</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="学员标签"
          prop="tags"
        >
          <el-select
            @change="$forceUpdate()"
            v-model="form.tags"
            multiple
            placeholder="请选择"
            style="width: 455px"
          >
            <el-option
              v-for="item in $store.state.labelOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="意向课程"
          prop="productsarr"
        >
          <el-select
            v-model="form.productsarr"
            multiple
            collapse-tags
            placeholder="请选择"
            style="width: 455px"
          >
            <el-option
              v-for="item in $store.state.productOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="意向院校"
          prop="apply_college"
        >
          <el-select
            v-model="form.apply_college"
            placeholder="请选择意向院校"
            filterable
            clearable
            @change="collegeChange"
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="意向专业"
          prop="apply_major"
        >
          <el-select
            v-model="form.apply_major"
            placeholder="请选择意向专业"
            @change="majorChange"
            clearable
            filterable
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.allnames"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="意向学院"
          prop="apply_major"
        >
          <el-select
            v-model="form.apply_academy"
            placeholder="请选择意向学院"
            clearable
            @change="academyChange"
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="{ value: item.academy_code, label: item.academy_name,directions:item.directions}"
            >
              <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="考试方向"
          prop="apply_major"
        >
          <el-select
            v-model="form.apply_direction"
            placeholder="请选择考试方向"
            clearable
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in directionsOptions"
              :key="index"
              :label="item.direction"
              :value="{ value: item.direction_code, label: item.direction}"
            >
              <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="辅导科目"
          prop="apply_subjects"
        >
          <el-select
            style="width:100%;"
            v-model="form.apply_subjects"
            placeholder="请选择辅导科目"
            clearable
            multiple
            @change="$forceUpdate()"
          >
            <el-option
              v-for="(item,index) in subjectOptions"
              :key="index"
              :label="item.subject_name"
              :value="{ value: item.subject_code, label: item.subject_name}"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="学员来源"
          prop="source"
        >
          <el-select
            v-model="form.source"
            placeholder="请选择"
            style="width: 284px"
          >
            <el-option
              v-for="item in $store.state.sourceOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="负责人"
          prop="watcher"
        >
          <el-select
            @change="$forceUpdate()"
            v-model="form.watcher"
            multiple
            placeholder="请选择"
            style="width: 284px"
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.id"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  dictionary,
  majorlist,
  collegeList,
  companyuserlist,
  companycourselist,
  subjectlist,
  academylist,
} from "../../../request/api";

import { adminUrl } from "@/utils/helper";

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      form: {
        status: "初步沟通",
      },
      academyOptions: [],
      majorOptions: [],
      subjectOptions: [],
      directionsOptions: [],//方向数组

      rules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入你的手机号", trigger: "blur" },
        ],
        wechat: [
          { required: true, message: "请输入你的微信号", trigger: "blur" },
        ],
        qq: [{ required: true, message: "请输入你的QQ号", trigger: "blur" }],
        tags: [
          { required: true, message: "请选择你的学员标签", trigger: "blur" },
        ],
        apply_year: [
          { required: true, message: "请选择你的考研年份", trigger: "blur" },
        ],
        source: [{ required: true, message: "请选择来源", trigger: "blur" }],
        productsarr: [
          { required: true, message: "请选择意向产品", trigger: "blur" },
        ],
        apply_college_name: [
          { required: true, message: "请选择意向院校", trigger: "blur" },
        ],
        apply_academy_name: [
          { required: true, message: "请选择意向学院", trigger: "blur" },
        ],
        apply_major_code: [
          { required: true, message: "请选择意向专业", trigger: "blur" },
        ],
        watcher: [{ required: true, message: "请选择负责人", trigger: "blur" }],
        subjectsarr: [
          { required: true, message: "请选择辅导科目", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      this.form = {
        status: "初步沟通",
      };
    },
  },
  methods: {
    emitClose() {
      this.form = {};
      this.$emit("close");
    },
    // 因为传课程产品时要上传对象所以在这里做一些处理
    formateProducts() {
      let arr = [];
      this.form.productsarr.forEach((item) => {
        let obj = {};
        obj = this.$store.state.productOptions.find((v) => {
          return v.id === item; //筛选出匹配数据
        });
        if (obj) {
          arr.push({
            product_id: obj.id,
            product_name: obj.name,
          });
        }
      });
      this.form.products = arr;
    },
    // 因为传科目时要上传对象所以在这里做一些处理
    formateSubjects() {
      let arr = [];
      this.form.subjectsarr.forEach((item) => {
        let obj = {};
        obj = this.subjectOptions.find((v) => {
          return v.subject_code == item; //筛选出匹配数据
        });
        if (obj) {
          arr.push({
            subject_code: obj.subject_code,
            subject_name: obj.subject_name,
          });
        }
      });
      this.form.subjects = arr;
    },
    submit(rules) {
      console.log(this.form);
      this.formateProducts();
      this.$refs[rules].validate((valid) => {
        if (valid) {
          if (this.form.tags) {
            this.form.tags = this.form.tags.join(",");
          }
          if (this.form.watcher) {
            this.form.watcher = this.form.watcher.join(",");
          }
          const data = Object.assign({}, this.form, {
            apply_college_code: this.form.apply_college ? this.form.apply_college.value : "",
            apply_college_name: this.form.apply_college ? this.form.apply_college.label : "",
            apply_major_code: this.form.apply_major ? this.form.apply_major.value : "",
            apply_major_name: this.form.apply_major ? this.form.apply_major.label : "",
            apply_academy_code: this.form.apply_academy ? this.form.apply_academy.value : "",
            apply_academy_name: this.form.apply_academy ? this.form.apply_academy.label : "",
            apply_direction_code: this.form.apply_direction ? this.form.apply_direction.value : "",
            apply_direction_name: this.form.apply_direction ? this.form.apply_direction.label : "",
            subjects: this.form.apply_subjects && this.form.apply_subjects.length ? this.form.apply_subjects.map(v => {
              return {
                subject_code: v.value,
                subject_name: v.label,
              }
            }) : []
          })
          this.$emit("submit", { form: data });
          this.form.tags = [];
          this.form.watcher = [];
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    collegeChange(e) {
      this.form.apply_major = {}
      this.majorOptions = []
      this.form.apply_academy = {}
      this.academyOptions = []
      this.form.apply_subjects = []
      this.subjectOptions = []
      if (e) {
        this.getMajor(e.value);
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((response) => {

        this.majorOptions = response.data.list.map(v => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`
          }
        })
      });
    },
    majorChange(e) {
      this.form.apply_academy = {}
      this.academyOptions = []
      this.form.apply_subjects = []
      this.subjectOptions = []
      this.form.directions = []

      if (e) {
        this.getMajorInfo(this.form.apply_college.value, e.value);
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get',//可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then(response => {
        this.academyOptions = response.data.academys
        this.directionsOptions = this.academyOptions.find(v => {
          return this.form.apply_academy.value == v.academy_code
        }).directions
      })

    },
    academyChange(e) {
      this.form.apply_subjects = []
      this.subjectOptions = []
      this.$forceUpdate()
      if (e) {
        this.directionsOptions = e.directions
        this.getSubjects();
      }
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.form.apply_college.value,
        academy_code: this.form.apply_academy.value,
        major_code: this.form.apply_major.value,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程"
        }
        this.subjectOptions = res.data.list || [];
        this.subjectOptions.push(obj)
      });
    },
    dialogFormVisibleClose() {
      this.form = {
        status: "初步沟通",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
</style>