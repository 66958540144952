<template>
  <div>
    <el-dialog
      title="发货清单"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :width="!isfixed?'375px':''"
    >
      <div class="info-wrap">
        <div class="title">基础信息</div>
        <div class="info-content">
          <div class="info">
            <div class="label">发货主题：</div>
            <div class="value">{{content.theme}}</div>
          </div>
          <div class="info">
            <div class="l abel">发货单ID：</div>
            <div class="value">{{content.id}}</div>
          </div>
          <div class="info">
            <div class="label">发货状态：</div>
            <div class="value">
              <el-tag
                type="warning"
                size="mini"
                v-if="content.express_status==0"
              >待发货</el-tag>
              <el-tag
                size="mini"
                v-if="content.express_status==1"
              >已发货</el-tag>
              <el-tag
                type="info"
                size="mini"
                v-if="content.express_status==2"
              >已取消</el-tag>
              <el-tag
                type="success"
                size="mini"
                v-if="content.express_status==3"
              >已送达</el-tag>
              <el-tag
                type="info"
                size="mini"
                v-if="content.express_status==4"
              >已退回</el-tag>
            </div>
          </div>
          <div class="info">
            <div class="label">创建人：</div>
            <div class="value">{{content.creator?content.creator.alias:""}}</div>
          </div>
          <div class="info">
            <div class="label">发货人：</div>
            <div class="value">{{content.express_creator?content.express_creator.alias:""}}</div>
          </div>
          <div class="info">
            <div class="label">创建日期：</div>
            <div class="value">{{$moment(content.create_time).format("YYYY-MM-DD HH:mm")}}</div>
          </div>
          <div class="info">
            <div class="label">发货日期：</div>
            <div class="value">{{$moment(content.express_time).format("YYYY-MM-DD HH:mm")}}</div>
          </div>
        </div>
      </div>
      <div class="info-wrap">
        <div class="title">收件信息</div>
        <div class="info-content">
          <div class="info">
            <div class="label">收货人：</div>
            <div class="value">{{content.receiver_name}}</div>
          </div>
          <div class="info">
            <div class="label">手机号：</div>
            <div class="value">{{content.receiver_phone}}</div>
          </div>
          <div class="info">
            <div class="label">收货地址：</div>
            <div class="value">{{content.receiver_address}}</div>
          </div>
        </div>
      </div>
      <div class="info-wrap">
        <div class="title">资料清单</div>
        <el-table
          :data="content.product_list"
          :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
          size="small"
          :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        >
          <el-table-column
            prop="product_name"
            label="名称"
            width="300"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              《{{scope.row.product_name}}》
            </template>
          </el-table-column>
          <el-table-column
            prop="year"
            label="年份"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{scope.row.year?scope.row.year+"年":"不限"}}
            </template>
          </el-table-column>
          <el-table-column
            prop="product_sku"
            label="规格"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="数量"
            show-overflow-tooltip
          >
          </el-table-column>

        </el-table>
      </div>
      <div
        class="info-wrap"
        v-if="content.express_no"
      >
        <div class="title">发货信息</div>
        <div class="info-content">
          <div class="info">
            <div class="label">收货地址：</div>
            <div class="value">
              <img
                :src="item"
                alt=""
                srcset=""
                v-for="(item,index) in content.express_pictures.split(',')"
                :key="index"
                class="express_pictures"
                @click="openViewer(item)"
              >
            </div>
          </div>
          <div class="info">
            <div class="label">快递名称：</div>
            <div class="value">{{content.express_name}}</div>
          </div>
          <div class="info">
            <div class="label">快递单号：</div>
            <div class="value">{{content.express_no}}</div>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="emitClose"
        >确 定</el-button>
      </div>

    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      :url-list="tableImg"
      :on-close="closeViewer"
      :zIndex="999999999"
    />
  </div>
</template>

<script>


import { shipslipOne } from "@/request/api";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";


export default {
  components: {
    ElImageViewer
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      content: {},
      dialogFormVisible: this.isVisible,
      isfixed: document.body.clientWidth < 820 ? false : true,
      tableImg: [],
      showViewer: false,
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail(this.id)
      }
    },
  },
  methods: {
    fetchDetail(id) {
      shipslipOne({ id }).then((res) => {
        this.content = res.data
      });
    },
    emitClose() {
      this.$emit("close");
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.tableImg = [url];
      this.showViewer = true;
    },
    /*    formateExpress_status(value) {
         const obj = {
           "0": "待发货",
           "1": "已发货",
           "2": "已取消",
           "3": "已送达",
           "4": "已退回",
         }
         return obj[value]
       } */

  },
};
</script>

<style lang="less" scoped>
.info-wrap {
  border: 1px dashed #dcdfe6;
  padding: 16px;
  & + .info-wrap {
    margin-top: 16px;
  }
  .title {
    color: #333;
    font-size: 16px;
  }
  .info-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .info {
      width: 100%;
      margin-top: 12px;
      display: flex;
      color: #333;
      font-size: 14px;
      align-items: flex-start;
      .label {
        width: 75px;
        text-align: right;
      }
      .value {
        .express_pictures {
          & + .express_pictures {
            margin-left: 16px;
          }
          width: 104px;
        }
      }
    }
  }
}
</style>