                                                                                                                                                                                                                                                                                                                                                                              <template>
  <div class="content">
    <div class="">
      <el-link
        type="primary"
        :underline="false"
        @click="materialChooseDialog=true"
        :disabled="disabled"
      >选择资料</el-link>
    </div>
    <div class="table-wrap">

      <el-table
        v-if="tableData.length"
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',

        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        size="mini"
      >
        <el-table-column
          label="图片"
          width="100"
        >
          <template slot-scope="scope">
            <el-image
              style="max-height:40px;height:40px;padding:-8px 0px;"
              :src="scope.row.picture_url"
              @click="openViewer(scope.row.picture_url)"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            《{{scope.row.name}}》
          </template>
        </el-table-column>
        <el-table-column
          prop="year"
          label="年份"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{scope.row.year?scope.row.year+"年":"不限"}}
          </template>
        </el-table-column>
        <el-table-column
          prop="sku"
          label="规格"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          label="数量"
          show-overflow-tooltip
          width="200px"
        >
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.quantity"
              :min="1"
            ></el-input-number>
      <!--       <el-input
              type="number"
              v-model="scope.row.quantity"
              placeholder="请输入内容"
              @input="sync"
              :disabled="disabled"
            ></el-input> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="stock"
          label="库存"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div :class="{'red':scope.row.quantity>scope.row.stock}">{{scope.row.stock}}
              <span
                v-if="scope.row.quantity>scope.row.stock"
                class="stock_red"
              >库存不足</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label=""
          width="80"
          v-if="!disabled"
        >
          <template slot-scope="scope">
            <img
              src="@/assets/images/close_icon.png"
              alt=""
              srcset=""
              style="width:16px;height:16px;"
              @click="handleToRemove(scope.$index)"
            >
          </template>
        </el-table-column>
      </el-table>

    </div>
    <MaterialChoose
      :visible="materialChooseDialog"
      @close="materialChooseDialog=false"
      @submit="materialChooseSubmit"
      :content="tableData"
    />
    <el-image-viewer
      v-if="showViewer"
      :url-list="tableImg"
      :on-close="closeViewer"
      :zIndex="999999999"
    />
  </div>
</template>

<script>
import MaterialChoose from "@/components/DialogComponents/DataList/DataChoose";
// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: {
    MaterialChoose,
    ElImageViewer
  },
  props: {
    content: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      materialChooseDialog: false,
      tableImg: [],
      showViewer: false,
    };
  },

  created() {

  },
  watch: {
    content(val) {
      this.tableData = val
    }
  },
  computed: {

  },
  methods: {
    addUser() {
      this.tableData.push({

      })
    },
    handleToRemove(index) {
      this.tableData.splice(index, 1)
    },
    sync() {
      this.$emit('submit', this.tableData)
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.tableImg = [url];
      this.showViewer = true;
    },
    materialChooseSubmit({ data }) {
      this.tableData = data
      this.tableData.forEach((item) => {
        if (item.quantity) {
          if (item.quantity < 1) {
            this.$set(item, 'quantity', 1)
          }
        } else {
          this.$set(item, 'quantity', 1)
        }
      })
      this.$emit('submit', this.tableData)
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  .btn-wrap {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.table-wrap {
  margin-top: 20px;
}
.el-table {
  & ::v-deep th.el-table__cell,
  ::v-deep td.el-table__cell {
    // 设置position 使得 子元素不与其产生新的层叠关系
    position: static;
  }
}
.red {
  color: red;
  font-weight: 600;
}
.stock_red {
  font-size: 8px;
}
</style>
