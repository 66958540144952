<template>
  <el-dialog
    title="跟进记录"
    :visible.sync="addRecordDialog"
    width="570px"
    @close="emitClose"
  >
    <el-form :model="addRecordForm" label-position="right">
      <!--       <el-form-item label="学员状态">
        <el-select
          v-model="addRecordForm.tags"
          placeholder="请选择"
          style="width: 443px"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.id"
            :label="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="跟进内容">
        <el-input
          type="textarea"
          v-model="addRecordForm.content"
          autocomplete="off"
          style="width: 443px"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="emitClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { dictionary } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      addRecordForm: this.content,
      addRecordDialog: this.isVisible,
      statusOptions: [],
    };
  },
  watch: {
    isVisible(val) {
      this.addRecordDialog = val;
    },
    content(val) {
      this.addRecordForm = val;
    },
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    getOptions() {
      let query = {
        code: "StudentStatus",
      };
      dictionary(query).then((res) => {
        this.statusOptions = res.data.data.values || {};
      });
    },
    emitClose() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit", { form: this.addRecordForm });
    },
  },
};
</script>

<style>
</style>