<template>
  <div class="content">
    <el-dialog
      title="课程状态"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="620px"
      @close="emitClose"
    >
      <el-radio-group v-model="course_status">
        <el-radio :label="1">等待上课</el-radio>
        <el-radio :label="2">正在上课</el-radio>
        <el-radio :label="3">课程结束</el-radio>
        <el-radio :label="4">课程终止</el-radio>
      </el-radio-group>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  studentBasicEdit
} from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      course_status: "",
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      if (val) {
        this.course_status = val.course_status
      }
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    // 因为传科目时要上传对象所以在这里做一些处理

    submit(rules) {
      let query = {
        cid: Number(localStorage.getItem("cid")),
        id: this.content.id,
        course_status: this.course_status,
      }
      studentBasicEdit(query).then((res) => {
        if (res.code == 0) {
          this.$message.success(`保存成功`);
          this.$emit('submit')
          this.emitClose()
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>