<template>
  <div class="content">
    <div class="demo-drawer__content">
      <el-form
        :label-position="labelPosition"
        :model="form"
        :inline="true"
        size="mini"
        ref="ruleForm"
      >
        <el-form-item label="学员状态" prop="status">
          <el-select
            v-model="form.status"
            placeholder="请选择学员状态"
            @change="submit"
            clearable
          >
            <el-option
              v-for="item in $store.state.statusOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考研年份" prop="apply_year">
          <el-select
            v-model="form.apply_year"
            placeholder="请选择考研年份"
            @change="submit"
            clearable
          >
            <el-option label="2022" value="2022"></el-option>
            <el-option label="2023" value="2023"></el-option>
            <el-option label="2024" value="2024"></el-option>
            <el-option label="2025" value="2025"></el-option>
            <el-option label="2026" value="2026"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学员来源" prop="source">
          <el-select
            v-model="form.source"
            placeholder="请选择学员来源"
            @change="submit"
            clearable
          >
            <el-option
              v-for="item in $store.state.sourceOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="未跟进时间" prop="region">
          <el-select
            v-model="end_advance_time"
            placeholder="请选择未跟进时间"
            @change="submit"
            clearable
          >
            <el-option
              v-for="item in timeArr"
              :key="item.timestamp"
              :label="item.name"
              :value="item.timestamp"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跟进时间" prop="last_advance_time">
          <el-date-picker
            v-model="advance_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="submit"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="创建时间" prop="last_advance_time">
          <el-date-picker
            v-model="create_time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="submit"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      dialogFormVisible: this.isVisible,
      labelPosition: "left",
      create_time: "",
      advance_time: "",
      end_advance_time: "",
      timeArr: [
        {
          name: "一天",
          timestamp: Date.now() - 1 * 24 * 60 * 60 * 1000,
        },
        {
          name: "三天",
          timestamp: Date.now() - 3 * 24 * 60 * 60 * 1000,
        },
        {
          name: "一周",
          timestamp: Date.now() - 7 * 24 * 60 * 60 * 1000,
        },
        {
          name: "两周",
          timestamp: Date.now() - 14 * 24 * 60 * 60 * 1000,
        },
        {
          name: "一个月",
          timestamp: Date.now() - 30 * 24 * 60 * 60 * 1000,
        },
        {
          name: "两个月",
          timestamp: Date.now() - 60 * 24 * 60 * 60 * 1000,
        },
        {
          name: "三个月",
          timestamp: Date.now() - 90 * 24 * 60 * 60 * 1000,
        },
      ],
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    submit() {
      // 创建时间区间处理
      if (this.create_time) {
        this.form.start_create_time = this.create_time[0];
        this.form.end_create_time = this.create_time[1] + 86399000;
      } else {
        this.form.start_create_time = "";
        this.form.end_create_time = "";
      }
      // 跟进时间区间处理
      if (this.advance_time) {
        this.form.start_advance_time = this.advance_time[0];
        this.form.end_advance_time = this.advance_time[1] + 86399000;
      } else {
        this.form.start_advance_time = "";
        this.form.end_advance_time = "";
      }
      // 判断，因为为跟进时间传end_advance_time不传start_advance_time
      if (this.end_advance_time) {
        this.form.end_advance_time = this.end_advance_time;
      }
      this.$emit("submit", { screenForm: this.form });
    },
  },
};
</script>

<style lang="less" scoped>
.demo-drawer__content {
  padding-top: 24px;
  height: 100%;
}
.tag-wrap {
  width: 178px;
  display: flex;
  flex-wrap: wrap;
}
.subject-item {
  margin-right: 24px;
  margin-bottom: 5px;
}
.demo-drawer__footer {
  margin-bottom: 24px;
  float: right;
}
.el-select {
  width: 184px;
}
</style>