import StudentManagerment from '../views/Service/StudentManagement/main.vue'
import OrderMatching from '../views/Service/OrderMatching/main.vue'
import DispatchOrder from "../views/Service/DispatchOrder/main.vue";
import TeachingAndResearch from "../views/Service/TeachingAndResearch/index.vue";
import TeachingAndResearchRecord from "../views/Service/TeachingAndResearch/record.vue";


import Record from "../views/Service/StudentManagement/Record/Record.vue";
import CommunicationRecord from "../views/Service/OrderMatching/CommunicationRecord/main.vue";
import DispatchOrderRecord from "../views/Service/DispatchOrder/CommunicationRecord/main.vue";

export default [
    /*** 服  务 ***/
    {
        path: '/service/studentmanagement',
        component: StudentManagerment,
        meta: { title: '学员管理' }
    },
    // {
    //     path: '/service/exteriorstudent',
    //     component: Exteriorstudent,
    //     meta: { title: '学员管理' }
    // },
    /*** 服务订单-查看跟进记录 ***/
    {
        path: '/service/studentmanagement/record',
        component: Record,
        name: 'servicerecord',
        meta: { title: '跟进记录' }
    }, {
        path: '/service/ordermatching',
        component: OrderMatching,
        meta: { title: '师资匹配' }
    }, {
        path: '/service/studentmanagement/communicationrecord',
        component: CommunicationRecord,
        name: 'communicationrecord',
        meta: { title: '沟通记录' }
    }, {
        path: '/service/dispatchorder',
        component: DispatchOrder,
        meta: { title: '师资派遣' }
    }, {
        path: '/service/studentmanagement/dispatchorderrecord',
        component: DispatchOrderRecord,
        name: 'dispatchorderrecord',
        meta: { title: '师资派遣沟通记录' }
    }, {
        path: '/service/teachingandresearch',
        component: TeachingAndResearch,
        name: 'teachingandresearch',
        meta: { title: '教研服务' }
    }, {
        path: '/service/teachingandresearch/record',
        component: TeachingAndResearchRecord,
        name: 'teachingandresearchcord',
        meta: { title: '教研服务记录' }
    },
]