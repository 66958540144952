<template>
  <div class="dialog-wrap">
    <el-dialog
      title="用户选择"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="980px"
    >
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="mini"
      >
        <el-form-item label="学生姓名">
          <el-input
            v-model.trim="query.user_keyword"
            placeholder="输入学生姓名"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="报名班次">
          <el-select
            v-model="query.product_ids"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in $store.state.productOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="课程状态">
          <el-select
            v-model="query.course_status"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
          >
            <el-option
              v-for="(item, index) in filter.course_status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务科目">
          <el-select
            v-model="query.service_subject_ids"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item, index) in $store.state.servicesubjectOptions"
              :key="index"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="院校">
          <el-input
            v-model.trim="query.college_keyword"
            placeholder="输入院校"
            @change="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="专业">
          <el-input
            v-model.trim="query.major_keyword"
            placeholder="输入专业"
            @change="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="年份">
          <el-select
            v-model="query.apply_year"
            placeholder="请选择考研年份"
            clearable
            @change="fetchList(true)"
            style="width: 150px"
          >
            <el-option label="不限" value=""></el-option>
            <el-option label="2022年" value="2022"></el-option>
            <el-option label="2023年" value="2023"></el-option>
            <el-option label="2024年" value="2024"></el-option>
            <el-option label="2025年" value="2025"></el-option>
            <el-option label="2026年" value="2026"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="card-header" >
        <TableTittle tittle="列表"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
          style="margin-bottom: 0px !important; display: flex"
          label-position="right"
        >
          <el-form-item label-width="32px" style="margin-bottom: 0px !important;">
            <el-radio-group
              v-model="query.basic_archive"
              @change="fetchList(true)"
            >
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button label="1">已归档</el-radio-button>
              <el-radio-button label="0">未归档</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <el-table
        class="result"
        ref="multipleTable"
        :data="results"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column
          v-if="!single"
          type="selection"
          width="50"
          fixed="left"
          :selectable="selectable"
        >
        </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="50"
          fixed="left"
        ></el-table-column>
        <el-table-column label="报名时间" width="150">
          <template slot-scope="scope">
            {{ $moment(scope.row.apply_time).format("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="apply_year"
          label="考研年份"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="学生姓名"
          width="130"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="课程状态" width="130" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.course_status == 1" size="mini"
              >等待上课</el-tag
            >
            <el-tag
              type="success"
              size="mini"
              v-else-if="scope.row.course_status == 2"
              >正在上课</el-tag
            >
            <el-tag
              type="info"
              size="mini"
              v-else-if="scope.row.course_status == 3"
              >课程结束</el-tag
            >
            <el-tag
              type="danger"
              size="mini"
              v-else-if="scope.row.course_status == 4"
              >课程终止</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="realname"
          label="发货次数"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{
              scope.row.student_express_count_total
                ? scope.row.student_express_count_total + "次"
                : "暂无"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="product_names"
          label="报名班次"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.product_names || "无" }}
          </template>
        </el-table-column>
        <el-table-column label="实收金额" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.receive_amount || 0 }}元
          </template>
        </el-table-column>
        <el-table-column
          prop="coach_need"
          label="辅导要求"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="服务科目" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.service_subject_names
                ? scope.row.service_subject_names
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="定校情况" width="300" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ collegeFormate(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="其他备注"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="receiver_address"
          label="收货地址"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.receiver_area + scope.row.receiver_address || "无" }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column label="修改时间" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column label="地址更新时间" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.receiver_update_time
                ? $moment(scope.row.receiver_update_time).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="提示" width="160" fixed="right" align="center">
          <template slot-scope="scope">
            <span v-if="!scope.row.receiver_area || !scope.row.receiver_address"
              >地址信息不全</span
            >
            <span
              class="now"
              v-else-if="
                new Date().getTime() - scope.row.receiver_update_time >
                604800000
              "
              >地址近7天未确认</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="80"
          show-overflow-tooltip
          fixed="right"
          v-if="single"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              @click="handleToChoose(scope.row)"
              :underline="false"
              :disabled="
                !scope.row.receiver_area || !scope.row.receiver_address
              "
              >选择</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          small
          layout="total, prev, pager, next,sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { studentBasiclist } from "@/request/api";
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
export default {
    components: {
    TableTittle,

  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
    single: {
      type: Boolean,
      default: false,
    },
  },
  data() {
   
    return {
      dialogVisible: this.visible,
      query: {
        keyword: "",
        college: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        basic_archive:'0'
      },
      pageTotal: 10,
      filter: {
        status: [
          { label: "全部", value: "" },
          { label: "正常", value: "1" },
          { label: "下线", value: "0" },
        ],
        course_status: [
          { label: "等待上课", value: 1 },
          { label: "正在上课", value: 2 },
          { label: "课程结束", value: 3 },
          { label: "课程终止", value: 4 },
        ],
      },
      results: [],
      selections: [],
      countSelections: [],
    };
  },
  filters: {
    formateCategorys: function (val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;

      if (val) {
        this.query = {
          pageIndex: 1,
          pageSize: 10,
          isPaging: 1,
          basic_archive:'0'
        };
        this.fetchList();
        this.$store.dispatch("getServiceSubjectDictionary");
        this.$store.dispatch("getProducts");
      }
    },
    content(val) {
      this.countSelections = val;
    },
  },
  methods: {
    selectable(row, index) {
      if (!row.receiver_area || !row.receiver_address) {
        return false;
      } else {
        return true;
      }
    },
    // 院校列表请求
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      let params = { ...this.query };
      if (params.product_ids) {
        params.product_ids = params.product_ids.join(",");
      }
      if (params.service_subject_ids) {
        params.service_subject_ids = params.service_subject_ids.join(",");
      }
      studentBasiclist(params).then((res) => {
        this.results = res.data.list || [];
        this.pageTotal = res.data.count;

        this.$nextTick(this.setRowSelection);
      });
    },
    // 页面数量调整
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.fetchList();
    },
    // 设置选中行
    setRowSelection() {
      if (this.results.length) {
        this.results.forEach((row) => {
          if (this.content.find((item) => item.id === row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageIndex", 1);
      this.fetchList();
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields();
      this.fetchList();
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.fetchList();
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val;
      this.diffSelection();
    },
    handleSubmit() {
      this.$emit("submit", { data: this.countSelections });

      this.handleClose();
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        keyword: "",
        type: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      };
      this.$emit("close");
    },
    // 选中项差异化计算
    diffSelection() {
      let diffs = [];
      // 减
      this.countSelections.forEach((item, index) => {
        if (!this.selections.find((v) => v.id === item.id)) {
          if (this.results.find((v2) => v2.id === item.id)) {
            diffs.push(item);
          }
        }
      });
      if (diffs.length) {
        this.countSelections = this.countSelections.filter(
          (item) => !diffs.find((v) => v.id === item.id)
        );
      }
      // 加
      this.selections.forEach((item) => {
        if (!this.countSelections.find((v) => v.id === item.id)) {
          this.countSelections.push(item);
        }
      });
    },
    service_subjectsFormate(value) {
      if (value && value.length) {
        let val = JSON.parse(value);
        let map_val = val.map((v) => {
          return `（${v.subject_code}）${v.subject_name}`;
        });
        return map_val.join(",");
      } else {
        return "无";
      }
    },
    collegeFormate(row) {
      if (row.is_choose) {
        return `${row.apply_college_name},（${row.apply_major_code}）${row.apply_major_name}`;
      } else {
        return "暂无定校";
      }
    },
    handleToChoose(row) {
      let data = [row];
      this.$emit("submit", { data: data });
      this.handleClose();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .table-title[data-v-c59d6aee]{
  margin-bottom: 0 !important;
}
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    margin-top: 10px;
  }
  .now {
    margin-left: 16px;
    color: red;
    
  }
}
</style>
