<template>
  <div>
    <el-dialog
      title="课程计划"
      :visible.sync="dialogFormVisible"
      @close="emitClose"
      :close-on-click-modal="false"
      width="620px"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="课时主题："
          prop="course_theme"
        >
          <el-input
            v-model="dataForm.course_theme"
            placeholder="请输入课时主题"
            :maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="内容简介："
          prop="course_content"
        >
          <el-input
            type="textarea"
            v-model="dataForm.course_content"
            placeholder="请输入内容简介"
            :maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="计划时长："
          prop="plan_lesson_hour"
        >
          <el-input
            placeholder="请输入计划时长"
            v-model="dataForm.plan_lesson_hour"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            @blur="hoursBlur"
          >
            <el-select
              v-model="companySelect"
              slot="append"
              placeholder="请选择"
              @change="hoursBlur"
              style="width:100px;"
            >
              <el-option
                label="课时"
                :value="1"
              ></el-option>
              <el-option
                label="小时"
                :value="2"
              ></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item
          label=""
          v-if="tipsText"
        >
          <div class="tips">
            {{tipsText}}
          </div>
        </el-form-item>
        <el-form-item
          label="上课时间："
          prop="plan_start_time"
        >
          <div class="">
            <el-date-picker
              v-model="dataForm.plan_start_time"
              type="datetime"
              style="width:260px;"
              placeholder="请选择日期时间"
              clearable
              value-format="timestamp"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item
          label="课程阶段："
          prop="course_stage"
        >
          <el-select
            v-model="dataForm.course_stage"
            placeholder="请选择"
            style="width:260px;"
            clearable
          >
            <el-option
              label="基础"
              :value="0"
            ></el-option>
            <el-option
              label="强化"
              :value="1"
            ></el-option>
            <el-option
              label="冲刺"
              :value="2"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="授课老师："
          prop="normal_course_plan_teacher_name"
        >
          <el-input
            v-model="dataForm.normal_course_plan_teacher_name"
            placeholder="请输入授课老师"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose(false)">取消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>



import {

} from "@/request/api";

import {
  manageUrl
} from "@/utils/helper";
export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    company_normal_course_id: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {
      },
      tipsText: "注：1课时等于0.75小时",
      companySelect: 1,
      rules: {
        grade: [{ required: true, message: "请选择班级", trigger: "blur" }],
        course_theme: [{ required: true, message: "请输入课时主题", trigger: "blur" }],
        course_content: [{ required: true, message: "请输入内容简介", trigger: "blur" }],
        course_stage: [{ required: true, message: "请选择课程阶段", trigger: "blur" }],
        course_target: [{ required: true, message: "请输入课程目标", trigger: "blur" }],
        references: [{ required: true, message: "请输入参考资料", trigger: "blur" }],
        plan_lesson_hour: [
          { required: true, message: "请输入辅导课时", trigger: "blur" },
          { pattern: /^(\d+|\d+\.\d{1,2})$/, message: '输入最多两位小数的数字' }
        ],
        plan_start_time: [{ required: true, message: "请选择上课时间", trigger: "blur" }],
        normal_course_plan_teacher_name: [{ required: true, message: "请输入授课老师", trigger: "blur" }],

      },
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;


    },
  },
  methods: {

    emitClose() {
      this.dataForm = {}
      this.$refs['formRules'].clearValidate();
      this.$emit("close");
    },
    hoursBlur() {
      if (!/^(\d+|\d+\.\d{1,2})$/.test(this.dataForm.plan_lesson_hour)) {
        this.tipsText = "输入最多两位小数的数字"
      } else {
        if (this.companySelect == 2) {
          this.tipsText = `${this.dataForm.plan_lesson_hour}小时等于${(this.dataForm.plan_lesson_hour * 1.33).toFixed(2)}课时`
        } else if (this.companySelect == 1) {
          this.tipsText = `${this.dataForm.plan_lesson_hour}课时等于${(this.dataForm.plan_lesson_hour * 0.75).toFixed(2)}小时`
        }
      }
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          const param = Object.assign({}, this.dataForm, {
            company_normal_course_id: this.company_normal_course_id,
            plan_lesson_hour: this.companySelect == 2 ? this.dataForm.plan_lesson_hour : (Number(this.dataForm.plan_lesson_hour) * 0.75).toFixed(2),
          })
          let str = manageUrl()
          this.$axios({
            url: `${str}/api/v1/lesson/coursePlan/byNormalCourse`,
            method: 'post',//可以省略 不写默认是get
            data: param,
          }).then(res => {
            if (res.code == 0) {
              this.emitClose()
              this.$emit('submit')
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
</script>

<style lang="less" scoped>
.tips {
  font-size: 14px;
  color: #909399;
  line-height: 16px;
}
.user-wrap {
  .top {
    display: flex;
    align-items: center;
  }
  .bottom {
  }
}
</style>