<template>
  <div class="content">
    <el-dialog
      title="缴纳保证金"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="570px"
      @close="emitClose"
    >
      <el-descriptions :column="2" border>
        <el-descriptions-item
          label="当前余额"
          :contentStyle="contentStyle"
          :labelStyle="labelStyle"
          >{{ $store.state.AccountInfo.balance + "元" }}</el-descriptions-item
        >
        <el-descriptions-item
          label="缴纳保证金"
          :contentStyle="contentStyle"
          :labelStyle="labelStyle"
          >{{ $store.state.companyInfo.deposit + "元" }}</el-descriptions-item
        >
        <el-descriptions-item
          label="结算后余额"
          :labelStyle="labelStyle"
          :span="2"
          >{{ computedNum }}</el-descriptions-item
        >
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="emitOpen"
          size="small"
          v-show="
            $store.state.AccountInfo.balance < $store.state.companyInfo.deposit
          "
          >去充值</el-button
        >
        <el-button
          type="primary"
          @click="paydeposit"
          size="small"
          v-show="
            $store.state.AccountInfo.balance >= $store.state.companyInfo.deposit
          "
          >立即支付</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deposittransfer } from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      contentStyle: {
        width: "230px",
      },
      labelStyle: {
        width: "120px",
      },
    };
  },
  computed: {
    computedNum: function () {
      let num =
        Number(this.$store.state.AccountInfo.balance) -
        Number(this.$store.state.companyInfo.deposit);
      return num + "元";
    },
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      this.$store.dispatch("getCompanyAccount");
      this.$store.dispatch("getCompanyInfo");
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    emitOpen() {
      this.$emit("open");
    },
    paydeposit() {
      deposittransfer().then((res) => {
        if (res.code == 0) {
          this.$notify.success({
            title: "提示",
            message: "缴纳成功",
          });
          this.dialogFormVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>