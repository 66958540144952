<template>
  <div>
    <el-table
      :data="tableData"
      :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
      :cell-style="{  fontSize: '13px' }"
      border
    >
      <el-table-column
        prop="content"
        label="任务内容"
        show-overflow-tooltip
        align="left"
        width="360"
      ></el-table-column>
      <el-table-column
        prop="student_realname"
        label="关联学生"
        show-overflow-tooltip
        align="center"
        width="150"
      >
        <template slot-scope="scope">
          {{scope.row.student_realname?scope.row.student_realname:"-"}}
        </template>
      </el-table-column>
      <el-table-column
        prop="do_teacher_name"
        label="服务老师"
        show-overflow-tooltip
        align="center"
        v-if="type==2"
      ></el-table-column>
      <el-table-column
        prop="service_type_name"
        label="服务类型"
        align="center"
        width="150"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{scope.row.service_type_name?scope.row.service_type_name:"-"}}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="截止时间"
        show-overflow-tooltip
        width="150"
      >
        <template slot-scope="scope">
          {{$moment(scope.row.stop_time).format("YYYY-MM-DD HH:mm")}}
        </template>
      </el-table-column>
      <el-table-column
        label="优先级"
        align="center"
        show-overflow-tooltip
        width="80"
      >
        <template slot-scope="scope">
          <div class="red">{{formatePriority_level(scope.row.priority_level)}}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="任务状态"
        align="center"
        show-overflow-tooltip
        width="100"
      >
        <template slot-scope="scope">
          <el-tag
            size="mini"
            v-if="scope.row.status==1"
          >进行中</el-tag>
          <el-tag
            size="mini"
            type="info"
            v-if="scope.row.status==2"
          >已取消</el-tag>
          <el-tag
            size="mini"
            type="success"
            v-if="scope.row.status==3"
          >已完成</el-tag>
          <el-tag
            size="mini"
            type="danger"
            v-if="scope.row.status==4"
          >已逾期</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="发布人"
        align="center"
        show-overflow-tooltip
        width="80"
      >
        <template slot-scope="scope">
          {{scope.row.publish_teacher_name}}
        </template>
      </el-table-column>
      <el-table-column
        label="发布日期"
        align="center"
        show-overflow-tooltip
        width="150"
      >
        <template slot-scope="scope">
          {{$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")}}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="140"
      >
        <template slot-scope="scope">
          <el-link
            type="primary"
            :underline="false"
            @click="handleToRecord(scope.row)"
          >查看详情</el-link>
          <!--      <el-link
            type="primary"
            :underline="false"
            @click="handleToEdit(scope.row.id)"
          >编辑</el-link> -->
          <el-link
            type="primary"
            :underline="false"
            v-if="scope.row.status!==3&&type=='0'"
            @click="changeStatus(scope.row.id)"
          >已完成</el-link>
          <el-link
            type="info"
            :underline="false"
            v-if="scope.row.status==3&&type=='0'"
          >已完成</el-link>
          <el-link
            type="danger"
            :underline="false"
            @click="handleToRemove(scope.row.id)"
            v-if="type=='2'"
          >删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <EditTask
      :id="editId"
      :isVisible="editDialog"
      @close="editDialog=false"
      @submit="$emit('submit')"
    />
  </div>
</template>

<script>



import EditTask from "./EditTask.vue";
import { editteacherServicetask, teacherServicetaskDelete, teacherServicelist } from "@/request/api";
export default {
  components: {
    EditTask
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      editId: null,
      editDialog: false,
    };
  },
  mounted() {

  },
  watch: {
    /*    type(val) {
         console.log(val);
       } */

  },
  methods: {
    changeStatus(id) {
      this.$confirm("确定要修改此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let query = {
          id,
          status: 3,
        }
        editteacherServicetask(query).then((res) => {
          this.$emit('submit')
          this.$store.dispatch('getTaskMsgCount')
        });
      })
    },
    handleToRecord(row) {
      if (!row.student_id) {
        this.$message.error('该学员无服务记录')
        return
      }
      teacherServicelist({ student_id: row.student_id }).then((res) => {
        if (res.data.list.length) {
          let routerData = this.$router.resolve({
            path: `/service/teachingandresearch/record?student_id=${row.student_id}&&service_id=${res.data.list[0].id}`,
          });
          window.open(routerData.href, '_blank')
        } else {
          this.$message.error('该学员无服务记录')
          return
        }
      });
    },
    handleToRemove(id) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        teacherServicetaskDelete({ id }).then((res) => {
          this.$emit('submit')
        });
      })
    },
    handleToEdit(id) {
      this.editId = id
      this.editDialog = true
    },
    formatePriority_level(val) {
      if (val == 1) {
        return "I"
      } else if (val == 2) {
        return "II"
      } else if (val == 3) {
        return "III"
      } else if (val == 4) {
        return "IV"
      } else if (val == 5) {
        return "V"
      }
    }

  },
};
</script>

<style lang="less" scoped>
.red {
  color: red;
}
</style>