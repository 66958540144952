<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>招生</el-breadcrumb-item>
        <el-breadcrumb-item>招生管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="openAddStudentDialog"
          :disabled="!$buttonPermission('student:add')"
        >新建</el-button>
        <el-button
          plain
          size="small"
          @click="goImportFile()"
        >导入</el-button>
      </div>
    </div>
    <el-card class="operation-card">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
      >
        <el-form-item label="搜索">
          <el-input
            v-model="query.keyword"
            placeholder="姓名/昵称/院校/专业"
            style="width: 230px"
            @input="inputSearch"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item style="float: right">
          <el-radio-group
            v-model="query.archive"
            @change="radioChange"
          >
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="1">已归档</el-radio-button>
            <el-radio-button label="0">未归档</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <ScreenStudent @submit="scrrenTable"></ScreenStudent>
    </el-card>
    <el-card class="table-card">
      <div class="card-header">
        <TableTittle :tittle="TableName"></TableTittle>
        <el-form
          :inline="true"
          label-width="70px"
          size="small"
        >
          <el-form-item label="只看关注">
            <el-switch
              v-model="query.follow"
              :active-value="1"
              inactive-value=" "
              @change="getList(query)"
            >
            </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column
          type="selection"
          width="50"
        > </el-table-column>
        <el-table-column
          prop="date"
          label="关注"
          width="50"
          fixed="left"
        >
          <template slot-scope="scope">
            <img
              alt=""
              srcset=""
              class="star"
              src="../../../assets/images/star-fill.png"
              @click="followDelete(scope.row)"
              v-show="scope.row.follow"
            />
            <img
              src="../../../assets/images/star.png"
              alt=""
              srcset=""
              class="star"
              v-show="!scope.row.follow"
              @click="followCreate(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="跟进记录"
          width="127"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="goRecord(scope.row)"
              :disabled="!$buttonPermission('studentAdvance:view')"
            >查看</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="primary"
              :underline="false"
              @click="openAddRecordDialog(scope.row)"
              :disabled="!$buttonPermission('studentAdvance:add')"
            >新增</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="学员状态"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              v-show="scope.row.status"
            >{{
              scope.row.status
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="apply_year"
          label="考研年份"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="nickname"
          label="姓名/昵称"
          width="180"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="openStudentDialog(scope.row)"
              :disabled="!$buttonPermission('student:edit')"
            >{{ scope.row.realname }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="tags"
          label="学员标签"
          width="260"
          show-overflow-tooltip
        >
          <template
            slot-scope="scope"
            v-if="scope.row.tags"
          >
            <el-tag
              v-for="item in scope.row.tags.split(',')"
              :key="item"
              type="success"
              size="small"
              effect="plain"
            >{{ item }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="apply_college_name"
          label="意向院校"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="
                scope.row.apply_college_name && scope.row.apply_college_code
              ">
              {{ `${scope.row.apply_college_name}` }}</span>
            <span v-else> 无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="apply_academy_name"
          label="意向专业"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.apply_major_code && scope.row.apply_major_name">
              {{
                `(${scope.row.apply_major_code})${scope.row.apply_major_name}`
              }}</span>
            <span v-else> 无</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="辅导科目"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.subjects | formateSub }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="考试方向"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.apply_direction_code&&scope.row.apply_direction_name">（{{ scope.row.apply_direction_code }}）{{ scope.row.apply_direction_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="watcher"
          label="意向课程"
          width="320"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.products | formateProducts }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="source"
          label="学员来源"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="creator"
          label="创建人"
          width="90"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.creator | formateCreator }} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="watcher"
          label="负责人"
          width="130"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.watcher | formateWatcher }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="last_advance_time"
          label="最后联系时间"
          width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.last_advance_time">{{
              scope.row.last_advance_time | dateFormat("YYYY-MM-DD")
            }}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="create_time"
          label="创建时间"
          width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>

        <el-table-column
          prop="update_time"
          label="修改时间"
          width="120"
        >
          <template slot-scope="scope">
            {{ scope.row.update_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>

        <!--         <el-table-column fixed="right" label="签单状态" width="120">
          <template slot-scope="scope">
            <el-link
              type="warning"
              :underline="false"
              v-show="scope.row.service_status == 2"
              >审核中</el-link
            >
            <el-link
              type="warning"
              :underline="false"
              v-show="scope.row.service_status == 3"
              >签单成功</el-link
            >
            <el-link
              type="danger"
              :underline="false"
              v-show="scope.row.service_status == 4"
              @click="opendialogFormVisible(scope.row.order)"
              >审核失败</el-link
            >
            <el-link
              type="success"
              :underline="false"
              v-show="scope.row.service_status == 1"
              >待签单</el-link
            >
          </template>
        </el-table-column> -->
        <el-table-column
          fixed="right"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-link
              :underline="false"
              v-show="scope.row.service_status == 2"
            >审核中</el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="openSignBillDialog(scope.row, 1)"
              v-show="scope.row.service_status == 1"
              :disabled="!$buttonPermission('student:sign')"
            >签单</el-link>
            <el-link
              type="danger"
              :underline="false"
              v-show="scope.row.service_status == 4"
              @click="openSignBillDialog(scope.row, 2)"
              :disabled="!$buttonPermission('studentOrder:add')"
            >驳回
            </el-link>
            <el-link
              type="success"
              :underline="false"
              @click="openSignBillDialog(scope.row, 1)"
              v-show="scope.row.service_status == 3"
              :disabled="!$buttonPermission('studentOrder:add')"
            >已签单</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button
          size="small"
          type="primary"
          @click="chooseAllEdit(1, '您确定要归档吗')"
          v-show="query.archive == 0 && query.archive != ''"
        >归档</el-button>
        <el-button
          size="small"
          type="primary"
          @click="chooseAllEdit(0, '您确定要取消归档吗')"
          v-show="query.archive == 1"
        >取消归档</el-button>
        <el-button
          size="small"
          type="danger"
          @click="chooseAllDelete"
          :disabled="!$buttonPermission('student:remove')"
        >删除</el-button>
        <el-button
          size="small"
          type="primary"
          @click="openBatchEditWatcherDialog"
          v-show="$buttonPermission('student:batchWatcher')"
        >分配</el-button>
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <CheckAndEditStudent
      :isVisible="studentDialog"
      :content="studentContent"
      @close="studentDialog = false"
      @submit="editStudentSubmit"
    ></CheckAndEditStudent>
    <AddRecord
      :content="addRecordContent"
      :isVisible="addRecordDialog"
      @close="addRecordDialog = false"
      @submit="addRecordSubmit"
    ></AddRecord>
    <SignBill
      :content="signBillContent"
      :isVisible="signBillDialog"
      @submit="signbillSubmit"
      @close="signBillDialog = false"
    ></SignBill>
    <AddStudent
      :isVisible="addStudentDialog"
      @close="addStudentDialog = false"
      @submit="addStudentSubmit"
    ></AddStudent>
    <el-dialog
      title="拒绝理由"
      :visible.sync="dialogFormVisible"
      width="570px"
    >
      <el-form>
        <el-form-item label="">
          <el-input
            v-model="audit_feedback"
            type="textarea"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogFormVisible = false"
        >确 定</el-button>
      </div>
    </el-dialog>
    <BatchEditWatcher
      :isVisible="BatchEditWatcherDialog"
      @close="BatchEditWatcherDialog = false"
      @submit="BatchEditWatcherSubmit"
    ></BatchEditWatcher>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import ScreenStudent from "../../../components/ScreenComponents/ScreenStudent/ScreenStudent.vue";
import CheckAndEditStudent from "../../../components/DialogComponents/CheckAndEditStudent/CheckAndEditStudent.vue";
import AddRecord from "../../../components/DialogComponents/AddRecord/AddRecord.vue";
import SignBill from "../../../components/DialogComponents/SignBill/SignBill.vue";
import AddStudent from "../../../components/DialogComponents/AddStudent/AddStudent.vue";
import BatchEditWatcher from "../../../components/DialogComponents/BatchEditWatcher/BatchEditWatcher.vue";
import {
  studentList,
  createstudentadvance,
  deletestudentlist,
  createstudentorder,
  editstudent,
  createstudent,
  createuserfollow,
  deleteuserfollow,
  editstudentlist,
} from "../../../request/api";
export default {
  components: {
    TableTittle,
    ScreenStudent,
    CheckAndEditStudent,
    AddRecord,
    SignBill,
    AddStudent,
    BatchEditWatcher
  },
  data() {
    return {
      TableName: "学员管理",
      tableData: [],
      formInline: {
        user: "",
        region: "",
      },
      // 查看以及编辑学员弹窗
      studentDialog: false,
      // 父子传学生信息
      studentContent: {},
      signBillContent: {},
      addRecordContent: {},
      multipleSelection: "",
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        archive: "0",
        keyword: "",
        follow: "",
      },
      studentId: "",
      // 添加跟进记录弹窗
      addRecordDialog: false,
      // 新建学生弹窗
      addStudentDialog: false,
      // 申请签单弹窗
      signBillDialog: false,
      addRecordForm: {},
      dialogFormVisible: false,
      audit_feedback: "",
      // 选中即将删除的表格数据
      deleteList: [],
      rules: {
        name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
      },
      lock: true,
      // 批量修改watcher弹窗
      BatchEditWatcherDialog: false
    };
  },
  mounted() {
    // 获取表格列表
    this.getList(this.query);
    this.getOptions();
  },
  filters: {
    formate: function (val) {
      if (val == null || val == 1) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.subject_name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formateWatcher: function (val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.alias + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formateProducts: function (val) {
      if (!val) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += item.product_name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
    formateCreator: function (val) {
      if (!val) {
        return "系统";
      } else {
        return val.alias;
      }
    },
    formateSub: function (val) {
      if (val == [] || val == null) {
        return "无";
      }
      var str = "";
      val.forEach((item) => {
        str += `(${item.subject_code})${item.subject_name}` + "，";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return "无";
      }
    },
  },
  methods: {
    getOptions() {
      this.$store.dispatch("getOptions");
      this.$store.dispatch("getProducts");
      this.$store.dispatch("getAllColleges");
    },
    onSubmit() {
      console.log("Submit");
    },
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val;
    },
    // 选中数据后做处理删除
    chooseAllDelete() {
      if (this.deleteList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要删除的数据",
        });
        return false;
      }
      this.$confirm("确定要删除选中的数据吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            ids: [],
          };
          this.deleteList.forEach((item) => {
            query.ids.push(item.id);
          });
          deletestudentlist(query).then((res) => {
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    //筛选组件
    scrrenTable({ screenForm }) {
      this.$set(this.query, "pageIndex", 1);
      this.query = {
        ...this.query,
        ...screenForm,
      };
      this.getList(this.query);
    },
    //页面列表刷新
    getList(query) {
      studentList(query).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    // 打开学生信息弹窗
    openStudentDialog(item) {
      this.studentContent = JSON.parse(JSON.stringify(item));
      this.studentContent.tags = this.studentContent.tags.split(",");
      if (this.studentContent.creator) {
        this.studentContent.creator = this.studentContent.creator.alias;
      }
      this.formateStudent();
      this.$nextTick(() => {
        this.studentDialog = !this.studentDialog;
      });
    },
    // 打开新增学生跟进记录组件
    openAddRecordDialog(item) {
      this.addRecordDialog = true;
      this.addRecordContent = JSON.parse(JSON.stringify(item));
      this.studentId = item.id;
    },
    goRecord(item) {
      this.$router.push({
        name: `record`,
        query: item,
      });
    },
    // 新增跟进记录提交
    addRecordSubmit({ form }) {
      let query = {
        cid: localStorage.getItem("cid"),
        student_id: this.studentId,
        content: form.content,
        status: form.status,
      };
      createstudentadvance(query).then((res) => {
        this.addRecordDialog = false;
        this.$nextTick(() => {
          this.getList(this.query);
        });
      });
    },
    signbillSubmit({ form }) {
      createstudentorder(form).then((res) => {
        this.$nextTick(() => {
          this.signBillDialog = false;
          this.getList(this.query);
        });
      });
    },
    openAddStudentDialog() {
      this.addStudentDialog = !this.addStudentDialog;
    },
    // 新建学生信息弹窗提交
    addStudentSubmit({ form }) {
      createstudent(form).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.addStudentDialog = false;
          this.getList(this.query);
        }
      });
    },
    // 修改学生信息弹窗提交
    editStudentSubmit({ form }) {
      editstudent(form).then((res) => {
        this.studentDialog = false;
        this.$nextTick(() => {
          this.getList(this.query);
        });
      });
    },
    // 打开签单审核弹窗
    openSignBillDialog(item, type) {
      this.signBillContent = JSON.parse(JSON.stringify(item));
      this.signBillContent.tags = this.signBillContent.tags.split(",");
      if (this.signBillContent.creator) {
        this.signBillContent.creator = this.signBillContent.creator.alias;
      }
      this.formateSignBill();
      this.signBillDialog = !this.signBillDialog;
    },
    // 右上角的全部 已归档 未归档
    radioChange(e) {
      this.getList(this.query);
    },
    // 搜索框搜索
    inputSearch(e) {
      this.getList(this.query);
    },
    formateStudent() {
      if (this.studentContent.watcher) {
        // 把watcher参数转换为处理并转换字符串，必须要number格式
        let arr = [];
        this.studentContent.watcher.forEach((item) => {
          arr.push(item.id);
        });
        arr = arr.map((v) => Number(v));
        this.studentContent.watcher = arr;
      }
      if (this.studentContent.products) {
        // 把products参数转换为处理并转换字符串，必须要number格式
        let arr2 = [];
        this.studentContent.products.forEach((item) => {
          arr2.push(item.product_id);
        });
        arr2 = arr2.map((v) => Number(v));
        this.studentContent.products = arr2;
      }
      if (this.studentContent.subjects) {
        // subjects
        let arr3 = [];
        this.studentContent.subjects.forEach((item) => {
          arr3.push(item.subject_code);
        });
        this.studentContent.subjects = arr3;
      }
    },
    formateSignBill() {
      if (this.signBillContent.watcher) {
        // 把watcher参数转换为处理并转换字符串，必须要number格式
        let arr = [];
        this.signBillContent.watcher.forEach((item) => {
          arr.push(item.uid);
        });
        arr = arr.map((v) => Number(v));
        this.signBillContent.watcher = arr;
      }
      if (this.signBillContent.products) {
        // 把products参数转换为处理并转换字符串，必须要number格式
        let arr2 = [];
        this.signBillContent.products.forEach((item) => {
          arr2.push(item.product_id);
        });
        arr2 = arr2.map((v) => Number(v));
        this.signBillContent.products = arr2;
      }
      // 把subjects参数转换为处理并转换字符串，必须要number格式

    },
    // 列表关注创建
    followCreate(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 1,
          follow_id: item.id,
        };
        createuserfollow(query).then((res) => {
          this.getList(this.query);
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 500);
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 1,
          follow_id: item.id,
        };
        deleteuserfollow(query).then((res) => {
          this.getList(this.query);
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 300);
      }
    },
    // 打开拒绝理由弹窗
    opendialogFormVisible(orderinfo) {
      this.audit_feedback = orderinfo.audit_feedback;
      this.dialogFormVisible = true;
    },
    // 批量修改
    chooseAllEdit(archive_status, text) {
      if (this.deleteList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            rows: [],
          };
          this.deleteList.forEach((item) => {
            let obj = {
              id: item.id,
              archive: archive_status,
            };
            query.rows.push(obj);
          });
          editstudentlist(query).then((res) => {
            this.$nextTick(() => {
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    // 跳转导入页面
    goImportFile() {
      this.$router.push({
        name: `importfile`,
        query: { postUrl: "/api/v1/student/list" },
      });
    },
    openBatchEditWatcherDialog() {
      if (this.deleteList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.BatchEditWatcherDialog = true
    },
    BatchEditWatcherSubmit(obj) {
      let query = {
        rows: this.deleteList.map((v) => {
          return {
            id: v.id,
            watcher: obj.watcher,
          }
        })
      }
      editstudentlist(query).then((res) => {
        this.$nextTick(() => {
          this.getList(this.query);
          this.$notify.success({
            title: "提示",
            message: "修改成功",
          })
          this.BatchEditWatcherDialog = false
        });
      });
    }
  },
};
</script>
<style lang="less" scoped>
@import url("../../../assets/css/elementCommon.css");
.content {
  .breadcrumb {
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between;
  }
  .operation-card {
    width: 100%;
    margin-top: 10px;
    .demo-form-inline {
      border-bottom: 2px dashed #d2d5df;
    }
  }
  .operation-menu {
    display: flex;
    justify-content: space-between;
    .menu_left {
      margin-top: 25px;
    }
    .menu_right {
      margin-top: 25px;
    }
  }
  .table-card {
    width: 100%;
    margin-top: 16px;
  }

  .block {
    float: right;
    display: flex;
    justify-content: center;
  }
  .addRecordspan {
    display: block;
    margin-bottom: 32px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #303133;
    opacity: 1;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
  }
}
</style>