<template>
  <div>
    <el-dialog
      title="学员信息"
      :visible.sync="dialogFormVisible"
      width="570px"
      @close="emitClose"
    >
      <el-form
        :model="dataForm"
        label-width="auto"
        label-position="left"
        size="small"
      >
        <el-form-item
          label="姓名/昵称："
          prop="student_nickname"
        >
          <el-input
            v-model="dataForm.student_realname"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input
            placeholder=""
            v-model="dataForm.student_phone"
            class="input-with-select"
          >
            <template slot="prepend">手机号</template>
          </el-input>

        </el-form-item>
        <el-form-item label="">
          <el-input
            placeholder=""
            v-model="dataForm.student_wechat"
            class="input-with-select"
          >
            <template slot="prepend">微信号</template>
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            placeholder=""
            v-model="dataForm.student_qq"
            class="input-with-select"
          >
            <template slot="prepend">Q Q号</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="emitClose"
        >确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>





export default {
  components: {

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.dataForm = val
    }

  },
  methods: {
    emitClose() {
      this.$emit("close");
    },

  },
};
</script>

<style lang="less" scoped>
</style>