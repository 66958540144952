import { render, staticRenderFns } from "./TagServiceSubject.vue?vue&type=template&id=2c62c37f&scoped=true&"
import script from "./TagServiceSubject.vue?vue&type=script&lang=js&"
export * from "./TagServiceSubject.vue?vue&type=script&lang=js&"
import style0 from "./TagServiceSubject.vue?vue&type=style&index=0&id=2c62c37f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c62c37f",
  null
  
)

export default component.exports