<template>
  <div>
    <el-dialog
      title="提交批改"
      :visible.sync="dialogFormVisible"
      width="700px"
      @close="emitClose"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item label="批改分数：">
          <el-input-number
            v-model="dataForm.score"
            controls-position="right"
            :min="0"
            :max="300"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="文件上传："
          prop="content_correct"
        >
          <div class="upload-demo">
            <el-upload
              :action="imgUploadUrl"
              multiple
              :on-success="handleFileSuccess"
              :limit="20"
              :file-list="fileList"
              :on-remove="handleRemove"
              accept=".png,.jpg,.jpeg,.pdf,.doc,.xlsx,.zip,.xls,.docx,.pptx,.ppt,"
            >
              <el-button
                slot="trigger"
                size="small"
                type="primary"
              >点击上传</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item prop="content">
          <div
            class="file-wrap"
            v-if="fileList.length"
          >

            <div
              class="file-item"
              v-for="(item,index) in fileList"
              :key="index"
            >
              <div class="left">
                <img
                  src="@/assets/images/pic_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.jpeg') != -1||item.name.indexOf('.png') != -1||item.name.indexOf('.jpg') != -1"
                >
                <img
                  src="@/assets/images/word_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.doc') != -1||item.name.indexOf('.docx') != -1"
                >
                <img
                  src="@/assets/images/pdf_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.pdf') != -1"
                >
                <img
                  src="@/assets/images/excle_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.xlsx') != -1||item.name.indexOf('.xls') != -1"
                >
                <img
                  src="@/assets/images/ppt_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.ppt') != -1||item.name.indexOf('.pptx') != -1"
                >
                <img
                  src="@/assets/images/zip_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.zip') != -1"
                >
                <span>{{item.name}}</span>
              </div>
              <div class="right">
                <div
                  class="btn"
                  @click="handleToPreview(item)"
                >预览</div>
                <img
                  src="@/assets/images/close.png"
                  alt=""
                  srcset=""
                  class="close"
                  @click="handleFileRemove(index)"
                >
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import { imgUploadUrl, fileUploadUrl } from "@/request/http";
import {
  mockprocessCorrect,
  mockexamprocessEdit
} from "@/request/api";



export default {
  components: {
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dataForm: {
        score: 0
      },
      dialogFormVisible: this.isVisible,
      rules: {
        content_correct: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.fileList.length) {
              callback(new Error('请上传文件'))
            } else {
              callback()
            }
          },
        }],
      },
      imgUploadUrl: imgUploadUrl(),
      fileList: [],
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.dataForm = this.content
        this.fileList = this.content.content_correct ? JSON.parse(this.content.content_correct) : []
      }
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },

    submit(rules) {

      this.$refs[rules].validate((valid) => {
        if (valid) {
          if (![2, 3].includes(this.content.exam_status)) {
            let query = {
              id: this.content.id,
              score: this.dataForm.score,
              content_correct: JSON.stringify(this.fileList),
              correct_status: 2,
            }
            mockexamprocessEdit(query).then((res) => {
              if (res.code == 0) {
                this.$message.success('提交成功')
                this.$emit('submit')
                this.emitClose()
              }
            });
          } else {
            if (!this.dataForm.corrector) {
              this.$message.error('暂无学长信息')
              return
            }
            let query = {
              id: this.content.id,
              senior_id: this.dataForm.corrector.id,
              score: this.dataForm.score,
              content_correct: JSON.stringify(this.fileList),
            }
            mockprocessCorrect(query).then((res) => {
              if (res.code == 0) {
                mockexamprocessEdit({
                  id: this.content.id,
                  correct_status: 2,
                }).then((res1) => {
                  if (res1.code == 0) {
                    this.$message.success('提交成功')
                    this.$emit('submit')
                    this.emitClose()
                  }
                });
                /*  this.$message.success('提交成功')
                 this.$emit('submit')
                 this.emitClose() */
              }
            });
          }

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleFileSuccess(response, file, fileList) {
      this.fileList.push({
        name: file.raw.name,
        url: response.data.url,
      })
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handleToPreview(item) {
      window.open(item.url)
    },
    handleFileRemove(index) {
      this.fileList.splice(index, 1)
    },
  },
};
</script>

<style lang="less" scoped>
.upload-demo {
  overflow: hidden;
  height: 35px;
}
.file-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .file-item {
    & + .file-item {
      margin-top: 16px;
    }
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .btn {
        width: 56px;
        height: 24px;
        border: 1px solid #dcdfe6;
        border-radius: 20px;
        color: #606266;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }
    }
  }
}
</style>