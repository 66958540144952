<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>服务</el-breadcrumb-item>
        <el-breadcrumb-item>教研服务</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px;padding-top:20px;">
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
        size="small"
        label-position="left"
      >
        <el-form-item label="学员信息">
          <el-input
            v-model.trim="query.student_keyword"
            placeholder="手机号/姓名"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="课程状态">
          <el-select
            v-model="query.course_statuses"
            placeholder="请选择"
            @change="getList(true)"
            clearable
            multiple
          >
            <el-option
              v-for="(item,index) in filter.course_status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考研年份">
          <el-select
            v-model="query.apply_years"
            placeholder="请选择"
            @change="getList(true)"
            clearable
            multiple
          >
            <el-option
              v-for="(item,index) in filter.apply_years"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报名班次">
          <el-select
            v-model="query.product_ids"
            placeholder="请选择"
            @change="getList(query,true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in $store.state.productOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务老师">
          <el-input
            v-model.trim="query.teacher_keyword"
            placeholder="请输入"
            @input="getList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="报名日期">
          <el-date-picker
            class="datetime"
            v-model="apply_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList(true)"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="记录时间">
          <el-date-picker
            class="datetime"
            v-model="latest_log_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList(true)"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="服务记录"
          prop="region"
        >
          <el-select
            v-model="log_time"
            placeholder="请选择跟进时间"
            clearable
            @change="recordQuery"
            style="width:140px;"
            @clear="recordQueryClear"
          >
            <el-option
              v-for="item in timeArr"
              :key="item.timestamp"
              :label="item.name"
              :value="item.timestamp"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="query.log_service_subject_id"
            placeholder="请选择服务科目"
            clearable
            @change="recordQuery"
            @clear="recordQueryClear"
            style="margin-left:16px;width:140px;"
          >
            <el-option
              v-for="(item) in $store.state.servicesubjectOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="query.log_service_type_id"
            style="margin-left:16px;width:140px;"
            placeholder="请选择服务类型"
            @change="recordQuery"
            @clear="recordQueryClear"
            clearable
          >
            <el-option
              v-for="(item) in $store.state.servicetypeOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="query.log_is_data"
            style="margin-left:16px;width:140px;"
            placeholder="请选择是否"
            @clear="recordQueryClear"
            @change="recordQuery"
            clearable
          >
            <el-option
              label="是"
              value="1"
            >
            </el-option>
            <el-option
              label="否"
              value="0"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName[1]"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
          style="margin-bottom:0px;display:flex;"
          label-position="right"
        >
          <el-form-item label="只看关注">
            <el-switch
              v-model="query.follow"
              :active-value="1"
              inactive-value=" "
              @change="getList(true,query)"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label-width="32px">
            <el-radio-group
              v-model="query.archive"
              @change="getList(true,query)"
            >
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button label="1">已归档</el-radio-button>
              <el-radio-button label="0">未归档</el-radio-button>
            </el-radio-group>
          </el-form-item>

        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
        @selection-change="handleSelectionChange"
      >

        <el-table-column
          type="selection"
          width="50"
          fixed="left"
        >
        </el-table-column>
        <el-table-column
          prop="date"
          label="关注"
          width="50"
          fixed="left"
        >
          <template slot-scope="scope">
            <img
              alt=""
              srcset=""
              class="star"
              src="@/assets/images/star-fill.png"
              @click="followDelete(scope.row)"
              v-show="scope.row.follow"
            />
            <img
              src="@/assets/images/star.png"
              alt=""
              srcset=""
              class="star"
              v-show="!scope.row.follow"
              @click="followCreate(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="报名日期"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.student_apply_time?$moment(scope.row.student_apply_time).format("YYYY-MM-DD"):""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="student_realname"
          label="学生姓名"
          width="130"
          show-overflow-tooltip
        >

          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="openStudentDialog(scope.row)"
            >{{ scope.row.student_realname }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="课程状态"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.student_course_status==1"
              size="mini"
            >等待上课</el-tag>
            <el-tag
              type="success"
              size="mini"
              v-else-if="scope.row.student_course_status==2"
            >正在上课</el-tag>
            <el-tag
              type="info"
              size="mini"
              v-else-if="scope.row.student_course_status==3"
            >课程结束</el-tag>
            <el-tag
              type="danger"
              size="mini"
              v-else-if="scope.row.student_course_status==4"
            >课程终止</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="考研年份"
          width="80"
        >
          <template slot-scope="scope">
            {{scope.row.student_apply_year}}年
          </template>
        </el-table-column>
        <el-table-column
          prop="product_names"
          label="报名班次"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="student_refer_book"
          label="参考书目"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          prop="student_coach_need"
          label="基本情况"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="定校情况"
          width="300"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{collegeFormate(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="其他备注"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="latest_log_content"
          label="最新记录内容"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="最新记录时间"
          width="150"
        >
          <template slot-scope="scope">
            {{scope.row.latest_log_time?$moment(scope.row.latest_log_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column
          label="教务管理"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{product_watchersFormate(scope.row.student_watcher)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="teacher_names"
          label="服务老师"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="产品经理"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{product_watchersFormate(scope.row.product_watchers)}}
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="操作"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToRecord(scope.row)"
            >详情</el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="openRecordCreateDialog(scope.row)"
            >记录</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button
          size="mini"
          type="primary"
          @click="chooseAllEdit(1, '您确定要归档吗')"
          v-show="query.archive == 0&&query.archive != ''"
        >归档</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="chooseAllEdit(0, '您确定要取消归档吗')"
          v-show="query.archive == 1"
        >取消归档</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="handleToDeleteAll()"
        >批量删除</el-button>
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <AddTeachingAndResearch
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="getList()"
      :id="rowId"
      :service_subject="student_serviceSubject"
    />
    <StudentInfo
      :isVisible="studentDialog"
      @close="studentDialog=false"
      :content="rowContent"
    />
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import AddTeachingAndResearch from "@/components/DialogComponents/Service/AddTeachingAndResearch.vue"
import StudentInfo from "@/components/DialogComponents/Service/StudentInfo.vue"



import {
  teacherServicelist,
  materialDelete,
  teacherServicelistDelete,
  editteacherServicelist,
  studentBasicOne,
  createuserfollow,
  deleteuserfollow,

} from "@/request/api";
export default {
  components: {
    TableTittle,
    AddTeachingAndResearch,
    StudentInfo

  },
  data() {
    return {
      TableName: ["待办事项", "教研服务"],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        archive: "0",
      },
      pageTotal: 0,
      tableData: [],
      createDialog: false,
      fileList: [],
      editId: null,
      editDialog: false,
      chooseList: [],
      createDatetime: "",
      tableImg: [],
      showViewer: false,
      rowContent: {},
      studentDialog: false,
      filter: {
        course_status: [
          { label: "等待上课", value: 1 },
          { label: "正在上课", value: 2 },
          { label: "课程结束", value: 3 },
          { label: "课程终止", value: 4 },
        ],
        apply_years: [
          { label: "2022", value: "2022" },
          { label: "2023", value: "2023" },
          { label: "2024", value: "2024" },
          { label: "2025", value: "2025" },
          { label: "2026", value: "2026" },
        ]
      },
      apply_time: "",
      latest_log_time: "",
      rowId: null,
      student_serviceSubject: [],
      lock: true,
      timeArr: [

        {
          name: "三天内",
          timestamp: Date.now() - 3 * 24 * 60 * 60 * 1000,
        },
        {
          name: "五天内",
          timestamp: Date.now() - 5 * 24 * 60 * 60 * 1000,
        },
        {
          name: "十天内",
          timestamp: Date.now() - 10 * 24 * 60 * 60 * 1000,
        },
        {
          name: "十五天内",
          timestamp: Date.now() - 15 * 24 * 60 * 60 * 1000,
        },
        {
          name: "三十天内",
          timestamp: Date.now() - 30 * 24 * 60 * 60 * 1000,
        }
      ],
      log_time: "",
    };
  },
  filters: {

  },
  watch: {
    // 时间数据监听
    apply_time(value) {
      if (value) {
        this.query.start_student_apply_time = this.$moment(value[0]).valueOf();
        this.query.end_student_apply_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_student_apply_time = "";
        this.query.end_student_apply_time = "";
        this.getList(true)
      }
    },
    // 时间数据监听
    latest_log_time(value) {
      if (value) {
        this.query.start_latest_log_time = this.$moment(value[0]).valueOf();
        this.query.end_latest_log_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_latest_log_time = "";
        this.query.end_latest_log_time = "";
        this.getList(true)
      }
    },
  },
  mounted() {

  },
  created() {
    this.parseQuery();
    this.getList();
    this.$store.dispatch('getServiceSubjectDictionary')
    this.$store.dispatch('getServiceTypeDictionary')
    this.$store.dispatch('getProducts')

  },
  methods: {

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList();
    },

    getList(isReset = false) {
      if (isReset) {
        this.$set(this.query, "pageIndex", 1);
      }
      const params = Object.assign({}, this.query);
      if (params.course_statuses) {
        params.course_statuses = params.course_statuses.filter((v) => v).join(",");
      }
      if (params.apply_years) {
        params.apply_years = params.apply_years.filter((v) => v).join(",");
      }
      if (params.product_ids) {
        params.product_ids = params.product_ids.filter((v) => v).join(",");
      }
      teacherServicelist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
      this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key];
      });
      if (this.query.course_statuses) {
        this.query.course_statuses = this.query.course_statuses.split(",") || [];
      }
      if (this.query.apply_years) {
        this.query.apply_years = this.query.apply_years.split(",") || [];
      }
      if (this.query.product_ids) {
        this.query.product_ids = this.query.product_ids.split(",") || [];
      }
      if (this.query.start_student_apply_time && this.query.end_student_apply_time) {
        this.apply_time = [
          this.$moment(Number(this.query.start_student_apply_time)),
          this.$moment(Number(this.query.end_student_apply_time)),
        ];
      }
      if (this.query.start_latest_log_time && this.query.end_latest_log_time) {
        this.latest_log_time = [
          this.$moment(Number(this.query.start_latest_log_time)),
          this.$moment(Number(this.query.end_latest_log_time)),
        ];
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query);
      if (params.course_statuses) {
        params.course_statuses = params.course_statuses.filter((v) => v).join(",");
      }
      if (params.apply_years) {
        params.apply_years = params.apply_years.filter((v) => v).join(",");
      }
      if (params.product_ids) {
        params.product_ids = params.product_ids.filter((v) => v).join(",");
      }
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      );
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        });
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.tableImg = [url];
      this.showViewer = true;
    },
    handleToEdit(id) {
      this.editId = id
      this.editDialog = true
    },
    handleToRemove(id) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        materialDelete({ id }).then((res) => {
          this.$nextTick(() => {
            this.getList();
          });
        });
      })
    },
    product_watchersFormate(value) {
      if (value && value.length) {
        let map_val = value.map((v) => { return v.alias })
        return map_val.join(',')
      } else {
        return '无'
      }
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    chooseAllEdit(archive_status, text) {
      if (this.chooseList.length == 0) {
        this.$notify.error({
          title: "提示",
          message: "还没有选择要修改的数据",
        });
        return false;
      }
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            rows: this.chooseList.map((v) => {
              return {
                id: v.id,
                archive: archive_status,
              }
            }),
          };
          editteacherServicelist(query).then((res) => {
            this.$nextTick(() => {
              this.getList();
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm("确定要删除这些信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.chooseList.map(v => { return v.id })
        teacherServicelistDelete({ ids }).then((res) => {
          this.getList();
        });
      })
    },
    handleToRecord(row) {
      this.$router.push(`/service/teachingandresearch/record?student_id=${row.student_id}&&service_id=${row.id}`)
    },
    openStudentDialog(row) {
      this.rowContent = Object.assign({}, row)
      this.studentDialog = true
    },
    openRecordCreateDialog(row) {
      studentBasicOne({ cid: localStorage.getItem("cid"), id: row.student_id }).then((res) => {
        if (res.data) {
          this.student_serviceSubject = res.data.teacher_service.service_subjects
          this.rowId = row.id
          this.createDialog = true
        }
      });
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 5,
          follow_id: item.id,
        };
        createuserfollow(query).then((res) => {
          this.getList();
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 500);
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false;
      if (this.lock) {
        this.lock = false;
        let query = {
          type: 5,
          follow_id: item.id,
        };
        deleteuserfollow(query).then((res) => {
          this.getList()
        });
        clearTimeout(flag);
        flag = setTimeout(() => {
          this.lock = true;
        }, 300);
      }
    },
    recordQuery(e) {
      if (this.log_time) {
        this.query.log_start_create_time = this.log_time;
        this.query.log_end_create_time = Date.now();
      } else {
        this.query.log_start_create_time = "";
        this.query.log_end_create_time = "";
      }
      if (this.query.log_start_create_time && this.query.log_end_create_time && this.query.log_service_subject_id && this.query.log_service_type_id && this.query.log_is_data) {
        this.getList(true)
      }
      this.$forceUpdate()
    },
    recordQueryClear() {
      this.log_time = ""
      this.query.log_start_create_time = ""
      this.query.log_end_create_time = ""
      this.query.log_service_subject_id = ""
      this.query.log_service_type_id = ""
      this.query.log_is_data = ""
      this.getList(true)
    },
    collegeFormate(row) {
      if (row.student_is_choose) {
        return `${row.student_apply_college_name},（${row.student_apply_major_code}）${row.student_apply_major_name}`
      } else {
        return "暂无定校"
      }
    },

  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.card-header {
  display: flex;
  justify-content: space-between;
  .el-button {
    height: 35px;
    line-height: 10px;
  }
}
.block {
  margin-bottom: 14px;
  float: right;
}
.el-input {
  width: 200px;
}
</style>