<template>
  <div>
    <!--  <script
      :id="id"
      type="text/plain"
    ></script> -->
    <vue-ueditor-wrap
      v-model="msg"
      :config="myConfig"
    ></vue-ueditor-wrap>
  </div>
</template>



<script>


import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  components: {
    VueUeditorWrap
  },
  data() {
    return {
      editor: null,
      msg: this.setDataStr,
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: true,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: '100%',
        autoFloatEnabled: false,
        serverUrl: location.host.includes('crm-api.yanshizi.com') ? "https://api.kaoyanbox.com/ueditor?_csrf={{ ctx.csrf | safe }}" : "https://test-api.kaoyanbox.net/ueditor?_csrf={{ ctx.csrf | safe }}",
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题
        UEDITOR_HOME_URL: '/static/UE/',
        iframeCssUrl: location.host.includes('crm-api.yanshizi.com') ? '/static/UE/themes/iframe.css' : '/static/ue/themes/iframe.css',
      }
    }
  },
  props: {
    setDataStr: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: "ue_editor"
    },
  },
  watch: {
    setDataStr(val) {
      if (val) {
        this.msg = val
        console.log(val,'val');
      }

    },
    msg(val) {
      this.$emit('change', val)
    }
  },
  created() {

  },
  mounted() {

  },
  destroyed() {

  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>