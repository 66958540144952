<template>
  <div>
    <el-dialog
      title="试卷详情"
      :visible.sync="dialogFormVisible"
      width="700px"
      @close="emitClose"
      :close-on-click-modal="false"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="试卷名称："
          prop="name"
        >
          <el-input
            v-model.trim="dataForm.name"
            placeholder="请输入试卷名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="考研年份："
          prop="year"
        >
          <el-select
            v-model="dataForm.year"
            placeholder="请选择考研年份"
            clearable
          >
            <el-option
              label="2022"
              value="2022"
            ></el-option>
            <el-option
              label="2023"
              value="2023"
            ></el-option>
            <el-option
              label="2024"
              value="2024"
            ></el-option>
            <el-option
              label="2025"
              value="2025"
            ></el-option>
            <el-option
              label="2026"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="院校名称："
          prop="college"
        >
          <el-select
            v-model="dataForm.college"
            placeholder="请输入选择院校名称"
            filterable
            clearable
            @change="collegeChange"
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="专业名称："
          prop="major"
        >
          <el-select
            v-model="dataForm.major"
            placeholder="请选择专业名称"
            clearable
            @change="majorChange"
            filterable

          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
              <span>{{ `（${item.code}）${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="科目名称："
          prop="subjects_"
        >
          <el-select
            v-model="dataForm.subjects_"
            placeholder="请选择科目名称"
            clearable
            :multiple-limit="1"
            multiple
          >
            <el-option
              v-for="(item,index) in subjectsOptions"
              :key="index"
              :label="item.subject_name"
              :value="{ value: item.subject_code, label: item.subject_name}"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="出题人："
          prop="author"
        >
          <el-input
            v-model.trim="dataForm.authorInfo"
            placeholder="请输入出题人"
            clearable
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="文件上传：">
          <div class="upload-demo">
            <el-upload
              :action="imgUploadUrl"
              multiple
              :on-success="handleFileSuccess"
              :limit="3"
              :file-list="fileList"
              accept=".png,.jpg,.jpeg,.pdf,.doc,.xlsx,.zip,.xls,.docx,.pptx,.ppt,"
            >
              <el-button
                slot="trigger"
                size="small"
                type="primary"
              >点击上传</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item prop="content">
          <div
            class="file-wrap"
            v-if="fileList.length"
          >

            <div
              class="file-item"
              v-for="(item,index) in fileList"
              :key="index"
            >
              <div class="left">
                <img
                  src="@/assets/images/pic_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.jpeg') != -1||item.name.indexOf('.png') != -1||item.name.indexOf('.jpg') != -1"
                >
                <img
                  src="@/assets/images/word_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.doc') != -1||item.name.indexOf('.docx') != -1"
                >
                <img
                  src="@/assets/images/pdf_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.pdf') != -1"
                >
                <img
                  src="@/assets/images/excle_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.xlsx') != -1||item.name.indexOf('.xls') != -1"
                >
                <img
                  src="@/assets/images/ppt_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.ppt') != -1||item.name.indexOf('.pptx') != -1"
                >
                <img
                  src="@/assets/images/zip_icon.png"
                  alt=""
                  srcset=""
                  v-if="item.name.indexOf('.zip') != -1"
                >
                <span>{{item.name}}</span>
              </div>
              <div class="right">
                <div
                  class="btn"
                  @click="handleToPreview(item)"
                >预览</div>
                <img
                  src="@/assets/images/close.png"
                  alt=""
                  srcset=""
                  class="close"
                  @click="handleFileRemove(index)"
                >
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <div style="float:left;">
          <el-button
            size="small"
            @click="download"
          >下 载</el-button>

        </div>

        <div v-if="dataForm.check_status==0">
          <el-button
            type="danger"
            @click="changeStatus(2)"
            size="small"
          >驳 回</el-button>
          <el-button
            type="primary"
            size="small"
            @click="changeStatus(1)"
          >通 过</el-button>
        </div>
        <div v-else-if="dataForm.check_status==1">
          <el-button
            type="info"
            size="small"
            disabled
          >已通过</el-button>
          <el-button
            size="small"
            type="primary"
            @click="submit('formRules',dataForm.check_status)"
          >修 改</el-button>
        </div>
        <div v-else-if="dataForm.check_status==2">
          <el-button
            type="info"
            size="small"
            disabled
          >已驳回</el-button>
          <el-button
            size="small"
            type="primary"
            @click="submit('formRules',dataForm.check_status)"
          >修 改</el-button>
        </div>

      </div>
    </el-dialog>
    <RefuseDialog
      :isVisible="refuseDialog"
      @submit="RefuseDialogSubmit"
      @close="refuseDialog=false"
    />
  </div>
</template>

<script>




import { imgUploadUrl, fileUploadUrl } from "@/request/http";
import { majorlist, mockexampaperOne, mockexampaperEdit, subjectlist } from "@/request/api";
import RefuseDialog from "@/components/DialogComponents/RefuseDialog.vue";
/* let baseurl = 'http://view.xdocin.com/xdoc?_xdoc=' */
let baseurl = `${window.location.protocol}://view.officeapps.live.com/op/view.aspx?src=`


export default {
  components: {
    RefuseDialog
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      majorOptions: [],
      subjectsOptions: [],
      imgUploadUrl: imgUploadUrl(),
      fileList: [],
      loading: false,
      rules: {
        name: [{ required: true, message: "请输入模考名称", trigger: "blur" }],
        year: [{ required: true, message: "请选择年份", trigger: "change" }],
        author: [{ required: true, message: "请输入选择出题人", trigger: "change" }],
        college: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.dataForm.college_code || !this.dataForm.college_name) {
              callback(new Error('请选择院校'))
            } else {
              callback()
            }
          },
        }],
        major: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.dataForm.major_code || !this.dataForm.major_name) {
              callback(new Error('请选择专业'))
            } else {
              callback()
            }
          },
        }],
        subjects_: [{ required: true, message: "请选择科目", trigger: "blur" }],

        content: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.fileList.length) {
              callback(new Error('请上传文件'))
            } else {
              callback()
            }
          },
        }],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      refuseDialog: false,
      baseurl: baseurl,

    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.$store.dispatch('getAllColleges')
        this.fetchDetail()
      }
    },

  },
  methods: {
    fetchDetail() {
      mockexampaperOne({ id: this.id }).then((res) => {
        this.dataForm = res.data
        this.$set(this.dataForm, 'college', {
          value: res.data.college_code,
          label: res.data.college_name,
        })
        this.getMajor(res.data.college_code)
        this.$set(this.dataForm, 'major', {
          value: res.data.major_code,
          label: res.data.major_name,
        })
        this.$set(this.dataForm, 'subjects_', res.data.subjects && res.data.subjects.length ? res.data.subjects.map(v => {
          return {
            value: v.subject_code,
            label: v.subject_name,
          }
        }) : [])
        this.getSubjects(res.data.college_code, res.data.major_code)
        this.$forceUpdate()
        if (res.data.author) {
          this.$set(this.dataForm, 'authorInfo', `${res.data.author.realname}/${res.data.author.mobile}`)
        }
        this.fileList = res.data.content ? JSON.parse(res.data.content) : []


      });
    },
    collegeChange(e) {
      this.subjectsOptions = []
      this.majorOptions = []
      this.subjectsOptions = []
      this.dataForm.major = null
      this.dataForm.major_code = null
      this.dataForm.major_name = null
      this.dataForm.subjects_ = null
      if (e) {
        this.dataForm.college_code = e.value
        this.dataForm.college_name = e.label
        this.getMajor(e.value);
      }
    },
    majorChange(e) {
      this.dataForm.major_code = e.value
      this.dataForm.major_name = e.label
      this.majorOptions = []
      this.subjectsOptions = []
      this.dataForm.subjects_ = null
      if (e) {
        this.getSubjects(this.dataForm.college_code, e.value);
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((res) => {
        this.majorOptions = res.data.list || [];
      });
    },
    getSubjects(college_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        this.subjectsOptions = res.data.list || [];
      });
    },
    emitClose() {
      this.dataForm = {}
      this.$emit("close");
    },
    handleFileSuccess(response, file, fileList) {
      this.fileList.push({
        name: file.raw.name,
        url: response.data.url,
      })
    },
    handleFileRemove(index) {
      this.fileList.splice(index, 1)
    },
    handleToPreview(item) {
      
      let data = item.url.split(".")
      if(data[data.length-1] == 'docx' ||data[data.length-1] == 'xls'||data[data.length-1] == 'ppt'||data[data.length-1] == 'pptx'||data[data.length-1] == 'xlsx'){
        window.open('https://view.officeapps.live.com/op/view.aspx?src='+ item.url)
      }else{
        window.open(item.url)
      }
      // if (item.name.indexOf('.pdf') != -1 || item.name.indexOf('.png') != -1 || item.name.indexOf('.jpg') != -1 || item.name.indexOf('.jpeg') != -1) {
      //   window.open(item.url)
      // } else {
      //   window.open(baseurl + item.url)
      // }
    },
    submit(rules, status, refuse_reason = "") {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.id,
            cid: localStorage.getItem("cid"),
            name: this.dataForm.name,
            year: this.dataForm.year,
            mock_exam_id: this.dataForm.mock_exam_id,
            college_code: this.dataForm.college.value,
            college_name: this.dataForm.college.label,
            major_code: this.dataForm.major.value,
            major_name: this.dataForm.major.label,
            content: JSON.stringify(this.fileList),
            check_status: status,
            refuse_reason: refuse_reason,
            subjects: this.dataForm.subjects_.map(v => {
              return {
                subject_code: v.value,
                subject_name: v.label,
              }
            })
          }
          mockexampaperEdit(query).then((res) => {
            this.$message.success('提交成功')
            this.$emit('submit')
            this.emitClose()
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    remoteMethod(query) {
      if (query != "") {
        this.$store.dispatch('fetchAdminSeniorList', { mobile: query })
      }
    },
    changeStatus(status) {
      switch (status) {
        case 1:
          this.$confirm("是否通过此试卷", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.submit('formRules', 1)
          })
          break;
        case 2:
          this.refuseDialog = true
          break;
        default:
          break;
      }
    },
    RefuseDialogSubmit({ text }) {
      this.submit('formRules', 2, text)
      this.refuseDialog = false
    },
    download() {
      if (this.fileList.length) {
        let fileData = this.fileList[0]
        this.getBlob(fileData.url, fileData.name)
      } else {
        this.$message.error('还没有上传文件')
      }
    },
    getBlob(imgurl, filename = '文件') {
      var ajax = new XMLHttpRequest();
      ajax.open("GET", imgurl, true);
      ajax.responseType = 'blob';
      ajax.onload = e => {
        let res = e.target.response
        let blob = new Blob([res], { type: res.type }) // 这里的res为后端返回的流数据
        if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
          navigator.msSaveBlob(blob, filename)
        } else {
          const href = URL.createObjectURL(blob) //创建新的URL表⽰指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = filename //指定下载⽂件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        }
      }
      ajax.send()
    },
  },
};
</script>

<style lang="less" scoped>
.el-input,
.el-select {
  width: 100%;
}
.upload-demo {
  overflow: hidden;
  height: 35px;
}
.file-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .file-item {
    & + .file-item {
      margin-top: 16px;
    }
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .btn {
        width: 56px;
        height: 24px;
        border: 1px solid #dcdfe6;
        border-radius: 20px;
        color: #606266;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }
    }
  }
}
</style>