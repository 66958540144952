<template>
  <div class="content">
    <!-- 发货列表 -->
    <el-table
      :data="tableData"
      :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
      :cell-style="{ textAlign: 'center', fontSize: '13px' }"
      border
    >
      <el-table-column
        prop="id"
        label="发货单ID"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="theme"
        label="发货主题"
        width="260"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="student_realname"
        label="学员姓名"
        width="130"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        label="收件信息"
        width="350"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{scope.row.receiver_name||"--"}}/{{scope.row.receiver_phone||"--"}}/{{scope.row.receiver_address||"--"}}
        </template>

      </el-table-column>
      <el-table-column
        label="发货清单"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-link
            type="primary"
            :underline="false"
            @click="handleToDetail(scope.row.id)"
          >清单详情</el-link>
        </template>
      </el-table-column>
      <el-table-column
        label="物流状态"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag
            type="warning"
            size="mini"
            v-if="scope.row.express_status==0"
          >待发货</el-tag>
          <el-tag
            size="mini"
            v-if="scope.row.express_status==1"
          >已发货</el-tag>
          <el-tag
            type="info"
            size="mini"
            v-if="scope.row.express_status==2"
          >已取消</el-tag>
          <el-tag
            type="success"
            size="mini"
            v-if="scope.row.express_status==3"
          >已送达</el-tag>
          <el-tag
            type="info"
            size="mini"
            v-if="scope.row.express_status==4"
          >已退回</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="express_name"
        label="快递名称"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="express_no"
        label="快递单号"
        width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        label="发货截图"
        width="100"
        show-overflow-tooltip
      >
        <template
          slot-scope="scope"
          v-if="scope.row.express_pictures"
        >
          <img
            src="@/assets/images/128@2x.png"
            alt=""
            srcset=""
            style="width:20px;height:20px;"
            @click="openViewer(scope.row.express_pictures)"
          >
        </template>
      </el-table-column>
      <el-table-column
        label="创建人"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{scope.row.creator.alias}}
        </template>
      </el-table-column>
      <el-table-column
        label="发货人"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{scope.row.express_creator?scope.row.express_creator.alias:""}}

        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        width="160"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")}}
        </template>
      </el-table-column>
      <el-table-column
        label="发货时间"
        width="160"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{$moment(scope.row.express_time).format("YYYY-MM-DD HH:mm")}}
        </template>
      </el-table-column>
    </el-table>
    <div
      class="pagination"
      style="display: flex;align-items: center;justify-content: flex-end;margin-top:16px;"
    >
      <div class="block">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes, jumper"
          :current-page="Number(query.pageIndex)"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="Number(query.pageSize)"
          :total="Number(pageTotal)"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <el-image-viewer
      v-if="showViewer"
      :url-list="tableImg"
      :on-close="closeViewer"
    />

    <DeliverDetail
      :isVisible="detailDialog"
      @close="detailDialog=false"
      :id="detailId"
    />
  </div>

</template>

<script>


import {
  shipliplist
} from "@/request/api";
// 导入组件
import DeliverDetail from "@/components/DialogComponents/DeliverList/DeliverDetail.vue"
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: {
    ElImageViewer,
    DeliverDetail
  },
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      pageTotal: 0,
      tableData: [],
      tableImg: [],
      showViewer: false,
      detailId: null,
      detailDialog: false,
    };
  },
  filters: {

  },
  mounted() {
    this.getList(this.query);
  },
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },

    getList(query) {
      const params = Object.assign({}, query)
      if (this.$route.params.id) {
        params.product_id = this.$route.params.id
      }
      shipliplist(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    openViewer(url) {
      this.tableImg = url.split(',');
      this.showViewer = true;
    },
    handleToDetail(id) {
      this.detailId = id
      this.detailDialog = true
    },
  },
};
</script>

<style lang="less" scoped>
</style>