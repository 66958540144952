<template>
  <div class="content">
    <el-table
      :data="tableData"
      :header-cell-style="{
        textAlign: 'center',
        color: '#909399',
        fontFamily: 'PingFang SC',
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: '#F2F6FC',
      }"
      size="mini"
      :cell-style="{ textAlign: 'center', fontSize: '13px' }"
    >
      <el-table-column label="姓名">
        <template slot-scope="scope">
          <el-input
          type="textarea"
          :rows="5"
            v-model="scope.row.name"
            placeholder="请输入内容"
            @input="sync"
            :disabled="disabled"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="手机号">
        <template slot-scope="scope">
          <el-input
           type="textarea"
          :rows="5"
            v-model="scope.row.phone"
            placeholder="请输入内容"
            @input="sync"
            :disabled="disabled"
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div class="btn-wrap">
      <el-button size="small" @click="addUser" class="small-btn"
        >增加用户</el-button
      >
    </div> -->
  </div>
</template>

<script>
import { read, utils } from "xlsx";
import layoutVue from '../../../views/layout.vue';

export default {
  components: {},
  props: {
    content: {
      type: Array,
      default: () => {
        return [];
      },
    },
    single: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [
        {
          name:'',
          phone:''
        }
      ],
    };
  },

  created() {},
  watch: {
    content(val) {
      this.tableData = val;
    },
  },
  computed: {},
  methods: {
    // addUser() {
    //   this.tableData.push({
    //     temp_student_realname: "",
    //     temp_student_phone: "",
    //   });
    // },
    // handleToRemove(index) {
    //   this.tableData.splice(index, 1);
    // },
    sync() {
      this.$emit("submit", this.tableData);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 16px 0px;
  border-bottom: 1px solid #ebeef5;
  .btn-wrap {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.upload-demo {
  width: 80px;
  overflow: hidden;
  height: 38px;
  margin-left: 16px;
}
.small-btn {
  width: 80px;
  height: 35px;
}
</style>
