<template>
  <div class="dialog-wrap">
    <el-dialog
      title="专业选择"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="980px"
    >
      <el-form
        class="search-wrap"
        ref="searchForm"
        :inline="true"
        :model="query"
      >
        <el-form-item
          label="专业名称"
          prop="keyword"
        >
          <el-input
            v-model="query.keyword"
            size="mini"
            placeholder="输入专业名称/代码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-select
            style="width: 100px"
            v-model="query.status"
            size="mini"
            placeholder="请选择"
          >
            <el-option
              v-for="item in filter.status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            @click="handleSearch"
          >搜索</el-button>
          <el-button
            class="submit"
            size="mini"
            @click="handleFormReset('searchForm')"
          >重置</el-button>
        </el-form-item>
      </el-form>
      <div class="values">
        <el-tabs
          v-model="tabName"
          @tab-click="handleTabChange"
        >
          <el-tab-pane
            label="学术型硕士"
            name="science"
          ></el-tab-pane>
          <el-tab-pane
            label="专业学位硕士"
            name="speciality"
          ></el-tab-pane>
        </el-tabs>
        <div class="v-main">
          <ul class="menu-list">
            <li
              :class="{'menu-item': true, 'active': level1_key == item.code}"
              v-for="item of level1"
              :key="item.id"
              @click="handleLevelKey(item, 1)"
            >{{item.name}} <small>({{item.code}})</small></li>
          </ul>
          <ul class="menu-list">
            <li
              :class="{'menu-item': true, 'active': level2_key == item.code}"
              v-for="item of level2"
              :key="item.id"
              @click="handleLevelKey(item, 2)"
            >{{item.name}} <small>({{item.code}})</small></li>
          </ul>
          <div class="table">
            <el-table
              class="table"
              ref="multipleTable"
              :data="level3"
              tooltip-effect="dark"
              size="mini"
              style="width: 100%"
            >
              <!--          <el-table-column
                type="selection"
                width="55"
              >
              </el-table-column> -->
              <el-table-column
                prop="name"
                label="专业名称"
                min-width="160"
                align="left"
              >
              </el-table-column>
              <el-table-column
                prop="code"
                label="专业代码"
                width="120"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="college_code"
                label="院校代码"
                width="120"
                align="center"
              >
              </el-table-column>
              <el-table-column
                label="状态"
                width="120"
                align="center"
              >
                <template slot-scope="scope">
                  <el-tag :type="scope.row.status == 1?'success':'danger'">{{scope.row.status == 1 ? '正常' : '下线'}}</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label=""
                width="60"
                align="center"
              >
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="choose(scope.row)"
                  >选择</el-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="handleSubmit"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { allmajorlist } from "@/request/api";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    majors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: this.visible,
      query: {
        keyword: '',
        status: '',
      },
      filter: {
        status: [
          { label: '全部', value: '' },
          { label: '正常', value: '1' },
          { label: '下线', value: '0' }
        ],
      },
      tabName: 'science',
      results: [],
      level1_key: '',
      level2_key: '',
      level1: [],
      level2: [],
      level3: [],
      selections: [],
      countSelections: []
    }
  },
  watch: {
    visible(val) {
      this.dialogVisible = val
      if (val) {
        this.fetchallmajorlist()
      }
    },
    majors(val) {
      this.countSelections = val
    }
  },
  methods: {
    // 设置选中行
    setRowSelection() {
      if (this.level3.length) {
        this.level3.forEach(row => {
          if (this.majors.find(item => item.id === row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true)
          }
        })
      }
    },
    // 专业列表请求
    fetchallmajorlist() {
      let params = { ...this.query }
      allmajorlist(params).then(res => {
        this.results = res.data.list || []

        this.handleLevelChange({ level: 0 })

        this.$nextTick(this.setRowSelection)
      })
    },
    // tab切换
    handleTabChange(tab) {
      if (tab.name == 'science') {
        if (this.results[0]) {
          this.handleLevelChange({ level: 0, index: 0 })
        }
      } else if (tab.name == 'speciality') {
        if (this.results[1]) {
          this.handleLevelChange({ level: 0, index: 1 })
        }
      }
    },
    // 一级专业选择
    handleLevelKey(item, level = 1) {
      if (level == 1) {
        this.level1_key = item.code
      } else if (level == 2) {
        this.level2_key = item.code
      }
      this.handleLevelChange({ key: item.code, level })

      this.$nextTick(this.setRowSelection)
    },
    // 二级专业选择
    handleLevelChange({ key, level = 0, index = 0 }) {
      if (level == 0) {
        if (key) {
          const major = this.results.find(v => v.code == key)
          if (major) {
            this.level1 = major.children || []
          }
        } else {
          if (this.results.length > index) {
            this.level1 = this.results[index].children || []
          } else {
            this.level1 = []
          }
        }
        if (this.level1.length) {
          this.level1_key = this.level1[0].code
          this.handleLevelChange({ key: this.level1[0].code, level: 1 })
        } else {
          this.level2 = []
          this.level3 = []
        }
      } else if (level == 1) {
        if (key) {
          const major = this.level1.find(v => v.code == key)
          if (major) {
            this.level2 = major.children || []
          }
        } else {
          if (this.level1.length > index) {
            this.level2 = this.level1[index].children || []
          } else {
            this.level2 = []
          }
        }
        if (this.level2.length) {
          this.level2_key = this.level2[0].code
          this.handleLevelChange({ key: this.level2[0].code, level: 2 })
        } else {
          this.level3 = []
        }
      } else if (level == 2) {
        if (key) {
          const major = this.level2.find(v => v.code == key)
          if (major) {
            this.level3 = major.children || []
          }
        } else {
          if (this.level2.length > index) {
            this.level3 = this.level2[index].children || []
          } else {
            this.level3 = []
          }
        }
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, 'pageIndex', 1)
      this.fetchallmajorlist()
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields()
      this.fetchallmajorlist()
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, 'pageIndex', val)
      this.fetchallmajorlist()
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val
      this.diffSelection()
    },
    handleSubmit() {
      this.$emit('submit', { majors: this.countSelections })
      this.handleClose()
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        keyword: '',
        type: '',
        status: '',
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1
      }
      this.$emit('close')
    },
    // 选中项差异化计算
    diffSelection() {
      let diffs = []
      // 减
      this.countSelections.forEach((item, index) => {
        if (!this.selections.find(v => v.id === item.id)) {
          if (this.results.find(v2 => v2.id === item.id)) {
            diffs.push(item)
          }
        }
      })
      if (diffs.length) {
        this.countSelections = this.countSelections.filter(item => !diffs.find(v => v.id === item.id))
      }
      // 加
      this.selections.forEach(item => {
        if (!this.countSelections.find(v => v.id === item.id)) {
          this.countSelections.push(item)
        }
      })
    },
    choose(row) {
      this.$emit('select', row)
      this.handleClose()

    },

  }
}
</script>

<style lang="less" scoped>
.dialog-wrap {
  .search-wrap {
    margin-bottom: -10px;
  }
  .values {
  }
  .v-main {
    display: flex;
    border: 1px solid #e5e5e5;
  }
  .menu-list {
    width: 200px;
    background: #ecf6fd;
    border-right: 1px solid #e5e5e5;
  }
  .menu-item {
    line-height: 39px;
    cursor: pointer;
    padding: 0 0 0 10px;

    &.active {
      color: #1787e0;
      background: #fff;
    }
  }
  .table {
    flex: 1;
  }
}
li {
  list-style-type: none;
}
</style>
