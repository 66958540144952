<template>
  <div class="content">
    <el-dialog
      title="学员信息"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="570px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="auto"
        label-position="left"
        size="small"
      >
        <el-form-item label="姓名">
          <el-input
            v-model="form.realname"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="考研年份">
          <el-select
            v-model="form.apply_year"
            placeholder="请选择"
            style="width: 284px"
          >
            <el-option
              label="2022年"
              value="2022"
            ></el-option>
            <el-option
              label="2023年"
              value="2023"
            ></el-option>
            <el-option
              label="2024年"
              value="2024"
            ></el-option>
            <el-option
              label="2025年"
              value="2025"
            ></el-option>
            <el-option
              label="2026年"
              value="2026"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input
            placeholder="请输入内容"
            v-model="form.phone"
            class="input-with-select"
            clearable
          >
            <template slot="prepend">手机号</template>
          </el-input>
          <el-input
            placeholder="请输入内容"
            v-model="form.wechat"
            class="input-with-select"
            clearable
          >
            <template slot="prepend">微信号</template>
          </el-input>
          <el-input
            placeholder="请输入内容"
            v-model="form.qq"
            class="input-with-select"
            clearable
          >
            <template slot="prepend">&nbsp;QQ号&nbsp;</template>
          </el-input>
        </el-form-item>
        <el-form-item label="学员标签">
          <el-select
            v-model="form.tags"
            multiple
            collapse-tags
            placeholder="请选择"
            style="width: 284px"
          >
            <el-option
              v-for="item in $store.state.labelOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="意向课程">
          <el-select
            v-model="form.products"
            multiple
            collapse-tags
            placeholder="请选择"
            style="width: 455px"
          >
            <el-option
              v-for="item in $store.state.productOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="意向院校">
          <el-select
            v-model="form.apply_college_code"
            filterable
            collapse-tags
            placeholder="请选择"
            style="width: 455px"
            @change="collegeChange"
          >
            <el-option
              v-for="(item, index) in  $store.state.collegeOptions"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="意向学院">
          <el-select
            v-model="form.apply_academy_code"
            filterable
            collapse-tags
            placeholder="请选择"
            style="width: 455px"
            @change="academyChange"
          >
            <el-option
              v-for="(item, index) in academyOptions"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="意向专业">
          <el-select
            v-model="form.apply_major_code"
            filterable
            placeholder="请选择"
            style="width: 455px"
            :disabled="!form.apply_academy_code"
            @change="majorChange"
          >
            <el-option
              v-for="(item, index) in majorOptions"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
              <span>{{ `（${item.code}）${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="辅导科目"
          prop="subjects"
        >
          <el-select
            v-model="form.subjects"
            multiple
            placeholder="请选择"
            style="width: 455px"
            :disabled="!form.apply_major_code"
          >
            <el-option
              v-for="(item, index) in subjectsOptions"
              :key="index"
              :label="item.subject_name"
              :value="item.subject_code"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学员来源">
          <el-select
            v-model="form.source"
            placeholder="请选择"
            style="width: 284px"
          >
            <el-option
              v-for="item in  $store.state.sourceOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="负责人">
          <el-select
            v-model="form.watcher"
            multiple
            placeholder="请选择"
            style="width: 400px"
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.uid"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  dictionary,
  majorlist,
  collegeList,
  companyuserlist,
  companycourselist,
  academylist,
  subjectlist,
} from "../../../request/api";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      form: this.content,
      majorOptions: [],
      academyOptions: [],
      subjectsOptions: [],
    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      if (val.apply_college_code) {
        this.getAcedemy(val.apply_college_code);
      }
      this.$nextTick(() => {
        this.form = val;
      });
    },
  },
  methods: {
    // 添加联系方式输入框
    addContectInput() {
      this.form.arr.push({
        value: "",
      });
    },
    emitClose() {
      this.$emit("close");
    },
    // 因为传课程产品时要上传对象所以在这里做一些处理
    formateProducts() {
      let arr = [];
      this.form.products.forEach((item) => {
        let obj = {};
        obj = this.$store.state.productOptions.find((v) => {
          return v.id === item; //筛选出匹配数据
        });
        if (obj) {
          arr.push({
            product_id: obj.id,
            product_name: obj.name,
          });
        }
      });
      this.form.products = arr;
    },
    submit() {
      if (this.form.products) {
        this.formateProducts();
      }
      if (this.form.subjects) {
        this.formateSubjects();
      }
      this.form.tags = this.form.tags.join(",");
      this.form.watcher = this.form.watcher.join(",");
      delete this.form.creator;
      this.$emit("submit", { form: this.form });
    },
    collegeChange(e) {
      this.form.apply_academy_name = "";
      this.form.apply_academy_code = "";
      this.form.apply_major_name = "";
      this.form.apply_major_code = "";
      this.form.subjects = [];
      let obj = {};
      obj = this.$store.state.collegeOptions.find((item) => {
        return item.code === e; //筛选出匹配数据
      });
      this.form.apply_college_name = obj.name;
      this.getAcedemy(this.form.apply_college_code);
    },
    academyChange(e) {
      this.form.apply_major_name = "";
      this.form.apply_major_code = "";
      this.form.subjects = [];
      let obj = {};
      obj = this.academyOptions.find((item) => {
        return item.code === e; //筛选出匹配数据
      });
      this.form.apply_academy_name = obj.name;
      this.getMajor(this.form.apply_college_code, this.form.apply_academy_code);
    },
    majorChange(e) {
      this.form.subjects = [];
      this.form.apply_major_code = e;
      let obj = {};
      obj = this.majorOptions.find((item) => {
        return item.code === e; //筛选出匹配数据
      });
      this.form.apply_major_name = obj.name;
      this.form.subjects = [];
      this.getSubjects(
        this.form.apply_college_code,
        this.form.apply_academy_code,
        this.form.apply_major_code
      );
    },
    getAcedemy(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      academylist(query).then((res) => {
        this.academyOptions = res.data.list || [];
        this.getMajor(
          this.form.apply_college_code,
          this.form.apply_academy_code
        );
      });
    },
    getMajor(college_code, academy_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        academy_code: academy_code,
      };
      majorlist(query).then((res) => {
        this.majorOptions = res.data.list || [];
        this.getSubjects(
          this.form.apply_college_code,
          this.form.apply_academy_code,
          this.form.apply_major_code
        );
      });
    },
    getSubjects(college_code, academy_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        academy_code: academy_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程"
        }
        this.subjectsOptions = res.data.list || [];
        this.subjectsOptions.push(obj)
      });
    },
    // 因为传科目时要上传对象所以在这里做一些处理
    formateSubjects() {
      let arr = [];
      this.form.subjects.forEach((item) => {
        let obj = {};
        obj = this.subjectsOptions.find((v) => {
          return v.subject_code === item; //筛选出匹配数据
        });
        if (obj) {
          arr.push({
            subject_code: obj.subject_code,
            subject_name: obj.subject_name,
          });
        }
      });
      this.form.subjects = arr;
    },
  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
</style>