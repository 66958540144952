import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/common.less'
import axios from 'axios'
import { buttonPermission } from "./utils/helper";

// 导入时间插件momentjs
import moment from 'moment'
// 导入粘贴板插件
import VueClipboard from 'vue-clipboard2'
import CanvasPoster from 'vue-canvas-poster'


import '../public/static/UE/ueditor.config.js'
import '../public/static/UE/ueditor.all.js'
import '../public/static/UE/lang/zh-cn/zh-cn.js'
import '../public/static/UE/ueditor.parse.js'
import '../public/static/UE/themes/default/css/ueditor.css'
import '../public/static/UE/kityformula-plugin/addKityFormulaDialog.js'
import '../public/static/UE/kityformula-plugin/getKfContent.js'
import '../public/static/UE/kityformula-plugin/defaultFilterFix.js'


Vue.prototype.$moment = moment


Vue.use(VueClipboard)
Vue.use(CanvasPoster)

Vue.prototype.$base64Img = (path) => {            //图片转换base64  , 异步处理
  function getBase64Image(img) {               		//转码格式方法
    var canvas = document.createElement("canvas"); 	// 创建一个canvas
    canvas.width = img.width;               		// 设置对应的宽高
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");      		// 二维绘图环境
    ctx.drawImage(img, 0, 0, img.width / 4, img.height / 4);                            // 将图片画在画布上
    var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    // 获取到图片的格式
    var dataURL = canvas.toDataURL(ext);                             // 得到base64 编码的 dataURL 
    return dataURL.replace(/^data:image\/\w+;base64,/, '');
  }
  return new Promise(function (resolve, reject) {      //使用Promise进行异步处理
    let image = new Image();
    //解决图片跨域问题
    image.crossOrigin = '';
    //获取传入的图片路径
    image.src = path;
    //图片加载完后的回调函数,调用转码函数
    image.onload = function () {
      resolve(getBase64Image(image))   	//回调函数返回base64值
    }
  })
}



// 定义全局时间格式过滤器
Vue.filter('dateFormat', function (daraStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(daraStr).format(pattern)
})


router.beforeEach((to, form, next) => {
  if (to.path == '/login') {
/*     localStorage.removeItem("userInfo");
    localStorage.removeItem("AccountInfo");
    localStorage.removeItem("cid");
    localStorage.removeItem("token"); */
  }
  next();
  /*  let user = JSON.parse(localStorage.getItem("userInfo"));
   if (!user && to.path != '/login') {
     next({ path: '/login' })
   } else {
     next();
   } */
})

Vue.prototype.$axios = axios
Vue.prototype.$buttonPermission = buttonPermission


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
