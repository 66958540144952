<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>制定教学计划</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">新建</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card style="margin-top: 20px; padding-top: 20px">
      <TableTittle tittle="新建"></TableTittle>
      <el-form
        :model="dataForm"
        label-position="right"
        ref="dataForm"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item label="院校：" prop="apply_college_code">
          <el-select
            style="width: 760px"
            v-model="dataForm.apply_college_code"
            filterable
            placeholder="请选择"
            clearable
            @change="collegeChoose"
          >
            <el-option
              v-for="item in $store.state.collegeList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业：" prop="apply_major_code">
          <el-select
            filterable
            placeholder="请选择"
            v-model="dataForm.apply_major_code"
            @change="majorChoose"
            clearable
            style="width: 760px"
          >
            <el-option
              v-for="(item, index) in majorOptions"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
              <span>{{ `（${item.code}）${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学院：" prop="apply_academy_code">
          <el-select
            filterable
            placeholder="请选择"
            v-model="dataForm.apply_academy_code"
            @change="academyChoose"
            clearable
            style="width: 760px"
          >
            <el-option
              v-for="(item, index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="item.academy_code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="辅导科目" prop="apply_subject_codes">
          <el-select
            v-model="dataForm.apply_subject_codes"
            
            placeholder="请选择"
            style="width: 760px"
          >
            <el-option
              v-for="(item, index) in subjectsOptions"
              :key="index"
              :label="item.subject_name"
              :value="item.subject_code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="'参考书' + (index + 1)"
          v-for="(domain, index) in dataForm.apply_reference_list"
          :prop="'apply_reference_list.' + index + '.apply_reference_name'"
          :key="index"
          :rules="{
            required: true,
            message: '参考书不能为空',
            trigger: 'blur',
          }"
        >
          <div class="reference">
            <el-input
              style="width: 760px"
              type="textarea"
              placeholder="请输入"
              v-model="domain.apply_reference_name"
            >
            </el-input>
            <el-button type="primary" @click="referenceAdd" v-if="index == 0"
              >添加</el-button
            >
            <el-button @click="referenceDel(index)" v-if="index != 0"
              >删除</el-button
            >
          </div>
        </el-form-item>

        <el-form-item label="辅导小时" prop="lesson_hours">
          <el-input

            placeholder="请输入"
            v-model="dataForm.lesson_hours"
            style="width: 264px"
          >
            <template slot="append">小时</template>
          </el-input>
        </el-form-item>

        <el-form-item label="规划分布" prop="plan_area_list">
          <div v-for="(item, index) in dataForm.plan_area_list" :key="index">
            <el-input
               :min="0"
              type="number"
              placeholder="请输入"
              v-model.number="item.area_percent"
              style="width: 264px; margin-bottom: 20px"
            >
              <template slot="prepend">
                <div
                  style="
                    width: 80px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.9);
                  "
                >
                  {{ getName(item.area_type) }}
                </div>
              </template>
              <template slot="append">%</template>
            </el-input>
          </div>
        </el-form-item>

        <el-form-item label="课程大纲" prop="plan_course_outline">
          <UEeditor
            :setDataStr="dataForm.plan_course_outline"
            @change="initDtaForm"
          />
        </el-form-item>

        <el-form-item label="其他" prop="plan_others">
          <UEeditor :setDataStr="dataForm.plan_others" @change="initDtaFormW" />
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">提交</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </el-card>
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import UEeditor from "@/components/UEeditor";
import { majorlist, subjectlist } from "@/request/api";
import { adminUrl, manageUrl } from "@/utils/helper";
export default {
  components: {
    TableTittle,
    UEeditor,
  },
  data() {
    var percentage = (rule, value, callback) => {
      let regexp = /^[0-9](\d*)$/;
      for (let i = 0; i < value.length; i++) {
        if (!regexp.test(value[i].area_percent)) {
          callback("请输入正整数");
          return;
        }
      }
      let count = 0;
      value.forEach((item) => {
        count += Number(item.area_percent);
      });
      if (count != 100) {
        console.log("count", count);
        callback("规划分布总和需要为100%");
      }
      callback();
    };
    return {
      dataForm: {
        apply_reference_list: [
          {
            apply_reference_name: "",
          },
        ],
        plan_area_list: [
          {
            area_type: 1,
            area_percent: 0,
          },
          {
            area_type: 2,
            area_percent: 0,
          },
          {
            area_type: 3,
            area_percent: 0,
          },
          {
            area_type: 4,
            area_percent: 0,
          },
          {
            area_type: 5,
            area_percent: 0,
          },
          {
            area_type: 6,
            area_percent: 0,
          },
        ],
      },
      majorOptions: [],
      academyOptions: [],
      subjectsOptions: [],
      rules: {
        apply_college_code: [
          { required: true, message: "请选择院校", trigger: "blur" },
        ],
        apply_major_code: [
          { required: true, message: "请选择专业", trigger: "blur" },
        ],
        apply_academy_code: [
          { required: true, message: "请选择学院", trigger: "blur" },
        ],
        apply_subject_codes: [
          { required: true, message: "请选择科目", trigger: "blur" },
        ],
        lesson_hours: [
          { required: true, message: "请选择辅导小时", trigger: "blur" },
          { pattern: /^[1-9](\d*)$/, message: "请输入正整数", trigger: "blur" },
        ],
        plan_area_list: [
          { validator: percentage, trigger: "blur", required: true },
        ],
      },
    };
  },
  created() {
    this.$store.dispatch("getAllColleges");
  },
  methods: {
    // 提交
    submit() {
      // this.dataForm.apply_subject_names = [];
      // this.dataForm.apply_subject_codes.forEach((item) => {
      //   this.subjectsOptions.find((its) => {
      //     if (its.subject_code == item) {
      //       this.dataForm.apply_subject_names.push(its.subject_name);
      //       return;
      //     }
      //   });
      // });
  // [0].subject_name
      this.dataForm.apply_subject_names = this.subjectsOptions.find((its) => its.subject_code == this.dataForm.apply_subject_codes).subject_name
    // console.log("DA",this.dataForm)
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          let str = manageUrl();
          this.$axios({
            url: `${str}/api/v1/gpt/gptQA`,
            method: "post", //可以省略 不写默认是get
            data: {
              ...this.dataForm,
              type: 1,
              uid: Number(localStorage.getItem("userId")),
              title:
                "" +
                this.dataForm.apply_college_name +
                this.dataForm.apply_academy_name +
                "("+this.dataForm.apply_major_code+")"+
                this.dataForm.apply_major_name + 
                "("+this.dataForm.apply_subject_codes + ")"+
                this.dataForm.apply_subject_names+
                "科目" +
                this.dataForm.lesson_hours +
                "小时的教学计划",
              // apply_subject_codes: this.dataForm.apply_subject_codes.toString(),
              // apply_subject_names: this.dataForm.apply_subject_names.toString(),
            },
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "创建成功!",
              });
              this.$router.go(-1);
            }
            // console.log(res)
          });
        }
      });
    },

    initDtaForm(e) {
      //   console.log('eee',e)
      this.dataForm.plan_course_outline = e;
    },
    initDtaFormW(e) {
      this.dataForm.plan_others = e;
    },
    getName(index) {
      switch (index) {
        case 1:
          return "知识点梳理";
        case 2:
          return "重难点讲解";
        case 3:
          return "往年真题精讲";
        case 4:
          return "命题趋势分析";
        case 5:
          return "必考点预测";
        case 6:
          return "模拟评测要求";
      }
    },
    referenceAdd() {
      this.dataForm.apply_reference_list.push({
        apply_reference_name: "",
      });
    },
    referenceDel(index) {
      this.dataForm.apply_reference_list.splice(index, 1);
      //   this.dataForm.apply_reference_list.
    },
    // 院校
    collegeChoose(e) {
      this.$set(this.dataForm, "apply_major_code", "");
      this.$set(this.dataForm, "apply_major_name", "");
      this.majorOptions = [];
      this.$set(this.dataForm, "apply_academy_name", "");
      this.$set(this.dataForm, "apply_academy_code", "");
      this.academyOptions = [];
      this.$set(this.dataForm, "apply_subject_names", "");
      this.$set(this.dataForm, "apply_subject_codes", "");
      this.subjectsOptions = [];
      this.getMajorOptions(e);

      let item = this.$store.state.collegeList.find((item) => item.code == e);
      this.$set(this.dataForm, "apply_college_name", item.name);
    },

    // 获取专业
    getMajorOptions(colleag_code) {
      let query = {
        isPaging: 0,
        college_code: colleag_code,
      };
      majorlist(query).then((res) => {
        this.majorOptions = res.data.list || [];
      });
    },
    // 选择专业
    majorChoose(e) {
      this.$set(this.dataForm, "apply_academy_name", "");
      this.$set(this.dataForm, "apply_academy_code", "");
      this.academyOptions = [];
      this.$set(this.dataForm, "apply_subject_names", []);
      this.$set(this.dataForm, "apply_subject_codes", []);
      this.subjectsOptions = [];
      this.getMajorInfo(this.dataForm.apply_college_code, e);

      let item = this.majorOptions.find((item) => item.code == e);
      this.$set(this.dataForm, "apply_major_name", item.name);

      // console.log("e",e)
    },
    // 获取学院
    getMajorInfo(college_code, major_code) {
      let str = adminUrl();
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: "get", //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((res) => {
        this.academyOptions = res.data.academys;
      });
    },
    // 选择学院
    academyChoose(e) {
      this.$set(this.dataForm, "apply_subject_names", []);
      this.$set(this.dataForm, "apply_subject_codes", []);
      this.subjectsOptions = [];
      this.getSubjects(
        this.dataForm.apply_college_code,
        e,
        this.dataForm.apply_major_code
      );

      let item = this.academyOptions.find((item) => item.academy_code == e);
      this.$set(this.dataForm, "apply_academy_name", item.academy_name);
    },

    // 获取科目
    getSubjects(college_code, academy_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        academy_code: academy_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        this.subjectsOptions = res.data.list || [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.reference {
  display: flex;
  /deep/.el-button--small,
  .el-button--small.is-round {
    height: 32px !important;
    margin-left: 5px;
  }
}
</style>