<template>
  <div class="content">
    <div class="card-main">
      <el-tag
        :key="item.id"
        v-for="item in list.values"
        :closable="item._default == 0"
        :disable-transitions="false"
        @close="handleClose(item)"
        type="success"
      >
        {{ item.value }}
      </el-tag>
      <el-button
        class="button-new-tag"
        size="small"
        @click="openDialog"
        type="primary"
        :disabled="!$buttonPermission('companySetting:edit')"
      >添加学员状态</el-button>
    </div>
    <el-dialog
      title="添加学员状态"
      :visible.sync="DialogShow"
      width="18.75%"
    >
      <el-form
        ref="dialogform"
        :rules="rules"
        :model="form"
      >
        <el-form-item
          label="请输入学员状态类型"
          prop="value"
        >
          <el-input
            v-model="form.value"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="DialogShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleInputConfirm"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  dictionary,
  createdictionaryvalue,
  deletedictionaryvalue,
} from "../../../request/api";
export default {
  data() {
    return {
      list: {},
      DialogShow: false,
      form: {},
      rules: {
        value: [
          { required: true, message: "请输入学员状态类型", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取学院状态字典
    getList() {
      let query = {
        code: "StudentStatus",
      };
      dictionary(query).then((res) => {
        this.list = res.data || {};
      });
    },
    handleClose(item) {
      this.$confirm("确定要删除此条信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            cid: localStorage.getItem("cid"),
            code: item.code,
            value: item.value,
          };
          deletedictionaryvalue(query).then((res) => {
            this.$nextTick(() => {
              this.getList();
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消删除",
          });
        });
    },
    openDialog() {
      this.DialogShow = true;
    },
    handleInputConfirm() {
      this.$refs["dialogform"].validate((valid) => {
        if (valid) {
          let query = {
            cid: localStorage.getItem("cid"),
            code: "StudentStatus",
            value: this.form.value,
          };
          createdictionaryvalue(query).then((res) => {
            this.$nextTick(() => {
              this.DialogShow = false;
              this.getList();
              this.form = {};
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 24px;
  height: 70px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-right: 24px;
  margin-top: 24px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 24px;
  float: right;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>