<template>
  <el-popover
    placement="top-end"
    width="294"
    trigger="click"
    popper-class="popperclass"
    offset="-15"
  >
    <div class="title">{{title}}</div>
    <div class="list-wrap">
      <div
        class="item"
        v-for="(item,index) in list"
        :key="index"
        @click="handleToOpen(item)"
      >
        <!--   橙色  -->
        <div
          class="point"
          style="background-color:#E6A23C;"
          v-if="[0,20,21,41].includes(item.plan_status)"
        ></div>
        <!--   蓝色  -->
        <div
          class="point"
          style="background-color:#409EFF;"
          v-if="[1,40].includes(item.plan_status)"
        ></div>
        <!--   红色  -->
        <div
          class="point"
          style="background-color:#F56C6C;"
          v-if="[22,42,5,46].includes(item.plan_status)"
        ></div>
        <!--   绿色  -->
        <div
          class="point"
          style="background-color:#67C23A;"
          v-if="[43,45].includes(item.plan_status)"
        ></div>
        <div class="topic-name">
          <span class="time">{{$moment(item.plan_start_time).format("HH:mm")}}</span>
          {{item.course_theme}}
        </div>
      </div>
    </div>
    <div
      class="more-content"
      slot="reference"
    >还有{{list.length-splice}}项...</div>
  </el-popover>
</template>

<script>

export default {

  props: {
    list: {
      type: Array,
      default: () => { return [] }
    },
    date: {
      type: Date,
      default: () => { return "" }
    },
    splice: {
      type: Number,
      default: () => { return 0 }
    }
  },
  watch: {
    date(val) {
      if (val) {
        this.title = this.$moment(val).format("DD")
      }
    }
  },
  data() {
    return {
      title: "",
    };
  },
  created() {

  },
  computed: {

  },
  methods: {
    handleToOpen(item) {
      this.$emit('submit', item)
    }
  }
};
</script>

<style lang="less" scoped>
.popperclass {
  border: solid !important;
  background-color: #fff !important;
}
.title {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
.more-content {
  cursor: pointer;
  margin-top: 8px;
  text-align: center;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 8px;
}
.more-content:hover {
  color: #909399;
  background: #dcdfe6;
}
.list-wrap {
  display: flex;

  flex-direction: column;
  margin-bottom: 16px;
  .item {
    cursor: pointer;
    background-color: #ebf5ff;
    & + .item {
      margin-top: 4px;
    }
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;

    .point {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #67c23a;
      margin-right: 10px;
    }
    .point-other {
      background: #f56c6c;
    }
    .topic-name {
      width: 100%;
      font-size: 14px;
      color: #606266;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      .time {
        color: #909399;
      }
    }
  }
}
</style>

