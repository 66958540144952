import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {
  companycourselist,
  dictionary,
  companyuserlist,
  allmajorlist,
  companysetting,
  userrolelist,
  companyaccount,
  companytaxnolist,
  usercompanys,
  userrulelist,
  userdeptlist,
  collegeList,
  companyinfo,
  queryconfigure,
  expresslist,
  teacherServicetaskMsgCount,
  normalCourselist
} from "../request/api";
import { Message } from "element-ui";
import { computePermission } from "@/utils/helper";
import { adminUrl,crmUrl,manageUrl } from "@/utils/helper.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    productOptions: [],// 产品列表
    labelOptions: [],
    sourceOptions: [],
    statusOptions: [],
    staffOptions: [],
    majorList: [],
    collegeList: [],
    roleOptions: [],
    settingInfo: {},
    // 账户信息
    AccountInfo: {},
    // 发票抬头列表
    taxnoList: [],
    companylist: [],
    UserRuleList: [],
    DeptList: [],
    companyInfo: {},
    bankInfo: {},
    expressList: [],
    servicesubjectOptions: [],
    servicetypeOptions: [],
    msgCount: {},
    cityList: [],
    seniorList: [],
    normalCourseList: [],
    staffOptionsList:[]
  },
  mutations: {
    downLoad({ state }, fileUrl) {
      if (fileUrl) {
        window.open(fileUrl)
      } else {
        Message({
          message: '空文件，请上传文件',
          type: 'error',
          duration: 2000
        })
      }
    }
  },
  actions: {
    
    // 总端员工列表
    // getStaffOptions({ state }) {
    //   let query = {
    //     isPaging: 0,
    //   };
    //   // userlist(query).then((res) => {
    //   //   state.staffOptions = res.data.data.list || [];
    //   // });
    //   let str = manageUrl();
    //   axios({
    //     url: `${str}/api/v1/user/list`,
    //     method: "get", //可以省略 不写默认是get
    //     params: query
    //   }).then((res) => {
    //     state.staffOptionsList = res.data.list || [];
    //   });
    // },
    getOptions({ state }) {
      // 获取“学员标签”options
      let query1 = {
        code: "StudentLabel",
      };
      // 获取“学员来源”options
      let query2 = {
        code: "StudentSource",
      };
      // 获取“学员状态”options
      let query4 = {
        code: "StudentStatus",
      };
      // 获取“负责人”options,机构下所有员工
      let query5 = {
        isPaging: 0
      };
      Promise.all([
        new Promise((resolve, reject) => {
          dictionary(query1).then((res) => {

            state.labelOptions = res.data.values || [];
          });
        }),
        new Promise((resolve, reject) => {
          dictionary(query2).then((res) => {
            state.sourceOptions = res.data.values || [];
          });
        }),
        new Promise((resolve, reject) => {
          dictionary(query4).then((res) => {
            state.statusOptions = res.data.values || [];
          });
        }),
        new Promise((resolve, reject) => {
          companyuserlist(query5).then((res) => {
            state.staffOptions = res.data.list || [];
          });
        }),

      ]);
    },
    getProducts({ state }) {
      //获取产品
      let query = {
        isPaging: 0,
        status: 1
      }
      companycourselist(query).then((res) => {
        state.productOptions = res.data.list || [];
      });
    },
    async getStaffList({ state }) {
      //获取员工列表
      let query = {
        isPaging: 0,
      };
      companyuserlist({ isPaging: 0 }).then((res) => {
        // console.log("r",res)
        state.staffOptions = res.data.list || [];

      });
    },
    getAllColleges({ state }) {
      //获取院校列表
      let query = {
        isPaging: 0,
      };
      collegeList(query).then((res) => {
        state.collegeList = res.data.list || [];
      });
    },
    getAllMajor({ state }) {
      //获取专业列表，不要树状
      let query = {
        isPaging: 0,
        combine: 0,
      };
      allmajorlist(query).then((res) => {
        state.majorList = res.data.list || [];
      });
    },
    getCompanysetting({ state }) {
      //获取机构信息设置
      let query = {
        cid: localStorage.getItem('cid')
      };
      companysetting(query).then((res) => {

        state.settingInfo = res.data || {};
      });
    },
    getCompanyAccount({ state }) {
      //获取机构信息(查询信息以及余额)
      let query = {
        cid: localStorage.getItem('cid')
      };
      companyaccount(query).then((res) => {
        state.AccountInfo = res.data || {};
      });
    },
    getCompanyInfo({ state }) {
      let query = {
        id: localStorage.getItem('cid')
      }
      //获取机构信息(机构查询信息)
      companyinfo(query).then((res) => {

        state.companyInfo = res.data || {};
      });
    },
    getTaxnolist({ state }) {
      //发票抬头
      let query = {
        isPaging: 0,
      };
      companytaxnolist(query).then((res) => {
        state.taxnoList = res.data.list || [];
      });
    },
    getCompanylist({ state }) {

      // 获取用户机构列表，跟用户绑定的机构列表
      let query = {
        isPaging: 0,
      };

      usercompanys(query).then((res) => {
        state.companylist = res.data.list || [];
      });
    },
    getUserRuleList({ state }) {
      // 用户权限列表
      let query = {
        isPaging: 0,
      };
      userrulelist(query).then((res) => {
        state.UserRuleList = res.data.list || [];
      });
    },
    getDeptList({ state }) {
      // 用户部门列表
      let query = {
        isPaging: 0,
      };
      userdeptlist(query).then((res) => {
        state.DeptList = res.data.list || [];
      });
    },
    getRoleList({ state }) {
      // 用户部门列表
      let query = {
        isPaging: 0,
      };
      new Promise((resolve, reject) => {
        // 获取部门角色
        userrolelist(query).then((res) => {
          state.roleOptions = res.data.list || [];
        });
      })
    },
    getBankInfo({ state }) {
      // 账户银行参数（参数配置）
      let query = {
        code: 'AccountInfo',
      };
      new Promise((resolve, reject) => {
        queryconfigure(query).then((res) => {
          state.bankInfo = res.data || [];
        });
      })
    },
    getExpressList({ state }) {
      // 快递列表
      let query = {
        isPaging: 0,
      };
      expresslist(query).then((res) => {
        state.expressList = res.data.list || [];
      });
    },
    getServiceSubjectDictionary({ state }) {
      // 字典值（服务科目）
      let query = {
        code: 'ServiceSubject',
      };
      dictionary(query).then((res) => {
        state.servicesubjectOptions = res.data.values || [];
      });
    },
    getServiceTypeDictionary({ state }) {
      // 字典值（服务类型）
      let query = {
        code: 'ServiceType',
      };
      dictionary(query).then((res) => {
        state.servicetypeOptions = res.data.values || [];
      });
    },
    getTaskMsgCount({ state }) {
      computePermission('/service/teachingandresearch') &&
        teacherServicetaskMsgCount().then((res) => {
          state.msgCount = res.data || {};
        });
    },
    // 省市区列表请求
    fetchCityList({ state }) {
      let str = adminUrl()
      axios({
        url: `${str}/api/v1/back/city/list`,
        method: 'get',//可以省略 不写默认是get
        params: { isPaging: 0 },
      }).then(res => {
        state.cityList = res.data.list
      })
    },
    // admin学长列表请求
    fetchAdminSeniorList({ state }, query = {}) {
      let str = crmUrl()
      axios({
        url: `${str}/api/v1/back/senior/list`,
        method: 'get',//可以省略 不写默认是get
        params: { pageIndex: 1, pageSize: 20, ...query },
        header: {
          'Authorization': localStorage.getItem('token'),
        },
      }).then(res => {
        // console.log("res",res)
        state.seniorList = res.data.list
      })
    },
    fetchNormalCourse({ state }) {
      // 普通课列表（产品-课程列表）
      let query = {
        isPaging: 0,
        status: 1,
      };
      new Promise((resolve, reject) => {
        normalCourselist(query).then((res) => {
          state.normalCourseList = res.data.list || [];
        });
      })
    },
  },
  modules: {
  }
})
