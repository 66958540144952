var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('el-table',{attrs:{"data":_vm.tableData,"header-cell-style":{
      textAlign: 'center',
      color: '#909399',
      fontFamily: 'PingFang SC',
      fontSize: '14px',
      fontWeight: '400',
      backgroundColor: '#F2F6FC',
    },"size":"mini","cell-style":{ textAlign: 'center', fontSize: '13px' }}},[_c('el-table-column',{attrs:{"label":"姓名"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"type":"textarea","rows":5,"placeholder":"请输入内容","disabled":_vm.disabled},on:{"input":_vm.sync},model:{value:(scope.row.name),callback:function ($$v) {_vm.$set(scope.row, "name", $$v)},expression:"scope.row.name"}})]}}])}),_c('el-table-column',{attrs:{"label":"手机号"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"type":"textarea","rows":5,"placeholder":"请输入内容","disabled":_vm.disabled},on:{"input":_vm.sync},model:{value:(scope.row.phone),callback:function ($$v) {_vm.$set(scope.row, "phone", $$v)},expression:"scope.row.phone"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }