<template>
  <div>
    <el-dialog title="驳回理由"
               :visible.sync="dialogFormVisible"
               width="400px"
               :close-on-click-modal="false"
               @close="emitClose"
               @open="open">
      <el-input type="textarea"
                v-model="resaon"
                autocomplete="off"
                style="width:300px"></el-input>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确 定</el-button>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import { adminUrl } from '@/utils/helper.js'
export default {
  data() {
    return {
      dialogFormVisible: false,
      resaon: '',
    }
  },
  props: {
    visibles: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  watch: {
    visibles(val) {
      this.dialogFormVisible = val
    },
  },
  methods: {
    emitClose() {
      this.$emit('emitClose')
    },
    open() {},
    submit() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/measureScroll/userMeasureScroll/correct`,
        method: 'post', //可以省略 不写默认是get
        data: {
          id: this.id,
          is_correct_access: 2,
          correct_refuse_reason: this.resaon,
        },
      }).then((res) => {
        // if (res.code == 200) {
        this.$message({
          message: '提交成功',
          type: 'sucess',
        })
        this.$router.push('/testPaper/testMangent')
        this.$emit('emitClose')
        this.$emit('getList')
        // }
      })
    },
  },
}
</script>
<style lang="less" scoped>
::v-deep .el-dialog__header {
  background: #ebeef5;
}
</style>