<template>
  <div class="content">
    <el-dialog
      title="服务"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="620px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="auto"
        label-position="left"
        size="small"
        ref="formRules"
        :rules="rules"
      >
   
        <el-form-item
          label="适合科目："
          prop="service_subject_ids"
        >
          <el-select
            v-model="form.service_subject_ids"
            placeholder="请选择服务科目"
            clearable
            style="width:100%;"
            multiple
          >
            <el-option
              v-for="(item) in $store.state.servicesubjectOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="服务老师："
          prop="teacher_ids"
        >
          <el-select
            v-model="form.teacher_ids"
            placeholder="请选择服务老师"
            multiple
            @change="$forceUpdate()"
            clearable
            style="width:100%;"
          >
            <el-option
              v-for="(item,index) in $store.state.staffOptions"
              :key="index"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            type="textarea"
            v-model="form.service_remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  studentBasicOne,
  confirmteacherService,
} from "../../../request/api";
export default {
  components: {

  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      labelWidth: "left",
      form: this.content,
      rules: {
        teacher_ids: [{ required: true, message: "请选择服务老师", trigger: "blur" }],
        is_service: [{ required: true, message: "请选择是否定校", trigger: "change" }],
        service_subject_ids: [{ required: true, message: "请选择合适科目", trigger: "change" }],
      },

    };
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchStudnt()
      }
    },
  },
  methods: {
    fetchStudnt() {
      studentBasicOne({ cid: localStorage.getItem("cid"), id: this.content.id }).then((res) => {
        this.form = res.data
        if (res.data.teacher_service) {
          this.$set(this.form, 'service_subject_ids', res.data.teacher_service.service_subject_ids.split(',').map(v => { return Number(v) }))
          this.$set(this.form, 'teacher_ids', res.data.teacher_service.teacher_ids.split(',').map(v => { return Number(v) }))
        }
      })
    },
    emitClose() {
      this.$emit("close");
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.form.id,
            cid: localStorage.getItem("cid"),
            is_service: 1,
            // 服务科目！！！！！
            service_subject_ids: this.form.service_subject_ids ? this.form.service_subject_ids.join(',') : "",
            teacher_ids: this.form.teacher_ids ? this.form.teacher_ids.join(',') : "",
            service_remark: this.form.service_remark
          }
          confirmteacherService(query).then((res) => {
            if (res.code == 0) {
              this.$message.success('提交成功')
              this.$emit('submit')
              this.$emit('close')
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
</style>