<template>
  <el-dialog
    title=""
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="678px"
  >
    <el-result
      icon="success"
      title="开票成功"
      v-if="content.invoice_status == 2"
    >
      <template slot="extra">
        <div class="content" >
          <span>当前发票为 {{billInfo.invoice_type==1?'纸质发票，已为您安排邮寄':'电子发票'}}</span>
          <span
            v-if="billInfo.invoice_type==1"
            >快递名称：{{ billInfo.express_name }}&nbsp;&nbsp;&nbsp;快递单号：{{
              billInfo.express_no
            }}</span >
            <span v-else>{{billInfo.invoice_online_file}}</span>
        </div>
        <!-- <div style="margin-bottom:24px">
          <iframe v-if="billInfo.invoice_type==2" :src="billInfo.invoice_online_file" frameborder="0"  style="width:500px;height:500px;"></iframe>
        </div> -->
        <el-button type="primary" size="medium" v-if="billInfo.invoice_type==1" @click="emitClose" 
          >返回</el-button
        >
         <el-button type="primary" size="medium" v-else @click="open(billInfo.invoice_online_file)" 
          >点击查看</el-button
        >
      </template>
    </el-result>
    <el-result
      title="正在处理"
      v-if="content.invoice_status == 1 || content.invoice_status == 0"
    >
      <template slot="icon">
        <el-image
          :src="require('../../../assets/images/3@2x.png')"
          class="elimg"
        ></el-image>
      </template>
      <template slot="extra">
        <div class="content">
          <span>正在处理中，稍后查看…</span>
        </div>
        <el-button type="primary" size="medium" @click="emitClose"
          >返回</el-button
        >
      </template>
    </el-result>
    <el-result
      icon="error"
      title=""
      v-if="content.invoice_status == 3 || content.audit_status == 2"
    >
      <template slot="extra">
        <div class="content">
          <span>开票申请失败</span>
          <span>拒绝理由：{{ content.audit_feedback }}</span>
        </div>
        <el-button type="primary" size="medium" @click="emitClose"
          >返回</el-button
        >
      </template>
    </el-result>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      billInfo: this.content,
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.billInfo = val;
    },
  },
  methods: {
    open(row){
      window.open(row)
    },
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;
  span {
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #909399;
    opacity: 1;
  }
}
.elimg {
  width: 53px;
  height: 53px;
}
</style>