<template>
  <div class="conte">
    <el-dialog
      title="签单审批提交"
      :visible.sync="signbillDialog"
      width="694px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-position="right"
        label-width="130px"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <div
          class="feed_back_div"
          v-if="form.service_status == 4"
        >
          <el-form-item label="驳回原因：">
            <span>{{ form.order.audit_feedback }}</span>
          </el-form-item>
        </div>
        <el-form-item
          label="真实姓名"
          prop="student_realname"
        >
          <el-input
            v-model="form.student_realname"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确定报名课程"
          prop="products"
        >
          <el-select
            v-model="form.products"
            multiple
            collapse-tags
            placeholder="请选择"
            style="width: 493px"
            @change="computedProducts"
          >
            <el-option
              v-for="item in $store.state.productOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="辅导课时"
          prop="service_hours"
        >
          <el-input
            v-model.number="form.service_hours"
            autocomplete="off"
            style="width: 493px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="意向院校"
          prop="apply_college"
        >
          <el-select
            v-model="form.apply_college"
            placeholder="请选择意向院校"
            filterable
            clearable
            @change="collegeChange"
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="意向专业"
          prop="apply_major"
        >
          <el-select
            v-model="form.apply_major"
            placeholder="请选择意向专业"
            @change="majorChange"
            clearable
            filterable
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in majorOptions"
              :key="index"
              :label="item.allnames"
              :value="{ value: item.code, label: item.name}"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="意向学院"
          prop="apply_major"
        >
          <el-select
            v-model="form.apply_academy"
            placeholder="请选择意向学院"
            clearable
            @change="academyChange"
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="{ value: item.academy_code, label: item.academy_name,directions:item.directions}"
            >
              <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="考试方向"
          prop="apply_major"
        >
          <el-select
            v-model="form.apply_direction"
            placeholder="请选择考试方向"
            clearable
            style="width: 100%;"
          >
            <el-option
              v-for="(item,index) in directionsOptions"
              :key="index"
              :label="item.direction"
              :value="{ value: item.direction_code, label: item.direction}"
            >
              <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="辅导科目"
          prop="subjects"
        >
          <el-select
            style="width:100%;"
            v-model="form.subjects"
            placeholder="请选择辅导科目"
            clearable
            multiple
            @change="$forceUpdate()"
          >
            <el-option
              v-for="(item,index) in subjectOptions"
              :key="index"
              :label="item.subject_name"
              :value="{ value: item.subject_code, label: item.subject_name}"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="付款总额（元）"
          prop="pay_amount"
        >
          <el-input
            v-model.number="form.pay_amount"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="付款日期"
          prop="pay_time"
        >
          <el-date-picker
            v-model="form.pay_time"
            type="date"
            placeholder="选择日期"
            style="width: 356px"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="付款截图">
          <el-upload
            action="http://crm-api.kaoyanbox.net/api/v1/upload/image"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-exceed="handleExceed"
            :file-list="fileList"
            :limit="1"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="付款订单号">
          <el-input
            v-model="form.trade_no"
            clearable
            style="width: 525px"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程报名协议">
          <el-upload
            class="upload-demo"
            action="http://crm-api.kaoyanbox.net/api/v1/upload/file"
            :limit="1"
            :on-success="handleSuccessFile"
            :on-exceed="handleExceed"
            ref="upload"
          >
            <el-button
              size="small"
              type="primary"
            >点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="服务备注">
          <el-input
            v-model="form.remark"
            clearable
            style="width: 525px"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img
        width="100%"
        :src="dialogImageUrl"
        alt=""
      />
    </el-dialog>
  </div>
</template>

<script>
import { academylist, majorlist, subjectlist } from "../../../request/api";
import { adminUrl } from "@/utils/helper";

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      form: this.content,
      signbillDialog: this.isVisible,
      // 预览上传图
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
      imgAlbum: [],
      academyOptions: [],
      majorOptions: [],
      subjectOptions: [],
      directionsOptions: [],//方向数组
      rules: {
        student_realname: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        products: [
          { required: true, message: "请确定报名课程", trigger: "blur" },
        ],
        pay_time: [
          { required: true, message: "请选择付款日期", trigger: "blur" },
        ],
        pay_voucher: [
          { required: true, message: "请上传付款截图凭证", trigger: "blur" },
        ],
        trade_no: [
          { required: true, message: "请填写付款订单号", trigger: "blur" },
        ],
        agreement: [
          { required: true, message: "请上传课程报名协议", trigger: "blur" },
        ],
        pay_amount: [
          { required: true, message: "请输入付款总额" },
          { type: "number", message: "付款总额必须为数字值" },
        ],
        service_hours: [
          { required: true, message: "请输入辅导课时" },
          { type: "number", message: "辅导课时必须为数字值" },
        ],
        apply_college_name: [
          { required: true, message: "请选择院校", trigger: "blur" },
        ],
        apply_academy_name: [
          { required: true, message: "请选择学院", trigger: "blur" },
        ],
        apply_major_name: [
          { required: true, message: "请选择专业", trigger: "blur" },
        ],
        subjects: [
          { required: true, message: "请选择辅导科目", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    isVisible(val) {
      this.signbillDialog = val;
      if (val) {
        this.fetchDetail()
      }
    },
    fileList(newVal, oldVal) {
      let arr = [];
      newVal.forEach((item) => {
        arr.push(item.response.data.url);
      });
      this.form.pay_voucher = arr.join(",");
    },
    /*   content(val) {
        this.form = val;
        this.form.student_realname = val.realname;
        if (val.apply_college_code) {
          this.getAcedemy(val.apply_college_code);
        }
        this.$nextTick(() => {
          this.form = val;
        });
      }, */
  },
  mounted() { },
  methods: {
    fetchDetail() {
      this.form = this.content
      console.log(this.form);
      if (this.form.apply_college_name && this.form.apply_college_code) {
        this.$set(this.form, 'apply_college', {
          label: this.form.apply_college_name,
          value: this.form.apply_college_code,
        })
        this.getMajor(this.form.apply_college_code)
      }
      if (this.form.apply_major_name && this.form.apply_major_code) {
        this.$set(this.form, 'apply_major', {
          label: this.form.apply_major_name,
          value: this.form.apply_major_code,
        })
        this.getMajorInfo(this.form.apply_college_code, this.form.apply_major_code)
      }
      if (this.form.apply_academy_name && this.form.apply_academy_code) {
        this.$set(this.form, 'apply_academy', {
          label: this.form.apply_academy_name,
          value: this.form.apply_academy_code,
        })
        this.getSubjects()
      }
      if (this.form.subjects) {
        this.$set(this.form, 'subjects', this.form.subjects.map(v => {
          return {
            value: v.subject_code,
            label: v.subject_name,
          }
        }))
      }
      if (this.form.apply_direction_code && this.form.apply_direction_name) {
        this.$set(this.form, 'apply_direction', {
          label: this.form.apply_direction_name,
          value: this.form.apply_direction_code,
        })
      }
    },
    // 图片列表删除
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 图片上传成功时放入数组
    handleSuccess(response, file, fileList) {
      this.fileList = fileList;
    },
    // 文件上传成功处理
    handleSuccessFile(response, file, fileList) {
      this.form.agreement = fileList[0].response.data.url;
    },
    // 文件超出一个
    handleExceed(files, fileList) {
      this.$notify({
        title: "提示",
        message: "只能上传一个文件",
        type: "warning",
      });
    },
    emitClose() {
      this.form = {};
      this.fileList = [];
      this.$refs["upload"].clearFiles();
      this.$emit("close");
    },
    // 提交
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          this.formateProducts();
          // 处理content.watcher，把uid提取出来并把数组换成字符串
          if (this.content.watcher) {
            let arr = [];
            this.content.watcher.forEach((item) => {
              arr.push(item.uid);
            });
            this.content.watcher = arr.join(",");
          } else {
            this.content.watcher = "";
          }
          delete this.content.creator; // 删除创建人参数，要不然报错
          // 结束
          const query = Object.assign({}, this.form, {
            apply_college_code: this.form.apply_college ? this.form.apply_college.value : "",
            apply_college_name: this.form.apply_college ? this.form.apply_college.label : "",
            apply_major_code: this.form.apply_major ? this.form.apply_major.value : "",
            apply_major_name: this.form.apply_major ? this.form.apply_major.label : "",
            apply_academy_code: this.form.apply_academy ? this.form.apply_academy.value : "",
            apply_academy_name: this.form.apply_academy ? this.form.apply_academy.label : "",
            apply_direction_code: this.form.apply_direction ? this.form.apply_direction.value : "",
            apply_direction_name: this.form.apply_direction ? this.form.apply_direction.label : "",
            subjects: this.form.subjects && this.form.subjects.length ? this.form.subjects.map(v => {
              return {
                subject_code: v.value,
                subject_name: v.label,
              }
            }) : []
          });
          query.student_id = query.id; // 把学员id换成student_id参数，要不然会创建一个学员
          query.student_qq = query.qq;
          query.student_phone = query.phone;
          query.student_wechat = query.wechat;
          this.$emit("submit", { form: query });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 因为传课程产品时要上传对象所以在这里做一些处理
    formateProducts() {
      let arr = [];
      this.form.products.forEach((item) => {
        let obj = {};
        obj = this.$store.state.productOptions.find((v) => {
          return v.id === item; //筛选出匹配数据
        });
        if (obj) {
          arr.push({
            product_id: obj.id,
            product_name: obj.name,
          });
        }
      });
      this.form.products = arr;
    },
    // 因为传科目时要上传对象所以在这里做一些处理
    formateSubjects() {
      let arr = [];
      this.form.subjects.forEach((item) => {
        let obj = {};
        obj = this.subjectsOptions.find((v) => {
          return v.subject_code === item; //筛选出匹配数据
        });
        if (obj) {
          arr.push({
            subject_code: obj.subject_code,
            subject_name: obj.subject_name,
          });
        }
      });
      this.form.subjects = arr;
    },
    collegeChange(e) {
      this.form.apply_major = {}
      this.majorOptions = []
      this.form.apply_academy = {}
      this.academyOptions = []
      this.form.subjects = []
      this.subjectOptions = []
      if (e) {
        this.getMajor(e.value);
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      };
      majorlist(query).then((response) => {

        this.majorOptions = response.data.list.map(v => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`
          }
        })
      });
    },
    majorChange(e) {
      this.form.apply_academy = {}
      this.academyOptions = []
      this.form.subjects = []
      this.subjectOptions = []
      this.form.directions = []

      if (e) {
        this.getMajorInfo(this.form.apply_college.value, e.value);
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get',//可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then(response => {
        this.academyOptions = response.data.academys
        this.directionsOptions = this.academyOptions.find(v => {
          return this.form.apply_academy.value == v.academy_code
        }).directions
      })

    },
    academyChange(e) {
      this.form.subjects = []
      this.subjectOptions = []
      this.$forceUpdate()
      if (e) {
        this.directionsOptions = e.directions
        this.getSubjects();
      }
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.form.apply_college.value,
        academy_code: this.form.apply_academy.value,
        major_code: this.form.apply_major.value,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程"
        }
        this.subjectOptions = res.data.list || [];
        this.subjectOptions.push(obj)
      });
    },
    // 计算课程课程数
    computedProducts(e) {
      let arr = [];
      let num = 0;
      e.forEach((item) => {
        let obj = {};
        obj = this.$store.state.productOptions.find((v) => {
          return v.id === item; //筛选出匹配数据
        });
        num += obj.hours;
      });
      this.form.service_hours = num;
    },
  },
};
</script>

<style lang="less" scoped>
.titlename {
  display: block;
  margin-bottom: 24px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 16px;
  color: #303133;
  opacity: 1;
}
.el-input {
  width: 356px;
}
.el-upload {
  width: 68px;
  height: 68px;
}
.feed_back_div {
  min-height: 66px;
  margin-bottom: 32px;
  border-bottom: dashed 1px #dcdfe6;
  span {
    color: #f56c6c;
  }
}
</style>