<template>
  <div class="dialog-wrap">
    <el-dialog title="学员列表"
               :close-on-click-modal="false"
               append-to-body
               :visible.sync="dialogFormVisible"
               width="980px"
               @close="emitClose">
      <div style="margin-top: 0px; padding-top: 20px">
        <el-form class="search-wrap"
                 ref="searchForm"
                 :inline="true"
                 :model="query"
                 size="small"
                 label-position="left">
          <el-form-item label="学员信息">
            <el-input v-model.trim="query.user_keyword"
                      placeholder="手机号/姓名"
                      @input="getList(true)"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="报名班次">
            <el-select v-model="query.product_ids"
                       placeholder="请选择"
                       @change="getList(true)"
                       clearable
                       multiple
                       collapse-tags>
              <el-option v-for="item in $store.state.productOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 
        <el-form-item label="课程状态">
          <el-select
            v-model="query.course_status"
            placeholder="请选择"
            @change="getList(true)"
            clearable
          >
            <el-option
              v-for="(item,index) in filters.course_status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
          <el-form-item label="课程状态">
            <el-select v-model="query.student_sync_statuses"
                       placeholder="请选择"
                       @change="getList(true)"
                       clearable
                       multiple
                       collapse-tags>
              <el-option v-for="(item, index) in filters.student_sync_statuses"
                         :key="index"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务科目">
            <el-select v-model="query.service_subject_ids"
                       placeholder="请选择"
                       @change="getList(true)"
                       clearable
                       multiple
                       collapse-tags>
              <el-option v-for="(item, index) in $store.state.servicesubjectOptions"
                         :key="index"
                         :label="item.value"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="院校">
            <el-input v-model.trim="query.college_keyword"
                      placeholder="输入院校"
                      @input="getList(true)"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="专业">
            <el-input v-model.trim="query.major_keyword"
                      placeholder="输入专业"
                      @input="getList(true)"
                      clearable></el-input>
          </el-form-item>

          <el-form-item label="咨询老师">
            <el-select v-model="query.consult_teacher_id"
                       placeholder="请选择咨询老师"
                       @change="getList(true)"
                       style="width: 100%"
                       clearable>
              <el-option v-for="(item, index) in $store.state.staffOptions"
                         :key="index"
                         :label="item.alias"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品经理">
            <el-select v-model="query.product_watchers"
                       placeholder="请选择咨询老师"
                       @change="getList(true)"
                       style="width: 100%"
                       clearable
                       multiple>
              <el-option v-for="(item, index) in $store.state.staffOptions"
                         :key="index"
                         :label="item.alias"
                         :value="item.id">
              </el-option>
            </el-select>
            <!--  <el-input
            v-model.trim="query.product_watchers"
            placeholder="输入产品经理"
            @input="getList(true)"
            clearable
          ></el-input> -->
          </el-form-item>
          <!-- <el-form-item label="报名时间">
            <el-date-picker class="datetime"
                            v-model="apply_time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="getList(true)"
                            clearable>
            </el-date-picker>
          </el-form-item>
          <el-form-item label="地址更新时间">
            <el-date-picker class="datetime"
                            v-model="receiver_update_time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="getList(true)"
                            clearable>
            </el-date-picker>
          </el-form-item> -->
          <!-- <el-form-item label="年份">
            <el-select v-model="query.apply_year"
                       placeholder="请选择考研年份"
                       clearable
                       @change="getList(true)"
                       style="width: 150px">
              <el-option label="不限"
                         value=""></el-option>
              <el-option label="2022年"
                         value="2022"></el-option>
              <el-option label="2023年"
                         value="2023"></el-option>
              <el-option label="2024年"
                         value="2024"></el-option>
              <el-option label="2025年"
                         value="2025"></el-option>
              <el-option label="2026年"
                         value="2026"></el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
      </div>
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                border
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="50"
                         fixed="left">
        </el-table-column>
        <!--         <el-table-column
          prop="id"
          label="ID"
          width="50"
          fixed="left"
        ></el-table-column> -->
        <!-- <el-table-column fixed="left"
                         label="课程安排"
                         width="200">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToService(scope.row)">服务</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToConfirm(scope.row)">定校</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToDeliver(scope.row)">资料</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToCalendar(scope.row)">课程表</el-link>
          </template>
        </el-table-column> -->
        <el-table-column label="报名时间"
                         width="100">
          <template slot-scope="scope">
            {{
              scope.row.apply_time
                ? $moment(scope.row.apply_time).format("YYYY-MM-DD")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="apply_year"
                         label="考研年份"
                         width="80">
          <template slot-scope="scope"> {{ scope.row.apply_year }}年 </template>
        </el-table-column>
        <el-table-column prop="realname"
                         label="学生姓名"
                         width="130"
                         show-overflow-tooltip></el-table-column>
        <el-table-column label="课程状态"
                         width="130"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.student_sync_status == 1"
                    type="warning">待对接</el-tag>
            <el-tag size="small"
                    v-if="scope.row.student_sync_status == 2"
                    type="warning">待分配</el-tag>
            <el-tag size="small"
                    v-if="scope.row.student_sync_status == 3">匹配中</el-tag>
            <el-tag size="small"
                    v-if="scope.row.student_sync_status == 4">上课中</el-tag>
            <el-tag size="small"
                    v-if="scope.row.student_sync_status == 5"
                    type="info">已终止</el-tag>
            <el-tag size="small"
                    v-if="scope.row.student_sync_status == 6"
                    type="danger">已结束</el-tag>
            <!--   <el-tag
              v-if="scope.row.course_status==1"
              size="mini"
              @click="handleToChange(scope.row)"
            >等待上课</el-tag>
            <el-tag
              type="success"
              size="mini"
              v-else-if="scope.row.course_status==2"
              @click="handleToChange(scope.row)"
            >正在上课</el-tag>
            <el-tag
              type="info"
              size="mini"
              v-else-if="scope.row.course_status==3"
              @click="handleToChange(scope.row)"
            >课程结束</el-tag>
            <el-tag
              type="danger"
              size="mini"
              v-else-if="scope.row.course_status==4"
              @click="handleToChange(scope.row)"
            >课程终止</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column prop="product_names"
                         label="报名班次"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.product_names || "无" }}
          </template>
        </el-table-column>
        <el-table-column label="实收金额"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.receive_amount || 0 }}元
          </template>
        </el-table-column>
        <el-table-column prop="coach_need"
                         label="基本情况"
                         width="200"
                         show-overflow-tooltip></el-table-column>
        <el-table-column label="服务科目"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.service_subject_names
                ? scope.row.service_subject_names
                : "暂无服务科目"
            }}
          </template>
        </el-table-column>
        <el-table-column label="定校情况"
                         width="300"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ collegeFormate(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="考试方向"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="
                scope.row.apply_direction_code && scope.row.apply_direction_name
              ">（{{ scope.row.apply_direction_code }}）{{
                scope.row.apply_direction_name
              }}</span>
          </template>
        </el-table-column>
        <!--        <el-table-column
          prop="refer_book"
          label="参考书目"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <el-table-column prop="remark"
                         label="其他备注"
                         width="300"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="receiver_address"
                         label="收货地址"
                         width="300"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.receiver_area + scope.row.receiver_address || "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="consult_teacher"
                         label="咨询老师"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.consult_teacher_obj
                ? scope.row.consult_teacher_obj.alias
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="watcher"
                         label="教务管理"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ product_watchersFormate(scope.row.watcher) }}
          </template>
        </el-table-column>
        <el-table-column label="产品经理"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ product_watchersFormate(scope.row.product_watchers) }}
          </template>
        </el-table-column>
        <el-table-column label="最后联系时间"
                         width="160"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.last_concat_time
                ? $moment(scope.row.last_concat_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="地址更新时间"
                         width="160"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.receiver_update_time
                ? $moment(scope.row.receiver_update_time).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间"
                         width="160"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column label="修改时间"
                         width="160"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         width="120">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToEdit(scope.row.id)">编辑</el-link>
            <el-link type="danger
            "
                     @click="handleToRemove(scope.row.id)"
                     :underline="false">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <span class="total">共{{ pageTotal }}条，共{{ sum_receive_amount }}元</span>
        <el-pagination background
                       layout="prev, pager, next, sizes, jumper"
                       :current-page="Number(query.pageIndex)"
                       :page-sizes="[10, 20, 50, 100, 500]"
                       :page-size="Number(query.pageSize)"
                       :total="Number(pageTotal)"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>
      </div>

      <div slot="footer"
           class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { studentBasiclist } from '@/request/api'
export default {
  props: {
    studentVisible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    studentVisible(val) {
      this.dialogFormVisible = val
      if (val == true) {
        this.checkList = []
      }
    },
  },
  created() {
    this.$store.dispatch('getAllColleges')
    this.$store.dispatch('getStaffList')
    this.$store.dispatch('fetchCityList')
    this.$store.dispatch('getProducts')
    this.$store.dispatch('getServiceSubjectDictionary')
    this.$store.dispatch('fetchNormalCourse')
    this.getList()
  },
  data() {
    return {
      tableData: [],
      checkList: [],
      filters: {
        course_status: [
          { label: '等待上课', value: 1 },
          { label: '正在上课', value: 2 },
          { label: '课程结束', value: 3 },
          { label: '课程终止', value: 4 },
        ],
        student_sync_statuses: [
          { label: '待对接', value: 1 },
          { label: '待分配', value: 2 },
          { label: '匹配中', value: 3 },
          { label: '上课中', value: 4 },
          { label: '已终止', value: 5 },
          { label: '已结束', value: 6 },
        ],
      },
      pageTotal: 0,
      sum_receive_amount: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem('cid'),
        basic_archive: '0',
      },
      dialogFormVisible: false,
    }
  },
  methods: {
    emitClose() {
      this.$emit('close')
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getList()
    },
    handleSelectionChange(val) {
      this.checkList = val

      console.log('val', val)
    },
    collegeFormate(row) {
      if (row.is_choose) {
        return `${row.apply_college_name},（${row.apply_major_code}）${row.apply_major_name}`
      } else {
        return '暂无定校'
      }
    },
    product_watchersFormate(value) {
      if (value && value.length) {
        let map_val = value.map((v) => {
          return v.alias
        })
        return map_val.join(',')
      } else {
        return '无'
      }
    },
    submit() {
      this.$emit('checkList', this.checkList)
      this.$emit('close')
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      if (params.product_ids) {
        params.product_ids = params.product_ids.filter((v) => v).join(',')
      }
      if (params.service_subject_ids) {
        params.service_subject_ids = params.service_subject_ids
          .filter((v) => v)
          .join(',')
      }
      if (params.student_sync_statuses) {
        params.student_sync_statuses = params.student_sync_statuses.join(',')
      }
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    getList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)
      if (params.product_ids) {
        params.product_ids = params.product_ids.join(',')
      }
      if (params.service_subject_ids) {
        params.service_subject_ids = params.service_subject_ids.join(',')
      }
      if (params.product_watchers) {
        params.product_watchers = params.product_watchers.join(',')
      }
      if (['暂未定校', '未定校', '无'].includes(params.college_keyword)) {
        params.college_keyword = 'null'
      }
      if (
        ['暂未确定', '未确定', '无'].includes(params.service_subjects_keyword)
      ) {
        params.service_subjects_keyword = 'null'
      }
      if (params.student_sync_statuses) {
        params.student_sync_statuses = params.student_sync_statuses.join(',')
      }
      studentBasiclist(params).then((res) => {
        this.tableData = res.data.list || []
        this.pageTotal = res.data.count
        this.sum_receive_amount = res.data.sum_receive_amount
      })
      this.syncQuery()
    },
  },
}
</script>
<style lang="less" scoped>
.block {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.dialog-footer {
  margin-top: 10px;
  margin-left: 30%;
}
</style>