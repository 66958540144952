<template>
  <div class="content">
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="570px"
      @close="emitClose"
    >
      <el-form
        :model="form"
        label-width="auto"
        label-position="left"
        size="small"
        ref="formRules"
        :rules="rules"
      >
        <el-form-item label="报名时间：" prop="apply_time">
          <el-date-picker
            v-model="form.apply_time"
            type="date"
            placeholder="请选择报名时间"
            clearable
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="考研年份：" prop="apply_year">
          <el-select
            v-model="form.apply_year"
            placeholder="请选择"
            style="width: 320px"
          >
            <!-- <el-option label="2022年" value="2022"></el-option>
            <el-option label="2023年" value="2023"></el-option> -->
            <el-option label="2024考研" value="2024"></el-option>
            <el-option label="2025考研" value="2025"></el-option>
            <el-option label="2026考研" value="2026"></el-option>
            <el-option label="2027考研" value="2027"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生姓名：" prop="student_realname">
          <el-input
            v-model="form.student_realname"
            autocomplete="off"
            clearable
            placeholder="请输入学生姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input
            placeholder="请输入内容"
            v-model="form.student_phone"
            class="input-with-select"
          >
            <template slot="prepend">手机号</template>
          </el-input>
          <el-input
            placeholder="请输入内容"
            v-model="form.student_wechat"
            class="input-with-select"
          >
            <template slot="prepend">微信号</template>
          </el-input>
          <el-input
            placeholder="请输入内容"
            v-model="form.student_qq"
            class="input-with-select"
          >
            <template slot="prepend">&nbsp;QQ号</template>
          </el-input>
        </el-form-item>

        <el-form-item label="报考院校：" prop="apply_college_name">
          <el-select
            v-model="form.apply_college_code"
            filterable
            placeholder="请选择"
            style="width: 100%"
            clearable
            @change="collegeChoose"
          >
            <el-option
              v-for="(item, index) in $store.state.collegeList"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考专业：" prop="apply_major_name">
          <el-select
            filterable
            placeholder="请选择"
            v-model="form.apply_major_name"
            @change="majorChoose"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in majorOptions"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
              <span>{{ `（${item.code}）${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考学院：" prop="apply_academy_name">
          <el-select
            filterable
            placeholder="请选择"
            v-model="form.apply_academy_name"
            @change="academyChoose"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in academyOptions"
              :key="index"
              :label="item.academy_name"
              :value="item.academy_code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试方向：" prop="apply_direction">
          <el-select
            v-model="form.apply_direction"
            placeholder="请选择考试方向"
            @change="$forceUpdate()"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in directionsOptions"
              :key="index"
              :label="item.direction"
              :value="{ value: item.direction_code, label: item.direction }"
            >
              <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <!--     <el-form-item
          label="报考院校："
          prop="apply_college_name"
        >
          <el-select
            v-model="form.apply_college_code"
            filterable
            placeholder="请选择"
            style="width: 429px"
            @change="collegeChange"
          >
            <el-option
              v-for="item in $store.state.collegeList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考专业："
          prop="apply_major_name"
        >
          <el-select
            v-model="form.apply_major_code"
            filterable
            placeholder="请选择"
            style="width: 429px"
            :disabled="!form.apply_college_code"
            @change="majorChange"
          >
            <el-option
              v-for="(item, index) in majorOptions"
              :key="index"
              :label="item.name"
              :value="item.code"
            >
              <span>{{ `（${item.code}）${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报考学院："
          prop="apply_academy_name"
        >
          <el-select
            v-model="form.apply_academy_code"
            filterable
            placeholder="请选择"
            style="width: 429px"
            :disabled="!form.apply_major_code"
            @change="academyChange"
          >
            <el-option
              v-for="item in academyOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="辅导科目：" prop="subjectsarr">
          <el-select
            :multiple-limit="4"
            @change="$forceUpdate()"
            v-model="form.subjectsarr"
            multiple
            placeholder="请选择"
            style="width: 429px"
            :disabled="!form.apply_major_code"
          >
            <el-option
              v-for="item in subjectsOptions"
              :key="item.id"
              :label="item.subject_name"
              :value="item.subject_code"
            >
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报名课时：" prop="service_hours">
          <el-input v-model="form.service_hours" autocomplete="off">
            <template slot="append">小时</template></el-input
          >
        </el-form-item>
        <el-form-item label="复习基础：" prop="coach_need">
          <el-input
            v-model="form.coach_need"
            autocomplete="off"
            clearable
            placeholder="请输入复习基础"
          ></el-input>
        </el-form-item>

        <el-form-item label="参考书目：">
          <el-input
            type="textarea"
            clearable
            v-model="form.refer_book"
            autocomplete="off"
            placeholder="请输入参考书目"
          ></el-input>
        </el-form-item>

        <el-form-item label="教务老师：" prop="watcher">
          <el-select
            @change="$forceUpdate()"
            v-model="form.watcher"
            multiple
            placeholder="请选择"
            style="width: 304px"
          >
            <el-option
              v-for="item in $store.state.staffOptions"
              :key="item.uid"
              :label="item.alias"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="服务备注：" prop="applremarky_year">
          <el-input
            type="textarea"
            clearable
            v-model="form.remark"
            autocomplete="off"
            placeholder="请输入服务备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary" @click="submit('formRules')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  collegeList,
  academylist,
  majorlist,
  subjectlist,
  studentOrder,
} from "../../../request/api";
import { adminUrl } from "@/utils/helper";

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      default: null,
    },
  },
  data() {
    return {
      title: "新建",
      dialogFormVisible: this.isVisible,
      labelWidth: "150px",
      form: {
        service_hours: 0,
        /* watcher: [JSON.parse(localStorage.getItem('userInfo')).id] */
      },
      collegeOptions: [],
      academyOptions: [],
      majorOptions: [],
      subjectsOptions: [],
      rules: {
        apply_year: [
          { required: true, message: "请选择考研年份", trigger: "blur" },
        ],
        student_realname: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        productsarr: [
          { required: true, message: "请选择报名课程", trigger: "blur" },
        ],
        service_hours: [
          { required: true, message: "请输入辅导课时" },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: "输入最多两位小数的数字",
          },
        ],
        apply_college_name: [
          { required: true, message: "请选择院校", trigger: "blur" },
        ],
        apply_academy_name: [
          { required: true, message: "请选择学院", trigger: "blur" },
        ],
        apply_major_name: [
          { required: true, message: "请选择专业", trigger: "blur" },
        ],
        subjectsarr: [
          { required: true, message: "请选择辅导科目", trigger: "blur" },
        ],
        apply_time: [
          { required: true, message: "请选择报名时间", trigger: "blur" },
        ],
      },
      directionsOptions: [],
    };
  },
  watch: {
    isVisible(val) {
      this.academyOptions = [];
      this.majorOptions = [];
      this.subjectsOptions = [];
      this.directionsOptions = [];
      this.dialogFormVisible = val;
      this.form = {
        service_hours: 0,
      };
      this.title = "新建";
      if (this.id) {
        this.title = "编辑";
        this.getDetail();
      }
    },
  },
  mounted() {},
  methods: {
    getDetail() {
      studentOrder({
        id: this.id,
      }).then((res) => {
        if (res.code == 0) {
          this.form = res.data;
          if (this.form.watcher) {
            this.form.watcher = this.form.watcher.map((item) => item.id);
          } else {
            this.form.watcher = [];
          }

          this.collegeChoose(this.form.apply_college_code);
          this.form.subjectsarr = this.form.subjects.map(
            (item) => item.subject_code
          );

          this.form.apply_direction = {
            label: this.form.apply_direction_name,
            value: this.form.apply_direction_code,
          };
        }
      });
    },
    emitClose() {
      this.form = {
        service_hours: 0,
      };
      this.$emit("close");
    },
    submit(rules) {
      // console.log("ll",this.form);
      // return
      if (!/^1[123456789]\d{9}$/.test(this.form.student_phone)) {
        this.$message({
          message: "请填写正确的手机号",
          type: "error",
          duration: 1000,
        });
        return false;
      }
      /*  this.formateProducts(); */
      this.formateSubjects();
      this.form.watcher = this.form.watcher.join(",") || "";
      this.$refs[rules].validate((valid) => {
        if (valid) {
          if (this.title == "新建") {
            this.$emit("submit", {
              form: Object.assign({}, this.form, {
                apply_direction_code: this.form.apply_direction
                  ? this.form.apply_direction.value
                  : "",
                apply_direction_name: this.form.apply_direction
                  ? this.form.apply_direction.label
                  : "",
              }),
            });
          } else {
            this.$emit("upSubmit", {
              form: Object.assign({}, this.form, {
                apply_direction_code: this.form.apply_direction
                  ? this.form.apply_direction.value
                  : "",
                apply_direction_name: this.form.apply_direction
                  ? this.form.apply_direction.label
                  : "",
              }),
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 因为传课程产品时要上传对象所以在这里做一些处理
    formateProducts() {
      let arr = [];
      this.form.productsarr.forEach((item) => {
        let obj = {};
        obj = this.$store.state.productOptions.find((v) => {
          return v.id === item; //筛选出匹配数据
        });
        if (obj) {
          arr.push({
            product_id: obj.id,
            product_name: obj.name,
          });
        }
      });
      this.form.products = arr;
    },
    // 因为传科目时要上传对象所以在这里做一些处理
    formateSubjects() {
      let arr = [];
      this.form.subjectsarr.forEach((item) => {
        let obj = {};
        obj = this.subjectsOptions.find((v) => {
          return v.subject_code === item; //筛选出匹配数据
        });
        if (obj) {
          arr.push({
            subject_code: obj.subject_code,
            subject_name: obj.subject_name,
          });
        }
      });
      this.form.subjects = arr;
    },
    computedProducts(e) {
      let arr = [];
      let num = 0;
      e.forEach((item) => {
        let obj = {};
        obj = this.$store.state.productOptions.find((v) => {
          return v.id === item; //筛选出匹配数据
        });
        num += obj.hours;
      });
      this.form.service_hours = num;
    },
    onInput(e) {
      // 通过正则过滤小数点后两位
      this.form.service_hours =
        this.form.service_hours.match(/^\d*(\.?\d{0,2})/g)[0];
      this.$message({
        message: "课时最多只能输入两位小数",
        type: "warning",
      });
    },
    majorChoose(e) {
      // console.log("cc",e,this.majorOptions);
      let obj = {};
      obj = this.majorOptions.find((item) => {
        return item.code === e; //筛选出匹配数据
      });
      // console.log('obj:', obj)
      this.form.apply_major_code = obj.code;
      this.form.apply_major_name = obj.name;
      this.getMajorInfo(
        this.form.apply_college_code,
        this.form.apply_major_code
      );
      this.getSubjectOptions(
        this.form.apply_college_code,
        this.form.apply_academy_code,
        this.form.apply_major_code
      );
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl();
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: "get", //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((res) => {
        this.academyOptions = res.data.academys;
        this.directionsOptions = this.academyOptions.find((v) => {
          return this.form.apply_academy_code == v.academy_code;
        }).directions;
      });
    },
    // 获取学院代码与学院名称
    academyChoose(e) {
      let obj = this.academyOptions.find((item) => {
        return item.academy_code === e; //筛选出匹配数据
      });
      this.form.apply_academy_code = obj.academy_code;
      this.form.apply_academy_name = obj.academy_name;
      this.directionsOptions = obj.directions;
      this.$set(this.form, "apply_direction", {
        label: this.directionsOptions[0].direction,
        value: this.directionsOptions[0].direction_code,
      });
    },
    getSubjectOptions(college_code, academy_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        academy_code: academy_code,
        major_code: major_code,
      };
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: "999",
          subject_name: "复试课程",
        };
        this.subjectsOptions = res.data.list || [];
        // console.log("this.subjectsOptions:", this.subjectsOptions);

        this.subjectsOptions.push(obj);
      });
    },
    // 选择院校options搜索出name（后台数据需要name和code两个参数）
    collegeChoose(e) {
      // console.log('e:', e)
      let obj = {};
      obj = this.$store.state.collegeList.find((item) => {
        return item.code === e; //筛选出匹配数据
      });
      this.form.apply_college_code = obj.code;
      this.form.apply_college_name = obj.name;
      this.getMajorOptions();
    },
    getMajorOptions() {
      let query = {
        isPaging: 0,
        college_code: this.form.apply_college_code,
      };
      majorlist(query).then((res) => {
        this.majorOptions = res.data.list || [];
        if (this.id) {
          // console.log("llll");
          this.majorChoose(this.form.apply_major_code);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.input-with-select {
  margin-bottom: 16px;
}
</style>