import { render, staticRenderFns } from "./ClassTeacherListIndex.vue?vue&type=template&id=c5edc7f2&scoped=true&"
import script from "./ClassTeacherListIndex.vue?vue&type=script&lang=js&"
export * from "./ClassTeacherListIndex.vue?vue&type=script&lang=js&"
import style0 from "./ClassTeacherListIndex.vue?vue&type=style&index=0&id=c5edc7f2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5edc7f2",
  null
  
)

export default component.exports