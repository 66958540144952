<template>
  <div>
    <el-dialog
      :title="radioList[radioIndex].name||'待完成事项'"
      :visible.sync="dialogFormVisible"
      width="1400px"
      @close="emitClose"
    >
        <div class="card-header">
          <!--    我发起的筛选 -->
          <el-form
            ref="searchForm"
            :inline="true"
            size="mini"
            label-position="left"
            v-if="type=='0'"
          >
            <el-form-item label="优先级">
              <el-select
                v-model="query.priority_level"
                placeholder="请选择"
                @change="formateType(type, true)"
                clearable
              >
                <el-option
                  label="I"
                  :value="1"
                >
                </el-option>
                <el-option
                  label="II"
                  :value="2"
                >
                </el-option>
                <el-option
                  label="III"
                  :value="3"
                >
                </el-option>
                <el-option
                  label="IV"
                  :value="4"
                >
                </el-option>
                <el-option
                  label="V"
                  :value="5"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="截止时间">
              <el-date-picker
                class="date"
                v-model="stop_time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="stop_timeChange"
                clearable
                style="width:350px;"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="任务状态">
              <el-select
                v-model="query.status"
                placeholder="请选择"
                @change="formateType(type, true)"
                clearable
              >
                <el-option
                  v-for="(item,index) in filter.status"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!--    @我的的筛选 -->
          <el-form
            ref="searchForm"
            :inline="true"
            size="mini"
            label-position="left"
            v-if="type=='1'"
          >
            <el-form-item label="记录时间">
              <el-date-picker
                class="date"
                v-model="createDatetime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="createDatetimeChange"
                clearable
                style="width:350px;"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="学员信息">
              <el-input
                v-model.trim="query.student_keyword"
                placeholder="学员姓名/学员手机号"
                @input="formateType(type, true)"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="消息状态">
              <el-select
                v-model="query.is_ait_read"
                placeholder="请选择"
                @change="formateType(type, true)"
                clearable
              >
                <el-option
                  label="已读"
                  :value="1"
                >
                </el-option>
                <el-option
                  label="未读"
                  :value="0"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!--    我发起的的筛选 -->
          <el-form
            ref="searchForm"
            :inline="true"
            size="mini"
            label-position="left"
            v-if="type=='2'"
          >
            <el-form-item label="优先级">
              <el-select
                v-model="query.priority_level"
                placeholder="请选择"
                @change="formateType(type, true)"
                clearable
              >
                <el-option
                  label="I"
                  :value="1"
                >
                </el-option>
                <el-option
                  label="II"
                  :value="2"
                >
                </el-option>
                <el-option
                  label="III"
                  :value="3"
                >
                </el-option>
                <el-option
                  label="IV"
                  :value="4"
                >
                </el-option>
                <el-option
                  label="V"
                  :value="5"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="截止时间">
              <el-date-picker
                class="date"
                v-model="stop_time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="stop_timeChange"
                clearable
                style="width:350px;"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <!--     <el-radio-group
            @change="radioChange"
            v-model="type"
            style="float:right;"
            size="mini"
          >
            <el-radio-button label="0">待完成事项</el-radio-button>
            <el-radio-button label="1">@我的</el-radio-button>
            <el-radio-button label="2">我发起的</el-radio-button>
          </el-radio-group> -->
          <div class="radio-group">
            <div
              class="radio-item"
              v-for="(item,index) in radioList"
              :key="index"
              :class="{'active':radioIndex==index}"
              @click="radioChange(index)"
            >
              {{item.name}}
              <div
                class="count"
                v-if="index==0&&$store.state.msgCount.todo_unread_count>0"
              >{{$store.state.msgCount.todo_unread_count}}</div>
              <div
                class="count"
                v-if="index==1&&$store.state.msgCount.ait_unread_count>0"
              >{{$store.state.msgCount.ait_unread_count}}</div>

            </div>
          </div>
        </div>
        <div class="">
          <ServiceTaskListTable
            v-if="['0','2'].includes(type)"
            :tableData="tableData"
            @submit="formateType(type, false)"
            :type="type"
          />
          <LogListTable
            :tableData="tableData"
            @submit="formateType(type, false)"
            v-else
          />
          <div class="block">
            <div style="float:left;">
              <el-button
                type="primary"
                v-if="type=='2'"
                @click="createDialog=true"
              >新 建</el-button>
            </div>

            <el-pagination
              background
              layout="total, prev, pager, next, sizes, jumper"
              :current-page="Number(query.pageIndex)"
              :page-sizes="[10, 20, 50, 100, 500]"
              :page-size="Number(query.pageSize)"
              :total="Number(pageTotal)"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
    </el-dialog>
    <AddTask
      :isVisible="createDialog"
      @close="createDialog=false"
      @submit="formateType(type, false)"
    />
  </div>
</template>

<script>



import { teacherServiceloglist, teacherServicetasklist } from "@/request/api";
import ServiceTaskListTable from "./ServiceTaskListTable.vue";
import LogListTable from "./LogListTable.vue";
import AddTask from "./AddTask.vue";

export default {
  components: {
    ServiceTaskListTable,
    LogListTable,
    AddTask
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      },
      type: "0",
      fetchUrl: teacherServicetasklist,
      user_id: "",
      tableData: [],
      pageTotal: 0,
      createDatetime: "",
      createDialog: false,
      stop_time: "",
      filter: {
        status: [
          { label: "进行中", value: 1 },
          { label: "已取消", value: 2 },
          { label: "已完成", value: 3 },
          { label: "已逾期", value: 4 },
        ],
      },
      radioList: [
        {
          name: "待完成事项",
        },
        {
          name: "@我的",
        }, {
          name: "我发起的",
        },
      ],
      radioIndex: 0,
      title: "待完成事项"
    };
  },
  mounted() {
    this.user_id = JSON.parse(localStorage.getItem("companyUser")).id

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.formateType(this.type, true)
        this.$store.dispatch('getServiceSubjectDictionary')
        this.$store.dispatch('getServiceTypeDictionary')
      }
    },
    watch: {

    },
  },
  methods: {
    emitClose() {
      this.$store.dispatch('getTaskMsgCount')
      this.$emit("close");
    },
    radioChange(e) {
      /*      if (e == 0) {
             this.title = "待完成事项"
           } else if (e == 1) {
             this.title = "@我的"
           } else if (e == 2) {
             this.title = "我发起的"
           } */
      this.radioIndex = e
      this.type = e + ''
      this.query = {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
      }
      this.createDatetime = ""
      this.stop_time = ""
      this.formateType(this.type, true)
    },
    formateType(e, isReset) {
      if (['0', '2'].includes(e)) {
        this.fetchUrl = teacherServicetasklist
        if (e == '0') {
          this.fetchList(isReset, { do_teacher_id: this.user_id })
        } else if (e == '2') {
          this.fetchList(isReset, { publish_teacher_id: this.user_id })
        }
      } else {
        this.fetchUrl = teacherServiceloglist
        this.fetchList(isReset, { ait_user_ids: this.user_id })
      }
    },
    fetchList(isReset = false, query) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query, {
        ...query
      })
      this.fetchUrl(params).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count
      });
    },

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.formateType(this.type, false);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.formateType(this.type, false);
    },
    createDatetimeChange(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf();
        this.query.end_create_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
      }
      this.formateType(this.type, true);
    },
    stop_timeChange(value) {
      if (value) {
        this.query.start_stop_time = this.$moment(value[0]).valueOf();
        this.query.end_stop_time = this.$moment(value[1]).valueOf();
      } else {
        this.query.start_stop_time = "";
        this.query.end_stop_time = "";
      }
      this.formateType(this.type, true);
    }
  },
};
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;

  .search-wrap {
    width: 100%;
  }
}
.block {
  margin: 16px 0px;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.el-dialog__body {
  padding-bottom: 100px;
  padding-top: 16px;
}
.radio-group {
  display: flex;
  border-radius: 4px;
  margin-bottom: 32px;
  transition: 0.5s;
  align-items: center;
  .radio-item {
    padding: 7px 10px;
    font-size: 13px;
    opacity: 1;
    border: 1px solid #dcdfe6;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
  }
  .active {
    transition: 0.5s;
    border: none;
    background: #409eff;
    border: 1px solid #409eff;
    color: #fff;
  }
  .count {
    width: 14px;
    height: 14px;
    background: red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    margin-left: 8px;
    font-size: 10px;
  }
}
</style>