<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>招生</el-breadcrumb-item>
        <el-breadcrumb-item>签单审批</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName"></TableTittle>
        <el-form :inline="true" label-width="70px" size="small">
          <el-form-item label="签单老师">
            <el-select
              v-model="query.salesman"
              placeholder="请选择"
              clearable
              @change="getList(query)"
            >
              <el-option
                v-for="item in $store.state.staffOptions"
                :key="item.id"
                :label="item.alias"
                :value="item.uid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="提交日期">
            <el-date-picker
              v-model="create_time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              @change="datachange"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item style="float: right">
            <el-radio-group
              v-model="query.audit_status"
              @change="getList(query)"
            >
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button :label="1">已通过</el-radio-button>
              <el-radio-button :label="0">待审核</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
        :cell-style="{ textAlign: 'center', fontSize: '13px' }"
        border
      >
        <el-table-column type="index" label="ID" width="80"> </el-table-column>
        <el-table-column prop="salesman" label="签单老师">
          <template slot-scope="scope" v-if="scope.row.creator">
            <span>{{ scope.row.creator.alias }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="student_realname" label="学员姓名">
        </el-table-column>
        <el-table-column
          prop="products"
          label="报名课程"
          width="290"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.products | formate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_amount" label="付款总额">
          <template slot-scope="scope">
            <span v-if="scope.row.pay_amount">{{
              scope.row.pay_amount + "元"
            }}</span>
            <span v-else>0元</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_time" label="付款日期" width="120">
          <template slot-scope="scope">
            {{ scope.row.pay_time | dateFormat("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column prop="date" label="付款截图" width="100">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="openViewer(scope.row.pay_voucher)"
              >查看</el-link
            >
          </template></el-table-column
        ><el-table-column prop="order_no" label="订单号" width="180">
        </el-table-column
        ><el-table-column prop="date" label="报名协议" width="100">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="download(scope.row.agreement)"
              >查看</el-link
            >
          </template> </el-table-column
        ><el-table-column
          prop="remark"
          label="服务备注"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column
        ><el-table-column prop="create_time" label="提交日期" width="120">
          <template slot-scope="scope">
            {{ scope.row.create_time | dateFormat("YYYY-MM-DD") }}
          </template> </el-table-column
        ><el-table-column prop="date" label="操作">
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link
                type="primary"
                :underline="false"
                @click="auditSuccess(scope.row)"
                v-show="scope.row.audit_status == 0"
                >通过</el-link
              >
              <el-link
                type="danger"
                :underline="false"
                @click="opendialogVisible(scope.row)"
                v-show="scope.row.audit_status == 0"
                >拒绝</el-link
              >
              <el-link
                type="success"
                :underline="false"
                v-show="scope.row.audit_status == 1"
                >已同意</el-link
              >
              <el-link
                type="danger"
                :underline="false"
                v-show="scope.row.audit_status == 2"
                >已拒绝</el-link
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page="Number(query.pageIndex)"
            :page-sizes="[10, 20, 50, 100, 500]"
            :page-size="Number(query.pageSize)"
            :total="Number(pageTotal)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog title="拒绝理由" :visible="dialogVisible" width="500px">
      <el-form :model="form" ref="dialogForm" :rules="rules">
        <el-form-item prop="audit_feedback">
          <el-input type="textarea" v-model="form.audit_feedback"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('dialogForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      :url-list="[tableImg]"
      :on-close="closeViewer"
    />
  </div>
</template>

<script>
import TableTittle from "../../../components/TableTittle/TableTittle.vue";
import {
  studentorderlist,
  studentorderauditdone, // 审核成功
  studentorderauditfail, // 审核失败
} from "../../../request/api";
// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: { TableTittle, ElImageViewer },
  data() {
    return {
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem("cid"),
        audit_status: 0,
        salesman: "",
      },
      create_time: "",
      tableData: [],
      // 预览图片数组
      showViewer: false,
      tableImg: [],
      srcUrl: require("../../../assets/images/128@2x.png"),
      TableName: "审批列表",
      form: {},
      dialogVisible: false, //拒绝弹窗
      rowInfo: {},
      rules: {
        audit_feedback: [
          { required: true, message: "请输入拒绝理由", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getList(this.query);
    this.$store.dispatch("getStaffList");
    let obj = {
      name: "机构机构",
      bank_name: "机构机构",
      bank_num: "机构机构",
      bank_address: "机构机构",
    };
    console.log(JSON.stringify(obj));
  },
  filters: {
    formate: function (val) {
      if (val == null || val == 1) {
        return "";
      }
      var str = "";
      val.forEach((item) => {
        str += item.product_name + ",";
      });
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1));
      } else {
        return str;
      }
    },
  },
  methods: {
    datachange() {
      // 时间区间处理
      if (this.create_time) {
        this.query.start_create_time = this.create_time[0];
        this.query.end_create_time = this.create_time[1] + 86399000;
      } else {
        this.query.start_create_time = "";
        this.query.end_create_time = "";
      }
      this.getList(this.query);
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList(this.query);
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val;
      this.getList(this.query);
    },
    getList(query) {
      studentorderlist(query).then((res) => {
        this.tableData = res.data.list || [];
        this.pageTotal = res.data.count;
      });
    },
    opendialogVisible(item) {
      this.rowInfo = item;
      this.dialogVisible = true;
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            audit_feedback: this.form.audit_feedback,
            id: this.rowInfo.id,
          };
          studentorderauditfail(query).then((res) => {
            this.$nextTick(() => {
              this.dialogVisible = false;
              this.getList(this.query);
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    download(url) {
      this.$store.commit("downLoad", url);
    },
    openViewer(url) {
      this.tableImg = url;
      this.showViewer = true;
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    auditSuccess(item) {
      this.$confirm("此次申请是否通过", item.name, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let query = {
            id: item.id,
          };
          studentorderauditdone(query).then((res) => {
            this.$nextTick(() => {
              this.dialogVisible = false;
              this.getList(this.query);
            });
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "提示",
            message: "已取消修改",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 35px;
}
.card-header {
  display: flex;
  justify-content: space-between;
}
.screenshotimg {
  width: 22px;
  height: 22px;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}
.templateLink {
  .el-link {
    margin: 0px 5px;
  }
}
</style>