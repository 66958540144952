<template>
  <el-dialog
    title="申请开票"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    width="678px"
  >
    <span class="tips">开票金额 </span>
    <div class="count-content">
      <span>￥{{ billInfo.invoice_amount }}</span>
    </div>
    <el-form
      label-width="120px"
      label-position="right"
      ref="formRules"
      :rules="rules"
      :model="form"
    >
      <el-form-item label="选择抬头" prop="company_tax_no">
        <el-select
          v-model="form.company_tax_no"
          placeholder="请选择"
          style="width: 500px"
          @change="taxnoListChange"
        >
          <el-option
            v-for="item in $store.state.taxnoList"
            :key="item.id"
            :label="item.company_name"
            :value="item.company_tax_no"
          >
            <span style="float: left"
              >抬头：{{ item.company_name }}&nbsp;&nbsp;&nbsp;&nbsp;税号：{{
                item.company_tax_no
              }}</span
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收件人姓名" prop="receiver_name">
        <el-input
          v-model="form.receiver_name"
          clearable
          style="width: 500px"
          placeholder="请输入收件人姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="收件人手机号" prop="receiver_phone">
        <el-input
          v-model="form.receiver_phone"
          clearable
          style="width: 500px"
          placeholder="请输入收件人手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮寄地址" prop="address">
        <el-cascader
          :props="defaultProps"
          :options="region"
          v-model="regionmodel"
          style="width: 500px"
        ></el-cascader>
        <el-input
          v-model="form.address"
          clearable
          style="width: 500px; margin-top: 16px"
          placeholder="详细地址"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="emitClose">取 消</el-button>
      <el-button type="primary" @click="submit('formRules')"
        >立即申请</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      billInfo: this.content,
      tags: "",
      form: {},
      //省市区数据
      region: regionData,
      regionmodel: [],
      // cascader绑定属性
      defaultProps: {
        label: "label",
        value: "label",
        children: "children",
      },
      nickname: "",
      rules: {
        company_tax_no: [
          { required: true, message: "请选择发票抬头", trigger: "blur" },
        ],
        receiver_name: [
          { required: true, message: "请输入邮寄人姓名", trigger: "blur" },
        ],
        receiver_phone: [
          { required: true, message: "请输入邮寄人手机号", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入邮寄人地址", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
    },
    content(val) {
      this.billInfo = val;
      console.log(val);
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    taxnoListChange(e) {
      this.form.company_tax_no = e;
      let obj = {};
      obj = this.$store.state.taxnoList.find((item) => {
        return item.company_tax_no === e; //筛选出匹配数据
      });
      this.form.company_name = obj.company_name;
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let arr = this.regionmodel;
          let str = arr[0] + arr[1] + arr[2];
          this.form.receiver_address = str + this.form.address;
          this.form = {
            ...this.billInfo,
            ...this.form,
          };
          this.$emit("submit", { form: this.form });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  width: 128px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #303133;
  opacity: 1;
}
.count-content {
  height: 55px;
  text-align: center;
  margin-bottom: 36px;
  span {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 55px;
    color: #e6a23c;
    opacity: 1;
  }
}
.taxrate-span {
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 37px;
  color: #409eff;
  opacity: 1;
}
</style>