<template>
  <div class="content">
    <el-table
      :data="tableData"
      :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
      size="mini"
      :cell-style="{ textAlign: 'center', fontSize: '13px' }"
    >
      <el-table-column
        label="姓名"
        width="120"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.receiver_name"
            placeholder="请输入内容"
            @input="sync"
            :disabled="disabled"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="手机号"
        width="150"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.receiver_phone"
            placeholder="请输入内容"
            @input="sync"
            :disabled="disabled"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="收货地址">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.receiver_address"
            placeholder="请输入内容"
            @input="sync"
            :disabled="disabled"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        label=""
        width="80"
      >
        <template slot-scope="scope">
          <img
            src="@/assets/images/close_icon.png"
            alt=""
            srcset=""
            style="width:16px;height:16px;"
            @click="handleToRemove(scope.$index)"
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="btn-wrap">
      <el-button
        size="small"
        @click="addUser"
        class="small-btn"
      >增加用户</el-button>
      <div class="upload-demo">
        <el-upload
          :action="imgUploadUrl"
          multiple
          accept=".xlsx,.xls"
          :before-upload="uploadBefore"
        >
          <el-button
            slot="trigger"
            size="small"
            type="primary"
          >导入用户</el-button>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script>
import { imgUploadUrl, fileUploadUrl } from "@/request/http";
import { read, utils } from 'xlsx'

export default {
  components: {

  },
  props: {
    content: {
      type: Array,
      default: () => {
        return [];
      },
    },
    single: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: this.content,
      imgUploadUrl: imgUploadUrl(),

    };
  },

  created() {

  },
  watch: {
    content(val) {
      this.tableData = val
    }
  },
  computed: {

  },
  methods: {
    addUser() {
      if (this.single) {
        if (this.tableData.length >= 1) {
          this.$message.error('已添加到上限')
          return
        }
      }
      this.tableData.push({
        receiver_name: "",
        receiver_phone: "",
        receiver_address: "",
      })
    },
    handleToRemove(index) {
      this.tableData.splice(index, 1)
    },
    sync() {
      this.$emit('submit', this.tableData)
    },
    uploadBefore(file, fileList) {
      let files = { 0: file }
      this.readExcel1(files);
    },
    // 表格导入
    readExcel1(files) {
      var that = this;
      console.log(files);
      // 此处判断不严谨，思路只是根据传入的参数校验数据的完整性，可根据自己需求修改
      // 如果没有文件名
      if (files.length <= 0) {
        return;
      }
      if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式');
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = read(data, {
            type: 'binary'
          });
          // 取第一张表
          const wsname = workbook.SheetNames[0];
          // 生成json表格内容
          const ws = utils.sheet_to_json(workbook.Sheets[wsname]);
          console.log(ws);
          let arr = ws.map(v => {
            return {
              receiver_name: v['姓名'] || v['收货人'],
              receiver_phone: v['手机号'] || v['联系方式'] || v['收件号码'],
              receiver_address: v['收货地址'] || v['联系地址'] || v['收件地址'] || v['地址'],
            }
          })
          this.tableData = this.tableData.concat(arr)
          this.sync()
          // 后续为自己对ws数据的处理
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },

  }
};
</script>

<style lang="less" scoped>
.content {
  padding: 16px 0px;
  border-bottom: 1px solid #ebeef5;
  .btn-wrap {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.upload-demo {
  width: 80px;
  overflow: hidden;
  height: 38px;
  margin-left: 16px;
}
.small-btn {
  width: 80px;
  height: 35px;
}
</style>
