import moment from 'moment'

// 权限判断
export function computePermission(url) {
  const permissions = JSON.parse(localStorage.getItem('permissions'))
  if (!permissions || !permissions.length) {
    return false
  }
  return !!permissions.find(v => typeof url === 'string' ? url == v.url : url.test(v.url))
}

// 按钮权限判断
export function buttonPermission(code) {
  const permissions = JSON.parse(localStorage.getItem('permissions'))
  if (!permissions || !permissions.length) {
    return false
  }
  return !!permissions.find(v => typeof code === 'string' ? code == v.code : code.test(v.code))
}

// 本地和线上地址判断
export function hostUrl() {
  const product = 'https://crm-api.yanshizi.com'
  const daily = 'http://crm-api.kaoyanbox.net'
  if (location) {
    if (location.host.includes('crm.yanshizi.com')) {
      return product
    } else if (location.host.includes('crm.kaoyanbox.net')) {
      if (location.host.includes('test')) {
        return daily
      }
      return daily
    } else {
      return daily
    }
  }
}


// 服务状态判断
export function formateServiceStatus(val) {
  let obj = {
    "0": "无",
    "1": "待定校",
    "2": "待服务",
    "3": "正在对接",
    "4": "正在匹配",
    "5": "匹配成功",
    "6": "匹配失败",
    "7": "开始上课",
    "8": "正在上课",
    "9": "课程结束",
    "10": "课程终止",
  }
  return obj[val] || ''
}

// 服务状态判断(代找订单)
export function formateServiceStatusDaiZhao(val) {
  let obj = {
    "0": "无",
    "1": "待推送",
    "2": "正在对接",
    "3": "正在匹配",
    "4": "匹配成功",
    "5": "订单完成",
    "6": "订单取消",
  }
  return obj[val] || ''
}

// 支付状态判断
export function formatePaytag(val) {
  let obj = {
    "0": "未收",
    "1": "已收",
    "2": "已退款",
  }
  return obj[val] || ''
}





// 连接老系统对象格式化
export function formateObj(item, type) {
  const data = {
    "id": item.old_id || null,
    "newId": item.id,
    "company": localStorage.getItem('companyname'),
    "status": formateServiceStatus(item.service_status),
    "year": Number(item.apply_year),
    "studentName": item.student_realname || '',
    "studentMobile": item.student_phone || '',
    "studentWechat": item.student_wechat || '',
    "studentQq": item.student_qq || '',
    "college": item.apply_college_name || '',
    "department": `(${item.apply_academy_code})${item.apply_academy_name}`,
    "specialty": `(${item.apply_major_code})${item.apply_major_name}`,
    "subjects": item.subjects.map((v) => { return `(${v.subject_code})${v.subject_name}` }),
    "hours": item.service_hours || '',
    "others": item.remark || '',
    "createdAt": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  }
  if (type == 'create') {
    delete data.id
  }
  return data
}


// 连接老系统对象格式化（代找订单）
export function formateObjDaiZhao(item, type) {
  const data = {
    "id": item.old_id || null,
    "newId": item.id,
    "company": localStorage.getItem('companyname'),
    "status": formateServiceStatusDaiZhao(item.status),
    "price": item.order_amount,
    "find_hours": 72,
    "paytag": formatePaytag(item.payment_status),
    "college": item.apply_college_name || '',
    "department": `(${item.apply_academy_code})${item.apply_academy_name}`,
    "specialty": `(${item.apply_major_code})${item.apply_major_name}`,
    "subjects": item.subjects.map((v) => { return `(${v.subject_code})${v.subject_name}` }),
    "others": item.remark || '',
    "createdAt": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    "remark": item.order_no,
  }
  if (type == 'create') {
    delete data.id
  }
  return data
}


export function crmUrl() {
  // 环境判断
  if (location) {
    if (location.host.includes('crm.yanshizi.com')) {
      return `${window.location.protocol}//api.kaoyanbox.com`
    } else {
      // return `${window.location.protocol}//api.kaoyanbox.com`
      return `${window.location.protocol}//test-api.kaoyanbox.net`
      /*   return "https://api.kaoyanbox.com" */
    }
  }
}

export function adminUrl() {
  // 环境判断
  if (location) {
    if (location.host.includes('crm.yanshizi.com')) {
      return `${window.location.protocol}//api.kaoyanbox.com`
    } else {
      return `${window.location.protocol}//test-api.kaoyanbox.net`
      /*   return "https://api.kaoyanbox.com" */
    }
  }
}

export function manageUrl() {
  // 环境判断
  if (location) {
    if (location.host.includes('crm.yanshizi.com')) {
      return `${window.location.protocol}//manage-api.yanshizi.com`
    } else {
      // return `${window.location.protocol}//manage-api.yanshizi.com`
      return `${window.location.protocol}//manage-api.kaoyanbox.net`

    }
  }
}






