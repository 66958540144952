<template>
  <div class="dialog-wrap">
    <el-dialog title="商品选择"
               :close-on-click-modal="false"
               :visible.sync="dialogVisible"
               @close="handleClose"
               :width="dialogWidth">
      <div class="content-wrap">
        <div class="left">
          <el-tabs v-model="tabKey"
                   tab-position="top"
                   style="height: 100%"
                   @tab-click="handleTabChange">
            <el-tab-pane label="在线课程"
                         name="course"></el-tab-pane>
            <el-tab-pane label="研盒题库"
                         name="exam"></el-tab-pane>
            <el-tab-pane label="阅读资料"
                         name="read"></el-tab-pane>
            <el-tab-pane label="实体资料"
                         name="material"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="right">
          <el-form class="search-wrap"
                   ref="searchForm"
                   :inline="true"
                   :model="query">
            <el-form-item label="商品ID"
                          prop="id">
              <el-input v-model="query.id"
                        placeholder="请输入商品ID"></el-input>
            </el-form-item>
            <el-form-item label="商品名称"
                          prop="name">
              <el-input v-model="query.name"
                        placeholder="请输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label="分类"
                          v-if="categorys.length">
              <el-select v-model="query.category_id"
                         size="mini"
                         placeholder="请选择">
                <el-option v-for="item in categorys"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态"
                          prop="status">
              <el-select style="width: 100px"
                         v-model="query.status"
                         size="mini"
                         placeholder="请选择">
                <el-option v-for="item in filter.status"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"
                         size="mini"
                         @click="handleSearch">搜索</el-button>
              <el-button class="submit"
                         size="mini"
                         @click="handleFormReset('searchForm')">重置</el-button>
            </el-form-item>
          </el-form>
          <el-table class="result"
                    ref="multipleTable"
                    :data="results"
                    tooltip-effect="dark"
                    size="mini"
                    @selection-change="handleSelectionChange">>
            <el-table-column type="selection"
                             width="55"
                             fixed
                             v-if="type == 'multiple'"
                             :selectable="selectEnable"></el-table-column>
            <el-table-column prop="id"
                             label="ID"
                             width="60"
                             align="center">
            </el-table-column>
            <el-table-column prop="name"
                             label="名称"
                             min-width="160"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.name || scope.row.title }}
              </template>
            </el-table-column>
            <el-table-column label="缩略图"
                             min-width="160"
                             align="center">
              <template slot-scope="scope">
                <img :src="scope.row.picture_url"
                     style="height: 50px"
                     alt="" />
              </template>
            </el-table-column>
            <el-table-column label="价格"
                             prop="price"
                             min-width="120"
                             align="center">
            </el-table-column>
            <el-table-column label="参考价格"
                             prop="market_price"
                             min-width="120"
                             align="center">
            </el-table-column>
            <el-table-column label="购买人数"
                             prop="user_count"
                             min-width="120"
                             align="center">
            </el-table-column>
            <el-table-column label="创建时间"
                             min-width="160"
                             align="center">
              <template slot-scope="scope">
                <span>{{
                  $moment(scope.row.create_time).format("YYYY.MM.DD")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="状态"
                             min-width="120"
                             align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.status ? "已发布" : "未发布" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             min-width="110"
                             align="center"
                             fixed="right"
                             v-if="type == 'single'">
              <template slot-scope="scope">
                <el-button type="primary"
                           size="mini"
                           @click="handleOneSubmit(scope.row)"
                           v-if="scope.row.activity == null && scope.row.joint == null">选择</el-button>
                <div v-else>
                  <span v-if="scope.row.activity != null">已参加拼团活动</span>
                  <span v-else-if="scope.row.joint != null">已参加联报活动</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="footer">
        <el-pagination background
                       small
                       layout="total, prev, pager, next, jumper"
                       :current-page="Number(query.pageIndex)"
                       :page-sizes="[10, 20, 50, 100, 500]"
                       :page-size="Number(query.pageSize)"
                       :total="Number(pageTotal)"
                       @size-change="handleSizeChange"
                       @current-change="handlePageChange"></el-pagination>
      </div>
      <div slot="footer"
           class="dialog-footer"
           v-if="type == 'multiple'">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary"
                   @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { courseList, courselistCount, courseCategoryList } from "@/api/course";
// import { examList, examcategorylist } from "@/api/exam";
// import { readList, readcategorylist } from "@/api/read";
// import { materialList, materialcategorylist } from "@/api/material";
import { adminUrl } from '@/utils/helper.js'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    goods: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'multiple', // single
    },
  },
  data() {
    return {
      dialogVisible: this.visible,
      query: {
        id: '',
        name: '',
        status: '',
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        is_all: 1,
      },
      tabKey: 'course',
      pageTotal: 10,
      filter: {
        status: [
          { label: '全部', value: '' },
          { label: '已发布', value: '1' },
          { label: '未发布', value: '0' },
        ],
      },
      results: [],
      selections: [],
      countSelections: [],
      dialogWidth: document.body.clientWidth < 820 ? '95%' : '980px',
      categorys: [],
    }
  },
  watch: {
    visible(val) {
      this.dialogVisible = val
      if (val) {
        this.fetchGoodList()
        this.fetchCategorylist()
      }
    },
    goods(val) {
      this.countSelections = val
    },
  },
  methods: {
    selectEnable(row, rowIndex) {
      if (this.selections.length >= 5) {
        return false
      } else {
        return true // 不禁用
      }
    },
    fetchCategorylist() {
      let str = adminUrl()
      if (this.tabKey == 'course') {
        this.$axios({
          url: `${str}/api/v1/course/category/list`,
          method: 'get', //可以省略 不写默认是get
          params: { isPaging: 0, status: 1 },
        }).then((res) => {
          this.categorys = res.data.list
        })
        // courseCategoryList({ isPaging: 0, status: 1 }).then((res) => {
        //   this.categorys = res.list;
        // });
      }
      if (this.tabKey == 'exam') {
        this.$axios({
          url: `${str}/api/v1/examItem/category/list`,
          method: 'get', //可以省略 不写默认是get
          params: { isPaging: 0, status: 1 },
        }).then((res) => {
          this.categorys = res.data.list
        })
        // examcategorylist({ isPaging: 0, status: 1 }).then((res) => {
        //   this.categorys = res.list;
        // });
      }
      if (this.tabKey == 'read') {
        this.$axios({
          url: `${str}/api/v1/read/category/list`,
          method: 'get', //可以省略 不写默认是get
          params: { isPaging: 0, status: 1 },
        }).then((res) => {
          this.categorys = res.data.list
        })
        // readcategorylist({ isPaging: 0, status: 1 }).then((res) => {
        //   this.categorys = res.list;
        // });
      }
      if (this.tabKey == 'material') {
        this.$axios({
          url: `${str}/api/v1/material/category/list`,
          method: 'get', //可以省略 不写默认是get
          params: { isPaging: 0, status: 1 },
        }).then((res) => {
          this.categorys = res.data.list
        })
        // materialcategorylist({ isPaging: 0, status: 1 }).then((res) => {
        //   this.categorys = res.list;
        // });
      }
    },
    queryResize() {
      this.query = {
        id: '',
        name: '',
        status: '',
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      }
    },
    fetchGoodList() {
      let str = adminUrl()
      if (this.tabKey == 'course') {
        this.$axios({
          url: `${str}/api/v1/back/course/list`,
          method: 'get', //可以省略 不写默认是get
          params: this.query,
        }).then((res) => {
          this.results = res.data.list
          this.$axios({
            url: `${str}/api/v1/back/course/listCount`,
            method: 'get', //可以省略 不写默认是get
            params: this.query,
          }).then((res) => {
            this.pageTotal = res.data.count[0].total
          })
          this.$nextTick(this.setRowSelection)
        })
        // courseList(this.query).then(res => {
        //   this.results = res.list
        //   courselistCount(this.query).then(res => {
        //     this.pageTotal = res.count[0].total;
        //   })
        //   this.$nextTick(this.setRowSelection)
        // })
      }
      if (this.tabKey == 'exam') {
        this.$axios({
          url: `${str}/api/v1/exam/list`,
          method: 'get', //可以省略 不写默认是get
          params: this.query,
        }).then((res) => {
          this.results = res.data.list
          this.pageTotal = res.data.count

          this.$nextTick(this.setRowSelection)
        })
        // examList(this.query).then((res) => {
        //   this.results = res.list;
        //   this.pageTotal = res.count;

        //   this.$nextTick(this.setRowSelection);
        // });
      }
      if (this.tabKey == 'read') {
        this.$axios({
          url: `${str}/api/v1/read/list`,
          method: 'get', //可以省略 不写默认是get
          params: this.query,
        }).then((res) => {
          this.results = res.data.list
          this.pageTotal = res.data.count
          this.$nextTick(this.setRowSelection)
        })

        // readList(this.query).then((res) => {
        //   this.results = res.list;
        //   this.pageTotal = res.count;

        //   this.$nextTick(this.setRowSelection);
        // });
      }
      if (this.tabKey == 'material') {
        this.$axios({
          url: `${str}/api/v1/material/list`,
          method: 'get', //可以省略 不写默认是get
          params: this.query,
        }).then((res) => {
          this.results = res.data.list
          this.pageTotal = res.data.count

          this.$nextTick(this.setRowSelection)
        })
        // materialList(this.query).then((res) => {
        //   this.results = res.list;
        //   this.pageTotal = res.count;

        //   this.$nextTick(this.setRowSelection);
        // });
      }
    },
    // 设置选中行
    setRowSelection() {
      if (this.results.length) {
        this.results.forEach((row) => {
          if (this.goods.find((item) => item.id === row.id)) {
            this.$refs.multipleTable.toggleRowSelection(row, true)
          }
        })
      }
    },
    // tab切换
    handleTabChange() {
      this.queryResize()
      this.fetchGoodList()
      this.fetchCategorylist()
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, 'pageIndex', 1)
      this.fetchGoodList()
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields()
      this.fetchGoodList()
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, 'pageIndex', val)
      this.fetchGoodList()
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchGoodList()
    },
    // 部分内容渲染
    handleRender(value, type) {
      let status = {
        1: '正常',
        0: '禁用',
      }
      if (type == 'status') {
        return status[value] || ''
      }
      return ''
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val
      this.diffSelection()
    },
    // 多选确定
    handleSubmit() {
      this.$emit('submit', { goods: this.countSelections })
      this.handleClose()
    },
    // 单选确定
    handleOneSubmit(val) {
      val.name = val.name || val.title
      this.$emit('submit', val)
      this.handleClose()
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        id: '',
        name: '',
        status: '',
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      }
      this.$emit('close')
    },
    // 选中项差异化计算
    diffSelection() {
      let diffs = []
      // 减
      this.countSelections.forEach((item, index) => {
        if (!this.selections.find((v) => v.id === item.id)) {
          if (this.results.find((v2) => v2.id === item.id)) {
            diffs.push(item)
          }
        }
      })
      if (diffs.length) {
        this.countSelections = this.countSelections.filter(
          (item) => !diffs.find((v) => v.id === item.id)
        )
      }
      // 加
      this.selections.forEach((item) => {
        if (!this.countSelections.find((v) => v.id === item.id)) {
          this.countSelections.push(item)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.dialog-wrap {
  /deep/ .el-dialog__body {
    padding-top: 20px;
  }

  .content-wrap {
    display: flex;
    width: 100%;
    flex-direction: column;

    .right {
      flex: 1;
      overflow: auto;
    }
  }
  .search-wrap {
    margin-bottom: -8px;

    .el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
      margin-bottom: 10px;
    }
  }
  .result {
    margin-top: 10px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>
